import { Component, Input, OnInit, Inject } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ExportToCsv } from 'export-to-csv';
// import * as jsPDF from 'jspdf';
// import 'jspdf-autotable';
// declare var jsPDF: any;
declare let jsPDF;

@Component({
	selector: 'app-exporter',
	templateUrl: './exporter.component.html',
	styleUrls: ['./exporter.component.scss']
})
export class Exporter implements OnInit {

	@Input('dataType') dataType;
	@Input('dataList') dataList;
	@Input('columns') columns;
	@Input('relatedColumns') relatedColumns;
	@Input('arrayOfArrays') arrayOfArrays;
	@Input('filename') filename;

	constructor(public activeModal?: NgbActiveModal) { }

	ngOnInit(): void { }

	// Because Angular has difficulty serialising Map
	saveMaterialTableDataToCSV() {
		console.log("Exporting");
		const headerDefs = [];
		const headers = [];
		const rows = [];
		for (const column of this.columns) {
			if (!(column.isCheckbox === true || column.isImage === true || column.isButtons === true || column.isAgentLink === true)) {
				headerDefs.push(column.columnDef);
				headers.push(column.header);
			}
		}

		for (const data of this.dataList) {
			let jsonStr = '{';
			// const row = [];
			for (let columnHeader of headerDefs) {
				if (columnHeader === 'agent_id') {
					columnHeader = 'agentId';
				} else if (columnHeader === 'lastaccessed') {
					columnHeader = 'lastAccessed';
				}
				let value = '';

				console.log(columnHeader);
				const splitByConcat: any[] = columnHeader.split("+");

				if (splitByConcat.length === 1) {

					const splitByPoint = columnHeader.split(".");
					if (splitByPoint.length === 2) {
						if (data[splitByPoint[0]] !== undefined && data[splitByPoint[0]][splitByPoint[1]] !== undefined) {
							value = data[splitByPoint[0]][splitByPoint[1]] + '';
						}
					} else {
						if (data[columnHeader] !== undefined) {
							value = data[columnHeader] + '';
						}
					}

				} else {

					splitByConcat.forEach(element => {
						const splitByPoint = element.split(".");
						if (splitByPoint.length === 2) {

							if (data[splitByPoint[0]] !== undefined && data[splitByPoint[0]][splitByPoint[1]] !== undefined) {
								value += data[splitByPoint[0]][splitByPoint[1]] + " ";
							}
						}
					});
				}

				value = value.trim();

				if (value === '') {
					jsonStr = jsonStr + '\n' + "\"" + columnHeader + "\"" + ':' + "\"\"" + ',';
				} else {
					jsonStr = jsonStr + '\n' + "\"" + columnHeader + "\"" + ':' + "\"" + value + "\"" + ',';
				}
			}
			// remove tailing coma left by last statement
			jsonStr = jsonStr.slice(0, -1);
			// close the json string
			jsonStr = jsonStr + '\n}';
			// create a new object and throw it into the rows
			rows.push(JSON.parse(jsonStr));
		}
		console.log(JSON.stringify(rows));
		let date: Date = new Date();
		let generatedFilename = this.filename ? this.filename : (this.dataType + "_" + date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + "_" + date.getHours() + '' + date.getMinutes() + '' + date.getSeconds());
		const options = {
			filename: generatedFilename,
			fieldSeparator: ',',
			quoteStrings: '"',
			decimalSeparator: '.',
			showLabels: true,
			showTitle: false,
			title: generatedFilename,
			useTextFile: false,
			useBom: true,
			headers: headers
		};
		const csvExporter = new ExportToCsv(options);
		if (rows.length > 0) {
			csvExporter.generateCsv(rows);
		} else {
			csvExporter.generateCsv(['']);	//This creates an empty line, its the only way I could (quickly) get it to work as [] failes.
		}
		this.close();
	}

	saveMaterialTableDataToPDF() {
		console.log("Exporting");
		const headerDefs = [];
		const headers = [];
		const rows = [];
		const doc = new jsPDF('l');
		for (let column of this.columns) {
			if (!(column.isCheckbox == true || column.isImage == true || column.isButtons == true || column.isAgentLink == true)) {
				headerDefs.push(column.columnDef);
				headers.push(column.header);
			}
		}
		for (let data of this.dataList) {
			/* Someone renamed agentId to agent_id and lastAccessed to lastaccessed: Because of PAT, I don't have the time find out why. Will get back to this */
			const row = [];
			for (let columnHeader of headerDefs) {
				if (columnHeader === 'agent_id') {
					columnHeader = 'agentId'
				} else if (columnHeader === 'lastaccessed') {
					columnHeader = 'lastAccessed'
				}
				if (!(columnHeader in data)) {
					row.push("");
				}
				if (data[columnHeader] != undefined) {
					row.push(data[columnHeader]);
				}
			}
			rows.push(row);
		}
		// doc.autoTable(headers, rows, {theme: 'grid'});
		doc.autoTable(headers, rows, { theme: 'grid' });
		let date: Date = new Date();
		doc.save(this.dataType + "_" + date.getFullYear() + (date.getMonth() + 1) + date.getDate() + "_" + date.getHours() + date.getMinutes() + date.getSeconds() + ".pdf");
		this.close();
	}

    /**
     * NFG-248
     *
     * Writes an array of arrays to a PDF file that the user can then save to disk.
     * The arrays in the array of arrays have to have a length of 2 for this to work
     *
     * see the tricket cited above for details
     */
	createTwoColumnPDF() {
		const headers = [];
		const doc = new jsPDF();
		headers.push("Key"); headers.push("Value");
		doc.autoTable(headers, this.arrayOfArrays, { theme: 'grid' });
		let date: Date = new Date();
		doc.save(this.dataType + "_" + date.getFullYear() + date.getMonth() + date.getDate() + "_" + date.getHours() + date.getMinutes() + date.getSeconds() + ".pdf");
	}

	close() {
		setTimeout(() => {
			this.activeModal.dismiss();
		});
	}
}
