import { Observable } from 'rxjs';
import { DataResponse, DynamicLink } from '../../../../dto/dtos';
import { FormData } from '../form-data';
import { Injectable } from '@angular/core';
import { CustomerService } from '../../../../services/customers/customer.service';
import { SectionUtils } from '../../../../../environments/environment';
import { Section } from '../../../../utils/constants';
import { DynamicFieldManager } from '../../../../settings/dynamic-fields/dynamic-field-manager';

/**
 * Overrides getFormData by fetching agent data from the server for the form
 */
export class CustomerByAxonIdFormData extends FormData {
    constructor(
        private _section: Section, 
        private _cardId: number, 
        private customerService: CustomerService, 
		private dynamicFieldManager: DynamicFieldManager,
        private _identifierNum: number) {
        super(_section, _cardId, dynamicFieldManager, customerService, _identifierNum);
    }

    getFormData(): Observable<DataResponse> {
        return this.customerService.getCustomerDynamicDataByAxonId(SectionUtils.getSectionId(this.section), this.identifierNum, this.cardId);
    }

    saveFormData(axonId: number, dynamicLink: DynamicLink): Observable<DataResponse> {
        return this.customerService.saveFormData(this.section, axonId, dynamicLink);
    }

    getCardId() {
        return this.cardId;
    }
}

