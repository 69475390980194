import { ReportDto, ReportQueryResultDto } from './../../dto/dtos';
import { Injectable } from '@angular/core';
import { AbstractHttpService } from './http-abstract-service';
import { DataRequest, DataResponse, ReportCategoryDto } from '../../dto/dtos';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Notifier } from '../../utils/notifier';

@Injectable({
	providedIn: 'root'
})
export class HttpReportService extends AbstractHttpService {

	constructor(httpClient: HttpClient, authService: AuthService, notifier: Notifier) {
		super(httpClient, authService, '/reports', notifier);
	}

	getCategories(): Observable<ReportCategoryDto[]> {
		return super.createGet('/categories');
	}
	getReport(id: number): Observable<ReportDto> {
		return super.createGet(`/${id}`);
	}
	getReports(): Observable<ReportDto[]> {
		return super.createGet('/list');
	}
	getParameterOptions(parameterId: number): Observable<ReportQueryResultDto> {
		return super.createGet(`/parameter/options/${parameterId}`);
	}
	executeQuery(reportId: number, parameters: {id: number, value: string}[]): Observable<ReportQueryResultDto> {
		return super.createPost(parameters, `/query/${reportId}`);
	}
}
