import { Component, Inject, Input, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatTabChangeEvent, MatTab } from '@angular/material';
import { ModalContainerComponent } from '../../component/modal-container/modal-container.component';
import { AgentService } from '../../services/agents/agent.service';
import { ServerError } from '../../utils/server-errors';
import { Agent, DataResponse, AxonModel, RoleRule } from '../../dto/dtos';
import { Storage } from '../../utils/storage';
import { Notifier } from '../../utils/notifier';
import { AxonComponent } from '../../axon.component';
import { MaterialTableData } from '../../component/material-table/material-table-data';
import { AgentDetailsComponent } from './agent-details/agent-details.component';
import { AgentPermissionsComponent } from './agent-permissions/agent-permissions.component';
import { AgentLocationsComponent } from './agent-locations/agent-locations.component';
import { AgentAllocateSIMsComponent } from './agent-allocate-sims/agent-allocate-sims.component';
import { ComponentType } from '@angular/cdk/portal';
import { AxonUtils } from '../../utils/axon-utils';
import { AuthService } from '../../services/auth/auth.service';
import { NO_PERMISSION_FOR_ACTION, Permission as PermissionEnum, ROLERULES } from '../../utils/constants';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AgentActionModalComponent } from './agent-action/agent-action-modal/agent-action-modal.component';
import { AgentSingularActionModalComponent } from './agent-singular-action/agent-singular-action-modal/agent-singular-action-modal.component';
import { DynamicFieldManager } from '../../settings/dynamic-fields/dynamic-field-manager';
import { AgentApprovalHistoryComponent } from './agent-approval-history/agent-approval-history.component';
import { environment } from '../../../environments/environment';
import { AgentAuditTrailComponent } from './agent-audit-trail/agent-audit-trail.component';

export enum TAB_CONFIG_LABEL {
	PERSONAL_DETAILS = 'Agent Details',
	APPROVAL_HISTORY = 'Approval History',
	LOCATIONS = 'Locations',
	AUDIT_TRAIL = 'Audit Trail',
	PERMISSIONS = 'Permissions'
}

@Component({
	selector: 'app-agent-modal',
	templateUrl: './agent-modal.component.html',
	styleUrls: ['./agent-modal.component.scss']
})
export class AgentModalComponent extends ModalContainerComponent implements AfterViewInit {

	@Input() checked: Boolean;
	@ViewChild(AgentDetailsComponent) private agentsDetailComponent: AgentDetailsComponent;
	@ViewChild("permissions") permissions: AgentPermissionsComponent;
	@ViewChild("locations") locations: AgentLocationsComponent;
    @ViewChild("allocatesims") allocateSims: AgentAllocateSIMsComponent;
    
    @ViewChild(AgentApprovalHistoryComponent)  private agentApprovalHistoryComponent: AgentApprovalHistoryComponent;
    @ViewChild(AgentAuditTrailComponent)  private agentAuditTrailComponent: AgentAuditTrailComponent;

    env = environment;
    tabConfigLabels = TAB_CONFIG_LABEL;

    selectedTabLabel: string = TAB_CONFIG_LABEL.PERSONAL_DETAILS;

	public parentComponent: AxonComponent;
	agent: Agent;
	isLoadingResults = true;
	instance: AgentModalComponent;

	disable: string;
	block: string;
	disableResetButton: boolean;
  disableBlockButton: boolean;
  disableDisableButton: boolean;

	showFooter: boolean;

	constructor(
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public matData: MaterialTableData,
    private modalService: NgbModal,
    private dynamicFieldMgr: DynamicFieldManager,
		private notifier: Notifier,
		private agentService: AgentService,
		public storage: Storage,
		private authService: AuthService) {

		super(dialog, storage);

		if (matData.data) {
			const agentId: number = matData.data.agentId;
			this.parentComponent = matData.parent;

			if (agentId) {
				console.log('Received agent with ID: ' + agentId + '. Fetching full agent details...');
				this.getUser(agentId);
			}
		}

		this.instance = this;
	}

	ngAfterViewInit() {
		// this.buttonState();
	}

	/**
	 * Check agent 'status' and 'accountStatus' to set the button accordingly
	 **/
	buttonState() {

		// Block button state
		if (this.agent.status === 'blocked') {
			this.block = 'Unblock';
			console.log('Agent status is: [ ' + this.agent.status + ' ]. Showing [ Unblock ]');
		} else {
			this.block = 'Block';
			console.log('Agent status is: [ ' + this.agent.status + ' ]. Showing [ Block ]');
		}

		// Disabled button state
		if (this.agent.accountStatus === 'disabled') {
			this.disable = 'Enable';
			this.disableBlockButton = true;
			console.log('Agent status is: [ ' + this.agent.status + ' ]. Showing [ Enable ]');
		} else {
			this.disable = 'Disable';
			this.disableBlockButton = false;
			console.log('Agent status is: [ ' + this.agent.status + ' ]. Showing [ Disable ]');
		}

		// Reset button state
		if (this.agent.status === 'new') {
			this.disableResetButton = true;
			console.log('Reset button is: [ ' + this.disableResetButton + ' ]. Reset button [ disabled ]');
		} else if (this.agent.accountStatus === 'disabled') {
			this.disableResetButton = true;
			console.log('Reset button is: [ ' + this.disableResetButton + ' ]. Reset button [ disabled ]');
		} else {
			this.disableResetButton = false;
			console.log('Reset button is: [ ' + this.disableResetButton + ' ]. Reset button [ enabled ]');
    }

    this.disableBlockButton = !AxonUtils.checkRoleRule(ROLERULES.NO_USER_BLOCK, this.agent.roles, this.dynamicFieldMgr);
    console.log('disableBlockButton = ' + this.disableBlockButton);
    this.disableDisableButton = !AxonUtils.checkRoleRule(ROLERULES.NO_USER_DISABLE, this.agent.roles, this.dynamicFieldMgr);
    console.log('disableDisableButton = ' + this.disableDisableButton);
    this.disableResetButton = !AxonUtils.checkRoleRule(ROLERULES.NO_USER_RESET, this.agent.roles, this.dynamicFieldMgr);
    console.log('disableResetButton = ' + this.disableResetButton);
  }

	/**
	* Returns this Component as a Component type - required for opening modals
	*/
	getType() {
		return AgentModalComponent;
	}

	/**
	* Fetches full agent data from the server
	* @param agentId
	*  @param callback an optional callback method (remember to bind callee's "this" object to the the callee)
	*/
	public getUser(agentId: number, callback?) {
		console.log("agent-modal - getUser( " + agentId + " )");
		this.agentService.getAgent(agentId).subscribe(resp => {
			console.log('Agent fetch successful ? [' + resp.success + ']');
			if (resp.success) {
				console.log('Agent data: ', resp.data);
				this.agent = resp.data;
				this.buttonState();
				try {
					if (callback !== undefined) {
						setTimeout(() => { callback(); }, 1200);
					}
				} catch (exception) { }
			} else {
				ServerError.printError(resp);
			}
			this.isLoadingResults = false;
        },
        err => {
            console.log("Error fetching agent from server: " + JSON.stringify(err));
        });
	}

	async blockOrUnblock() {
		let agentList: AxonModel[] = [];
		agentList.push(this.agent);
		const hasPermission = AxonUtils.checkActionPermission(
			PermissionEnum.AGENT_ADMIN_BLOCK_RESET_UNBLOCK, this.authService, this.notifier
		);

		if (!hasPermission) {
			return;
		}

		const agentSelected = this.agent.agentId;
		console.log('Agent status: [ ' + this.agent.status + ' ]');

		if (this.agent.status === 'blocked') {

			console.log('unblocking agent: [ ' + agentSelected + ' ]');
			// const result: DataResponse = await this.agentService.agentUnblock(agentSelected);

			// if (result.success === true) {

			// 	console.log('Agent has been unblocked');
			// 	this.notifier.success('Agent unblocked successfully');

			// 	this.getUser(this.agent.agentId);
			// 	this.parentComponent.refresh();


			// } else {

			// 	console.log('Agent has not been unblocked: [ ' + result.error + ' ]');
			// 	this.notifier.error('Agent has not been unblocked: [ ' + result.error + ' ]');
			// }

			const options: NgbModalOptions = {
				windowClass: "modal-editform-confirmclose",
				backdropClass: "fraud-backdrop"
			};
			const modalRef = this.modalService.open(AgentSingularActionModalComponent, options);
			modalRef.componentInstance.singular = this.block;
			modalRef.componentInstance.pastTense = 'unblocked';
			// modalRef.componentInstance.path = '/unblockall';
			modalRef.componentInstance.action = 'unblock';
			modalRef.componentInstance.agent = this.agent;
			modalRef.componentInstance.agentModalComponent = this.instance;

		} else if (this.agent.status !== 'blocked') {

			// console.log('blocking agent: [ ' + agentSelected + ' ]');
			// const result: DataResponse = await this.agentService.agentBlock(agentSelected);

			// console.log(result);

			// if (result.success === true) {

			// 	console.log('Agent has been Blocked');
			// 	this.notifier.success('Agent blocked successfully');

			// 	this.getUser(this.agent.agentId);
			// 	this.parentComponent.refresh();

			// } else {

			// 	console.log('Agent has not been blocked: [ ' + result.error + ' ]');
			// 	this.notifier.error('Agent has not been blocked: [ ' + result.error + ' ]');
			// }



			// const modalRef = this.modalService.open(AgentSingularActionModalComponent, { windowClass: "modal-confirm-action" });
			// modalRef.componentInstance.singular = this.block;
			// modalRef.componentInstance.pastTense = 'blocked';
			// modalRef.componentInstance.path = '/blockall';
			// modalRef.componentInstance.agentList = agentList;
			// modalRef.componentInstance.child = this.child;


			const options: NgbModalOptions = {
				windowClass: "modal-editform-confirmclose",
				backdropClass: "fraud-backdrop"
			};
			const modalRef = this.modalService.open(AgentSingularActionModalComponent, options);
			modalRef.componentInstance.singular = this.block;
			modalRef.componentInstance.pastTense = 'blocked';
			// modalRef.componentInstance.path = '/blockall';
			modalRef.componentInstance.action = 'block';
			modalRef.componentInstance.agent = this.agent;
			modalRef.componentInstance.agentModalComponent = this.instance;


		}
	}

	async enableOrDisable() {

		const hasPermission = AxonUtils.checkActionPermission(PermissionEnum.AGENT_ADMIN_ENABLE_DISABLE, this.authService, this.notifier);

		if (!hasPermission) {
			return;
		}

		const agentSelected = this.agent.agentId;
		console.log('Agent account status: [ ' + this.agent.accountStatus + ' ]');

		if (this.agent.accountStatus === 'disabled') {

			// console.log('enabling agent: [ ' + agentSelected + ' ]');
			// const result: DataResponse = await this.agentService.agentEnable(agentSelected);

			// if (result.success === true) {

			// 	console.log('Agent has been enabled');
			// 	this.notifier.success('Agent enabled successfully');

			// 	this.getUser(this.agent.agentId);
			// 	this.parentComponent.refresh();

			// } else {

			// 	console.log('Agent has not been enabled: [ ' + result.error + ' ]');
			// 	this.notifier.error('Agent has not been enabled: [ ' + result.error + ' ]');
			// }

			const options: NgbModalOptions = {
				windowClass: "modal-editform-confirmclose",
				backdropClass: "fraud-backdrop"
			};
			const modalRef = this.modalService.open(AgentSingularActionModalComponent, options);
			modalRef.componentInstance.singular = this.disable;
			modalRef.componentInstance.pastTense = 'enable';
			modalRef.componentInstance.action = 'enable';
			modalRef.componentInstance.agent = this.agent;
			modalRef.componentInstance.agentModalComponent = this.instance;

		} else if (this.agent.accountStatus === 'enabled') {

			// console.log('disabling agent: [ ' + agentSelected + ' ]');
			// const result: DataResponse = await this.agentService.agentDisable(agentSelected);

			// if (result.success === true) {

			// 	console.log('Agent has been disabled');
			// 	this.notifier.success('Agent disabled successfully');

			// 	this.getUser(this.agent.agentId);
			// 	this.parentComponent.refresh();

			// } else {
			// 	console.log('Agent has not been disabled: [ ' + result.error + ' ]');
			// 	this.notifier.error('Agent has not been disabled: [ ' + result.error + ' ]');
			// }

			const options: NgbModalOptions = {
				windowClass: "modal-editform-confirmclose",
				backdropClass: "fraud-backdrop"
			};
			const modalRef = this.modalService.open(AgentSingularActionModalComponent, options);
			modalRef.componentInstance.singular = this.disable;
			modalRef.componentInstance.pastTense = 'disable';
			modalRef.componentInstance.action = 'disable';
			modalRef.componentInstance.agent = this.agent;
			modalRef.componentInstance.agentModalComponent = this.instance;
		}
	}

	async agentReset() {

		const hasPermission = AxonUtils.checkActionPermission(
			PermissionEnum.AGENT_ADMIN_BLOCK_RESET_UNBLOCK, this.authService, this.notifier
		);

		if (!hasPermission) {
			return;
		}

		const agentSelected = this.agent.agentId;
		console.log('resetting agent: [ ' + agentSelected + ' ]');
		const result: DataResponse = await this.agentService.agentReset(agentSelected);

		if (result.success === true) {

			console.log('Agent has been reset');
			this.notifier.success('Agent reset successfully');

			this.getUser(this.agent.agentId);
			this.parentComponent.refresh();

		} else {

			console.log('Agent has not been reset: [ ' + result.error + ' ]');
			this.notifier.error('Agent has not been reset: [ ' + result.error + ' ]');
		}
	}

	saveSuccessful() {
		console.log('Received notification that form data was saved successfully. Reloading data from server...');
		this.getUser(this.agent.agentId);
	}

	tabChanged(tabChangeEvent: MatTabChangeEvent) {
        let tab: MatTab;
        tab = tabChangeEvent.tab;
        
		super.tabChanged(tabChangeEvent);
        this.selectedTabLabel = tabChangeEvent.tab.textLabel;
        this.tabChangeAction();
	}

	public changeTab(tabLabel: string) {
        this.selectedTabLabel = tabLabel;
		this.tabChangeAction();
	}

	private tabChangeAction() {

        this.showFooter = false;
        
		if (this.selectedTabLabel === TAB_CONFIG_LABEL.PERMISSIONS) {
			this.permissions.setDatesForRightList();
			this.permissions.saveSuccessful();
        } else  if ( this.selectedTabLabel === TAB_CONFIG_LABEL.APPROVAL_HISTORY ) {
            this.agentApprovalHistoryComponent.loadData();
        } else  if ( this.selectedTabLabel === TAB_CONFIG_LABEL.AUDIT_TRAIL  ) {
            this.agentAuditTrailComponent.loadData();
        }
	}

	public getAgent() {
		return this.agent;
	}

	public updatePermissions() {
		console.log('agent-modal - updatePermissions()');
		this.permissions.refresh();
	}

	// public save() {
	//     if (this.selectedTab === TAB_CONFIG.ALLOCATE_SIMS) {
	//         this.allocateSims.save();
	//     }
	// }

	// public cancel() {
	//     if (this.selectedTab === TAB_CONFIG.ALLOCATE_SIMS) {
	//         this.allocateSims.cancel();
	//     }
	// }
}
