import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { GroupLists, DynamicField } from '../../dto/dtos';
import { GroupListsManager } from '../../settings/grouplists/group-lists-manager';

export const CONFIRM = "confirm";
export const CANCEL = "cancel";

@Component({
    selector: 'app-rejection-modal-confirm',
    templateUrl: './rejection-modal-confirm.component.html',
    styleUrls: ['./rejection-modal-confirm.component.scss']
})
export class RejectionConfirmModalComponent implements OnInit {

    @Input('confirmButton') confirmButton: string;
    @Input('cancelButton') cancelButton: string;
    @Input('title') title: string;
    @Input('message') message: string;
    @Input('additionalMessage') additionalMessage: string;
    @Input('rejectedFields') rejectedFields: Array<DynamicField>;
    @Input('label') label: string;
    @Input('error') error: string;
    @Input('groupListsId') groupListsId = 0;

    inputFormControl: FormControl;

    private grpValues: GroupLists[];

    rejectedFieldsColumns = [
		{ columnDef: 'field'          , header: 'Field' , cell: (element: DynamicField) => `${element.field}` },
        { columnDef: 'value'          , header: 'Value' , cell: (element: DynamicField) => `${element.value}` },
        { columnDef: 'rejectionReason', header: 'Reason', cell: (element: DynamicField) => `${element.rejectionReason}` },
		
	];

    constructor(public activeModal: NgbActiveModal, public groupListsMgr: GroupListsManager) {
    }

    ngOnInit() {
        this.inputFormControl = new FormControl();

        if ( this.groupListsId !== 0 ) {
            this.grpValues = this.groupListsMgr.getGroupList( this.groupListsId );
        }
    }

    confirm() {

        if (!this.inputFormControl.invalid) {
            this.activeModal.close(this.inputFormControl.value);
        }
    }

    cancel() {
        this.activeModal.close(CANCEL);
    }

}
