import { AxonComponent } from "./../../axon.component";
import { Component, OnInit } from "@angular/core";
import { MatDialog, MatTabChangeEvent, MatDialogRef } from "@angular/material";
import { Storage } from "../../utils/storage";
import { AxonUtils } from "../../utils/axon-utils";
import { ComponentType } from "@angular/cdk/portal";

export class ModalContainerComponent extends AxonComponent implements OnInit {
	constructor(public dialog: MatDialog, public storage: Storage) {
		super();

		/* 
        Save the dialog to storage - this is needed for logging out an expired session, 
        to close all modals
        */
		AxonUtils.addOpenModal(this.storage, this.dialog);
	}

	ngOnInit() {}

	onNoClick() {
		console.log("No Clicked");
		this.dialog.closeAll();
		AxonUtils.removeOpenModal(this.storage, this.dialog);
	}

	/**
	 * Each sub class must override and return itself
	 */
	getType(): ComponentType<ModalContainerComponent> {
		return ModalContainerComponent;
	}

	/**
	 * Default behaviour for Modals, where the window adjustments are
	 * made when a tab is changed. The children modals will need to
	 * add (selectedTabChange)="tabChanged($event)" to the mat-tab-group element
	 * in order to benefit from the automatic window resizing / adjustments.
	 * @param tabChangeEvent
	 */
	tabChanged(tabChangeEvent: MatTabChangeEvent) {
		this.initResizeListener();
	}
}

/**
 * Keeps track of all the modals that are open.
 */
export class AxonModal {
	private openModals: Array<MatDialog>;

	private initOpenModals() {
		if (!this.openModals) {
			this.openModals = new Array<MatDialog>();
		}
	}

	/**
	 * Adds the given modal to the local storage
	 * @param modal
	 */
	public addModal(modal: MatDialog) {
		this.initOpenModals();

		// console.log('Adding modal: ' + modal);

		this.openModals.push(modal);
	}

	/**
	 * Removes the given modal to the local storage
	 * @param modal
	 */
	public removeModal(modal: MatDialog) {
		this.initOpenModals();

		// console.log('Removing modal: ' + modal);

		const index = this.openModals.indexOf(modal);
		if (index !== -1) {
			this.openModals.splice(index, 1);
		}
	}

	/**
	 * Fetches the modals from local storage. If it does not exist, an empty array is returned.
	 */
	public getModals(): Array<MatDialog> {
		this.initOpenModals();

		// console.log('Returning modals: ' + this.openModals.length);

		return this.openModals;
	}
}
