import { Component, Input, OnInit, ElementRef, Output, EventEmitter } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';

@Component({
	selector: 'app-upload',
	templateUrl: './upload.component.html',
	styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

	@Input() public url: string;
	@Input() public maxFileSize: number;
	@Input() public allowedMimeTypes: string[];
	@Input() public manualProgressBar;
	@Input() parentComponent: any;
	@Input() imageUpload: boolean;

	@Output() onUploadSuccess = new EventEmitter();
	@Output() onUploadError = new EventEmitter();

	recordCount = 0;
	parseSuccess = false;
	uploaded = false;
	private fileName: string;

	public uploader: FileUploader;
	reader: FileReader = new FileReader();
	public hasBaseDropZoneOver = false;
	public manualProgress = 1;

	public constructor(private eleRef: ElementRef) { }

	ngOnInit(): void {
		console.log("Allowed Types:", this.allowedMimeTypes);
		this.uploader = new FileUploader({ url: this.url, maxFileSize: this.maxFileSize, allowedMimeType: this.allowedMimeTypes });

		this.reader.onload = (ev: any) => {
			if ( this.parentComponent === undefined ) {
				this.onUploadSuccess.emit({ 'result': ev.target.result, 'fileName': this.fileName });
			} else {
				console.log('Uploading file to stupid parent component:', new Date().toISOString());
				this.parentComponent.sendFile(ev.target.result, this.fileName);
				console.log('Done:', new Date().toISOString());
			}
			this.eleRef.nativeElement.querySelector('#upload').value = "";
		};

		this.uploader.onAfterAddingFile = (fileItem: any) => {
			this.recordCount = 0;
			this.parseSuccess = false;
			this.uploaded = true;

			try {
				this.fileName = fileItem.file.name;
			} catch (e) {
				this.fileName = undefined;
			}

			this.uploader.uploadItem(fileItem);
			if ( this.imageUpload ) {
				this.reader.readAsDataURL(fileItem._file);
			} else {
				this.reader.readAsText(fileItem._file);
			}
		};

		this.uploader.onWhenAddingFileFailed = (item: any, filter: any, options: any) => {

			console.log("upload.component.ts - adding file failed: ");
			console.log("error filter is:");
			console.log(filter);
			console.log("error options are:");
			console.log(options);
			console.log("item is:");
			console.log(item);

			if (filter && filter.name === 'mimeType') {
				console.log("Upload error due to invalid mime type");
				let error = "Mime type uploaded is not allowed. Please use ";
				for (let i = 0; i < this.allowedMimeTypes.length; i++) {
					error += (i < this.allowedMimeTypes.length - 2) ? this.allowedMimeTypes[i] + " or " : this.allowedMimeTypes[i];
				}
				
				if ( this.parentComponent === undefined ) {
					this.onUploadError.emit({ 'error': error });
				} else {
					this.parentComponent.sendError(error);
				}
			} else if (filter && filter.name === 'fileSize') {
				console.log("Upload error due to file size");
				let error = "File size is too large. Maximum file size upload is " + (this.maxFileSize < 1000000 ? this.maxFileSize/1024 + "Kb" : this.maxFileSize/1024/1024 + "Mb");
				if ( this.parentComponent === undefined ) {
					this.onUploadError.emit({ 'error': error });
				} else {
					this.parentComponent.sendError(error);
				}
			}
		};
	}

	public fileOverBase(e: any): void {
		this.hasBaseDropZoneOver = e;
	}

	public browse() {
		this.eleRef.nativeElement.querySelector('#upload').click();
	}

	public setRecordCount(count: number) {
		this.recordCount = count;
	}

	public setParseSuccess(success: boolean) {
		this.parseSuccess = success;
	}

	public setManualProgress(percent: number) {
		this.manualProgress = percent;
		console.log('progress: ', this.manualProgress);
	}
}
