import { Component, AfterViewInit } from '@angular/core';
// declare var $:any;
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { AxonUtils } from '../../utils/axon-utils';
import { DEFAULT_ROUTE } from '../../utils/constants';

@Component({
    selector: 'app-no-permission',
    templateUrl: './unauthorized.component.html',
    styleUrls: ['unauthorized.component.scss']
})
export class UnauthorizedComponent implements AfterViewInit {

    constructor(private router: Router) {}

    ngAfterViewInit() {
        $(function () {
            $('.preloader').fadeOut();
        });
    }

    goBack() {

        let url = AxonUtils.getPreviousUrl();
        if ( url === undefined ) {
            url = DEFAULT_ROUTE;
        }

        this.router.navigate([url]);
    }
}
