import { Component, Inject, ViewChild, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatTabChangeEvent } from '@angular/material';
import { ServerError } from '../../utils/server-errors';
import { Customer, Agent } from '../../dto/dtos';
import { CustomerService } from '../../services/customers/customer.service';
import { Storage } from '../../utils/storage';
import { AgentService } from '../../services/agents/agent.service';
import { SubmissionHistoryComponent } from './submission-history/submission-history.component';
import { RegistrationHistoryComponent } from './registration-history/registration-history.component';
import { MaterialTableData } from '../../component/material-table/material-table-data';
import { AxonComponent } from '../../axon.component';
import { Notifier } from '../../utils/notifier';
import { DownloadPdfComponent } from './download-pdf/download-pdf.component';
import { CustomerModalContainerComponent } from '../../component/modal-container/customer-modal-container.component';
import { ArchiveDataComponent } from './archive-data/archive-data.component';
import { ModalContainerComponent } from '../../component/modal-container/modal-container.component';
import { ComponentType } from 'ngx-toastr/portal/portal';
import { AxonUtils } from '../../utils/axon-utils';

export enum TAB_CONFIG {
  CUSTOMER_DETAIL = 0,
	REG_HISTORY = 1,
	SUBMISSION_HISTORY = 2,
	ARCHIVE_DATA = 3,
	PDF = 4
}

@Component({
  selector: 'app-customer-modal',
  templateUrl: './customer-modal.component.html',
  styleUrls: ['./customer-modal.component.scss']
})
export class CustomerModalComponent extends CustomerModalContainerComponent {

    customer: Customer;
    isLoadingResults = true;
    private instance: CustomerModalComponent;
	private approvalAgent: Agent;
    private parentComponent: AxonComponent;
    private savedSuccessfully: boolean;

    selectedTab = TAB_CONFIG.CUSTOMER_DETAIL;

    @ViewChild(RegistrationHistoryComponent)  private registrationHistoryComponent: RegistrationHistoryComponent;
    @ViewChild(SubmissionHistoryComponent)    private submissionHistoryComponent: SubmissionHistoryComponent;
    @ViewChild(ArchiveDataComponent)          private archiveDataComponent: ArchiveDataComponent;
    @ViewChild(DownloadPdfComponent)          private downloadPdfComponent: DownloadPdfComponent;

    constructor(
            public dialog: MatDialog,
            @Inject(MAT_DIALOG_DATA) public matData: MaterialTableData,
            private customerService: CustomerService,
            private agentService: AgentService,
            public storage: Storage,
            private notifier: Notifier) {

        super(dialog, storage);

        if ( matData.data ) {
            const axonId = matData.data.axonId;
            this.parentComponent = matData.parent;

            if ( axonId ) {
                console.log('Received customer with Axon ID: ' + axonId + ". Fetching full customer details...");
                this.getCustomer(axonId);
            }

            /* If we want to go straight to a specific tab then we can add it in */
            if ( matData['tab'] !== undefined ) {
                setTimeout( () => {
                    this.changeTab(matData['tab']);
                }, 3000);
            }
        }

        this.instance = this;
    }

    /**
     * Returns this Component as a Component type - required for opening modals
     */
    getType(): ComponentType<ModalContainerComponent> {
        return CustomerModalComponent;
    }

    /**
     * Fetches full customer data from the server
     * @param agentId
     */
    private getCustomer( axonId: number ) {
        this.isLoadingResults = true;
        this.customerService.getCustomer(axonId).subscribe(
            resp => {
                console.log('Customer fetch successful ? [' + resp.success + ']');
                if ( resp.success ) {
                    console.log('Customer data: ' + JSON.stringify(resp.data));
                    this.customer = resp.data;

                    if ( this.customer.approvalAgent && this.customer.approvalAgent.agentId > 0 ) {
                        console.log( "Fetching approval agent details" );
                        this.agentService.getApprovalAgentDetails(this.customer.approvalAgent.agentId).subscribe(

                            agentResp => {
                                if ( agentResp.success ) {
                                    this.approvalAgent = agentResp.data;
                                } else {
                                    ServerError.printError(agentResp);
                                }
                            }
                        );
                    }
                    // this.approvalAgent = {
                    //     username: '27825610599',
                    //     msisdn: '260952239239',
                    //     name: 'Jason',
                    //     surname: 'Kaplan',
                    // };

                } else {
                    ServerError.printError(resp);
                    this.onNoClick();
                    this.notifier.error(ServerError.formatError(resp));
                }
                this.isLoadingResults = false;
            },
            error => {
                this.onNoClick();
                this.notifier.error( ServerError.formatHttpError( error ) );
            }
        );
    }

    saveSuccessful() {
        console.log('Received notification that form data was saved successfully. Reloading data from server...');

        this.getCustomer( this.customer.axonId );

        this.savedSuccessfully = true;

        /* Close the modal - and re-open it */
        // this.onNoClick();

        // this.instance = new AgentModalComponent(this.dialog, this.data, this.agentService);

    }

    onNoClick() {
        super.onNoClick();

        if ( this.savedSuccessfully ) {
            this.parentComponent.saveSuccessful();
        }
    }

    changeTab(index?: number) {
        this.selectedTab = index;
    }

    tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
        super.tabChanged( tabChangeEvent );

        this.selectedTab = tabChangeEvent.index;

        /* Submission history tab */
        if ( tabChangeEvent.index === 1 ) {
            this.registrationHistoryComponent.loadData();
        } else if ( tabChangeEvent.index === 2 ) {
            this.submissionHistoryComponent.loadData();
        } else if ( tabChangeEvent.index === 3) {
            this.archiveDataComponent.loadData();
        } else if ( tabChangeEvent.index === 4) {
            this.downloadPdfComponent.loadData();
        }
    }

    getCustomerFullname(customer: Customer): string {

        return AxonUtils.getCustomerFullname(customer);
    }

    getAgentFullname(agent: Agent): string {

        return AxonUtils.getAgentFullname(agent);
    }
}
