import { Component, Input, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { FormDataDto } from '../../../dto/dtos';
import { Instruction } from '../../form/dynamic-action/dynamic-action.service';
import { Form2Component } from '../../form2/form2.component';

@Component({
    selector: 'app-dynamic-text',
    templateUrl: './dynamic-text.component.html',
    styleUrls: ['./dynamic-text.component.scss']
})
export class DynamicTextComponent implements OnInit {

	@Input() formDataDto: FormDataDto;
	@Output() onDynamicAction : EventEmitter<Instruction> = new EventEmitter();
	@Output() onSave = new EventEmitter();;

	@ViewChild("axonform") form: Form2Component;

	title: string;

	isMandatory: boolean;

	ngOnInit() {
		this.title = this.formDataDto.data.cards[0].card;
	}

	passDynamicAction(instruction: Instruction) {
		this.onDynamicAction.emit(instruction);
	}

	isFormValid(): boolean {
		return this.form.isFormValid();
	}
}