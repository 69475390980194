import { Observable } from 'rxjs';
import { DataResponse } from '../../../../dto/dtos';
import { TableData } from '../table-data';
import { FilterOption } from '../../material-table.component';
import { AgentService } from '../../../../services/agents/agent.service';

/**
 * Overrides getTableData by fetching agent approval history table data
 */
export class AgentApprovalHistoryTableData extends TableData {

    private axonId: number;

    constructor(axonId: number, private agentService: AgentService) {
        super(agentService);
        this.axonId = axonId;
    }

    getTableData(sort: string, order: string, page: number, pageSize: number, filterOptions: FilterOption[]): Observable<DataResponse> {
        return this.agentService.getAgentApprovalHistoryData(this.axonId, page, pageSize, filterOptions);
    }
}
