import { Component, OnInit, Injectable } from '@angular/core';
import { AgentModalComponent } from '../../../agents/modal/agent-modal.component';
import { AgentsTableData } from '../../../component/material-table/table-data/agents/agents-table-data';

@Injectable({
    providedIn: 'root'
})
@Component({
  selector: 'test-material-table',
  templateUrl: './material-table-test.component.html',
  styleUrls: ['./material-table-test.component.css']
})
export class MaterialTableTestComponent implements OnInit {

    columns = [
        { columnDef: 'agentId' , header: 'Agent ID', cell: (element: any) => `${element.agentId}`  },
        { columnDef: 'name'    , header: 'Name'    , cell: (element: any) => `${element.name}`     },
        { columnDef: 'surname' , header: 'Surname' , cell: (element: any) => `${element.surname}`  },
        { columnDef: 'msisdn'  , header: 'MSISDN'  , cell: (element: any) => `${element.msisdn}`   },
        { columnDef: 'username', header: 'Username', cell: (element: any) => `${element.username}` },
    ];

    constructor(private modal: AgentModalComponent, private tableData: AgentsTableData) {}

    ngOnInit() {
    }

}
