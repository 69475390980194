import { Notifier } from './../../../utils/notifier';
import { HttpReportService } from './../../../services/http/http-report.service';
import { ReportParameterDto, ReportQueryResultRowDto, ReportQueryResultDto } from './../../../dto/dtos';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-report-parameter-filter-combo',
	templateUrl: './report-parameter-filter-combo.component.html',
	styleUrls: ['./report-parameter-filter-combo.component.css']
})
export class ReportParameterFilterComboComponent implements OnInit {
	@Input() parameter: ReportParameterDto;
	@Input() parameterData: ReportQueryResultDto;

	@Output() change = new EventEmitter();
	@Input() initValue: string;
	options: Option[] = [];
	_value: string;
	loading = true;

	constructor(private service: HttpReportService, private notifier: Notifier) { }

	ngOnInit() {
		if (this.parameterData) {
			this.loading = false;
			this.options = this.parameterData.rows.map(i => {
				if (i.data.length === 1) {
					return { value: i.data[0], label: i.data[0] };
				}
				return { value: i.data[0], label: i.data[1] };
			});
			if (this.initValue) {
				this._value = this.initValue;
			}
		} else {
			this.service.getParameterOptions(this.parameter.id).subscribe(
				item => {
					if (item['success'] === false) {
						this.notifier.error("Failed to dropdown data");
					} else {
						this.options = item.rows.map(i => {
							if (i.data.length === 1) {
								return { value: i.data[0], label: i.data[0] };
							}
							return { value: i.data[0], label: i.data[1] };
						});
						if (this.initValue) {
							this._value = this.initValue;
						}
						this.loading = false;
					}
				},
				error => {
					this.notifier.error("Failed to dropdown data");
				});
		}
	}

	get name(): string {
		return this.parameter.name;
	}
	get value(): string {
		return this._value;
	}
	set value(input: string) {
		console.log('Filter changed (COMBO)', input);

		this._value = input;
		this.change.emit({ parameter: this.parameter, value: this._value });
	}

	getOptionHash(index: number, option: Option): string {
		return option.label + ':' + option.value;
	}
}
interface Option {
	value: string;
	label: string;
}
