import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { DataRequest, DataResponse, ApprovalRequest, DynamicLink, DynamicField } from '../../dto/dtos';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AxonService } from '../axon-service';
import { HttpDataService } from '../http/http-data.service';
import { AdvancedSearchCriteria } from '../../approvals/advanced-search/advanced-search.component';
import { Notifier } from '../../utils/notifier';
import { FilterOption } from '../../component/material-table/material-table.component';

@Injectable({
  providedIn: 'root'
})
/**
 * Entry point for components requiring access to approval data
 */
export class ApprovalService extends AxonService {

    private httpService: HttpDataService;

    constructor(_httpClient: HttpClient, _authService: AuthService, _notifier: Notifier) {
        super( _httpClient, _authService, _notifier );
    }

    /**
     * Fetches the next record requiring approval
     * @param approvalStatus - OPEN or PENDING
     * @param searchCriteria - Search criteria
     * @param isOverride - if true, then server will force getNextRecord to return an approved or rejected record
     */
    public getNextRecord(approvalStatus: string, searchCriteria: AdvancedSearchCriteria, isOverride: boolean): Observable<DataResponse> {

        const dataRequest: ApprovalRequest = {
            approvalStatus: approvalStatus,
            searchCriteria: searchCriteria,
            override: isOverride
        };

        return this.getDataResponse(dataRequest, '/getnextrecord');
    }

    /**
     * Sends command to server to approve the given record
     * @param axonId
     * @param pinref
     * @param isBulkAction - true if this came from bulk approval
     * @param isOverride - true if this is an overriding of a previously approved / rejected record
     */
    public approve( axonId: number, pinref: number, isBulkAction: boolean, isOverride: boolean ): Observable<DataResponse> {

        const dataRequest: ApprovalRequest = {
            axonId: axonId,
            pinref: pinref,
            bulkApprovalAction: isBulkAction,
            override: isOverride
        };

        return this.getDataResponse(dataRequest, '/approve');
    }

    /**
     * Sends command to server to reject or final reject the given record
     * @param finalReject
     * @param axonId
     * @param pinref
     * @param rejectionReason - general reason for rejection
     * @param reasons - list of dynamic fields and their individual reasons
     * @param isBulkAction  - true if this came from bulk approval
     * @param isOverride - true if this is an overriding of a previously approved / rejected record
     */
    public reject(
            finalReject: boolean,
            axonId: number,
            pinref: number,
            rejectionReason: string,
            reasons: Array< DynamicField >,
            isBulkAction: boolean,
            isOverride: boolean ): Observable<DataResponse> {

        const dataRequest: ApprovalRequest = {
            axonId: axonId,
            pinref: pinref,
            rejectionReason: rejectionReason,
            reasons: reasons,
            finalReject: finalReject,
            bulkApprovalAction: isBulkAction,
            override: isOverride
        };

        return this.getDataResponse(dataRequest, '/reject');
    }

    /**
     * Sends command to server to reject final the given record
     *
     * @param axonId
     * @param pinref
     * @param reason
     * @param isBulkAction  - true if this came from bulk approval
     * @param isOverride - true if this is an overriding of a previously approved / rejected record
     */
    public markPending(
        axonId: number, pinref: number, reason: string, isBulkAction: boolean, isOverride: boolean
        ): Observable<DataResponse> {

        const dataRequest: ApprovalRequest = {
            axonId: axonId,
            pinref: pinref,
            pendingReason: reason,
            bulkApprovalAction: isBulkAction,
            override: isOverride
        };

        return this.getDataResponse(dataRequest, '/markpending');
    }

    /**
     * Sends command to server to skip this record and move to the next available record
     *
     * @param axonId
     * @param pinref
     */
    public skip(
        axonId: number, pinref: number
        ): Observable<DataResponse> {

        const dataRequest: ApprovalRequest = {
            axonId: axonId,
            pinref: pinref
        };

        return this.getDataResponse(dataRequest, '/skip');
    }

    /**
     * Sends command to server to switch approval status mode
     * @param axonId
     */
    public switchApprovalMode( approvalStatus: string ): Observable<DataResponse> {

        const dataRequest: ApprovalRequest = {
            approvalStatus: approvalStatus
        };

        return this.getDataResponse(dataRequest, '/switchmode');
    }

    /**
     * Fetches the next record requiring approval
     * @param filterOptions
     */
    public getApprovalPerformanceStatistics(): Observable<DataResponse> {

        const dataRequest: ApprovalRequest = {
        };

        return this.getDataResponse(dataRequest, '/getapprovalperformancestats');
    }

    /**
     * Fetches the next record requiring approval
     * @param filterOptions
     */
    public getSummarisedApprovalStats(): Observable<DataResponse> {

        const dataRequest: ApprovalRequest = {
        };

        return this.getDataResponse(dataRequest, '/summarisedapprovalstats');
    }

    /**
     * Sends command to server to sync approval lock time.
     * This is sent after the next approval record is successfully fetched.
     * This is required, because fetching the record involves calling server,
     * which could find an Axon ID in a few seconds. Once it does this, that record
     * is locked (for example) 5 minutes. Server then uses that Axon ID, to fetch
     * the customer. Returning this data, with images, could take (for example) 30 seconds.
     * This means, server is ahead of GUI by 30 seconds, and would expire the record
     * 30 seconds earlier than GUI, resulting in the possibility of 2 agents viewing the
     * same record. Therefore, after GUI has downloaded customer and images, GUI
     * will start it's lock timer of 5 minutes, and then send this command to server,
     * which will reset the expiry time on server, to 5 minutes from that point.
     * Resulting in server always being behind GUI, meaning GUI will expire the lock
     * before server.
     * @param axonId
     */
    public syncApprovalLock( axonId: number, expirySeconds: number ): Observable<DataResponse> {

        const dataRequest: ApprovalRequest = {
            axonId: axonId,
            expirySeconds: expirySeconds
        };

        return this.getDataResponse(dataRequest, '/syncapprovallock');
    }

    /**
     * Fetches approval records in the agent's queue for table data
     * @param pageNum
     * @param pageMax
     * @param filterOptions
     */
    public getApprovalQueue(
        pageNum: number, pageMax: number, filterOptions: FilterOption[], approvalStatus: string): Observable<DataResponse> {

        const dataRequest: ApprovalRequest = {
            pageNum: pageNum,
            pageMax: pageMax,
            filterOptions: filterOptions,
            approvalStatus: approvalStatus,
        };

        return this.getDataResponse(dataRequest, '/getqueue');
    }

    /**
     * Fetches approved or rejected records allowing user to override their status
     * @param pageNum
     * @param pageMax
     * @param filterOptions
     */
    public getApprovalOverride(
        pageNum: number, pageMax: number, filterOptions: FilterOption[]): Observable<DataResponse> {

        const dataRequest: ApprovalRequest = {
            pageNum: pageNum,
            pageMax: pageMax,
            filterOptions: filterOptions
        };

        return this.getDataResponse(dataRequest, '/getoverride');
    }

	/**
     * Fetches approved / rejected records that will be displayed to a manager for them to review
     * @param pageNum
     * @param pageMax
     * @param filterOptions
     */
    public getApprovalReview(filterOptions: FilterOption[]): Observable<DataResponse> {

        const dataRequest: ApprovalRequest = {
            filterOptions: filterOptions
        };

        return this.getDataResponse(dataRequest, '/getapprovalreview');
    }

	/**
     * submits an approval review
     * @param pageNum
     * @param pageMax
     * @param filterOptions
     */
    public submitApprovalReview(axonId: number, pinref: number, approvalAgentId: number, acceptable: string, comment: string): Observable<DataResponse> {

        const dataRequest: ApprovalRequest = {
            axonId: axonId,
			pinref: pinref,
			approvalAgentId: approvalAgentId,
			approvalReviewAcceptable: acceptable,
			approvalReviewComment: comment,
        };

        return this.getDataResponse(dataRequest, '/submitapprovalreview');
    }

    public bulkApprove(customers: any[]): Observable<DataResponse> {

        const dataRequest: ApprovalRequest = {
            nameValuesList: customers
        };

        return this.getDataResponse(dataRequest, '/bulkapprove');
    }

    /**
     * Helper to get the observable containing the response
     * @param dataRequest
     * @param subpath - the subpath following the rootpath
     * @param rootpath - if null, will be set to /user
     */
    private getDataResponse( dataRequest: DataRequest, subpath: string, rootpath?: string ): Observable<DataResponse> {

        /* If not defined, set to /customer i.e. the default root path */
        if ( !rootpath ) {
            rootpath = '/approval';
        }

        this.httpService = new HttpDataService(this.httpClient, this.authService, rootpath, this.notifier);
        return this.httpService.sendData(dataRequest, subpath).pipe(
            map(response => {
                return response;
            })
        );
    }
}
