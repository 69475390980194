import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { ModalContainerComponent } from '../modal-container/modal-container.component';
import { Storage } from '../../utils/storage';


@Component({
    selector: 'app-offline-dialog',
    templateUrl: './offline-dialog.component.html',
    styleUrls: ['./offline-dialog.component.scss']
})
export class OfflineDialogComponent extends ModalContainerComponent implements OnInit {

    status;

    // tslint:disable-next-line: max-line-length
    constructor(
                dialog: MatDialog,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<OfflineDialogComponent>,
                public storage: Storage) {

        super(dialog, storage);
    }

    ngOnInit() { }

    getType() {
        return OfflineDialogComponent;
    }

    setStatus(status) {
        this.status = status;
    }
}
