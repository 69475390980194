import { Component, Injectable, OnDestroy } from '@angular/core';
import { UserIdleService, UserIdleConfig } from 'angular-user-idle';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { merge, fromEvent, TimeoutError, Subscription } from 'rxjs';
import { ConfirmModalComponent, CONFIRM } from '../../component/form/confirm-modal/confirm-modal.component';
import { AxonUtils } from '../../utils/axon-utils';
import { Storage } from '../../utils/storage';
import { Router } from '@angular/router';
import { Notifier } from '../../utils/notifier';
import { HttpClient } from '@angular/common/http';
import { AuthResponse } from '../../dto/dtos';
import { AbstractHttpService } from '../http/http-abstract-service';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
// import * as Stomp from 'stompjs';
// import * as SockJS from 'sockjs-client';

@Injectable({
  providedIn: 'root'
})
export class SessionMonitorService implements OnDestroy {
    private sessionModalExtension = null;

    private endingSession: boolean;

    private loggedSessionTimeoutWarning: boolean;

    sessionSubscription: Subscription;
    userIdleSubscription: Subscription;

    // private stompClient = null;

    constructor(
            private httpClient: HttpClient,
            private userIdle: UserIdleService,
            private modalService: NgbModal,
            private dialog: MatDialog,
            private storage: Storage,
            private router: Router,
            private notifier: Notifier) { 
    }

    ngOnDestroy() {
        if ( this.sessionSubscription ) {
            this.sessionSubscription.unsubscribe();
        }

        if ( this.userIdleSubscription ) {
            this.userIdleSubscription.unsubscribe();
        }
        
    }
    
    /**
     * Initialises the session monitor, which makes use of the UserIdleService to
     * run a timer. The token expiry in seconds is obtained from the server. 
     * The idle time is set to the expiry of the token, less 60 seconds.
     * The extra 60 seconds is considered the timeout, and it is at that time that a 
     * popup shows, informing user their session will expire soon, allowing them to extend
     * their session or logout.
     * @param expiry 
     */
    public async startSessionMonitor(expiry?: number) {

        this.loggedSessionTimeoutWarning = false;

        const token = AxonUtils.getAuthToken(this.storage);

        if ( token === null ) {
            console.log( 'User is not logged in. Not starting monitor' );
            /* User is not authed / logged in - return */
            return;
        }

        if ( !expiry || expiry === undefined || expiry === 0 ) {
            expiry = await this.getTokenExpiryInSecondsFromServer();
            console.log( 'User is logged in. Starting session monitor, ' + 
                'using remaining token expiry in seconds, from server [' + expiry + ']' );
        } else {
            console.log( 'User is logged in. Starting session monitor, using expiry in seconds [' + expiry + ']' );
        }
        
        console.log( 'Initialising session monitor' );

        const config = new UserIdleConfig();
        config.timeout = 60;
        config.idle = expiry - config.timeout;
        // config.idle = 10;
        if ( config.timeout <= 0 ) {
            config.timeout = 1;
        }
        config.ping = 0;

        console.log( 'Session Idle Time [' + config.idle + '] - Timeout [' + config.timeout + ']' );

        this.userIdle.stopTimer();
        this.userIdle.stopWatching();
        this.userIdle.setConfigValues( config );

        this.userIdle.setCustomActivityEvents(
            merge(
                fromEvent(window, 'mousemove'),
                fromEvent(window, 'resize'),
                fromEvent(document, 'keydown'),
                fromEvent(document, 'touchstart'),
                fromEvent(document, 'touchend'),
                fromEvent(window, 'scroll'),
                fromEvent(window, 'click')
            )
        );

        // Start watching for user inactivity.
        this.userIdle.startWatching();
        
        // Start watching when user idle is starting.
        this.sessionSubscription = this.userIdle.onTimerStart().subscribe(count => { 

                if ( !this.loggedSessionTimeoutWarning ) {
                    console.log('Session is about to expire in [' + config.timeout + '] sec(s). Showing extension modal');
                    this.loggedSessionTimeoutWarning = true;
                }
                this.showSessionExtensionModal();
            } 
        );

        // this.userIdle.ping$.subscribe(() => {
        //         console.log("Session monitor PING");
        //     }
        // );
        
        // Start watch when time is up.
        this.userIdleSubscription = this.userIdle.onTimeout().subscribe(() => { 

                if ( !this.endingSession ) {
                    console.log('Session has expired. Auto logging user out');
                    this.endSession();
                }
            } 
        );

    }

    // register(sessionId: any, transactionId: any) {
    //     var message = {
    //         "sessionId": sessionId,
    //         "transactionId": transactionId
    //     }
    //     var registrationMessage = JSON.stringify(message);
    //     this.stompClient.send("/app/register", {}, registrationMessage);
    // }

    // unregister(sessionId: any, transactionId: any) {
    //     var message = {
    //         "sessionId": sessionId,
    //         "transactionId": transactionId
    //     }
    //     var unregisterMessage = JSON.stringify(message);
    //     this.stompClient.send("/app/unregister", {}, unregisterMessage);
    // }

    // connectToSessionWebSocket() {
    //     var sessionId, transactionId;
    //     const socket = new SockJS('/axonapi/ws');
    //     this.stompClient = Stomp.over(socket);
  
    //     this.stompClient.connect({}, function (frame) {
    //         var urlArray = socket._transport.url.split('/');
    //         var index = urlArray.length - 2;
    //         sessionId = urlArray[index];

    //         transactionId = this.makeid(10);

    //         $('#sessionIdLabel').html(sessionId);
    //         $('#transactionIdLabel').html(sessionId);
    //         this.stompClient.subscribe('/user/queue/notify', function (hello) {
    //             console.log("**************** !!! ****************");
    //         });
    //         // this.register(sessionId, transactionId);
    //     });

    //     return;
    // }

    // makeid(length): string {
    //     var result           = '';
    //     var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    //     var charactersLength = characters.length;
    //     for ( var i = 0; i < length; i++ ) {
    //        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    //     }
    //     return result;
    //  }

    /**
     * Ends the session by closing modals and logging user out.
     */
    private endSession(error?: string) {
        this.endingSession = true;
        this.modalService.dismissAll();
        this.dialog.closeAll();
        if ( error ) {
            AxonUtils.doLogoutAndRedirectWithError(this.router, this.notifier, this.storage, error);
        } else {
            AxonUtils.doLogoutAndRedirect(this.router, this.notifier, this.storage);
        }
    }

    /**
     * Stops the session monitoring, and closes the extension modal windows
     */
    public stopSessionMonitor() {
        console.log( "Stopping session monitor" );
        this.userIdle.stopTimer();
        this.userIdle.stopWatching();
        if ( this.sessionModalExtension ) {
            this.sessionModalExtension.close();
            this.sessionModalExtension = null;
        }
    }

    private getTokenExpiryInSecondsFromServer(): Promise<number> { 
        const token = AxonUtils.getAuthToken(this.storage).token;
        const agentId: string = AxonUtils.getAuthAgent(this.storage).agentId + '';

        const endpoint = '/auth/querytoken';
        console.log( "Querying auth token with server at endpoint [" + endpoint + "]" );

        return new Promise<number>(resolve => {
            this.httpClient.get<AuthResponse>(AbstractHttpService.BASE_URL + endpoint, {
                headers: {
                    "axon-token": token,
                    "axon-agent-id": agentId,
                    'Content-Type': 'application/json',
                    'Cache-control': 'no-cache no-store',
                    'Expires': '0',
                    'Pragma': 'no-cache',
                    'timeout': `${10000}`
                }
            }).subscribe(response => {

                console.log('=================SERVER TOKEN QUERY RESPONSE====================');
                console.log('Success [' + response.success + ']');
                console.log('Error [' + response.error + ']');
                console.log('Error Category [' + response.errorCategory + ']');
                console.log('Error Code [' + response.errorCode + ']');
                console.log('==================================================');

                if (response.success === true) {

                    this.userIdle.resetTimer();
                    if ( this.sessionModalExtension ) {
                        this.sessionModalExtension.close();
                        this.sessionModalExtension = null;
                    }

                    AxonUtils.saveAuth(response.token, response.agent, this.storage);

                    resolve( response.token.expiryInSeconds );

                } else {
                    resolve(0);
                }

            },
            err => {
                if ( err && err instanceof TimeoutError ) {
                    console.log( 'Timeout occurred while executing [' + endpoint + ']' );
                    
                } 

                this.stopSessionMonitor();
                this.endSession( 'Failed to obtain session expiration time' );
            });
        });
        
    }

    /**
     * Displays modal to the user asking if they'd like to extend their session or log out
     */
    private showSessionExtensionModal() {
        if ( this.sessionModalExtension === null ) {
            this.sessionModalExtension = this.modalService.open(ConfirmModalComponent, 
                { 
                    windowClass : "modal-session-extension", 
                    backdrop  : 'static',
                    keyboard  : false
                }
            );
            this.sessionModalExtension.componentInstance.title = 'Session Expiry';
            this.sessionModalExtension.componentInstance.message = 
                'Your session is about to expire and you will be logged out.<br/><br/>Would you like to extend it?';
            this.sessionModalExtension.componentInstance.confirmButton = 'Extend';
            this.sessionModalExtension.componentInstance.cancelButton = 'Logout';
            this.sessionModalExtension.componentInstance.isHtml = true;

            this.sessionModalExtension.result.then((result) => {
                this.handleSessionExtension(result);
            }, (result) => {
                this.handleSessionExtension(result);
            });
        }
    }

    /**
     * Handles outcome of user's choice i.e. to extend the session, or to 
     * log them out
     * @param result 
     */
    private handleSessionExtension(result) {
        if ( result === CONFIRM ) {
            this.extendTokenWithServer();
        } else {
            if ( !this.endingSession ) {
                AxonUtils.doLogoutAndRedirect(this.router, this.notifier, this.storage);
            }
        }
    }

    /**
     * Sends get request to /auth/extendtoken to extend the token's expiration
     */
    private extendTokenWithServer() {

        const token = AxonUtils.getAuthToken(this.storage).token;
        const agentId: string = AxonUtils.getAuthAgent(this.storage).agentId + '';
        console.log( "Extending auth token with server" );
        return this.httpClient.get<AuthResponse>(AbstractHttpService.BASE_URL + '/auth/extendtoken', {
			headers: {
                "axon-token": token,
                "axon-agent-id": agentId,
				'Content-Type': 'application/json',
				'Cache-control': 'no-cache no-store',
				'Expires': '0',
				'Pragma': 'no-cache'
			}
		}).pipe(
            map(response => {

                console.log(response);
                console.log('=================SERVER TOKEN EXTENSION RESPONSE====================');
                console.log('Success [' + response.success + ']');
                console.log('Error [' + response.error + ']');
                console.log('Error Category [' + response.errorCategory + ']');
                console.log('Error Code [' + response.errorCode + ']');
                console.log('==================================================');

                if (response.success === true) {

                    this.userIdle.resetTimer();
                    this.sessionModalExtension.close();
                    this.sessionModalExtension = null;

                    AxonUtils.saveAuth(response.token, response.agent, this.storage);
                }
                return response;
            })
        ).subscribe(result => {
            if (result.success === true) {
                console.log( 'Session extended successfully' );
            } else {
                AxonUtils.doLogoutAndRedirectWithError(
                    this.router, this.notifier, this.storage, 
                    'Unable to extend session expiry. Your session has expired'
                );
            }
        });
    }
}
