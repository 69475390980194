import { Component, Input, OnInit, ViewChild, OnDestroy, ViewChildren, QueryList, OnChanges, ElementRef, Output, EventEmitter } from '@angular/core';
import { AxonComponent } from '../../axon.component';
import { DynamicLink, DynamicCard, DynamicField, LinkedLists, Node, DataRequest, GroupLists } from '../../dto/dtos';
import {
    FormControl,
    Validators,
    FormGroupDirective,
    NgForm,
    ValidatorFn,
    FormGroup,
    FormBuilder,
    AsyncValidatorFn,
    ValidationErrors
} from '@angular/forms';
import { ErrorStateMatcher, MatDialog, MatInput } from '@angular/material';
import { FormData } from './form-data/form-data';
import { AxonUtils } from '../../utils/axon-utils';
import { ServerError } from '../../utils/server-errors';
import { Notifier } from '../../utils/notifier';
import { DynamicCharType, DynamicInputField, LinkedListType, DynamicCardType, ActionRule, Section, Field } from '../../utils/constants';
import { DynamicFieldManager } from '../../settings/dynamic-fields/dynamic-field-manager';
import { Subscription, Observer } from 'rxjs';
import { DragndropListComponent, DIRECTION } from './dragndrop-list/dragndrop-list.component';
import { LinkedListDropdownComponent, RETURN_TYPE_ID } from '../linked-list-dropdown/linked-list-dropdown.component';
import { LinkedListValidator } from './validators/linkedlist.validator';
import { BirthdateValidator } from './validators/birthdate.validator';
import { UniqueFieldValidator } from './validators/uniquefield.validator';
import { AgentService } from '../../services/agents/agent.service';
import { CustomerByAxonIdFormData } from './form-data/customers/customer-form-data';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent, CONFIRM } from './confirm-modal/confirm-modal.component';
import { DynamicActionService, Instruction } from './dynamic-action/dynamic-action.service';
import { AuthService } from '../../services/auth/auth.service';
import { SystemService } from '../../services/system/system.service';
import { GroupListsManager } from '../../settings/grouplists/group-lists-manager';
import { SectionUtils } from '../../../environments/environment';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

export class AxonErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})
export class FormComponent extends AxonComponent implements OnInit, OnDestroy, Observer<any> {

    @Input() identifierNum: number;
    @Input() identifierStr: number;
    @Input() formData: FormData;
    @Input() showCardTitles: boolean;
    @Input() showButton = false;
	@Input() pinProtect: boolean;
    @Input() confirmSave;
    @Input() showButtonForCardGroup = false;
    @Input('dragndropEditable') dragndropEditable: boolean;
    @Input('dragndropNotEditableMessage') dragndropNotEditableMessage: string;
	@Output() onImageDynamicAction : EventEmitter<Instruction> = new EventEmitter();

    /* Needed to instruct the calling / parent component of this form, that data was saved successfully */
    @Input() parentComponent: AxonComponent;

    fields: DynamicField[];

    matcher = new AxonErrorStateMatcher();

    dynamicLink: DynamicLink;
    cards: Array<DynamicCard> = new Array();

    form: FormGroup;
    formControlList: Array<FormControl>;
    buildingForm: boolean = true;

    instance: FormComponent;

    /* Add buttons, maps and drag n drop lists */
    @ViewChild('dragNDrop') dragNDropRef: DragndropListComponent;

    addedItemsList = [];
    addedTypesList = [];
    addedLabelsList = [];

    map: any;
    MAP_ID = "dynamicMap";
    mapLineSuggestions = [];
    mapSuggestion: any;

    loading = false;
    saving = false;
    subscriptions: Subscription[] = [];

    linkedListFlat: LinkedListDropdownComponent[] = [];
    linkedListCascade: LinkedListDropdownComponent[] = [];

    @ViewChildren('addedItemsListElement') addedItemsListULElementRef: QueryList<ElementRef>;

    @ViewChildren('linkedListFlat')
    set flat(content: QueryList<LinkedListDropdownComponent>) {
        const linkedLists = content.toArray();
        linkedLists.forEach(linkedList => {
            this.linkedListFlat[linkedList.getFieldId()] = linkedList;
        });
    }

    @ViewChildren('linkedListCascade')
    set cascade(content: QueryList<LinkedListDropdownComponent>) {
        const linkedLists = content.toArray();
        linkedLists.forEach(linkedList => {
            this.linkedListCascade[linkedList.getFieldId()] = linkedList;
        });
    }

    defaultBirthdate = new Date((new Date().getTime() - (3888000000 * 24 * 12)));

    rightListFirstIsPrimary = false;
    leftListFirstIsPrimary = false;

	dialogRef: any;

    constructor(
		private notifier: Notifier,		
        private formBuilder: FormBuilder,
        private agentService: AgentService,
        private authService: AuthService,
		private systemsService: SystemService,
		public dialog: MatDialog,
        private modalService: NgbModal,
        private grouplistMgr: GroupListsManager,
        private dynamicFieldMgr: DynamicFieldManager,
        private dynamicActionSrv: DynamicActionService) {
        super();
        this.instance = this;
    }

    ngOnInit() {

        if (this.formData !== undefined) {
            /* If formData is NOT in new mode i.e. in edit mode, then send request to server to fetch the form and it's dynamic data */
            if (!this.formData.isNewMode()) {
                console.log("Building form for section ID [" + this.formData.getSectionId() + "] in EDIT mode");
                this.loading = true;
                this.formData.getFormData().subscribe(
                    data => {
                        try {
                            console.log("received data", data);
                            if (!data.success) {
                                ServerError.printError(data);
                                this.notifier.error(ServerError.formatError(data));
                                return null;
                            }

                            this.dynamicLink = data.data;
                            // console.log("Dynamic Link Data: " + JSON.stringify(this.dynamicLink));
                            this.buildForm();
                        } finally {
                            this.loading = false;
                        }
                    }
                );
            } else {
                console.log("Building form for section ID [" + this.formData.getSectionId() + "] in NEW mode");
                /*
                If the formData is null, then user is adding, not editing. Construct the form using the
                dynamic fields from local storage
                */
                // setTimeout(() => {

                // });

                if (this.formData.getCardId() !== 0) {
                    this.dynamicLink = this.dynamicFieldMgr.getFieldsBySectionIdCardId(this.formData.getSectionId(), this.formData.getCardId());
                } else {
                    this.dynamicLink = this.dynamicFieldMgr.getFieldsBySectionId(this.formData.getSectionId());
                }
                // console.log("Dynamic Link Data: " + JSON.stringify(this.dynamicLink));
                this.buildForm();
            }
        }
    }

    /**
     * Builds the form using the fields linked to the section ID
     */
    private buildForm() {

        this.buildingForm = true;

        this.formControlList = new Array<FormControl>();

        if (this.dynamicLink && this.dynamicLink.cards) {
            for (const card of this.dynamicLink.cards) {

                if (card.type === 'dragndrop') {

                    /* intiialise the dragndrop state */
                    setTimeout(() => {
                        this.dragNDropRef.leftList = (card.fields[0].lovValues === undefined) ? [] : card.fields[0].lovValues;
                        this.dragNDropRef.rightList = (card.fields[1].lovValues === undefined) ? [] : card.fields[1].lovValues;
                    });

                } else {

                    for (const field of card.fields) {
                        this.buildField(field);
                    }

                    /* Run dynamic action on the initial state of the form */
                    const allFields = AxonUtils.getAllFields(this.dynamicLink);
                    for (const field of allFields) {
                        this.processDynamicAction(field, allFields);
                    }
                }
            }
        }

        /* Create the form group, containing the list of form controls */
        this.form = this.formBuilder.group(this.formControlList);

        /* let the parentComponent listen for changes */
        this.enableValueChangesSubscription();

        this.buildingForm = false;
    }

    /**
     * Builds the validation required for the given field based on the field's properties
     * i.e. min, max, mandatory, hidden, etc
     */
    private buildField(field: DynamicField) {

        try {
            console.log('Building Field Id [' + field.id + '] - Field [' + field.field + ']');

            const validatorList: Array<ValidatorFn> = new Array();
            const aysncValidatorList: Array<AsyncValidatorFn> = new Array();

            /* Define field minimum */
            if (field.min > 0) {
                validatorList.push(
                    Validators.minLength(field.min)
                );
            }

            /* Define field maximum */
            if (field.max > 0) {
                validatorList.push(
                    Validators.maxLength(field.max)
                );
            }

            /* Define mandatory / optional */
            if (field.mandatory === 1 && field.inputField !== DynamicInputField.CHOOSER) {
                validatorList.push(
                    Validators.required
                );
            }

            /* Build a regex pattern for input fields based on the dynamic field settings */
            if (field.inputField === DynamicInputField.INPUT) {

                const blockedList = new Array<string>();

                /* Allow letters if field is ALPHA or ALPHANUMERIC. If not, add error to blocked list */
                let pattChars = "";
                if (field.charType === DynamicCharType.ALPHA
                    || field.charType === DynamicCharType.ALPHANUMERIC
                    || field.charType === DynamicCharType.EMAIL) {
                    pattChars += "a-zA-Z";
                } else {
                    blockedList.push("Letters");
                }

                if (field.charType === DynamicCharType.EMAIL) {
                    validatorList.push(Validators.email);
                }

                /*
                field.pattern should only be set from dynamic action instructions.
                If this is not set, then build a REGEX pattern based on field properties.
                This means, if dynamic action rule REGEX is applied on a field, it will
                override the standard regex pattern applied.
                */
                let pattern = field.pattern;
                if ((pattern === null || pattern === undefined) && field.editable === 1) {

                    /* Allow numbers if field is ALPHANUMERIC, MSISDN or NUMERIC. If not, add error to blocked list */
                    if (field.charType === DynamicCharType.ALPHANUMERIC
                        || field.charType === DynamicCharType.MSISDN
                        || field.charType === DynamicCharType.NUMERIC
                        || field.charType === DynamicCharType.EMAIL) {
                        pattChars += "0-9";
                    } else {
                        blockedList.push("Numbers");
                    }

                    /* Allow spaces if field allows spaces. If not, add error to blocked list */
                    if (field.allowSpaces) {
                        pattChars += " ";
                    } else {
                        blockedList.push("Spaces");
                    }

                    /* Allow special characters if field allows chars. If not, add error to blocked list */
                    if (field.charsAllowed) {
                        pattChars += "*#@$%&^!)({}{}';<,>?/\\_.=-";
                    } else {
                        if (field.value !== undefined && field.value.includes("_")) {
                            pattChars += "_";
                        }

                        blockedList.push("Special characters");
                    }

                    /*
                    TODO - Get sequential chars, which is actually consecutive chars regex to work
                    0 = allow any number of sequential chars
                    > 1 = allow x number of sequential chars
                    */
                    // if ( field.sequentialChar > 0 ) {
                    //     pattChars += "\\" + field.sequentialChar;
                    // }

                    // pattChars = "(^[" + pattChars + ")]\\1";


                    pattern = "^[" + pattChars + "]{" + field.min + "," + field.max + "}$";
                    // const pattern = "^(?!.*?[ '.-]{2})[A-Za-z0-9 '.-]{1,30}$";

                    /* Build the pattern error list */
                    if (field.patternError === null || field.patternError === undefined) {
                        let prefix = "";
                        let patternError = "";
                        for (const blocked of blockedList) {
                            patternError += prefix + blocked;
                            prefix = ", ";
                        }

                        patternError += " are not allowed. Please check your input";
                        field.patternError = patternError;
                    }
                }

                validatorList.push(
                    Validators.pattern(pattern)
                );

                if (field.uniqueness === 1) {
                    const identifierNum = (this.formData.isNewMode()) ? undefined : this.identifierNum;
                    aysncValidatorList.push(UniqueFieldValidator.createValidator(field, this.agentService, identifierNum));
                }

            } else if ( field.inputField === DynamicInputField.DROPDOWN || field.inputField === DynamicInputField.DROPDOWN_FILTER ) {
                if (field.lov !== undefined && field.field === 'Type') {
                    // if the field is Type, Don't use the populated LOVs (which is the List of ICCIDs, use the LOV in grouplists)
                    const grouplists: Array<GroupLists> = this.grouplistMgr.getGroupListIdLang(field.lov, 'en');                    
                    const lov = [];
                    for (const gl of grouplists) {
                        lov.push(gl.text);
                    }
                    field.lovValues = lov;
                } else if (field.lov !== undefined && field.lovValues === undefined) {
                    const grouplists: Array<GroupLists> = this.grouplistMgr.getGroupListIdLang(field.lov, 'en');                    
                    const lov = [];
                    for (const gl of grouplists) {
                        lov.push(gl.text);
                    }
                    field.lovValues = lov;
                }
            } else if (field.inputField === DynamicInputField.TICKBOX) {

                /*
                tickboxes in html can only handle true or false, not yes / no
                */
                switch (field.value) {
                    case 'yes':
                    case 'true':
                    case true:
                        field.value = true;
                        break;
                    default:
                        field.value = false;
                        break;
                }

            } else if (field.inputField === DynamicInputField.DATE || field.inputField === DynamicInputField.DATE_MANUAL) {

                console.log('BUILDING DATE FIELD WITH VALUE: ', field.value);

                if (field.value !== null) {
					let date = field.value;
                    /* Convert datetimes to date only - avoids timezone issues */
                    date = new Date(AxonUtils.getDate(date));

					//Chrome can handle dates in mm-dd-yyyy but firefox only works with mm/dd/yyyy
					if ( isNaN(new Date(date).getDate())) {
						const dateStr = '' + field.value;
						date = Date.parse(dateStr.split('-').join('/'));
					}

					field.value = date;
                }

				// If char type == DATE then set the min and max to 200 years from now in the past (on Jan 1st) and 200 years in the future on Dec 31
				const currentYear = new Date().getFullYear();
				field.minDate = new Date(currentYear - 200, 0, 1);
				field.maxDate = new Date(currentYear + 200, 11, 31);

				switch (field.charType) {
                    case DynamicCharType.PASTDATE:
						// min = 6000
						// max = 37000
						var dateMinOffset = (24*60*60*1000) * field.min; 
						field.maxDate= new Date();
						field.maxDate.setTime(field.maxDate.getTime() - dateMinOffset);

						var dateMaxOffset = (24*60*60*1000) * field.max; 
						field.minDate = new Date();
						field.minDate.setTime(field.minDate.getTime() - dateMaxOffset);

						break;

                    case DynamicCharType.FUTUREDATE:
						// min = 100
						// max = 37000
						var dateMinOffset = (24*60*60*1000) * field.min; 
						field.minDate = new Date();
						field.minDate.setTime(field.minDate.getTime() + dateMinOffset);

						var dateMaxOffset = (24*60*60*1000) * field.max; 
						field.maxDate = new Date();
						field.maxDate.setTime(field.maxDate.getTime() + dateMaxOffset);
                    
                        break;
                }

				console.log("Date field is [" + field.charType + "]. Min Date [" + field.minDate + "]. Max Date [" + field.maxDate + "]");

            } else if (field.inputField === DynamicInputField.CHOOSER) {


                /*
                If the field params contains LINKED_LIST, then it will provide instructions for linked lists.
                The format for this is:
                LINKED_LIST=categoryIndividual;TYPE=cascade
                or
                LINKED_LIST=categoryIndividual;TYPE=flat

                There are 2 more optional params, which would follow the above i.e.
                ;PLACEHOLDER=Ethio Telecom:Region:Shop / Distributor:Sub Distributor / Dealer;RETURN_TYPE=ID
                Where placeholder is used for multi tier lists, providing a place holder for each list in order
                of hierarchy. And RETURN_TYPE can be either ID or VALUE.
                ID = the multi tier component will return the lowest ID (in the hierarchy) chosen
                VALUE = the string representation of all dropdowns chosen i.e val1/val2/val3/val4 etc
                */
                if (field.params !== null && field.params.indexOf('LINKED_LIST') >= 0) {
                    const props = field.params.split(";");
                    const linkedListName = props[0].split("=")[1];
                    const type = props[1].split("=")[1];

                    let placeholder = null;
                    if (props[2]) {
                        placeholder = props[2].split("=")[1];
                    }

                    let returnType = null;
                    if (props[3]) {
                        returnType = props[3].split("=")[1];
                    }

                    /* Flat LinkedList soetimes needs an ID return type value */
                    if (props.length > 2) {
                        props.forEach(prop => {
                            if (prop.indexOf('RETURN_TYPE') > -1) {
                                returnType = prop.split("=")[1];
                            }
                        });
                    }

                    const linkedListData: LinkedLists = this.dynamicFieldMgr.getLinkedListData();
                    let linkedList: Array<Node>;

                    /*
                    The parent list is ready to go, as it's already formatted into a hierarchy from the server
                    */
                    Object.entries(linkedListData).forEach(entry => {
                        if (entry[0] === linkedListName) {
                            linkedList = entry[1];
                        }
                    });

                    if (!linkedList) {
                        console.log('Unable to find appropriate linked list named [' + linkedListName + ']. Please check dynamic settings');
                    }

                    if (type === LinkedListType.FLAT) {

                        if (field.mandatory > 0) {
                            validatorList.push(LinkedListValidator(linkedList, field.field));
                        }

                        /*
                        The hierarchy needs to be converted into a flat structure
                        */
                        field.lovValues = new Array();

                        let idx = 1;
                        for (const node of linkedList) {

                            if (node.children) {
                                for (const nodeChild of node.children) {

                                    if (nodeChild.children) {

                                        for (const nodeGrandChild of nodeChild.children) {
                                            field.lovValues.push(
                                                {
                                                    id: idx,
                                                    value: node.value + " / " + nodeChild.value + " / " + nodeGrandChild.value
                                                }
                                            );
                                            idx++;
                                        }
                                    }
                                }
                            } else {

                                /* In some FLAT linked list we want to be saving the id not the value (such as POS Coordinator) */
                                if (node.id !== undefined) {
                                    field.lovValues.push({
                                        id: node.id,
                                        value: node.value
                                    });
                                } else {
                                    /* For a single level linkedlist ( like roles for add agent Section 98 ) */
                                    field.lovValues.push({
                                        id: idx,
                                        value: node.value
                                    });
                                    idx++;
                                }
                            }
                        }

                        setTimeout(() => {
                            if (this.linkedListFlat.length > 0) {
                                if (returnType !== null) {
                                    this.linkedListFlat[field.id].setReturnType(returnType);
                                }

                                if (returnType === RETURN_TYPE_ID) {
                                    this.linkedListFlat[field.id].setValue(this.linkedListFlat[field.id].getValueForId(field.value));
                                } else {
                                    this.linkedListFlat[field.id].setValue(field.value);
                                }
                                field.formComponent = this.linkedListFlat[field.id];
                            }
                        });
                    } else if (type === LinkedListType.CASCADE) {

                        field.linkedListValue = linkedList;
                        field.placeholder = (placeholder === null ? field.field : placeholder);

                        setTimeout(() => {
                            if (this.linkedListCascade[field.id]) {
                                this.linkedListCascade[field.id].setReturnType(returnType);
                                this.linkedListCascade[field.id].setValue(field.value);
                            }

                            field.formComponent = this.linkedListCascade[field.id];
                        });
                    }
                }
            } else if (field.inputField === DynamicInputField.ADD_BUTTON) {

                const inputField = this.getFormFieldForAddInput();

                if (inputField && inputField.lovValues && inputField.lovValues.length > 0) {
                    inputField.lovValues.forEach(val => {
                        if (inputField.field === 'Type' && inputField.id === 361) {
                            // do nothing. This is a SIMs & Devices Section.
                        } else {
                            this.addedItemsList.push(val);
                            this.addedLabelsList.push(inputField.field);
                            this.parentComponent.addButtonAddItem(this.addedItemsList, this.getCardName(0), val);
                        }
                    });
                }
            }

            /* Assign the form control to the field */
            field.formControl = new FormControl(
                {
                    disabled: field.editable === 1
                },
                validatorList,
                aysncValidatorList
            );

            /* Set the form controls value */
            field.value = (field.value === undefined) ? "" : field.value;
            if (field.inputField === DynamicInputField.DATE || field.inputField === DynamicInputField.DATE_MANUAL) {
                if (!this.formData.isNewMode()) {
                    field.formControl.setValue(new Date(field.value));
                }
            } else {
                field.formControl.setValue(field.value);
            }

            /*
            Mark the form as touched i.e user did something to it, only in edit mode.
            This is needed so that the mat-errors display as soon as the form
            is loaded, if there is an error with the form control
            */
            if (!this.formData.isNewMode()) {
                field.formControl.markAsTouched();
            }

            /*
            Keep a record of all form controls - this is needed to build the
            form group for knowing if form is valid
            */
            this.formControlList[field.id] = field.formControl;
            // this.formControlList.push(field.formControl );

            if (field.inputField === DynamicInputField.MAP && this.mapSuggestion !== undefined) {
                setTimeout(() => {
                    this.map = AxonUtils.updateMap(
                        this.map, this.MAP_ID, this.mapSuggestion.center.lat, this.mapSuggestion.center.lng
                    );
                });
            }
            console.log('Successfully built Field Id [' + field.id + '] - Field [' + field.field + ']');
        } catch (exception) {
            console.error('FAILED TO BUILD: Field Id [' + field.id + '] - Field [' + field.field + ']');
            console.error(exception);
        }
    }

    /**
     * Processes the instruction received from dynamic action rules on the given field.
     * Requires the field that needs to be acted upon, as well as all fields in the
     * section - as the target could be in the list of allFields
     * @param field
     * @param allFields
     */
    private processDynamicAction(field: DynamicField, allFields: Array<DynamicField>) {
        if (field.charType === DynamicCharType.BLOB || field.inputField === DynamicInputField.ADD_BUTTON || field.formControl === undefined) {
            return;
        }

        console.log('Processing dynamic action for field with value [' + field.value + '] - control value [' + field.formControl.value  + '] - field: ', field);

        // if ( field.formControl.value === null || field.formControl.value === undefined || field.formControl.value.length === 0 ) {
        // 	return;
        // }

        const instructions = this.dynamicActionSrv.getInstructions(field.id, field.formControl.value, allFields);

        /* Get the actionable fields only i.e. only fields that exist in the list of instructions */
        const uniqueFields = new Set<DynamicField>();
        for (const instruction of instructions) {

            for (const f of allFields) {
                if (f.id === instruction.fieldId) {
                    uniqueFields.add(f);
                    break;
                }
            }
        }

        this.processInstructions(instructions, Array.from(uniqueFields));
    }

    /**
     * Used in UI - when a field is changed, that should trigger dynamic action rules
     * @param field
     */
    private handleFieldChange(field: DynamicField) {
        console.log('handling field change for field ID [' + field.id + '] - value [' + field.formControl.value + ']');

        if (field.charType === DynamicCharType.BIRTHDATE && this.dynamicLink.sectionId === SectionUtils.getSectionId(Section.AGENT_DETAILS)) {
            console.log(field.formControl.value);
            if (field.formControl.value !== undefined && (field.formControl.value + "").toLowerCase().indexOf('invalid') < 0) {

                const birthdate = new Date(field.formControl.value);
                const age = AxonUtils.calculateAgeFromBirthDate(birthdate);

				if (age < field.min) {
					field.formControl.setErrors({ ageMin: false, min: field.min });
				} else if (age > field.max) {
					field.formControl.setErrors({ ageMax: false, max: field.max });
				} else {

				}

                // this.systemService.fetchAgeRange().subscribe(dataReponse => {

                //     const ageRange: DynamicField = dataReponse.data;
                //     if (ageRange !== undefined && ageRange.min !== undefined && ageRange.max !== undefined && !isNaN(ageRange.min) && !isNaN(ageRange.max)) {

                //         if (age < ageRange.min) {
                //             field.formControl.setErrors({ ageMin: false, min: ageRange.min });
                //         } else if (age > ageRange.max) {
                //             field.formControl.setErrors({ ageMax: false, max: ageRange.max });
                //         } else {

                //         }
                //     }
                // });
            }
        }

		if ( this.parentComponent ) {
        	this.parentComponent.next(field.formControl.value);
		}

        if (field.charType === DynamicCharType.ALPHA) {
            if (field.id === Field.FIRST_NAME || field.id === Field.MIDDLE_NAME || field.id === Field.LAST_NAME) {
                if (!(field.formControl.value.match(/^[A-Za-z]+$/))) {
                    field.formControl.setErrors({ 'specialsAndNums': true });
                }
            }
        }

        const allFields = AxonUtils.getAllFields(this.dynamicLink);

        this.processDynamicAction(field, allFields);

		// It's important to rebuild the form, as the form control linked to the field 
		// would have been changed during the processDynamicAction call which in turn calls buildField,
		// which will generate a new form control and add to the formControlList.
		this.form = this.formBuilder.group(this.formControlList);
    }

    /**
     * Processes the instructions given, against the fields. The list of given fields should fields
     * that have a field ID in the list of instructions i.e. no fields without a corresponding instruction
     * should be supplied
     * @param instructions
     * @param actionableFields
     */
    private processInstructions(instructions: Array<Instruction>, actionableFields: Array<DynamicField>) {

        /* Before we start, reset some field properties */
        for (const f of actionableFields) {
            f.pattern = null;
            f.invalidError = null;
        }

        if (instructions.length > 0) {

            /*
            Apply all instructions to all fields first i.e. could be multiple instructions, example:
            to set min and another to set max, on the same field
            */
            console.log('Applying all instructions on fields...');
            for (const instruction of instructions) {
                console.log('Received instruction: ', instruction);

                /* Get the field */
                let field: DynamicField;
                for (const f of actionableFields) {
                    if (f.id === instruction.fieldId) {
                        field = f;
                        break;
                    }
                }

				if ( !field ) {
					if ( this.onImageDynamicAction ) {
						this.onImageDynamicAction.emit(instruction);
					}
					continue;
				}

				switch (instruction.actionRule) {
					case ActionRule.MANDATORY:

						console.log('Making field ID [' + field.id + '] MANDATORY');
						field.mandatory = 1;

						break;
					case ActionRule.NON_MANDATORY:

						console.log('Making field ID [' + field.id + '] NON-MANDATORY');
						/* Nothing to do here, if it was mandatory, it would be cleared */
						field.mandatory = 0;

						break;
					case ActionRule.REGEX:

						console.log('Applying REGEX [' + instruction.value + '] to field ID [' + field.id + ']');

						field.pattern = instruction.value;
						field.patternError = instruction.error

						break;
					case ActionRule.MIN:

						console.log('Applying MIN [' + instruction.value + '] to field ID [' + field.id + ']');

						field.min = instruction.value;

						break;
					case ActionRule.MAX:

						console.log('Applying MAX [' + instruction.value + '] to field ID [' + field.id + ']');

						field.max = instruction.value;

						break;
					case ActionRule.ENABLE:

						console.log('Making field ID [' + field.id + '] ENABLED');

						field.editable = 1;

						break;
					case ActionRule.DISABLE:

						console.log('Making field ID [' + field.id + '] DISABLED');

						field.editable = 0;

						break;
					case ActionRule.HIDE:

						console.log('Making field ID [' + field.id + '] HIDDEN');

						field.hidden = 1;

						break;
					case ActionRule.SHOW:

						console.log('Making field ID [' + field.id + '] VISIBLE');

						field.hidden = 0;

						break;
					case ActionRule.INVALID:

						console.log('Making field ID [' + field.id + '] INVALID with error [' + instruction.value + ']');

						field.invalidError = instruction.value;

						break;
				}
            }

            /*
            Now that all fields have had their instructions applied,
            rebuild the fields, to have their validation match their properties i.e. min, max, etc
            */
            console.log('Rebuilding all fields...');
            for (const field of actionableFields) {
                console.log('Rebuilding field [' + field.field + ']');
                this.buildField(field);
            }
        }
    }

    getCapturedData(): DynamicLink {
        /* Ensure form is valid before processing */
        if (this.form !== undefined && !this.form.valid) {
            console.log('Form contains invalid data. User needs to check inputs');
            this.notifier.error('Form contains invalid data. Please check your inputs');
            return;
        }

        return this.mapDynamicValues(this.dynamicLink);
    }

	async showUpdateDialog() {
		if (this.pinProtect) {
			const pin = await this.getPin(this.systemsService);
			this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
				data: {
					question: 'Are you sure you want to save the security questions?',
					positive: 'Save',
					negative: 'Cancel',
					title: 'Confirm Save Security Questions',
					onPositive: this.save,
					onNegative: this.stop,
					caller: this,
					pinProtected: true,
					pin: pin
				}
			});
		} else {
			this.save(undefined);
		}
	}

	stop() {

	}
	
    /*
    Handles the save click - sends the dynamic field data to the server for saving
    */
    save(data) {
		let app = this;

		if (data !== undefined) {
			app = data.caller
		}
	
		console.log("Save Clicked In Appform: ", app.pinProtect);		
		const dynamicLinkForServer = app.getCapturedData();		
	
		if ( dynamicLinkForServer === null || dynamicLinkForServer === undefined ) {
			return;
		}
	
		console.log('Sending DynamicLink object to server :');
		console.log(dynamicLinkForServer);
		console.log("identifierNum = " + app.identifierNum);
	
		if (app.confirmSave !== undefined) {
	
			const modalRef = app.createSaveConfirmModal();
			modalRef.result.then((result) => {
				if (result === CONFIRM) {
					app.confirmSave = undefined;
					app.save(undefined);
				}
			});
			return;
		}
	
		/* Send to the server */
		app.saving = true;
		console.log('Seding to server');
		
		if (app.pinProtect) {
			app.formData.saveFormDataWithPin(app.identifierNum, {
				dynamicLinkForServer: dynamicLinkForServer,
				pin: data.pin
			}).subscribe(
				data => {
					try {
						if (!data.success) {
		
							const parentShowedError = (app.parentComponent === undefined ? false : app.parentComponent.showError(data));
							ServerError.printError(data);
							if (parentShowedError !== true) {
								app.notifier.error(ServerError.formatError(data));
							}
		
							return null;
						} else {
							app.notifier.success("Successfully saved");
		
							if (app.formData instanceof CustomerByAxonIdFormData) {
								const custFormData: CustomerByAxonIdFormData = app.formData;
								app.parentComponent.saveSuccessful(custFormData.getCardId());
							} else {
								app.parentComponent.saveSuccessful();
							}
		
							/* Make a call to refresh linked lists */
							/* Depending on what has been saved, the linked lists may change and we need to refresh them */
							const dataRequest: DataRequest = {};
							app.agentService.getDataResponse(dataRequest, '/getupdatedlinkedlists', '/dynamicdata').subscribe(
								result => {
									app.authService.saveLinkedLists(result.data);
								}
							);
						}
					} finally {
						app.saving = false;
					}
				}
			);
		} else {
			app.formData.saveFormData(app.identifierNum, dynamicLinkForServer).subscribe(
				data => {
					try {
						if (!data.success) {
	
							const parentShowedError = (app.parentComponent === undefined ? false : app.parentComponent.showError(data));
							ServerError.printError(data);
							if (parentShowedError !== true) {
								app.notifier.error(ServerError.formatError(data));
							}
	
							return null;
						} else {
							app.notifier.success("Successfully saved");
	
							if (app.formData instanceof CustomerByAxonIdFormData) {
								const custFormData: CustomerByAxonIdFormData = app.formData;
								app.parentComponent.saveSuccessful(custFormData.getCardId());
							} else {
								app.parentComponent.saveSuccessful();
							}
	
							/* Make a call to refresh linked lists */
							/* Depending on what has been saved, the linked lists may change and we need to refresh them */
							const dataRequest: DataRequest = {};
							app.agentService.getDataResponse(dataRequest, '/getupdatedlinkedlists', '/dynamicdata').subscribe(
								result => {
									app.authService.saveLinkedLists(result.data);
								}
							);
						}
					} finally {
						app.saving = false;
					}
				}
			);
		}
    }

    /**
     *
     * @param dynamicLink
     * @param raiseErrorIfNull if set to true, an error will be raised if the value is null / undefined / incomplete
     */
    mapDynamicValues(dynamicLink, raiseErrorIfNull?: boolean) {
        /*
        Create a copy of the dynamic link which we can manipulate and send to server
        without affecting the base dynamic link object
        */
		const dynamicLinkForServer = new DynamicLink(dynamicLink);
		console.log("dynamicLinkForServer: ", dynamicLinkForServer);
        /* Assign the value of the dynamic field from the form control */
        for (const card of dynamicLinkForServer.cards) {

			console.log("card: ", card);
            if (card.type === DynamicCardType.DRAGNDROP) {
                if (card.fields.length > 1) {
                    if (this.dragNDropRef.showDate === true) {
                        card.fields[1].lovValues = this.dragNDropRef.getRightListWithDates();
                    } else {
                        card.fields[1].lovValues = this.dragNDropRef.rightList;
                    }
                    card.fields[0].lovValues = this.dragNDropRef.leftList;
                }
            }

            for (const field of card.fields) {

				console.log("Field: ", field);

                let keepLovValues = false;
                if (field.inputField === DynamicInputField.CHOOSER) {
                    /* For chooser i.e. LinkedListDropdownComponent,
                    the value cannot be attached to the FormControl as the value
                    is derived from the values of the 3 selections. So the value
                    must be obtained from the overridable AxonComponent.getValue()
                    */

                    if (field.params !== null && field.params.indexOf('LINKED_LIST') >= 0) {
                        const props = field.params.split(";");
                        const type = props[1].split("=")[1];

                        if (type === LinkedListType.CASCADE) {
                            const list = this.linkedListCascade[field.id];
                            if (list !== undefined) {

                                if (raiseErrorIfNull) {
                                    if (field.value === undefined ||
                                        (list.isMandatory() && !list.isFullySelected())) {
                                        throw new Error('Please capture ' + card.card);
                                    }
                                }

                                field.value = list.getValue();
                            }
                        } else {
                            const list = this.linkedListFlat[field.id];
                            if (list !== undefined) {
                                field.value = list.getValue();
                            }
                        }
                    }
                } else if (field.inputField === DynamicInputField.ADD_BUTTON) {
                    card.fields[1].lovValues = this.parentComponent.getAddedItemsList(this.getCardName(0));
                    card.fields[0].lovValues = this.addedTypesList;

                    // we must also add what is currently in the input and pass it in the request
                    const formControlForInput = this.getFormControlForAddInput();
                    const formTypeControlForInput = this.getTypeControlForAddInput();
                    if (formControlForInput.value !== null && formControlForInput.value.trim() !== "") {
                        card.fields[1].lovValues.push(formControlForInput.value);
                        card.fields[0].lovValues.push(formTypeControlForInput.value);
                    }

                    keepLovValues = true;
                } else if (field.inputField === DynamicInputField.DATE || field.inputField === DynamicInputField.DATE_MANUAL) {
                    const dd = new Date(field.formControl.value).toLocaleString();
                    field.value = dd;
                } else if (field.inputField === DynamicInputField.DROPDOWN || field.inputField === DynamicInputField.DROPDOWN_FILTER) {
                    console.log(this.formControlList);
                    field.value = field.formControl.value;
                } else if (card.type !== DynamicCardType.DRAGNDROP) {
                    /* Fetch value from field.formControl */
                    field.value = field.formControl.value;
                    keepLovValues = true;
                }
                /*
                For some reason, when attempting to JSON.stringify FormControl or FormComponent it
                results in an error:
                ERROR TypeError: Converting circular structure to JSON
                To avoid this, just set form control to null.
                */
                field.formControl = null;
                field.formComponent = null;
                field.rejectionCheckControl = null;
                field.rejectionReasonControl = null;

                /* We also don't need to flood the server with lovValues, map stores coordinate as array of length 2 */
                if (card.type !== DynamicCardType.DRAGNDROP &&
                    field.inputField !== DynamicInputField.MAP &&
                    field.inputField !== DynamicInputField.ADD_BUTTON
                    && keepLovValues === false) {

                    field.lovValues = null;
                }

                console.log('field ID [' + field.id + '] - name [' + field.field + '] has value [' + field.value + ']');
			}
			
			console.log("OOL2");
		}

		console.log("OOL");
		
		console.log(dynamicLinkForServer);
        return dynamicLinkForServer;
    }

    ngOnDestroy(): void {
        this.clearSubscriptions();
    }

    clearSubscriptions() {
        if (this.subscriptions) {
            Object.keys(this.subscriptions).forEach((formName) => {
                console.log("Clear subscriptions --- " + this.getFormName());
                this.subscriptions[formName].unsubscribe();
            });
        }
    }

    disableValueChangesSubscription() {
        if (this.subscriptions && this.subscriptions[this.getFormName()]) {
            console.log("Disable subscriptions --- " + this.getFormName());
            this.subscriptions[this.getFormName()].unsubscribe();
        }
    }

    enableValueChangesSubscription() {
        if (this.subscriptions) {
            console.log("Enable subscriptions --- " + this.getFormName());
            this.subscriptions[this.getFormName()] = this.form.valueChanges.subscribe(this);
        }
    }

    /**
     * This is a method to allow for changing between different form sections in the case of a multipart form.
     * Call the method when you want to swop out the current formData with a new formData.
     * @param formData
     */
    public updateFormData(formData: FormData, callback?: any) {

        console.log("updateFormData");

        this.disableValueChangesSubscription();

        if (!formData.isNewMode()) {

            formData.getFormData().subscribe((result) => {
                this.dynamicLink = result.data;
                this.formData = formData;
                this.buildForm();
                if (callback !== undefined) {
                    setTimeout(() => {
                        callback();
                    });
                }
            });
        } else {
            this.formData = formData;
            this.dynamicLink = this.dynamicFieldMgr.getFieldsBySectionId(formData.getSectionId());
            this.buildForm();
        }
    }

    /**
     *  This works using the nativeelementinjector.directive
     *  which finds matInput's and puts a nativeElement property to them to allow things like focusing on them
     */
    focusFirstInvalidField() {

        let foundInvalidField = false;

        try {

            if (this.form.controls !== undefined) {
                for (let i = 0; i < this.dynamicLink.cards.length; i++) {
                    for (let j = 0; j < this.dynamicLink.cards[i].fields.length; j++) {

                        const id = this.dynamicLink.cards[i].fields[j].id;
                        if (Object.keys(this.form.controls).indexOf(id + "") > -1) {

                            if (this.form.controls[id].invalid) {
                                foundInvalidField = true;

                                const nativeElement = (<any>this.form.controls[id]).nativeElement;
                                setTimeout(() => {
                                    (<any>this.form.controls[id]).markAsTouched();
                                    nativeElement.focus();
                                });
                                // break after the first invalid field is found
                                break;
                            }
                        }
                    }
                }
            }
        } catch (error) {
            console.error("!!!!!!!! ERROR !!!!!!!!!!!");
            console.error(error);
        }

        return foundInvalidField;
    }

    /**
     * Inserts an item into the list maintained by the parent component
     * @param $event
     */
    addButtonAction($event) {

        const formControlForInput = this.getFormControlForAddInput();

        if (this.insertListItem() && this.form !== undefined) {
            this.form.reset();
        } else {
            formControlForInput.markAsTouched();
        }
    }

    resetForm() {
        this.form.reset();
    }

    /* Gets the input control for the element immediately before the add_button inputField */
    getFormControlForAddInput() {

		const inputField = this.getFormFieldForAddInput();
		if (inputField !== null && inputField !== undefined && inputField.id !== undefined) {			
			const formControlForInput = this.formControlList[inputField.id];

			return formControlForInput;
		}
    }

    getFormControlLabelForAddInput() {

        const inputField = this.getFormFieldForAddInput();
        const formControlLabelForInput = inputField.field;

        return formControlLabelForInput;
    }

    getTypeControlForAddInput() {

		const inputField = this.getTypeFieldForAddinput();
		if (inputField !== null && inputField !== undefined && inputField.id !== undefined) {
			const formControlForInput = this.formControlList[inputField.id];

			return formControlForInput;
		}	
    }

    getTypeFieldForAddinput(): DynamicField {
        console.log(this.dynamicLink.cards);
        for(let card of this.dynamicLink.cards) {
            if(card.card === 'Agent SIMS') {
                return card.fields[0];
            }
        }

        return null;
    }

    getFormFieldForAddInput(): DynamicField {
        let prevField;
        let inputField;

        this.dynamicLink.cards.forEach(card => {

            card.fields.forEach(field => {
                if (field.inputField === DynamicInputField.ADD_BUTTON) {
                    inputField = prevField;
                }
                prevField = field;
            });
        });
        return inputField;
    }

    refreshData() {
        this.updateFormData(this.formData);
    }

    /**
     * @returns a form name based on the sectionId of the formData
     */
    getFormName(section?: Section): string {
        return (section === undefined) ? "Section_" + this.formData.getSectionId() : "Section_" + section;
    }

    /**
     * @param index
     * @returns the name of the card at the index
     */
    getCardName(index) {
        try {
            return this.dynamicLink.cards[index].card;
        } catch (exception) {
            console.error(exception);
        }
    }

    /**
     * The add_button inputField generates a list when used, the parent maintains the list
     * Deletes an item from the list
     * @param addedItemName
     */
    deleteListItem(addedItemName) {
        const index = this.addedItemsList.indexOf(addedItemName);
        this.addedLabelsList.splice(index, 1);
        this.addedTypesList.splice(index, 1);
        this.addedItemsList = this.parentComponent.addButtonDeleteItem(this.addedItemsList, this.getCardName(0), addedItemName);
    }

    /**
     * Inserts an item into the list
     * @returns true if input is valid
     */
    insertListItem(): Boolean {
        console.log('Adding Item');
        const formTypeControlForInput = this.getTypeControlForAddInput();
        const formControlForInput = this.getFormControlForAddInput();
        const formControlLabelForInput = this.getFormControlLabelForAddInput();         
        if (formControlForInput.value !== null && formControlForInput.value.trim() !== "" && formControlForInput.errors === null) {
            this.addedItemsList = this.parentComponent.addButtonAddItem(this.addedItemsList, this.getCardName(0), formControlForInput.value);
            this.addedLabelsList.push(formControlLabelForInput);
            this.addedTypesList.push(formTypeControlForInput.value);
            return true;
        }
        return false;
    }

    clearAddedItemsList() {
        this.addedItemsList = [];
    }

    editAddedItem($event) {

        const addedItemsList = [];
        this.addedItemsListULElementRef.forEach((item: ElementRef) => {
            const input = item.nativeElement.firstElementChild.firstElementChild.firstElementChild.firstElementChild.firstElementChild.firstElementChild;
            const matInput = (<MatInput>input);
            console.log(matInput.value);
            addedItemsList.push(matInput.value);
        });

        this.addedItemsList = addedItemsList;
        this.parentComponent.setAddedItemsList(this.getCardName(0), addedItemsList);
    }

    updateMap(lat?, lng?) {

        for (const field of this.dynamicLink.cards[0].fields) {
            if (field.inputField === 'map') {
                field.lovValues = [lat + "", lng + ""];
            }
        }

        setTimeout(() => {
            if (lat !== undefined && lat !== null) {
                this.map = AxonUtils.updateMap(this.map, this.MAP_ID, lat, lng);
            } else {
                this.map = AxonUtils.updateMap(this.map, this.MAP_ID);
            }
        });
    }

    /**
     * For inputField of 'map', update the suggestions after user has typed something, call it from the parent
     * @param suggestions
     */
    updateMapSuggestions(suggestions: string[]) {
        console.log("updateMapSuggestion");
        console.log(suggestions);
        this.mapLineSuggestions = suggestions;
    }

    /**
     * This will populate any address type form fields based on their field name, eg. Suburb
     * @param $event
     * @param mapSuggestion
     */
    pickAddress($event, mapSuggestion) {

        console.log("pickAddress");

        this.mapSuggestion = mapSuggestion;
        this.map = AxonUtils.updateMap(this.map, this.MAP_ID, this.mapSuggestion.center.lat, this.mapSuggestion.center.lng);

        const addressObj = mapSuggestion.address;
        const [addressLine1, suburb, townCity, province] = AxonUtils.parseAddressObject(addressObj);

        /* Deactivate form change subscription to avoid unnecessary code running */
        this.disableValueChangesSubscription();

        /* Fill out the form using the data from the chosen Leaflet geocode location */
        this.dynamicLink.cards[0].fields.forEach((field) => {
            if (field.field.toLowerCase().indexOf('Address Line'.toLowerCase()) > -1) {

                const addrLine1 = this.formControlList[field.field].value.split(' ');
                let roadNumber;

                if (addrLine1.length > 0 && !isNaN(parseInt(addrLine1[0]))) {
                    roadNumber = addrLine1[0];
                }

                this.formControlList[field.field].setValue(
                    (roadNumber !== undefined) ? roadNumber + " " + addressLine1 : addressLine1
                );
            } else if (field.field.indexOf('Suburb') > -1) {
                this.formControlList[field.field].setValue(suburb);
            } else if (field.field.indexOf('City') > -1) {
                this.formControlList[field.field].setValue(townCity);
            } else if (field.field.indexOf('Province') > -1) {
                this.formControlList[field.field].setValue(province);
            } else {
                field.value = ["" + mapSuggestion.center.lat, "" + mapSuggestion.center.lng];
            }
        });

        /* re-enable the form value change subscription */
        this.enableValueChangesSubscription();

        this.parentComponent.pickedAddress(mapSuggestion);
    }

    /* >>> ReactiveForms valueChanges Observer */
    next(value) {

        if (this.parentComponent) {
            console.log("next(?) " + value);
            this.dynamicLink.cards[0].fields.forEach((field) => {

                /* Prevent user from typing gibberish, only the autocomplete values can be used for the control */
                if (field.inputField === 'chooser') {
                    const props = field.params.split(";");
                    const type = props[1].split("=")[1];

                    if (type === LinkedListType.FLAT) {

                        if (this.linkedListFlat[field.id] !== undefined && value[field.id] !== null && value[field.id] !== "" && value[field.id] !== undefined) {

                            const linkedListFlatDropdown: LinkedListDropdownComponent = (<LinkedListDropdownComponent>this.linkedListFlat[field.id]);

                            if (linkedListFlatDropdown.autoCompleteComponent.value === '') {
                                linkedListFlatDropdown.autoCompleteComponent.value = value[field.id];
                            } else {
                                const matcher = linkedListFlatDropdown.autoCompleteComponent.filterValues(value[field.id]);
                                if (matcher.length === 1) {
                                    this.disableValueChangesSubscription();
                                    linkedListFlatDropdown.autoCompleteComponent.setValue(matcher[0].value);
                                    this.enableValueChangesSubscription();
                                }
                            }

                            const matches = linkedListFlatDropdown.parseValue(value[field.id]);

                            if (matches === undefined || (<string>matches).length === 0) {
                                this.notifier.warn("No matches for value [" + value[field.id] + "]");
                                field.formControl.setValue(value[field.id].substring(0, value[field.id].length - 1));
                            }
                        }
                    }
                } else if (field.inputField === DynamicInputField.ADD_BUTTON) {
                    const addInputFormCtl: FormControl = this.getFormControlForAddInput();
                    if (addInputFormCtl.valid) {
                        field.formControl.setErrors(null);
                    }
                }
            });
            console.log("Calling parent's next observer...");
            this.parentComponent.next(value);
        }
    }
    /* <<< ReactiveForms valueChanges Observer */

    dragNDropUpdated() {
        this.parentComponent.next("");
        this.parentComponent.dragNDropUpdated();
    }

    validateDragndrop() {

        const field: DynamicField = this.dynamicLink.cards[0].fields[1];
        const validation = this.dragNDropRef.isValid(field);
        this.dragNDropRef.setErrors(validation);

        if (this.form !== undefined) {
            if (validation !== true) {
                this.form.setErrors(validation);
            } else {
                this.form.setErrors(null);
            }
        }

        if (this.dynamicLink !== undefined) {
            if (this.dynamicLink.sectionId === SectionUtils.getSectionId(Section.AGENT_LOCATIONS)) {
                this.setRightListFirstIsPrimary(true);
            }
        }
    }

    setRightListFirstIsPrimary(isPrimary) {
        if (this.dragNDropRef !== undefined) {
            this.dragNDropRef.rightListFirstIsPrimary = isPrimary;
        } else {
            throw new Error('Dragndrop not ready');
        }
    }

    setLeftListFirstIsPrimary(isPrimary) {
        if (this.dragNDropRef !== undefined) {
            this.dragNDropRef.leftListFirstIsPrimary = isPrimary;
        } else {
            throw new Error('Dragndrop not ready');
        }
    }

    insertIntoDragnDrop(value: string, side: DIRECTION) {

        if (side === DIRECTION.LEFT) {
            this.dragNDropRef.leftList.push(value);
        } else {
            this.dragNDropRef.rightList.push(value);
        }
    }

    deleteFromDragnDrop(value: string) {

        const leftCopy = Object.assign([], this.dragNDropRef.leftList);
        const rightCopy = Object.assign([], this.dragNDropRef.rightList);

        if (leftCopy.indexOf(value) >= 0) {
            const i = leftCopy.indexOf(value);
            this.dragNDropRef.leftList.splice(i, 1);
        }

        if (rightCopy.indexOf(value) >= 0) {
            const i = rightCopy.indexOf(value);
            this.dragNDropRef.rightList.splice(i, 1);
        }

        this.validateDragndrop();
    }

    resetDragnDrop() {
        this.dragNDropRef.reset();
    }

    /**
     * Helper to create modals
     */
    private createSaveConfirmModal(): NgbModalRef {

        const options: NgbModalOptions = {
            container: '.confirm-modal-container',
            windowClass: "modal-editform-confirmclose"
        };

        const modalRef = this.modalService.open(ConfirmModalComponent, options);
        modalRef.componentInstance.title = "Confirm";
        modalRef.componentInstance.message = this.confirmSave;
        modalRef.componentInstance.confirmButton = "Continue";
        modalRef.componentInstance.cancelButton = "Cancel";

        return modalRef;
    }

    /**
     * Helper to return true if the field is a numeric based field
     * @param field
     */
    private isFieldNumeric(field: DynamicField): boolean {
        return field.charType === 'msisdn' || field.charType === 'numeric';
    }
}
