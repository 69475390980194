import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export const CONFIRM = "confirm";
export const CANCEL = "cancel";

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {

  @Input('confirmButton') confirmButton: string;
  @Input('cancelButton') cancelButton: string;
  @Input('title') title: string;
  @Input('message') message: string;
  @Input('isHtml') isHtml: boolean;
  @Input('additionalMessage') additionalMessage: string;

  constructor(public activeModal: NgbActiveModal) { }

  confirm() {
      this.activeModal.close(CONFIRM);
  }

  cancel() {
      this.activeModal.close(CANCEL);
  }

}
