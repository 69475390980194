import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { faAngleDown, faAngleRight, faBan, faCircleCheck, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

export class TreeNode {
	ref: any;
	parentRef: any;
	data: any;
	selectable: boolean;
	label: string;

	iconSelected?: IconDefinition;
	iconNotSelected?: IconDefinition;
}

class TreeNodeInternal {
	data: any;
	ref: any;
	label: string;
	children: TreeNodeInternal[];
	expanded: boolean;
	selectable: boolean;

	iconSelected?: IconDefinition;
	iconNotSelected?: IconDefinition;
}

@Component({
	selector: 'app-tree',
	templateUrl: './tree.component.html',
	styleUrls: ['./tree.component.css']
})
export class TreeComponent implements OnInit {
	@Input() title: string;
	@Output() onSelect = new EventEmitter<any>();
	@Input() expanded = false;
	trees: TreeNodeInternal[] = [];
	selected: TreeNodeInternal;
	faAngleDown = faAngleDown;
	faAngleRight = faAngleRight;

	constructor() { }

	ngOnInit() {
	}

	@Input()
	set data(input: TreeNode[]) {
		if (!input) {
			this.trees = [];
			return;
		}

		this.trees = input.filter(item => {
			if (item.parentRef == null) {
				return true;
			}
			if (input.filter(i => item.parentRef === i.ref).length === 0) {
				return true;
			}
			return false;
		}).map(item => this.buildTreeSection(item, input));
	}

	@Input()
	set initiallySelected(selectedReference: any) {
		if (!selectedReference) {
			return;
		}

		if (this.selected) {
			return;
		}
		this.selected = this.findTreeNodeByRef(selectedReference);
	}
	private findTreeNodeByRef(ref: any, relativeTo?: TreeNodeInternal) {
		let children: TreeNodeInternal[];

		if (relativeTo === undefined || relativeTo === null) {
			children = this.trees;
		} else {
			if (relativeTo.ref === ref) {
				return relativeTo;
			}
			if (!relativeTo.children) {
				return null;
			}
			children = relativeTo.children;
		}

		return children
			.map(item => this.findTreeNodeByRef(ref, item))
			.filter(item => item !== undefined && item !== null)[0];
	}
	private buildTreeSection(input: TreeNode, nodes: TreeNode[]): TreeNodeInternal {
		return {
			data: input.data,
			ref: input.ref,
			label: input.label,
			expanded: null,
			selectable: input.selectable,
			iconSelected: input.iconSelected,
			iconNotSelected: input.iconNotSelected,
			children: nodes
				.filter(item => input.ref === item.parentRef)
				.map(item => this.buildTreeSection(item, nodes))
		};
	}
	setSelected(selected: TreeNodeInternal) {
		if (selected.selectable === true) {
			this.selected = selected;
			this.onSelect.emit(this.selected.data);
		}
	}
	isSelected(node: TreeNodeInternal): boolean {
		if (!this.selected) {
			return false;
		}
		return this.selected.ref === node.ref;
	}
	isExpanded(node: TreeNodeInternal): boolean {
		if (node.expanded !== null) {
			return node.expanded;
		}
		return this.expanded;
	}
	getIcon(node: TreeNodeInternal): IconDefinition {
		if (this.isSelected(node)) {
			if (node.iconSelected) {
				return node.iconSelected;
			}
			return faCircleCheck;
		}
		if (node.iconNotSelected) {
			return node.iconNotSelected;
		}
		if(node.selectable === false){
			return faBan;
		}
		return faCircle;
	}
	getIconSize(node: TreeNodeInternal): any {
		const icon = this.getIcon(node);
		if (icon === faCircle) {
			return 'xs';
		}
		if (icon === faBan) {
			return 'xs';
		}
		return 'lg';
	}
}
