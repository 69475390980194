import { Component, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { version } from '../../../../package.json';
// declare var $:any;
import * as $ from 'jquery';
import { Storage, KEY_SYSTEM_INFO_VERSION } from '../../utils/storage';
import { expand } from 'rxjs/operators';
import { Permission as PermissionEnum } from "../../utils/constants";
import { AxonUtils } from '../../utils/axon-utils';
import { environment } from '../../../environments/environment';
import { ConfigService } from '../../services/config/config.service';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit, AfterViewInit {
	// this is for the open close
	isActive = true;
	showMenu = '';
	showSubMenu = '';
	PermissionEnum = PermissionEnum;

	public version: string = version;
	systemVersion: string;
	featureList: string[] = [];

	permissions: {[name: string]: boolean} = {};

	constructor(private authService: AuthService, private storage: Storage, private router: Router, private configService: ConfigService) { }

	customerText: string;
	usersText: string;
	showChurnMenu: boolean;
	showApprovalReviewMenu: boolean;
	showApprovalsMenu: boolean;

	ngOnInit() {
		this.systemVersion = this.storage.getItem(KEY_SYSTEM_INFO_VERSION);

		this.customerText = this.environment.customer_text;
		this.usersText = this.environment.users_text;
		this.showChurnMenu = this.environment.show_churn_menu;
		this.showApprovalReviewMenu = this.environment.show_approval_review_menu;
		this.showApprovalsMenu = this.environment.show_approvals_menu;
		
		this.configService.getFeaturesList().subscribe(
			result => this.featureList = result
		);
	}

	addExpandClass(element: any) {
		if (element === this.showMenu) {
			this.showMenu = '0';
		} else {
			this.showMenu = element;
		}
	}
	addActiveClass(element: any) {
		if (element === this.showSubMenu) {
			this.showSubMenu = '0';
		} else {
			this.showSubMenu = element;
		}
	}
	eventCalled() {
		this.isActive = !this.isActive;
	}
	// End open close
	ngAfterViewInit() {
		$(function () {
			$('.sidebartoggler').on('click', function (e) {
				if ($('body').hasClass('mini-sidebar')) {
					SidebarComponent.expand();
				} else {
					SidebarComponent.collapse();
				}
			});
		});
	}
	public static collapse(): void {
		$('body').trigger('resize');
		$('.scroll-sidebar, .slimScrollDiv')
			.css('overflow-x', 'visible')
			.parent()
			.css('overflow', 'visible');
		$('body').addClass('mini-sidebar');
		$('.navbar-brand span').hide();
		// $(".sidebartoggler i").removeClass("ti-menu");
	}
	public static expand(): void {
		$('body').trigger('resize');
		$('.scroll-sidebar, .slimScrollDiv')
			.css('overflow', 'hidden')
			.parent()
			.css('overflow', 'visible');
		$('body').removeClass('mini-sidebar');
		$('.navbar-brand span').show();
		// $(".sidebartoggler i").addClass("ti-menu");
	}
	get apiVersion() {
		return this.systemVersion;
	}
	logout() {

		this.authService.deleteAuth();
		this.router.navigate(['authentication/login']);

	}

	hasAccess(permissions: PermissionEnum[]): boolean {
		for (const permission of permissions) {
			if ( this.permissions[permission] === true ) {
				return this.permissions[permission];
			}
			if (this.permissions[permission] === false) {
				continue;
			}

			if (AxonUtils.checkActionPermission(permission, this.authService, null, false)) {
				this.permissions[permission] = true;
				return true;
			} else {
				this.permissions[permission] = false;
			}
		}
		return false;
	}

	get environment() {
		return environment;
	}

	hasFeature(feature: String) {
		return this.featureList.filter(item => item.toLowerCase() === feature.toLowerCase()).length > 0;
	}
}
