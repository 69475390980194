import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { DataRequest, DataResponse } from '../../dto/dtos';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AxonService } from '../axon-service';
import { HttpDataService } from '../http/http-data.service';
import { Notifier } from '../../utils/notifier';

@Injectable({
  providedIn: 'root'
})
/**
 * Entry point for components requiring access to customer data
 */
export class DashBoardService extends AxonService {

    private httpService: HttpDataService;

    constructor(_httpClient: HttpClient, _authService: AuthService, _notifier: Notifier) {
        super( _httpClient, _authService, _notifier );
    }

    /**
     * Fetches customers for table data
     * @param pageNum
     * @param pageMax
     * @param filterOptions
     */
    public getCharts(): Observable<DataResponse> {

        const dataRequest: DataRequest = {
        };

        return this.getDataResponse(dataRequest, '/system');
    }

    /**
     * Helper to get the observable containing the response
     * @param dataRequest
     * @param subpath - the subpath following the rootpath
     * @param rootpath - if null, will be set to /user
     */
    private getDataResponse( dataRequest: DataRequest, subpath: string, rootpath?: string ): Observable<DataResponse> {

        /* If not defined, set to /customer i.e. the default root path */
        if ( !rootpath ) {
            rootpath = '/dashboard';
        }

        this.httpService = new HttpDataService(this.httpClient, this.authService, rootpath, this.notifier);
        return this.httpService.sendData(dataRequest, subpath).pipe(
            map(response => {
                return response;
            })
        );
    }
}
