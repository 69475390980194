import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { LoginAuthGuardService } from './services/auth/login-auth-guard.service';
import { NotLoginAuthGuardService } from './services/auth/not-login-auth-guard.service';
import { DEFAULT_ROUTE } from './utils/constants';
import { ReportComponent } from './report/report.component';
import { ReportTypeComponent } from './report/report-list.component';
import { UnauthorizedComponent } from './component/unauthorized/unauthorized.component';
import { ProfileDynamicCardComponent } from './agents/profile/profile-dynamic-card.component';
import { DownloadExpiredComponent } from './authentication/download-expired-component/download-expired-component';

export const routes: Routes = [
	{
		path: '',
		component: FullComponent,
		canActivate: [LoginAuthGuardService],
		children: [

			// Axon routes
			{ path: '', redirectTo: DEFAULT_ROUTE, pathMatch: 'full' },
			{ path: 'profile', component: ProfileDynamicCardComponent },
			{ path: 'agents', loadChildren: './agents/agents.module#AgentsModule', },
			{ path: 'customers', loadChildren: './customers/customers.module#CustomersModule', },
			{ path: 'election', loadChildren: './election/election.module#ElectionModule', },
			{ path: 'approvals', loadChildren: './approvals/approval.module#ApprovalModule', },
			{ path: 'candidates/approvals', loadChildren: './candidates-approvals/candidates-approval.module#CandidatesApprovalModule', },
			// { path: 'override', loadChildren: './approvals/approval.module#ApprovalModule', },
			// { path: 'review', loadChildren: './approvals/approval.module#ApprovalModule', },
			{ path: 'churn', loadChildren: './churn/churn.module#ChurnModule', },
			{ path: 'devices', loadChildren: './devices/devices.module#DevicesModule', },
			{ path: 'organizations', loadChildren: './organizations/organizations.module#OrganizationsModule', },
			{ path: 'reports-list', component: ReportTypeComponent, data: { title: 'Reports List', urls: [{ title: 'Reports List', url: '/reports-list' }] } },
			{ path: 'report', children: [{ path: '', data: { title: 'Report', urls: [{ title: 'Report' }] }, component: ReportComponent }] },
			{ path: 'reports', loadChildren: './report/report.module#ReportModule', },
			{ path: 'system', loadChildren: './system/system.module#SystemModule', },
			{ path: 'dms', loadChildren: './dms/dms.module#DmsModule', },
			{ path: 'momokash', loadChildren: './momokash/momokash.module#MomokashModule', },
			{ path: 'dashboard', loadChildren: './dashboards/dashboard.module#DashboardModule' },
			{ path: 'management', loadChildren: './management/management.module#ManagementModule' },
		]
	},
	{
		path: '',
		component: BlankComponent,
		canActivate: [NotLoginAuthGuardService],
		children: [
			{
				path: 'authentication',
				loadChildren: './authentication/authentication.module#AuthenticationModule'
			}
		]
	},
	{ path: 'unauthorized', component: UnauthorizedComponent },
	{ path: 'download-link-expired', component:  DownloadExpiredComponent},
	{ path: '**', redirectTo: '404' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes), NgbModule.forRoot()],
	exports: [RouterModule]
})
export class AppRoutingModule { }
