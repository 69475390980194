import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { KEY_CONFIGURATION, Storage } from '../../utils/storage';

export enum Criteria {
    at_least_eight_chars,
    at_least_one_lower_case_char,
    at_least_one_upper_case_char,
    at_least_one_digit_char,
    at_least_one_special_char
}

@Component({
    selector: 'app-password-input',
    templateUrl: './password-input.component.html',
    styleUrls: ['./../../authentication/auth.scss']
})
export class PasswordInputComponent implements OnInit {

  @Input() typePassword = false;
  @Input('inputPasswordForm') inputPasswordForm: FormGroup;
  @Input() validators: Criteria[] = Object.keys(Criteria).map(key => Criteria[key]);
  @Input() checkStrength: false;
  @Input() controlName: string;
  @Input() placeholder: string;
  @Input() confirmPassword: false;

  capsOn = false;

  @ViewChild("passwordRef") passwordField: ElementRef;

  criteriaMap = new Map<Criteria, RegExp>();

  currentElement;

  allowGuiRememberPassword = false;

  constructor(private formBuilder: FormBuilder, private storage: Storage) {}

  ngOnInit() {

      this.criteriaMap.set(Criteria.at_least_eight_chars, RegExp(/^.{8,63}$/));
      this.criteriaMap.set(Criteria.at_least_one_lower_case_char, RegExp(/^(?=.*?[a-z])/));
      this.criteriaMap.set(Criteria.at_least_one_upper_case_char, RegExp(/^(?=.*?[A-Z])/));
      this.criteriaMap.set(Criteria.at_least_one_digit_char, RegExp(/^(?=.*?[0-9])/));
      this.criteriaMap.set(Criteria.at_least_one_special_char, RegExp(/^(?=.*?[" !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"])/));


      /* If checkStrength is set in the template declaration then the password strength must be validated */
      let validators = (this.checkStrength) ? [Validators.required,
        ...this.validators.map(criteria => Validators.pattern(this.criteriaMap.get(criteria)))]
        : [Validators.required];

      this.inputPasswordForm.addControl(this.controlName, new FormControl('', validators));

      if (this.confirmPassword) {
          this.inputPasswordForm.setValidators([this.checkPasswords]);
      }

      /* Focus the first password input if there is also one for confirm password */
      if (this.passwordField !== undefined && this.confirmPassword === undefined) {
          this.passwordField.nativeElement.focus();
      }

	  const configFile = JSON.parse(this.storage.getItem(KEY_CONFIGURATION));
	  console.log(configFile);
	  console.log(configFile['allow_gui_remember_password']);
	  this.allowGuiRememberPassword = configFile['allow_gui_remember_password'] === 'yes' ? true : false;

  }

  get f() { return this.inputPasswordForm.controls; }

	togglePassword() {
		const el = this.passwordField.nativeElement;
		this.typePassword = !this.typePassword;
			
		if (!this.typePassword) {
			el.style["text-security"] = "disc";
			el.style["-webkit-text-security"] = "disc";
			el.style["-moz-text-security"] = "disc";							
		} else {
			el.style["text-security"] = "none";
			el.style["-webkit-text-security"] = "none";
			el.style["-moz-text-security"] = "none";		   
		}
	}

  get password() {
      return this.inputPasswordForm.get(this.controlName);
  }

  get passwordStrengthError() {

      if (this.f[this.controlName].errors && this.f[this.controlName].errors.pattern) {
          const requiredPattern = this.f[this.controlName].errors.pattern.requiredPattern;

          if (requiredPattern === this.criteriaMap.get(Criteria.at_least_eight_chars).toString()) {
              return "Password must have at least 8 characters.";
          } else if (requiredPattern === this.criteriaMap.get(Criteria.at_least_one_digit_char).toString()) {
              return "Password must have at least one digit.";
          } else if (requiredPattern === this.criteriaMap.get(Criteria.at_least_one_lower_case_char).toString()) {
              return "Password must have at least one lower case character.";
          } else if (requiredPattern === this.criteriaMap.get(Criteria.at_least_one_special_char).toString()) {
              return "Password must have at least one special character.";
          } else if (requiredPattern === this.criteriaMap.get(Criteria.at_least_one_upper_case_char).toString()) {
              return "Password must have at least one upper case character.";
          }
      }

      return "";
  }

  checkPasswords(formGroup: FormGroup) { // here we have the 'passwords' group
      const pass = formGroup.controls.password.value;
      const confirmPass = formGroup.controls.confirmPassword.value;
      return pass === confirmPass ? null : { notSame: true }
  }

  focus() {
      console.log('trying to focus this input');
      this.passwordField.nativeElement.focus();
  }

  setCurrentElement($event) {
      this.currentElement = $event.target;
  }

  /**
   * This method is used to make sure tabbing does not focus the btn for password toggle.
   * It must rather go to the next password input available.
   * It uses setCurrentElement from above to keep track of the last focused password input.
   * @param $event
   */
  onFocusPasswordToggle($event) {

      $event.preventDefault();

      //find all tab-able elements
      const allElements = document.querySelectorAll('input');
      const allElementsOrderedArray = [];
      const allElementsUnorderedArray = [];
      Array.from(allElements).forEach(element => {
          const rect = element.getBoundingClientRect();
          allElementsUnorderedArray[rect.top] = element;
      });

      //Sort the array so that the elements are in order from top to bottom
      Object.keys(allElementsUnorderedArray).forEach((key) => {
          const element = allElementsUnorderedArray[key];
          if (element.className !== 'hidden') {
              allElementsOrderedArray.push(element);
          }
      });

      //Find the current tab index.
      const currentIndex = allElementsOrderedArray.findIndex(el => this.currentElement.isEqualNode(el));

      //focus the following element
      try {
          (<any> allElementsOrderedArray[currentIndex + 1]).focus();
      } catch (exception) {
          console.log(exception);
      }
  }
}
