import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { ModalContainerComponent } from '../modal-container/modal-container.component';
import { Storage } from '../../utils/storage';
import { data } from '../../table/smart-table/smart-data-table';
import { MaterialTableData } from '../material-table/material-table-data';
import { CustomDateAdapter } from '../dateadapter/custom-date-adapter';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { SystemRolesService } from '../../services/system/system-roles-service';
import { Role, Organization } from '../../dto/dtos';
import { OrganizationsService } from '../../services/organizations/organizations.service';

export interface DialogData {
	title: string;
	input?: string;
	type?: string;
	negative?: string;
	positive?: string;
}

@Component({
	selector: 'app-dialog',
	templateUrl: './dialog.component.html',
	styleUrls: ['./dialog.component.scss']
})
export class DialogComponent extends ModalContainerComponent implements OnInit {
	negative = 'Cancel';
	positive = 'Apply';

	data: DialogData;
	hoveredDate: NgbDate;
	fromDate: NgbDate;
	toDate: NgbDate;
	displayStartMonth: NgbDate;
	displayMaxDate: NgbDate;
	availableOptions: string[] = [];
	isLoadingAvailableOptions = false;

	// 'select_role' | 'select_shop'
	constructor(
		dialog: MatDialog,
		calendar: NgbCalendar,
		@Inject(MAT_DIALOG_DATA) public matData: MaterialTableData,
		public dialogRef: MatDialogRef<DialogComponent>,
		public storage: Storage,
		private organizationsService: OrganizationsService,
		private systemRolesService: SystemRolesService) {
		super(dialog, storage);

		this.data = matData.data;
		this.positive = (matData.data.positive ? matData.data.positive : 'Apply');
		this.negative = (matData.data.negative ? matData.data.negative : 'Cancel');

		if (!this.isDate) {
			return;
		}

		this.displayStartMonth = calendar.getPrev(calendar.getToday(), 'm', 1);
		this.displayMaxDate = calendar.getToday();
		if (this.isDateRange) {
			this.onDateSelection(calendar.getToday());	//Set from date
			this.onDateSelection(calendar.getPrev(calendar.getToday(), 'd', 14));	//Set from to date as from date is already set
		} else {
			this.onDateSelection(calendar.getPrev(calendar.getToday(), 'd', 1));	//Set both from and to date.
		}

	}

	ngOnInit() {
		this.availableOptions = [];
		if (this.filterType === 'select_shop') {
			console.log('Dialog: Loading Shops');
			this.isLoadingAvailableOptions = true;
			this.organizationsService.getOrgsByType('shop').subscribe(item => {
				console.log('Dialog: Loaded Shops', item);
				this.availableOptions = item.dataList
					.map(i => i as Organization)
					.map(i => i.name)
					.map(i => i.trim())
					.sort();
				this.isLoadingAvailableOptions = false;
			});
			return;
		}
		if (this.filterType === 'select_region') {
			console.log('Dialog: Loading Regions');
			this.isLoadingAvailableOptions = true;
			this.organizationsService.getOrgsByType('region').subscribe(item => {
				console.log('Dialog: Loaded Shops', item);
				this.availableOptions = item.dataList
					.map(i => i as Organization)
					.map(i => i.name)
					.map(i => i.trim())
					.sort();
				this.isLoadingAvailableOptions = false;
			});
			return;
		}
		if (this.filterType === 'select_role') {
			console.log('Dialog: Loading Roles');
			this.isLoadingAvailableOptions = true;
			this.systemRolesService.getRoles([]).subscribe(item => {
				console.log('Dialog: Loaded Roles', item);
				this.availableOptions = item.dataList
					.map(i => i as Role)
					.map(i => i.role)
					.map(i => i.trim())
					.sort();
				this.isLoadingAvailableOptions = false;
			});
			return;
		}
	}

	getType() {
		return DialogComponent;
	}

	onDateSelection(date: NgbDate) {
		if (!date) {
			return;
		}
		if (!this.isDateRange) {
			this.fromDate = date;
			this.toDate = date;
			this.data.input = this.convert(date.year, date.month, date.day);
			return;
		}

		let sFromDate: string = null;
		let sToDate: string = null;
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
			sFromDate = this.convert(date.year, date.month, date.day);
		} else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
			this.toDate = date;
			sToDate = this.convert(date.year, date.month, date.day);
		} else {
			this.toDate = null;
			sToDate = null;
			this.fromDate = date;
			sFromDate = this.convert(date.year, date.month, date.day);
		}

		if (sFromDate == null && sToDate == null) {
			this.data.input = null;
			return;
		}
		if (sFromDate == null) {
			this.data.input = sToDate;
			return;
		}
		if (sToDate == null) {
			this.data.input = sFromDate;
			return;
		}
		this.data.input = sFromDate + '|' + sToDate;
	}
	private convert(year: number, month: number, day: number): string {
		let out = '' + year;
		out += '-';
		out += month < 10 ? ('0' + month) : month;
		out += '-';
		out += day < 10 ? ('0' + day) : day;
		console.log(`Convert date parts.  year:[${year}] month:[${month}] day:[${day}]`, out);
		return out;
	}


	isHovered(date: NgbDate) {
		return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
	}

	isInside(date: NgbDate) {
		return date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
	}
	isPastMax(date: NgbDate) {
		return date.after(this.displayMaxDate);
	}
	get isDateRange(): boolean {
		return this.data.type === 'dateRange';
	}
	get isDate(): boolean {
		if (this.data.type === 'dateRange') {
			return true;
		}
		if (this.data.type === 'date') {
			return true;
		}
		if (this.data.type === 'dateTo') {
			return true;
		}
		return false;
	}
	get filterType(): 'dateRange' | 'date' | 'select_shop' | 'select_role' | 'select_region' | 'search' | 'number' {
		if (this.isDateRange) {
			return "dateRange";
		}
		if (this.isDate) {
			return 'date';
		}
		if (this.data.type === 'select_shop') {
			return 'select_shop';
		}
		if (this.data.type === 'select_role') {
			return 'select_role';
		}
		if (this.data.type === 'select_region') {
			return 'select_region';
		}
		if (this.data.type === 'number') {
			return 'number';
		}
		return 'search';
	}
	get filteredOptions(): string[] {
		if (!this.data.input || this.data.input === null || this.data.input.length === 0) {
			return this.availableOptions;
		}
		return this.availableOptions.filter(item => item.startsWith(this.data.input));
	}

	doSubmit() {
		this.dialogRef.close(this.data);
	}
}