import { Component, OnInit, Input, ViewChild, AfterViewInit, OnChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Agent } from '../../../dto/dtos';
import { FormComponent } from '../../../component/form/form.component';
import { AgentByAgentIdFormData } from '../../../component/form/form-data/agents/agent-form-data';
import { AgentService } from '../../../services/agents/agent.service';
import { Section } from '../../../utils/constants';
import { AxonComponent } from '../../../axon.component';
import { NewFormData } from '../../../component/form/form-data/new-form-data';
import { AgentModalComponent } from '../agent-modal.component';
import { DynamicFieldManager } from '../../../settings/dynamic-fields/dynamic-field-manager';

@Component({
	selector: 'app-agent-locations',
	templateUrl: './agent-locations.component.html',
	styleUrls: ['./agent-locations.component.scss']
})
export class AgentLocationsComponent extends AxonComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @Input() agent: Agent;
  @ViewChild('appFormLocations') appFormLocations: FormComponent;
  @Output() onSave = new EventEmitter();

  instance: AgentLocationsComponent;
  locationsFormData: AgentByAgentIdFormData;

  constructor(private agentService: AgentService, private dynamicFieldManager: DynamicFieldManager) {
      super();
      this.instance = this;
  }

  ngOnInit() {
	console.log("Neil:: init", this.constructor.name);
  }
  ngOnDestroy(){
	console.log("Neil:: destroy", this.constructor.name);
  }

  ngAfterViewInit() {
	console.log("Neil:: after view init", this.constructor.name);
    //   setTimeout(() => {
    //       this.setRightListPrimary();
    //       this.saveSuccessful();
    //   }, 3000);
  }

  ngOnChanges() {
	console.log("Neil:: changes", this.constructor.name);
	this.locationsFormData = new AgentByAgentIdFormData(Section.AGENT_LOCATIONS, 0, this.dynamicFieldManager, this.agentService, this.agent.agentId);
	  this.buildComplete();
	}

  saveSuccessful() {
	  this.onSave.emit();
  }

  buildComplete(): any {
      this.setRightListPrimary();
  }

  setRightListPrimary() {
      this.appFormLocations.setRightListFirstIsPrimary(true);
  }

}
