import { AxonUtils } from './../../utils/axon-utils';
import { FilterOption } from './../../component/material-table/material-table.component';
import { GroupListRequest, AppDataRequest, SystemAuditTrail, DataRequest, DynamicLink, DynamicField, SystemInfo, PinProtection, DynamicLinkRequest } from './../../dto/dtos';
import { HttpSystemService } from './../http/http-system-service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { DataResponse } from '../../dto/dtos';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AxonService } from '../axon-service';
import { Notifier } from '../../utils/notifier';
import { Section } from '../../utils/constants';
import { SectionUtils } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
/**
 * Entry point for components requiring access to agent data
 */
export class SystemService extends AxonService {

  	private httpSystemService: HttpSystemService;

  constructor(_httpClient: HttpClient, _authService: AuthService, _notifier: Notifier) {
    super(_httpClient, _authService, _notifier);
  }
	
  	getProtectionPin(): Promise<PinProtection> {
		this.httpSystemService = new HttpSystemService(this.httpClient, this.authService, "/system", this.notifier);
		return new Promise<PinProtection>((resolve, reject) => {
			this.httpSystemService.post({
				agentId: this.authService.getAuthAgent().agentId
			}, "/getpin").pipe(
				map(response => {
					return response;
				})
			).subscribe(result => {
				if (result.success === false) {
					reject(result.error);
				} else {
					resolve(result.data);
				}
			});
		});			
	}	

	/**
	 * Fetches Api Version from the database
	 */
	public getSystemInfo(): Observable<SystemInfo> {
		this.httpSystemService = new HttpSystemService(this.httpClient, this.authService, "/actuator", this.notifier);
		return this.httpSystemService.getUnwrappedData('/info');
	}
	
	/**
	 * Add new Grouplist to the database
	 * @param grouplist the grouplist to add
	 * @param maxGrouplistPosition the current max position of the grouplists
	 */

  public addNewGroupList(grouplist: GroupListRequest, path): Observable<DataResponse> {
    this.httpSystemService = new HttpSystemService(this.httpClient, this.authService, "/system", this.notifier);
    console.log(grouplist);
    return this.httpSystemService.addNewGroupList(grouplist, path);
  }

  /**
   * Removes a grouplist from database
   * @param grouplist the grouplist to remove
   */
  public removeGroupList(grouplist: GroupListRequest, path) {
    this.httpSystemService = new HttpSystemService(this.httpClient, this.authService, "/system", this.notifier);

    return this.httpSystemService.removeGroupList(grouplist, path);
  }

  /**
   * updates a grouplist setting in the database
   * @param grouplist the grouplist to update
   */
  public updateGroupList(grouplist: GroupListRequest, path) {
    this.httpSystemService = new HttpSystemService(this.httpClient, this.authService, "/system", this.notifier);
    return this.httpSystemService.updateGroupList(grouplist, path);
  }

  /**
   * updates a grouplist setting in the database
   * @param grouplist the grouplist to update
   */
  public updateAppData(request, path) {
    this.httpSystemService = new HttpSystemService(this.httpClient, this.authService, "/system", this.notifier);

    return this.httpSystemService.updateAppData(request, path);
  }

  public updatePasswordStrength(passwordStrength, path) {
    this.httpSystemService = new HttpSystemService(this.httpClient, this.authService, "/system", this.notifier);
    return this.httpSystemService.updatePasswordStrength(passwordStrength, path);
  }

  public fetchAgeRange(path?: string) {
    path = (path === undefined) ? '/fetchagerange' : path;
    this.httpSystemService = new HttpSystemService(this.httpClient, this.authService, "/system", this.notifier);
    return this.httpSystemService.get(path);
  }

  public postDynamicFieldRequest(ageRangeRequest, path: string) {
    this.httpSystemService = new HttpSystemService(this.httpClient, this.authService, "/system", this.notifier);
    return this.httpSystemService.postDynamicFieldRequest(ageRangeRequest, path);
  }

	/**
	 * Fetches Grouplist from the database
	 */
  public getGroupList(path): Observable<DataResponse> {
    this.httpSystemService = new HttpSystemService(this.httpClient, this.authService, "/system", this.notifier);
    return this.httpSystemService.get(path).pipe(
      map(response => {
        return response;
      })
    );
  }

  /**
   * Fetches all audit actions
   */

  public getAuditActions(path): Observable<DataResponse> {
    this.httpSystemService = new HttpSystemService(this.httpClient, this.authService, "/audittrail", this.notifier);
    return this.httpSystemService.get(path).pipe(
      map(response => {
        return response;
      })
    );
  }

  /**
   * Fetches Agents
   */

  public getAgents(path): Observable<DataResponse> {
    this.httpSystemService = new HttpSystemService(this.httpClient, this.authService, "/audittrail", this.notifier);
    return this.httpSystemService.get(path).pipe(
      map(response => {
        return response;
      })
    );
  }

  /**
   *
   * @param path Fetches devices
   */
  public getDevices(path): Observable<DataResponse> {
    this.httpSystemService = new HttpSystemService(this.httpClient, this.authService, "/audittrail", this.notifier);
    return this.httpSystemService.get(path).pipe(
      map(response => {
        return response;
      })
    );
  }
  
  /**
   *
   * @param path find all audit trail that matches the condition
   */
  public searchAuditTrail1(auditSearch: SystemAuditTrail, path: string): Observable<DataResponse> {
    this.httpSystemService = new HttpSystemService(this.httpClient, this.authService, "/audittrail", this.notifier);
    return this.httpSystemService.searchAuditTrail(auditSearch, path).pipe(
      map(response => {
        return response;
      })
    );
  }

    /**
     * Fetches agents for table data
     * @param pageNum
     * @param pageMax
     * @param filterOptions
     */
    public getSysteAuditTrails(pageNum: number, pageMax: number, filterOptions: FilterOption[]): Observable<DataResponse> {

      const dataRequest: DataRequest = {
          pageNum: pageNum,
          pageMax: pageMax,
          filterOptions: filterOptions
      };

      return this.getDataResponse(dataRequest, '/audittrails', '/audittrail');
  }

    /**
     * Fetches the audit trail for a specific agent
     * @param axonId
     * @param pageNum
     * @param pageMax
     * @param filterOptions
     */
    public getAgentAuditTrails(axonId: number, pageNum: number, pageMax: number, filterOptions: FilterOption[]): Observable<DataResponse> {
        const dataRequest: DataRequest = {
            identifierNum: axonId,
            pageNum: pageNum,
            pageMax: pageMax,
            filterOptions: filterOptions
        };

        return this.getDataResponse(dataRequest, '/audittrails', '/audittrail');
    }

    public getSettingsFormData ( section: Section ): Observable<DataResponse> {

        const dataRequest: DataRequest = {
            sectionId: SectionUtils.getSectionId(section),
            cardId: 0
        };

        return this.getDataResponse(dataRequest, '/settings', '/dynamicdata');
    }

    /**
       * Fetches a single agent by agent ID for form data.
       * @param path
       * @param identifierNum
       */
      public getAgentFormDataByAgentId( sectionId: number, identifierNum: number ): Observable<DataResponse> {
        const dataRequest: DataRequest = {
            sectionId: sectionId,
            cardId: 0,
            identifierNum: identifierNum
        };

        return this.getDataResponse(dataRequest, '/agentid', '/dynamicdata');
    }

    public saveFormData( section: Section, dynamicLink: DynamicLink ): Observable<DataResponse> {
        const dataRequest: DataRequest = {
            sectionId: SectionUtils.getSectionId(section),
            agentId: this.authService.getAuthAgent().agentId,
            dynamicLink: dynamicLink
        };

        return this.getDataResponse(dataRequest, '/save', '/dynamicdata');
	}
	
    public saveFormDataWithPin( section: Section, dynamicLink: DynamicLinkRequest ): Observable<DataResponse> {
        const dataRequest: DataRequest = {
            sectionId: SectionUtils.getSectionId(section),
            agentId: this.authService.getAuthAgent().agentId,
			dynamicLink: dynamicLink.dynamicLinkForServer,
			pin: dynamicLink.pin
        };

        return this.getDataResponse(dataRequest, '/save', '/dynamicdata');
    }	

	public sendOtp( msisdn: string, otpMessage?: string, grouplistId?: number, grouplistPosition?: number ): Observable<any> {
        const request = {
			agentId: this.authService.getAuthAgent().agentId,
            grouplistId: grouplistId,
            grouplistPosition: grouplistPosition,
            otpMessage: otpMessage,
			msisdn: msisdn
        };

        return this.getDataResponse(request, '/sendotp', '/sms');
    }

    /**
     * Helper to get the observable containing the response
     * @param dataRequest
     * @param subpath - the subpath following the rootpath
     * @param rootpath
     */
    public getDataResponse( dataRequest: DataRequest, subpath: string, rootpath: string ): Observable<DataResponse> {

      this.httpSystemService = new HttpSystemService(this.httpClient, this.authService, rootpath, this.notifier);
      return this.httpSystemService.searchAuditTrail(dataRequest, subpath).pipe(
          map(response => {

              if ( response.data ) {
                  if ( response.data.birthdate ) {
                      response.data.birthdate = AxonUtils.getDate(response.data.birthdate);
                  }
      }
              /* Put the elementId on for the tables, to allow for maintaining selected/checkbox state */
              if (response.dataList) {
                  response.dataList.forEach(row => {
                      row.elementId = row.agentId;
                  });
              }
              return response;
          })
      );
  }
}
