import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataRequest } from '../../dto/dtos';
import { AgentService } from '../../services/agents/agent.service';
import { Router } from '@angular/router';
import { Notifier } from '../../utils/notifier';
import { Storage, KEY_CONFIGURATION } from '../../utils/storage';
import { AbstractHttpService } from '../../services/http/http-abstract-service';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-terms',
  templateUrl: 'terms.component.html'
})
export class TermsComponent implements OnInit {

    status: string = "";

    constructor(
            private storage: Storage,
            private authService: AuthService,
            public activeModal: NgbActiveModal) {}

    async ngOnInit() {

        const configFile = JSON.parse(this.storage.getItem(KEY_CONFIGURATION));
        const termsPath = configFile['terms_and_conditions'];
        const termsUrl = AbstractHttpService.BASE_URL + "/../" + termsPath;

        this.terms = await this.fetchTerms(termsUrl);
    }

    fetchTerms(url: string): Promise<string> {

      return new Promise<string>(resolve => {

          const request = new Request(url, {
              headers: new Headers({
                'Content-Type': 'text/plain'
              })
          });

          fetch(request).then(function(response) {
              return response.text();
          }).then(function(text) {
              resolve(text);
          });
      });
    }

    get terms(): string {
        return this.authService.terms;
    }

    set terms(terms: string) {
        this.authService.terms = terms;
    }
}
