import { Component, Input, ViewChild, OnChanges, SimpleChanges, OnInit, ɵConsole } from "@angular/core";
import { AxonComponent } from "../../axon.component";
import { DynamicCard, DynamicField, Agent, DynamicLink, LinkedLists, Node } from "../../dto/dtos";
import { FormComponent } from "../../component/form/form.component";
import { AgentService } from "../../services/agents/agent.service";
import { DynamicFieldManager } from "../../settings/dynamic-fields/dynamic-field-manager";
import { AgentDetailsComponent } from "../modal/agent-details/agent-details.component";
import { ServerError } from "../../utils/server-errors";
import { Router, ActivatedRoute } from "@angular/router";
import { AgentModalComponent } from "../modal/agent-modal.component";
import { Notifier } from "../../utils/notifier";
import { AgentActionModalComponent } from "../modal/agent-action/agent-action-modal/agent-action-modal.component";
import { NgbModalOptions, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "../../services/auth/auth.service";
import { AxonUtils } from "../../utils/axon-utils";
import { Permission as PermissionEnum, NO_PERMISSION_FOR_ACTION } from "../../utils/constants";
import { AgentSingularActionModalComponent } from "../modal/agent-singular-action/agent-singular-action-modal/agent-singular-action-modal.component";
import { environment } from "../../../environments/environment";

@Component({
	selector: 'app-agent-dynamic-card',
	templateUrl: './agent-dynamic-card.component.html',
	styleUrls: ['./agent-dynamic-card.component.scss']
})
export class AgentDynamicCardComponent extends AxonComponent implements OnInit, OnChanges {

	@Input() agent: Agent;
	@Input() sectionId: number;
	@Input() dynamicCard: DynamicCard;
	@Input() dynamicLink: DynamicLink;
	@Input() fieldList: Array<DynamicField>;
	@Input() showImageUrl: boolean;
	@Input() parentComponent: AxonComponent;
	@Input() rejectionView: boolean;
	@Input() heading: string;
	@Input() editting: boolean;
	@Input() editCallback: Function;
	@Input() formData: any;
	@Input() confirmSave: any;

	@ViewChild(FormComponent) formComponent: FormComponent;

	instance: AgentDynamicCardComponent;

	isLoadingResults = false;
	confirmSaveDetails: any;
	provisioningAgent: Agent;
	singular: string;
	pastTense: string;
	path: string;
	action: string;

	env = environment;

	constructor(
		private agentService: AgentService,
		private dynamicFieldMgr: DynamicFieldManager,
		private authService: AuthService,
		private router: Router,
		private modalService: NgbModal,
		private notifier: Notifier,
		private route: ActivatedRoute
	) {
		super();
		this.instance = this;
	}

	ngOnInit() {
		if (this.confirmSave !== undefined) {
			this.confirmSaveDetails = this.confirmSave;
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.refresh();
	}

	edit() {
		if (AxonUtils.checkActionPermission(PermissionEnum.AGENT_ADMIN_MODIFY, this.authService, this.notifier)) {
			this.editCallback();
		}
	}

	saveForm() {
		this.parentComponent.save(this.formComponent);
	}

	refresh() {

		this.agentService.getAgentFormDataByAgentId(this.sectionId, this.agent.agentId).subscribe((result) => {
			console.log('Response for section ID', this.sectionId);
			console.log('Response for refresh', result);
			this.dynamicLink = result.data;

			if (this.dynamicLink) {

				this.dynamicLink.cards.forEach(card => {
					this.dynamicCard = card;
					this.fieldList = card.fields;
				});

				for (let j = 0; j < this.dynamicLink.cards.length; j++) {
					for (let i = 0; i < this.dynamicLink.cards[j].fields.length; i++) {
						const field = this.dynamicLink.cards[j].fields[i];

						if (field.inputField === 'chooser') {
							const value = AxonUtils.getChooserValue(field, this.dynamicFieldMgr);
							field.value = value;
						}

						if (field.field === 'Provisioning Agent ID' && field.value !== undefined) {
							this.getProvisioningAgent(field.value);
						}
					}
				}
			}
		});
	}

	/**
	* Fetches full agent data from the server
	* @param provisioningAgentId
	* @param callback an optional callback method (remember to bind callee's "this" object to the the callee)
	*/
	public getProvisioningAgent(provisioningAgentId: number) {
		console.log("LOoking for agent with ID " + provisioningAgentId);
		this.agentService.getAgent(provisioningAgentId).subscribe(resp => {
			console.log('Provisioning agent fetch successful ? [' + resp.success + ']');
			if (resp.success) {
				console.log('Provisioning agent data: ' + JSON.stringify(resp.data));
				this.provisioningAgent = resp.data;
			} else {
				ServerError.printError(resp);
			}
			this.isLoadingResults = false;
		});
	}

    /**
     * Show provisioning agent details modal
     *
     * @param obj minimal details of the agent to be shown - most important thing here is agentId field
     */
	goToProvioningAgent() {
		// (<AgentsComponent>((<AgentModalComponent>((<AgentDetailsComponent>(this.parentComponent)).parentComponent)).parentComponent)).fetchAndDisplayAgent(this.provisioningAgent.agentId);
		this.router.navigate(['agents', 'view', this.provisioningAgent.agentId]);
	}

	async flagAgent() {
		// this.agentService.flagAgent(this.agent, "something clever over here").subscribe(resp => {
		// 	console.log('Flag agent successful? [' + resp.success + ']');
		// 	if (resp.success) {
		// 		console.log('Flagged agent data: ' + JSON.stringify(resp.data));
		// 		this.notifier.success("Successfully flagged agent")
		//         this.agent = resp.data;
		// 	} else {
		// 		ServerError.printError(resp);
		// 	}
		// 	this.isLoadingResults = false;
		// });

		this.path = '/flagagent'; this.pastTense = 'marked as fraudulent'; this.singular = 'apply a fraudulence red flag to';
		this.action = 'flag';
		await this.singularAction();
	}

	async unflagAgent() {
		// this.agentService.unflagAgent(this.agent, "something not so clever over here").subscribe(resp => {
		// 	console.log('Unflag agent successful? [' + resp.success + ']');
		// 	if (resp.success) {
		// 		console.log('Unflagged agent data: ' + JSON.stringify(resp.data));
		//         this.notifier.success("Successfully unflagged agent");
		//         this.agent = resp.data;
		// 	} else {
		// 		ServerError.printError(resp);
		// 	}
		// 	this.isLoadingResults = false;
		// });

		this.path = '/unflagagent'; this.pastTense = 'cleared of fraudulence';
		this.singular = 'remove the fraudulence red flag associated with';
		this.action = 'unflag';
		await this.singularAction();
	}

    /**
     * A generic method to handle bulk actions
     * @param path eg. '/blockall'handleButtonClick
     * @param pastTense eg 'blocked' or 'reset' so we can add it to notifier strings
     * @param singular eg 'block' or 'reset' so we can add it to notifier strings
     */
	async bulkAction() {
		const options: NgbModalOptions = {
			windowClass: "modal-editform-confirmclose",
			backdropClass: "fraud-backdrop"
		};
		const modalRef = this.modalService.open(AgentActionModalComponent, options);
		modalRef.componentInstance.singular = this.singular;
		modalRef.componentInstance.pastTense = this.pastTense;
		modalRef.componentInstance.path = this.path;
		modalRef.componentInstance.agentList = [this.agent.agentId];
		modalRef.componentInstance.agentDynamicCardComponent = this.instance;
	}

    /**
     * A generic method to handle singular actions using AgentSingularActionModalComponent
     * @param path eg. '/blockall'handleButtonClick
     * @param pastTense eg 'blocked' or 'reset' so we can add it to notifier strings
     * @param singular eg 'block' or 'reset' so we can add it to notifier strings
     */
	async singularAction() {
		const options: NgbModalOptions = {
			windowClass: "modal-editform-confirmclose",
			backdropClass: "fraud-backdrop"
		};
		const modalRef = this.modalService.open(AgentSingularActionModalComponent, options);
		modalRef.componentInstance.singular = this.singular;
		modalRef.componentInstance.pastTense = this.pastTense;
		modalRef.componentInstance.path = this.path;
		modalRef.componentInstance.action = this.action;
		modalRef.componentInstance.agent = this.agent;
		modalRef.componentInstance.agentDynamicCardComponent = this.instance;
	}

	get canEdit(): boolean {
		if (!this.agent) {
			return true;
		}
		// console.log(`Can Edit.  Agent:[${this.agent.agentId}] : AuthAgent:[${this.authService.getAuthAgent().agentId}]`, this.authService.getAuthAgent());
		return this.agent.agentId !== this.authService.getAuthAgent().agentId;
	}
}
