import { Component, OnInit, Input, ViewChild, AfterViewInit, OnChanges, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Agent } from '../../../dto/dtos';
import { FormComponent } from '../../../component/form/form.component';
import { AgentByAgentIdFormData } from '../../../component/form/form-data/agents/agent-form-data';
import { AgentService } from '../../../services/agents/agent.service';
import { Section, Card } from '../../../utils/constants';
import { AxonComponent } from '../../../axon.component';
import { NewFormData } from '../../../component/form/form-data/new-form-data';
import { AgentModalComponent } from '../agent-modal.component';
import { DynamicFieldManager } from '../../../settings/dynamic-fields/dynamic-field-manager';
import { AuthService } from '../../../services/auth/auth.service';
import { AxonUtils } from '../../../utils/axon-utils';
import { Notifier } from '../../../utils/notifier';
import { Permission as PermissionEnum } from "../../../utils/constants";

@Component({
	selector: 'app-agent-permissions',
	templateUrl: './agent-permissions.component.html',
	styleUrls: ['./agent-permissions.component.scss']
})
export class AgentPermissionsComponent extends AxonComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

	@Input() agent: Agent;
	@ViewChild('appFormPermissions') appFormPermissions: FormComponent;

	instance: AgentPermissionsComponent;
	permissionsFormData: AgentByAgentIdFormData;

	showButton = true;

	permissionsEditable = true;
	permissionsNotEditableMessage = 'This agent can not edit their own permissions or roles';

	constructor(
		private agentService: AgentService,
		private authService: AuthService,
		private dynamicFieldMgr: DynamicFieldManager,
		private cdRef: ChangeDetectorRef,
		private notifier: Notifier) {

		super();
		this.instance = this;

		this.buildComplete = this.buildComplete.bind(this);
	}

	ngOnInit() {
		console.log("Neil:: init", this.constructor.name);
	}
	ngOnDestroy() {
		console.log("Neil:: destroy", this.constructor.name);
	}

	ngAfterViewInit() {
		console.log("Neil:: after view init", this.constructor.name);
		setTimeout(() => {
			this.setDatesForRightList();
			this.saveSuccessful();
		}, 4000);

		if (!AxonUtils.checkActionPermission(PermissionEnum.AGENT_ADMIN_PERMISSION, this.authService, this.notifier, false)) {

			console.log("Agent does not have required permissions to edit permissions");

			this.permissionsEditable = this.showButton = false;
			this.permissionsNotEditableMessage = "You do not have sufficient permissions to modify this agent's permissions";
		} else {

			console.log("Agent has permissions to edit permissions");
			console.log("Agent being editted = [" + this.agent.agentId + "]");
			console.log("Logged in agent = [" + this.authService.getAuthAgent().agentId + "]");
			if (this.agent.agentId === this.authService.getAuthAgent().agentId) {
				console.log("Agent is not allowed to edit their own permissions");
			}

			this.permissionsEditable = this.showButton = (this.agent.agentId !== this.authService.getAuthAgent().agentId);
		}
	}

	ngOnChanges() {
		console.log("Neil:: changes", this.constructor.name);
		this.permissionsFormData = new AgentByAgentIdFormData(
			Section.AGENT_PERMISSIONS, Card.AGENT_PERMISSIONS, this.dynamicFieldMgr, this.agentService, this.agent.agentId);
		this.setDatesForRightList();


	}

	saveSuccessful() {
	}

	buildComplete() {
		this.setDatesForRightList();
	}

	/** Use sparingly as it causes the dragndrop to flash */
	refresh() {
		this.appFormPermissions.updateFormData(this.permissionsFormData, () => {
			this.setDatesForRightList();
		});
	}

	setDatesForRightList() {

		const listWithDates = [];
		this.agent.permissions.forEach(permission => {
			listWithDates.push([permission.permission, permission.expiry]);
		});

		if (this.appFormPermissions && this.appFormPermissions.dragNDropRef) {
			this.appFormPermissions.dragNDropRef.setRightListDateValues(listWithDates);
		}
	}

}
