/*
For development use import * as $ from 'jquery' for intellisense.
For serving or building use declare var $:any - which loses intellisense
but resolves the issue with the error on usage of tooltip()
*/
import * as $ from 'jquery';
// declare var $: any;
import { Component, AfterViewInit, Input, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { AxonComponent } from '../../axon.component';
import { Agent } from '../../dto/dtos';
import { AgentService } from '../../services/agents/agent.service';

@Component({
	selector: 'app-navigation',
	styleUrls: ['./navigation.component.scss'],
	templateUrl: './navigation.component.html'
})

export class NavigationComponent extends AxonComponent implements OnInit, AfterViewInit {

	@Input() agent: Agent;

	name: string;
	showHide: boolean;
	loggedInUser: string = 'User';

	constructor(private authService: AuthService, private agentService: AgentService, private router: Router) {
		super();
		this.showHide = true;
	}

	ngOnInit() {
		this.agentService.getAgent(this.agent.agentId).subscribe(response => {
			this.loggedInUser = response.data.username;
		});

	}

	logout() {
		console.log('User is logging out');
		this.authService.deliberateUserLogout = true;
		this.authService.doLogoutAndRedirect();
		this.authService.deliberateUserLogout = false;

		// this.authService.deleteAuth();
		// this.router.navigate(['authentication/login']);
	}

	changeShowStatus() {
		this.showHide = !this.showHide;
	}

	ngAfterViewInit() {
		$(function () {
			$('.preloader').fadeOut();
		});

		$(document).on('click', '.mega-dropdown', function (e) {
			e.stopPropagation();
		});

		$('.search-box a, .search-box .app-search .srh-btn').on(
			'click',
			function () {
				$('.app-search').toggle(200);
			}
		);

		try {
			(<any>$('[data-toggle="tooltip"]')).tooltip();
		} catch (error) {}

		try {
			(<any>$('.scroll-sidebar')).slimScroll({
				position: 'left',
				size: '5px',
				height: '100%',
				color: '#dcdcdc'
			});
		} catch (error) {}

		try {
			(<any>$('.right-sidebar')).slimScroll({
				height: '100%',
				position: 'right',
				size: '5px',
				color: '#dcdcdc'
			});
		} catch (error) {}

		try {
			(<any>$('.message-center')).slimScroll({
				position: 'right',
				size: '5px',
				color: '#dcdcdc'
			});
		} catch (error) {}
		
		$('body').trigger('resize');
	}
}
