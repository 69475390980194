import { AxonService } from '../../../services/axon-service';
import { Observable } from 'rxjs';
import { DataResponse } from '../../../dto/dtos';
import { FilterOption } from '../material-table.component';

/**
 * Super class providing structure for fetching table data via http
 */
export abstract class TableData {
    constructor(private axonService: AxonService) {}

    // tslint:disable-next-line: max-line-length
    abstract getTableData(sort: string, order: string, page: number, pageSize: number, filterOptions: FilterOption[]): Observable<DataResponse>;
}
