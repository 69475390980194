import { Component, Input, OnChanges } from '@angular/core';
import { AxonComponent } from '../../axon.component';
import { Image, DynamicField, GroupLists, DynamicLink } from '../../dto/dtos';
import { ImageService } from '../../services/images/images.service';
import { ServerError } from '../../utils/server-errors';
import { NgScrollbar } from 'ngx-scrollbar';
import { finalize } from 'rxjs/operators';
import { Notifier } from '../../utils/notifier';
import { FormControl } from '@angular/forms';
import { RemoveUnderscorePipe } from '../../pipes/remove-underscore.pipe';
import { TitleCasePipe } from '@angular/common';
import { IDUpperCasePipe } from '../../pipes/id-uppercase.pipe';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OriginalImageDialogComponent } from '../original-image-dialog/original-image-dialog.component';
import { DynamicCharType, GROUPLIST } from '../../utils/constants';
import { GroupListsManager } from '../../settings/grouplists/group-lists-manager';
import { DynamicFieldManager } from '../../settings/dynamic-fields/dynamic-field-manager';
import imageToBase64 from 'image-to-base64/browser';
import { environment } from "../../../environments/environment";

const SCROLL_AMOUNT = 400;

@Component({
	selector: 'app-rejection-image-viewer',
	templateUrl: './rejection-image-viewer.component.html',
	styleUrls: ['./rejection-image-viewer.component.scss']
})
export class RejectionImageViewerComponent extends AxonComponent implements OnChanges {

	@Input() axonId: number;
	@Input() sectionId: number;
	@Input() fetchImages: boolean;

	private images: Image[];
	private singleScanImages: Image[];
	private multiScanImages: Image[];

	private viewingImage: Image;

    /*
    As the user clicks on a thumbnail, the image is downloaded, and then stored in this
    array - this is to prevent re-downloads of the same image from the server. The next
    time the user clicks on the same image, it will use the local previously downloaded
    copy from the array.
    */
	private originalImages: Image[];

	isLoadingResults = false;

	listCustomerIdCopy: Array<GroupLists>;
	listCustomerPortrait: Array<GroupLists>;
	listCustomerIdBack: Array<GroupLists>;
	listCustomerSignature: Array<GroupLists>;
	listSupportLetter: Array<GroupLists>;

	constructor(
		private imageService: ImageService,
		private notifier: Notifier,
		private modalService: NgbModal,
		public groupListsMgr: GroupListsManager,
		private dynamicFieldMgr: DynamicFieldManager) {
		super();

		this.listCustomerIdCopy = this.groupListsMgr.getGroupList(GROUPLIST.IDX_REJECTION_REASON_ID_COPY);
		this.listCustomerPortrait = this.groupListsMgr.getGroupList(GROUPLIST.IDX_REJECTION_REASON_PICTURE);
		this.listCustomerIdBack = this.groupListsMgr.getGroupList(GROUPLIST.IDX_REJECTION_REASON_ID_COPY_BACK);
		this.listCustomerSignature = this.groupListsMgr.getGroupList(GROUPLIST.IDX_REJECTION_REASON_SIGNATURE);
		this.listSupportLetter = this.groupListsMgr.getGroupList(GROUPLIST.IDX_REJECTION_REASON_SUPPORT_DOCS);
	}

	ngOnChanges() {
		console.log('Fetch images [' + this.fetchImages + ']');
		if (this.fetchImages) {
			/* On init, show the first image in the list */
			this.getRejectionImages();
		}
	}

	private getRejectionImages() {

		this.isLoadingResults = true;
		console.log('Fetching all images for customer rejection view');
		this.imageService.getRejectionViewImages(this.axonId, this.sectionId)
			.pipe(
				finalize(
					() => {
						this.isLoadingResults = false;
					}
				)
			)
			.subscribe(
				resp => {
					console.log('Fetching images for rejection view successful ? [' + resp.success + ']');
					if (resp.success) {
						if (resp.dataList !== null) {
							this.images = resp.dataList;

							if ( environment.show_rejection_non_existent_image_placeholder ) {
								/* ET wanted the ability to reject a record when the user does NOT capture the image. To do this, we need to show all images in the section */
								console.log( "Images: Adding missing images as placeholders" );
								var dl: DynamicLink = this.dynamicFieldMgr.getFieldsBySectionId( this.sectionId );
								console.log( "Images: Looping through cards and fields" );

								imageToBase64("../../../assets/images/Placeholder.png") // Path to the image
								.then(
									(imgData) => {
										console.log(imgData); // "cGF0aC90by9maWxlLmpwZw=="

										for ( const card of dl.cards ) {
											for ( const field of card.fields ) {

												if ( field.charType === DynamicCharType.BLOB) {

													let addMissingImage: boolean = true;
													for ( const img of this.images ) {
														if ( field.id === img.fieldId ) {
															console.log( "Images: Image with field ID [" + img.fieldId + "] exists in the images list - will not add" );
															addMissingImage = false;
															break;
														}	
													}
		
													if ( addMissingImage ) {

														console.log( "Images: Found a blob char type of image [" + field.field + "]" );
														let image: Image = {
															id: 0,
															type: field.field,
															fieldId: field.id,
															image: "data:image/png;base64," + imgData,
															size: 1,
															ext: "png",
															pagesCount: 0,
															pageNumber: 0,
															datetime: new Date,
															thumbImageUrl: "data:image/png;base64," + imgData,
															imageUrl: "data:image/png;base64," + imgData,
															isPlaceholder: true,
														};
														console.log( "Images: Adding image field ID [" + image.fieldId + "] to images array" );
														this.images.push(image);
													}
												}
											}
										}

										this.prepareImageControl();

									}
								)
								.catch(
									(error) => {
										console.log(error); // Logs an error if there was one
									}
								)
							} else {
								this.prepareImageControl();
							}

							
						} else {
							this.notifier.warn('No images found');
						}
					} else {
						this.notifier.error(ServerError.formatError(resp));
						ServerError.printError(resp);
					}
				}, error => {
					console.log("Error fetching customer's images", error);
					this.notifier.error("An error occurred fetching the customer's images");
				}
			);
	}

	private prepareImageControl() {
		for (const image of this.images) {
			image.rejectionCheckControl = new FormControl();
			image.rejectionReasonControl = new FormControl();
			image.showRejectionReason = false;
			image.rejectionReason = null;
		}
		this.singleScanImages = this.images.filter(item => item.type !== 'multiscan');
		this.multiScanImages = this.images.filter(item => item.type === 'multiscan');
	}

	
	private addPlaceHolderImages() {
		console.log( "Images: Adding missing images as placeholders" );
		var dl: DynamicLink = this.dynamicFieldMgr.getFieldsBySectionId( this.sectionId );
		console.log( "Images: Looping through cards and fields" );

		imageToBase64("../../../assets/images/Placeholder.png") // Path to the image
		.then(
			(imgData) => {
				console.log(imgData); // "cGF0aC90by9maWxlLmpwZw=="

				for ( const card of dl.cards ) {
					for ( const field of card.fields ) {
						if ( field.charType === DynamicCharType.BLOB) {
							console.log( "Images: Found a blob char type of image [" + field.field + "]" );
							let image: Image = {
								id: 0,
								type: field.field,
								image: "data:image/png;base64," + imgData,
								size: 1,
								ext: "png",
								pagesCount: 0,
								pageNumber: 0,
								datetime: new Date,
								thumbImageUrl: "data:image/png;base64," + imgData,
								imageUrl: "data:image/png;base64," + imgData,
								isPlaceholder: true,
								hashCode: new Date().getUTCMilliseconds(),
							};
							console.log( "Images: Adding image to images array" );
							this.images.push(image);
						}
					}
				}
			}
		)
		.catch(
			(error) => {
				console.log(error); // Logs an error if there was one
			}
		)

		
	}

	rejectBoxChange(event: any, imageFieldId: number) {
		for (const image of this.images) {
			if (image.fieldId === imageFieldId) {
				image.showRejectionReason = event.checked;
				image.rejectionReasonControl.markAsUntouched();
				image.rejectionReasonControl.setValue(null);
				image.rejectionReason = null;
				return;
			}
		}
	}

    /**
     * Creates a list of DynamicFields representing rejected fields.
     * Rejection reason and field ID are copied from the images list, into the
     * returned list, only if the image was rejected.
     */
	getRejectedFields(): Array<DynamicField> {
		const fields = new Array<DynamicField>();

		const removeUnderscore: RemoveUnderscorePipe = new RemoveUnderscorePipe();
		const titlecase: TitleCasePipe = new TitleCasePipe();
		const idUppercase: IDUpperCasePipe = new IDUpperCasePipe();

		for (const image of this.images) {

			if (image.rejectionReasonControl.value !== null) {

				let imageType = removeUnderscore.transform(image.type);
				imageType = titlecase.transform(imageType);
				imageType = idUppercase.transform(imageType);

				const dynamicField: DynamicField = {
					id: image.fieldId,
					field: imageType,
					value: 'N/A',
					rejectionReason: image.rejectionReasonControl.value
				};

				console.log('added field id: ' + dynamicField.id);

				fields.push(dynamicField);
			}
		}

		return fields;
	}

	private viewOriginal(image: Image) {
		this.getImage(image.id);
	}

    /**
     * Fetches an image using the axon ID and given imageId from the server for displaying,
     * or from the originalImages array, if it was previously downloaded
     * in the viewing image section
     * @param imageType
     */
	getImage(imageId: number) {

		/* Check if image exists in the originalImages array - before calling server */
		let imgFound: Boolean = false;
		if (this.originalImages) {
			for (const img of this.originalImages) {
				if (img.id === imageId) {
					console.log('Found previously downloaded image. Not fetching from server - displaying local copy.');
					this.viewingImage = img;
					imgFound = true;
					this.showImage();
					break;
				}
			}
		}

		/* If the image doesn't exist in the originalImages array, then download and add it */
		if (!imgFound) {
			this.isLoadingResults = true;
			console.log('No previously downloaded local copy image found. ' +
				'Fetching image for Axon ID [' + this.axonId + '] with Image ID [' + imageId + ']');
			this.imageService.getImage(this.axonId, imageId, true).subscribe(
				resp => {
					console.log('Image fetch successful ? [' + resp.success + ']');
					if (resp.success) {
						// console.log('Customer data: ' + JSON.stringify(resp.data));

						this.viewingImage = resp.data;

						/* Add the image to full image list - so next time this image is viewed we don't need to call server */
						if (!this.originalImages) {
							this.originalImages = new Array<Image>();
						}

						this.originalImages.push(this.viewingImage);
						this.showImage();
					} else {
						ServerError.printError(resp);
					}
					this.isLoadingResults = false;
				}
			);
		}
	}

	private showImage() {
		const modalRef = this.modalService.open(OriginalImageDialogComponent, { windowClass: "modal-original-image" });
		modalRef.componentInstance.viewingImage = this.viewingImage;
	}

	getGrouplistForImage(image) {

		switch (image.type) {
			case 'customer_id_copy':
				return this.listCustomerIdCopy;
			case 'customer_portrait':
				return this.listCustomerPortrait;
			case 'customer_id_copy_back':
				return this.listCustomerIdBack;
			case 'customer_signature':
			case 'sales_agent_signature':
			case 'customer_signature_transfer':
				return this.listCustomerSignature;
			default:
				return this.listSupportLetter;
		}
	}
	getGrouplistForImageMultiLingual(image) {
		const groupLists = this.getGrouplistForImage(image);
		const out: GroupLists[] = [];
		
		for (const groupList of groupLists) {
			const text = groupList.text; // + '/' + groupList.text.toUpperCase();

			if (text.indexOf('/') === -1) {
				out.push({
					id: groupList.id,
					position: groupList.position,
					language: groupList.language,
					otherId: groupList.otherId,
					text: groupList.text,
					otherText: groupList.text
				});
			} else {
				out.push({
					id: groupList.id,
					position: groupList.position,
					language: groupList.language,
					otherId: groupList.otherId,
					text: groupList.text,
					otherText: text.substring(0, text.indexOf('/'))
				});
				out.push({
					id: groupList.id,
					position: groupList.position,
					language: groupList.language,
					otherId: groupList.otherId,
					text: groupList.text,
					otherText: text.substring(text.indexOf('/') + 1)
				});
			}
		}
		return out;
	}
	groupListTrackBy(index, item: GroupLists) {
		return item.text;
	}
	imageTrackBy(index, item: Image) {
		return item.fieldId;
	}
}
