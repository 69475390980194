import { AxonService } from '../../../services/axon-service';
import { Observable } from 'rxjs';
import { DataResponse, DynamicLink, DynamicLinkRequest } from '../../../dto/dtos';
import { Section } from '../../../utils/constants';
import { SectionUtils } from '../../../../environments/environment';
import { DynamicFieldManager } from '../../../settings/dynamic-fields/dynamic-field-manager';
import { SystemService } from '../../../services/system/system.service';

export interface Save {
    formName: string;
    state: Map<string, any>;
}

/**
 * Super class providing structure for fetching table data via http
 */
export abstract class FormData {

    private newMode: boolean;
    private persistChanges: boolean;
    private multiColumn = false;

    dynamicLink: DynamicLink;

    constructor(
        protected section: Section,
        protected cardId: number,
		private dynamicFieldMgr: DynamicFieldManager,
        private axonService?: AxonService,
        protected identifierNum?: number,
        protected identifierStr?: string,
		) {

            /* Assume new mode if there is no property to identify anything provided */
            if ( this.identifierNum == null && this.identifierStr == null ) {
                this.newMode = true;
            }
        }

    abstract getFormData(): Observable<DataResponse>;

    /*
    Used to send data to the server
    @param dynamicLink - the input data to send to server
    @param axonService - optional service to use to send the data
    */
    abstract saveFormData(identifierNum: number, dynamicLink: DynamicLink, axonService?: AxonService): Observable<DataResponse>;

	saveFormDataWithPin(identifierNum: number, dynamicLink: DynamicLinkRequest): Observable<DataResponse> {
		return null;
	}

    getSectionId() {
        return SectionUtils.getSectionId(this.section);
    }

	getCardId() {
        return this.cardId;
    }

    /**
     * Returns true if the form is in new mode. False if the form is in edit mode.
     */
    isNewMode() {
        this.newMode = ( this.newMode === undefined ) ? ( this.identifierNum == null && this.identifierStr == null ) : this.newMode;
        return this.newMode;
    }

    setPersistChanges(on: boolean) {
        this.persistChanges = on;
    }

    /**
     *
     * @param save A Save object with a name and containing a Map of name, value pairs to respresent a forms state
     */
    persist(save: Save) {
        if (this.persistChanges) {
            try {
                this.axonService[save.formName] = save;
            } catch (exception) {
                console.error("Save " + save.formName + " failed in " + this.axonService.constructor.name);
                console.error(exception);
            }
        }
    }

    /**
     * Call this whenever you want to restore the state if you have set persistChanges to true
     * @param formName
     */
    restore(formName: string): Save {

        try {
            return this.axonService[formName];
        } catch (exception) {
            console.error("Save " + formName + " not found in service " + this.axonService.constructor.name);
            console.error(exception);
        }
    }

    clear(formName: string) {
        try {
            this.axonService[formName] = undefined;
            delete(this.axonService[formName]);
        } catch (exception) {
            console.error("clear " + formName + " failed for service " + this.axonService.constructor.name);
            console.error(exception);
        }
    }

    setMultiColumn(isMultiColumn: boolean) {
        this.multiColumn = isMultiColumn;
    }

	createNewDynamicLink() {
		if (this.cardId !== 0) {
			this.dynamicLink = this.dynamicFieldMgr.getFieldsBySectionIdCardId(this.getSectionId(), this.cardId);
		} else {
			this.dynamicLink = this.dynamicFieldMgr.getFieldsBySectionId(this.getSectionId());
		}
	}
}
