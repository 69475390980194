
import { Observable } from 'rxjs';
import { DataResponse } from '../../../../dto/dtos';
import { TableData } from '../table-data';
import { FilterOption } from '../../material-table.component';
import { CustomerService } from '../../../../services/customers/customer.service';

/**
 * Overrides getTableData by fetching submission history table data
 */
export class ArchiveDataTableData extends TableData {

    private msisdn: string;

    constructor(msisdn: string, private customerService: CustomerService) {
        super(customerService);

        this.msisdn = msisdn;
    }

    getTableData(sort: string, order: string, page: number, pageSize: number, filterOptions: FilterOption[]): Observable<DataResponse> {
        return this.customerService.getArchiveData(this.msisdn);
    }
}
