import { Component, Input, OnInit, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import imageToBase64 from 'image-to-base64/browser';
import { DynamicField, FormDataDto, Image } from '../../../../dto/dtos';
import { DynamicInputField } from '../../../../utils/constants';
import { DynamicImageUploadComponent } from './dynamic-image-upload/dynamic-image-upload.component';

@Component({
    selector: 'app-dynamic-image',
    templateUrl: './dynamic-images.component.html',
    styleUrls: ['./dynamic-images.component.scss']
})
export class DynamicImageComponent implements OnInit {
	
	@Input() formDataDto: FormDataDto;
	@Output() onImageUploaded = new EventEmitter();
	images: Array<Image>;
	displayFields: Array<Image>;
	signatureFields: Array<Image>;

	imageListReady: boolean;

	title: string;

	constructor(private modalService: NgbModal, private domSanitizer: DomSanitizer) {}

	ngOnInit(): void {
		this.title = this.formDataDto.data.cards[0].card;

		this.imageListReady = false;
		
		const card = this.formDataDto.data.cards[0];

		for ( const field of card.fields ) {
			switch (field.inputField) {
				case DynamicInputField.DISPLAY_CONTRACT:
					this.buildDisplayContractImageField(field);
					break;
				case DynamicInputField.SIGNBOX:
					this.buildSignatureImageField(field);
					break;
				default:
					this.buildImageField(field);
					break;
			}
		}
		this.imageListReady = true;
	}

	viewOriginal(image: Image) {
		if ( !image.isPlaceholder ) {
			console.log('Viewing image', image);
			const tab = window.open("", "_blank");
			tab.document.write("<iframe width='100%' height='100%' src='" + image.imageUrl + "'></iframe>");
		}
	}

	upload(image: Image) {
		let ngbModalOptions: NgbModalOptions = {
			backdrop : 'static',
			keyboard : false,
			windowClass: "modal-cust-img-upload"
	  	};
		const modalRef = this.modalService.open(DynamicImageUploadComponent, ngbModalOptions);
		modalRef.componentInstance.image = image;
		modalRef.componentInstance.sendImage.subscribe(($e) => {
			console.log('Image component image upload success event', $e);
			image.image = $e.result;
			image.imageUrl = $e.result;
			image.safeImageUrl = this.domSanitizer.bypassSecurityTrustUrl($e.result);
			image.isPlaceholder = false;
			this.onImageUploaded.emit({'image': image, 'modalRef':modalRef});
		});
	}

	acceptance($e) {
		console.log("Images received acceptance", $e);

		for (let image of this.displayFields) {
			if ($e.image_field_id === image.fieldId) {
				image.image = $e.signature;
				image.imageUrl = $e.signature;
				image.safeImageUrl = this.domSanitizer.bypassSecurityTrustUrl($e.signature);
				image.isPlaceholder = false;
				this.onImageUploaded.emit({'image': image});
				break;
			}
			
		}
	}

	signature($e) {
		console.log("Images received signature", $e);

		for (let image of this.signatureFields) {
			if ($e.image_field_id === image.fieldId) {
				image.image = $e.signature;
				image.imageUrl = $e.signature;
				image.safeImageUrl = this.domSanitizer.bypassSecurityTrustUrl($e.signature);
				image.isPlaceholder = false;
				this.onImageUploaded.emit({'image': image});
				break;
			}
			
		}
	}

	buildDisplayContractImageField(field: DynamicField) {
		if ( !this.displayFields ) {
			this.displayFields = new Array<Image>();
		}

		imageToBase64('../../../assets/images/placeholder-signature.png') // Path to the image
		.then(
			(imgData) => {

				const image: Image = {
					id: 0,
					type: field.field,
					fieldId: field.id,
					image: "data:image/png;base64," + (field.value ? field.value : imgData),
					size: 1,
					ext: "png",
					pagesCount: 0,
					pageNumber: 0,
					datetime: new Date,
					thumbImageUrl: "data:image/png;base64," + (field.value ? field.value : imgData),
					imageUrl: "data:image/png;base64," + (field.value ? field.value : imgData),
					safeImageUrl: this.domSanitizer.bypassSecurityTrustUrl("data:image/png;base64," + (field.value ? field.value : imgData)),
					isPlaceholder: (field.value ? false : true),
					isMandatory: field.mandatory,
					hidden: field.hidden,
					field: field,
				};

				this.displayFields.push(image);
			}
		)
		.catch(
			(error) => {
				console.log(error); // Logs an error if there was one
			}
		)
	}

	buildSignatureImageField(field: DynamicField) {
		if ( !this.signatureFields ) {
			this.signatureFields = new Array<Image>();
		}

		imageToBase64('../../../assets/images/placeholder-signature.png') // Path to the image
		.then(
			(imgData) => {

				const image: Image = {
					id: 0,
					type: field.field,
					fieldId: field.id,
					image: "data:image/png;base64," + (field.value ? field.value : imgData),
					size: 1,
					ext: "png",
					pagesCount: 0,
					pageNumber: 0,
					datetime: new Date,
					thumbImageUrl: "data:image/png;base64," + (field.value ? field.value : imgData),
					imageUrl: "data:image/png;base64," + (field.value ? field.value : imgData),
					safeImageUrl: this.domSanitizer.bypassSecurityTrustUrl("data:image/png;base64," + (field.value ? field.value : imgData)),
					isPlaceholder: (field.value ? false : true),
					isMandatory: field.mandatory,
					hidden: field.hidden,
					field: field,
				};

				this.signatureFields.push(image);
			}
		)
		.catch(
			(error) => {
				console.log(error); // Logs an error if there was one
			}
		)
	}

	buildImageField(field: DynamicField) {

		if ( !this.images ) {
			this.images = new Array<Image>();
		}

		imageToBase64('../../../assets/images/placeholder-img.jpeg') // Path to the image
		.then(
			(imgData) => {

				const image: Image = {
					id: 0,
					type: field.field,
					fieldId: field.id,
					image: "data:image/png;base64," + (field.value ? field.value : imgData),
					size: 1,
					ext: "png",
					pagesCount: 0,
					pageNumber: 0,
					datetime: new Date,
					thumbImageUrl: "data:image/png;base64," + (field.value ? field.value : imgData),
					imageUrl: "data:image/png;base64," + (field.value ? field.value : imgData),
					safeImageUrl: this.domSanitizer.bypassSecurityTrustUrl("data:image/png;base64," + (field.value ? field.value : imgData)),
					isPlaceholder: (field.value ? false : true),
					isMandatory: field.mandatory,
					hidden: field.hidden,
				};

				this.images.push(image);
			}
		)
		.catch(
			(error) => {
				console.log(error); // Logs an error if there was one
			}
		)
	}
}