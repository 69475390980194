import { Component, ViewChildren, QueryList, Input, Output, EventEmitter, AfterViewChecked, AfterViewInit } from '@angular/core';
import { DynamicCard, DynamicField, DynamicLink, FormDataDto, Image } from '../../dto/dtos';
import { ActionRule, DynamicInputField, Section } from '../../utils/constants';
import { Notifier } from '../../utils/notifier';
import { DynamicFieldManager } from '../../settings/dynamic-fields/dynamic-field-manager';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SectionUtils } from '../../../environments/environment';
import { Instruction } from '../../component/form/dynamic-action/dynamic-action.service';
import { DynamicTextComponent } from './dynamic-text-component/dynamic-text-component';
import { LinkedListDropdownComponent } from '../linked-list-dropdown/linked-list-dropdown.component';
import { exit } from 'process';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-dynamic-add',
    templateUrl: './dynamic-add.component.html',
    styleUrls: ['./dynamic-add.component.scss']
})
export class DynamicAddComponent {

	section: Section;

	@Output() onFormTextBuilt = new EventEmitter();

	formDataTextDtos: FormDataDto[];
	formDataImageDtos: FormDataDto[];

	existingFieldData: DynamicField[];
	existingImageData: Image[];

	images: Array<Image>;

	@ViewChildren('dynamicAddText') addTextComponents: QueryList<DynamicTextComponent>;

    constructor(
        private notifier: Notifier,
		private dynamicFieldMgr: DynamicFieldManager,
		private domSanitizer: DomSanitizer) {
    }

	@Input()
	set existingFields(existingFields: DynamicField[]) {
		this.existingFieldData = existingFields;
		console.log('Got existingFields:', existingFields);
		this.attach();
	}

	@Input()
	set existingImages(existingImages: Image[]) {
		this.existingImageData = existingImages;
		console.log('Got existingImages:', existingImages);
		this.attach();
	}

	@Input()
	set dynamicSection(input: Section) {
		if (input !== undefined) {
			this.section = input;
			this.buildSection();
			console.log('Got section:', this.section);
			this.attach();
		}
	}

	private attach() {
		this.attachText();
		this.attachImages();
	}

	private attachText() {
		if (this.existingFieldData && this.formDataTextDtos) {
			this.existingFieldData.forEach(field => {
				this.formDataTextDtos.forEach(formData => {
					formData.data.cards.forEach(card => {
						// card.fields.filter(formField => formField.id === field.id)
						card.fields.forEach(formField => {
							if (field.id === formField.id) {
								formField.value = field.value;
							}
						})
					})
				})
			});
		}
	}
	
	private attachImages() {
		if (this.existingImageData && this.formDataImageDtos) {
			this.existingImageData.forEach(image => {
				this.formDataImageDtos.forEach(formData => {
					formData.data.cards.forEach(card => {
						// card.fields.filter(formField => formField.id === field.id)
						card.fields.forEach(formField => {
							if (image.fieldId === formField.id) {
								formField.value = image.image;

								image.imageUrl = "data:image/jpeg;base64," + image.image;
								image.safeImageUrl = this.domSanitizer.bypassSecurityTrustUrl(image.imageUrl);
								image.isPlaceholder = false;
								this.saveImage({'image': image});
							}
						})
					})
				})
			});
		}
	}

	

	buildSection() {

		try {
			console.log('Fetching DynamicLink for Section ID [' + SectionUtils.getSectionId(this.section) +']');
			const dynLinkBase = new DynamicLink(this.dynamicFieldMgr.getFieldsBySectionId(SectionUtils.getSectionId(this.section)));
			console.log('DynamicLink for Section ID [' + SectionUtils.getSectionId(this.section) +'] =', dynLinkBase);

			this.formDataTextDtos = new Array<FormDataDto>();
			for ( const card of dynLinkBase.cards ) {
				if ( card.type === 'text' ) {

					const dynLink = new DynamicLink(dynLinkBase)
					dynLink.sectionEnum = this.section;

					const dynCard: DynamicCard = {
						id: card.id,
						card: card.card,
						type: card.type,
						fields: card.fields
					};

					dynLink.cards = new Array<DynamicCard>(dynCard);

					const formDataDto: FormDataDto = {
						id: 0,
						data: dynLink
					};
					this.formDataTextDtos.push(formDataDto);
				}
			}

			this.formDataImageDtos = new Array<FormDataDto>();
			for ( const card of dynLinkBase.cards ) {
				if ( card.type === 'image' ) {
					const dynLink = new DynamicLink(dynLinkBase)
					dynLink.sectionEnum = this.section;
					dynLink.cards = new Array<DynamicCard>(card);

					const formDataDto: FormDataDto = {
						id: 0,
						data: dynLink
					};
					this.formDataImageDtos.push(formDataDto);
				}
			}

			if ( this.onFormTextBuilt ) {
				const dynLinks = new Array();
				for ( let dto of this.formDataTextDtos ) {
					dynLinks.push(dto.data);
				}
				this.onFormTextBuilt.emit(dynLinks);
			}

		} catch (error) {
			console.log(error);
			this.notifier.error("No dynamic section defined");
		}

	}

	saveImage(event) {
		if ( event.modalRef ) {
			(<NgbModalRef>event.modalRef).close();
		}

		if ( this.images === null || this.images === undefined ) {
			this.images = new Array<Image>();
		}

		this.images.forEach( (item, index) => {
			if (event.image.fieldId === item.fieldId) {
				this.images.splice(index,1);
			}
		});

		this.images.push(event.image);
		console.log( "Received Image Field ID [" + event.image.fieldId + "] of size [" + event.image.size + "] byte(s). Total images received [" + this.images.length + "]");
	}

	getDynamicLinkData(): DynamicLink {

		for ( let addText of this.addTextComponents.toArray() ) {
			if ( !addText.isFormValid() ) {
				return null;
			}
		}

		if ( this.images && this.images !== null ) {
			this.images
			.filter(item => item.image !== null && item.image !== undefined)
			.forEach(image => {
				this.formDataImageDtos
				.map(item => item.data.cards)
				.reduce((accumulated, value) => accumulated.concat(value),[])
				.map(item => item.fields)
				.reduce((accumulated, value) => accumulated.concat(value),[])
				.filter(item => item.id === image.fieldId)
				.forEach(field => {
					field.value = image.image;

					/* The image data may contain the prefix "data:image/png:base64," - remove this */
					let imageData = <string>field.value;
					if ( imageData.indexOf("base64,") > 0 ) {
						imageData = imageData.substring(imageData.indexOf("base64,") + 7, imageData.length);
						field.value = imageData;
					}
				});
			});
		}


		if ( !this.checkMandatoryImages() ) {
			return null;
		}

		const dynLinkMain = new DynamicLink(this.dynamicFieldMgr.getFieldsBySectionId(SectionUtils.getSectionId(this.section)));
		for ( const card of dynLinkMain.cards ) {
			for ( const formDataTextDto of this.formDataTextDtos ) {
				for ( const capturedDynCardText of formDataTextDto.data.cards ) {
					if ( card.id === capturedDynCardText.id ) {
						card.fields = new Array<DynamicField>();
						for ( let field of capturedDynCardText.fields ) {
							if ( field.formControl && field.formControl.value ) {
								const dynField = new DynamicField(field);
								card.fields.push(dynField);
							} else if (field.inputField === DynamicInputField.CHOOSER) {

								const dynField = new DynamicField(field);

								const linkedList = <LinkedListDropdownComponent> dynField.formComponent;
								dynField.formControl.setValue(linkedList.getValue());
								card.fields.push(dynField);
							}
						}
						break;
					}
				}
			}

			for ( const formDataImageDto of this.formDataImageDtos ) {
				for ( const capturedDynCardImage of formDataImageDto.data.cards ) {
					if ( card.id === capturedDynCardImage.id ) {
						card.fields = new Array<DynamicField>();
						for ( let field of capturedDynCardImage.fields ) {
							if ( field.value ) {
								const dynField = new DynamicField(field);
								card.fields.push(dynField);
							}
						}
						break;
					}
				}
			}
		}

		for (const card of dynLinkMain.cards ) {
			for ( const field of card.fields ) {
				/*
				For some reason, when attempting to JSON.stringify FormControl or FormComponent it
				results in an error:
				ERROR TypeError: Converting circular structure to JSON
				To avoid this, just set form control to null.
				*/
				if ( field.formControl ) {
					field.value = field.formControl.value;
					field.formControl = null;
					field.formComponent = null;
					field.rejectionCheckControl = null;
					field.rejectionReasonControl = null;
				}
			}
		}

		dynLinkMain.sectionId = SectionUtils.getSectionId(this.section);

		return dynLinkMain;
	}

	resetForm() {
		// this.formReady = false;
		// this.customerSimsComponent.resetForm();
	}

	private checkMandatoryImages(): boolean {

		/* Check all mandatory images are captured */
		for ( const formDataDto of this.formDataImageDtos ) {
			for ( const field of formDataDto.data.cards[0].fields ) {

				if ( field.mandatory === 1 ) {
					let captured = false;
					for ( const image of this.images ) {
						if ( image.fieldId === field.id && field.value ) {
							captured = true;
						}
					}

					if ( !captured ) {
						this.notifier.error( "Please capture " + field.field );
						return false;
					}
				}
			}
		}

		return true;
	}

	fireImageDynamicAction(instruction: Instruction) {
		console.log('Firing image dynamic action instruction: ', instruction);

		let changeOccurred = false;
		for ( const formDataDto of this.formDataImageDtos ) {
			for ( let card of formDataDto.data.cards ) {
				for ( let field of card.fields ) {
					if ( field.id === instruction.fieldId ) {

						changeOccurred = true;

						switch (instruction.actionRule) {
							case ActionRule.MANDATORY:

								console.log('Making field ID [' + field.id + '] MANDATORY');
								field.mandatory = 1;

								break;
							case ActionRule.NON_MANDATORY:

								console.log('Making field ID [' + field.id + '] NON-MANDATORY');
								/* Nothing to do here, if it was mandatory, it would be cleared */
								field.mandatory = 0;

								break;
							case ActionRule.MIN:

								console.log('Applying MIN [' + instruction.value + '] to field ID [' + field.id + ']');

								field.min = instruction.value;

								break;
							case ActionRule.MAX:

								console.log('Applying MAX [' + instruction.value + '] to field ID [' + field.id + ']');

								field.max = instruction.value;

								break;
							case ActionRule.ENABLE:

								console.log('Making field ID [' + field.id + '] ENABLED');

								field.editable = 1;

								break;
							case ActionRule.DISABLE:

								console.log('Making field ID [' + field.id + '] DISABLED');

								field.editable = 0;

								break;
							case ActionRule.HIDE:

								console.log('Making field ID [' + field.id + '] HIDDEN');

								field.hidden = 1;

								break;
							case ActionRule.SHOW:

								console.log('Making field ID [' + field.id + '] VISIBLE');

								field.hidden = 0;

								break;
							case ActionRule.INVALID:

								console.log('Making field ID [' + field.id + '] INVALID with error [' + instruction.value + ']');

								field.invalidError = instruction.value;

								break;
							default:
								changeOccurred = false;
								break;

						}

					}
				}
			}
		}

		if ( changeOccurred ) {
			/*
			A COMPLETELY new object needs to be created in order to trigger a change detection.
			This is what updates the actual Customer Image component, with the new DynamicLink object
			resulting in changes being displayed
			*/
			this.formDataImageDtos = this.formDataImageDtos.map(x => Object.assign({}, x));
		}
	}

}
