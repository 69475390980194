import { Injectable, Component, Input } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

@Component({
    selector: 'app-axon-block',
    templateUrl: './axon-block.component.html',
    styleUrls: ['./axon-block.component.scss'],
})
export class AxonBlockComponent {

    @Input() value: any;
    @Input() caption: string;

    private numberVal: number;
    private stringVal: string;

    constructor() {
        this.numberVal = parseInt(this.value, 10);
        this.stringVal = this.value;
    }

    isNumber() {
        return !isNaN(this.value);
    }

}