import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Notifier } from '../../../../utils/notifier';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NewFormData } from '../../../../component/form/form-data/new-form-data';
import { AgentService } from '../../../../services/agents/agent.service';
import { Section } from '../../../../utils/constants';
import { DynamicFieldManager } from '../../../../settings/dynamic-fields/dynamic-field-manager';

@Component({
  selector: 'app-agent-action-modal',
  templateUrl: './agent-action-modal.component.html',
  styleUrls: ['./agent-action-modal.component.css']
})
export class AgentActionModalComponent implements OnInit {

  instance: AgentActionModalComponent;
  newFormData: NewFormData;

  @Input('singular') singular;
  @Input('path') path;
  @Input('agentList') agentList;
  @Input('pastTense') pastTense;
  @Input('child') child;
  @Input('agentDynamicCardComponent') agentDynamicCardComponent;
  @ViewChild('appForm') appFormRef;

  constructor(
    private agentService: AgentService,
    private notifier: Notifier,
    public activeModal: NgbActiveModal,
	private dynamicFieldManager: DynamicFieldManager) {
      this.instance = this;
    }

  ngOnInit() {
      this.newFormData = new NewFormData(Section.AGENT_ACTIONS, 0, this.dynamicFieldManager, this.agentService);
  }

  async submit() {
      if (Object.keys(this.appFormRef.formControlList).length > 0) {
          const reasonValue = this.appFormRef.formControlList[this.appFormRef.dynamicLink.cards[0].fields[0].id].value;
          const result = await this.agentService.bulkAction(this.path, this.agentList, null, reasonValue);

          let validationResults = "";
          for (const key of Object.keys(result.dataList[0])) {
              if (!isNaN( <any> key)) {
                  const validationResult = result.dataList[0][key];
                  if (validationResult.indexOf('already') > -1 || validationResult.indexOf('prevents agent') > -1) {
                      validationResults += "Agent with Agent ID " + key + ": " + validationResult + ". ";
                  }
              }
          }

          if (result.error === undefined && result.success === true && validationResults.length === 0) {
              this.notifier.success("Agent(s) have been " + this.pastTense);
          } else if (result.error && result.success === true) {
              this.notifier.success("Agent(s) have been " + this.pastTense);
              this.notifier.warn('Could not ' + this.singular + ' some of the agents');
          } else {
              this.notifier.error(validationResults);
          }

          if (this.child) {
            this.child.selection.clear();
            this.child.refreshData();
          }
          this.activeModal.close();
      }
  }

}
