import { Component, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';
import { ReportDto, ReportParameterDto, ReportQueryResultDto, ReportQueryResultRowDto, ReportWithDataDto } from '../../../dto/dtos';
import { HttpReportService } from '../../../services/http/http-report.service';
import { Notifier } from '../../../utils/notifier';

@Component({
	selector: 'app-report-display-table',
	templateUrl: './report-display-table.component.html',
	styleUrls: ['./report-display-table.component.css']
})
export class ReportDisplayTableComponent implements OnInit {

	@Input() report: ReportWithDataDto;

	data: { parameter: ReportParameterDto, value: string }[] = [];
	loading: boolean;
	pageSize = 20;
	pageIndex = 0;
	blockSubmit = false;

	constructor(private reportService: HttpReportService, private notifier: Notifier) { }

	ngOnInit() {
	}

	onPaginationChange(event: PageEvent) {
		this.pageIndex = event.pageIndex;
		this.pageSize = event.pageSize;
	}
	get pagingStartPosition(): number {
		return this.pageIndex * this.pageSize;
	}
	get pagingEndPosition(): number {
		return this.pagingStartPosition + this.pageSize;
	}

	trackByCell(index: number, value: string): string {
		return value;
	}
	trackByRow(index: number, value: ReportQueryResultRowDto): string {
		return "" + value.hashCode;
	}
	filterValueChanged(event: { parameter: ReportParameterDto, value: string }): void {
		console.log('Filter changed', event);
		if (event.parameter === undefined || event.value === undefined) {
			this.blockSubmit = true;
			return;
		}
		this.blockSubmit = false;
		const list = this.data.filter(item => item.parameter.id === event.parameter.id);
		if (list.length === 0) {
			this.data.push(event);
		} else {
			list[0].value = event.value;
		}
		const params = {};
		this.data.forEach(item => params[item.parameter.id] = item.value);
		// AxonUtils.updateUrlWithAppendedQueryParam(this.location, this.route, null, 'report-filters', params);
	}
	getParameterValue(parameter: ReportParameterDto): string {
		const out = this.data.filter(item => item.parameter.id === parameter.id).map(item => item.value);
		if (out.length === 0) {
			return undefined;
		}
		return out[0];
	}
	getParameterData(parameter: ReportParameterDto): ReportQueryResultDto {
		if(!this.report.report.parameterData){
			return undefined;
		}
		return this.report.report.parameterData[parameter.id] as any as ReportQueryResultDto;
	}
	getParameterDisplayType(parameter: ReportParameterDto): string {
		if (parameter.type === 'PASTDATE') {
			return 'date';
		}
		if (parameter.type === 'FUTUREDATE') {
			return 'date';
		}
		if (parameter.type === 'COMBO') {
			return 'combo';
		}
		if (parameter.type === 'ORGANIZATION') {
			return 'organization';
		}

		return 'text';
	}
	get submitEnabled(): boolean {

		if (this.blockSubmit === true) {
			return false;
		}

		for (const parameter of this.report.report.parameters) {
			if (!parameter.required) {
				continue;
			}
			const data = this.data.filter(item => item.parameter.id === parameter.id);
			if (data.length === 0) {
				return false;
			}
			if (data[0].value === undefined || data[0].value === null) {
				return false;
			}
		}
		return true;
	}
	run() {

		if (!this.submitEnabled) {
			return;
		}

		const data: { id: number, value: string }[] = [];
		this.data.forEach(item => {
			data.push({ id: item.parameter.id, value: item.value });
		});
		this.loading = true;
		this.reportService.executeQuery(this.report.report.id, data).subscribe(
			item => {
				if (item['success'] === false) {
					this.loading = false;
					this.notifier.error("Failed to load report");
				} else {
					this.loading = false;
					this.report.data = item;
					this.pageIndex = 0;
				}
			},
			error => {
				this.notifier.error("Failed to load report");
			});
	}
	hasExportData(): boolean {
		if (!this.report || !this.report.data) {
			return false;
		}
		return this.report.data.columns.length > 0 && this.report.data.rows.length > 0;
	}

}
