import { Observable } from 'rxjs';
import { DataResponse, DynamicLink } from '../../../../dto/dtos';
import { AgentService } from '../../../../services/agents/agent.service';
import { FormData } from '../form-data';
import { Injectable } from '@angular/core';
import { Section } from '../../../../utils/constants';
import { SectionUtils } from '../../../../../environments/environment';
import { DynamicFieldManager } from '../../../../settings/dynamic-fields/dynamic-field-manager';

/**
 * Overrides getFormData by fetching agent data from the server for the form
 */
export class AgentByAgentIdFormData extends FormData {
    constructor(private _section: Section, private _cardId: number, private dynamicFieldManager: DynamicFieldManager, private agentService: AgentService,  private _identifierNum: number) {
        super(_section, _cardId, dynamicFieldManager, agentService, _identifierNum);
    }

    getFormData(): Observable<DataResponse> {
        return this.agentService.getAgentFormDataByAgentId(SectionUtils.getSectionId(this.section), this.identifierNum);
    }

    saveFormData(identifierNum: number, dynamicLink: DynamicLink): Observable<DataResponse> {
        return this.agentService.saveFormData(SectionUtils.getSectionId(this.section), identifierNum, dynamicLink);
    }
}

