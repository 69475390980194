import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './404/not-found.component';
import { DEFAULT_ROUTE } from '../utils/constants';
import { AuthComponent } from './auth.component';

export const AuthenticationRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '', redirectTo: DEFAULT_ROUTE, pathMatch: 'full'
      },
      {
        path: '404',
        component: NotFoundComponent
      },
      {
        path: 'login',
        component: AuthComponent
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(AuthenticationRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class AuthenticationRoutingModule { }
