import { SystemService } from '../../../../services/system/system.service';
import { Observable } from 'rxjs';
import { DataResponse } from '../../../../dto/dtos';
import { TableData } from '../table-data';
import { FilterOption } from '../../material-table.component';

/**
 * Overrides getTableData by fetching agent table data
 */
export class AgentAuditTrailTableData extends TableData {

    private axonId: number;

    constructor(axonId: number, private systemService: SystemService) {
        super(systemService);
        this.axonId = axonId;
    }

    getTableData(sort: string, order: string, page: number, pageSize: number, filterOptions: FilterOption[]): Observable<DataResponse> {
        return this.systemService.getAgentAuditTrails(this.axonId, page, pageSize, filterOptions);
    }
}
