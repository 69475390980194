import { filter, switchMap } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { DmsService } from './../../../dms/service/dms.service';
import { DmsNodeInfoDto } from './../../../dto/dtos';
import { Component, OnInit, Input, Output, ViewChild, AfterViewInit, ChangeDetectorRef, OnChanges, OnDestroy } from '@angular/core';
import { Agent, DynamicCard, DynamicLink, DynamicField, Customer } from '../../../dto/dtos';
import { ModalContainerComponent } from '../../../component/modal-container/modal-container.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AxonComponent } from '../../../axon.component';
import { AgentByAgentIdFormData } from '../../../component/form/form-data/agents/agent-form-data';
import { AgentService } from '../../../services/agents/agent.service';
import { Section } from '../../../utils/constants';
import { FormComponent } from '../../../component/form/form.component';
import { Observer } from 'rxjs';
import { AgentModalComponent, TAB_CONFIG_LABEL } from '../agent-modal.component';
import { Notifier } from '../../../utils/notifier';
import { DynamicFieldManager } from '../../../settings/dynamic-fields/dynamic-field-manager';
import { AuthService } from '../../../services/auth/auth.service';
import { Permission as PermissionEnum, NO_PERMISSION_FOR_ACTION } from "../../../utils/constants";
import { AxonUtils } from '../../../utils/axon-utils';
import { AgentDynamicCardComponent } from '../../dynamic-card/agent-dynamic-card.component';
import { environment, SectionUtils } from '../../../../environments/environment';
import { EventEmitter } from 'events';


@Component({
	selector: 'app-agent-details',
	templateUrl: './agent-details.component.html',
	styleUrls: ['./agent-details.component.scss']
})
export class AgentDetailsComponent extends AxonComponent implements OnInit, OnDestroy, AfterViewInit, Observer<any>, OnChanges {

	edittingPersonalDetails = false;
	edittingAddressDetails = false;
	edittingContactInformation = false;
	edittingSIMs = false;
	env = environment;

	@Input() agent: Agent;
	@Input() parentComponent: AgentModalComponent;
	@ViewChild('appFormDetails') appFormDetails: FormComponent;
	@ViewChild('appFormAddress') appFormAddress: FormComponent;
	@ViewChild('appFormContact') appFormContact: FormComponent;
	@ViewChild('appFormSIMs') appFormSIMs: FormComponent;
	@ViewChild('appFormRoles') appFormRoles: FormComponent;

	@ViewChild('dynamicCardAgentDetails') dynamicCardAgentDetails: AgentDynamicCardComponent;
	@ViewChild('dynamicCardContactDetails') dynamicCardContactDetails: AgentDynamicCardComponent;
	@ViewChild('dynamicCardAddressDetails') dynamicCardAddressDetails: AgentDynamicCardComponent;

	cardDetails: DynamicCard;
	cardContact: DynamicCard;
	cardAddress: DynamicCard;
	cardRoles: DynamicCard;
	linkDetails: DynamicLink;
	linkAddress: DynamicLink;
	linkContact: DynamicLink;
	linkRoles: DynamicLink;
	fieldsDetails: Array<DynamicField>;
	fieldsAddress: Array<DynamicField>;
	fieldsContact: Array<DynamicField>;
	fieldsRoles: Array<DynamicField>;

	instance: AgentDetailsComponent;
	map: any;
	editAddressMap: any;
	VIEW_ADDR_MAP_ID = "viewAgentMap";
	lat;
	lng;
	rolesNotEditableMessage = "This agent can not edit their own roles or permissions";

	personalDetailsFormData: AgentByAgentIdFormData;
	addressDetailsFormData: AgentByAgentIdFormData;
	contactInformationFormData: AgentByAgentIdFormData;
	simsFormData: AgentByAgentIdFormData;
	rolesFormData: AgentByAgentIdFormData;

	addItemLists = [];
	geocodeRunning = false;
	dmsNodes: DmsNodeInfoDto[];
	dmsNodeSelected: DmsNodeInfoDto;

	SECTION_AGENT_DETAILS = SectionUtils.getSectionId(Section.AGENT_DETAILS);
	SECTION_AGENT_CONTACT_DETAILS = SectionUtils.getSectionId(Section.AGENT_CONTACT_INFORMATION);
	SECTION_AGENT_ADDRESS_DETAILS = SectionUtils.getSectionId(Section.AGENT_ADDRESS_DETAILS);
	SECTION_AGENT_ROLES = SectionUtils.getSectionId(Section.AGENT_ROLES);

	permissionColumns = [
		{ columnDef: 'permission', header: 'Permission', cell: (element: any) => `${element.permission}` },
		{ columnDef: 'expiry', header: 'Expiry', cell: (element: any) => `${element.expiry}` },
	];

	simsColumns = [
		{ columnDef: 'serial', header: 'ICCID', cell: (element: any) => `${element.serial}` },
	];

	devicesColumns = [
		{ columnDef: 'deviceId', header: 'Device ID', cell: (element: any) => `${element.deviceId}` },
		{ columnDef: 'deviceType', header: 'Device Type', cell: (element: any) => `${element.deviceType}` },
		{ columnDef: 'make', header: 'Brand', cell: (element: any) => `${element.make}` },
		{ columnDef: 'model', header: 'Model', cell: (element: any) => `${element.model}` },
		{ columnDef: 'version', header: 'Version', cell: (element: any) => `${element.version}` },
		{ columnDef: 'status', header: 'Status', cell: (element: any) => `${element.status}`, isApprovalStatus: true },
		{ columnDef: 'usedLast', header: 'Last Used', cell: (element: any) => this.getValidDateString(`${element.usedLast}`) },
		{ columnDef: 'lastAgentId', header: 'Last Agent ID', cell: (element: any) => this.getValidNumber(`${element.lastAgentId}`) },
		{ columnDef: 'lastAgentMsisdn', header: 'Last Agent MSISDN', cell: (element: any) => `${element.lastAgentMsisdn}` }
	];

	permissionToModify = true;

	selectedRole: string;

	/* >>> ReactiveForms valueChanges Observer */
	next(values) {
	}
	/* <<< ReactiveForms valueChanges Observer */

	constructor(
		private cdRef: ChangeDetectorRef,
		private modalService: NgbModal,
		private notifier: Notifier,
		private dynamicFieldMgr: DynamicFieldManager,
		private agentService: AgentService,
		private authService: AuthService,
		private dmsService: DmsService) {

		super();

		this.instance = this;
		this.saveSuccessful = this.saveSuccessful.bind(this);
		this.showError = this.showError.bind(this);
		this.editPersonalDetails = this.editPersonalDetails.bind(this);
		this.editContactInformation = this.editContactInformation.bind(this);
		this.editAddressDetails = this.editAddressDetails.bind(this);
		this.editPermissions = this.editPermissions.bind(this);
		this.editSIMs = this.editSIMs.bind(this);
		this.buildComplete = this.buildComplete.bind(this);
		this.save = this.save.bind(this);
	}

	ngOnInit(): void {
		this.permissionToModify = AxonUtils.checkActionPermission(
			PermissionEnum.AGENT_ADMIN_MODIFY, this.authService, this.notifier, false
		);
		console.log("Loading DMS", this.agent.agentId);
		this.dmsService.isDmsEnabled()
			.pipe(
				filter(item => item === true),
				switchMap(item => forkJoin([
					this.dmsService.getAgentsDmsNode(this.agent.agentId),
					this.dmsService.getDmsNodesForAgent(this.agent.agentId)
				])))
			.subscribe(results => {
				this.dmsNodeSelected = results[0];
				this.dmsNodes = results[1];
			});
	}
	ngOnDestroy() {
	}

	ngAfterViewInit() {
		if (this.agent !== undefined) {
			this.selectedRole = this.agent.role;
		}

		// if (this.agent) {
		// 	this.agent.lastLatitude = "-26.0";
		// 	this.agent.lastLongitude = "28.0";
		// 	AxonUtils.addMap('regMap', this.agent.lastLatitude, this.agent.lastLongitude, true, null, 15);
		// }
	}

	ngOnChanges() {
		setTimeout(() => {
			this.editRoles();
		});
	}
	get rolesEditable(): boolean {
		if (this.authService === undefined) {
			return false;
		}
		if (this.authService.getAuthAgent() === undefined) {
			return false;
		}
		if (this.agent === undefined) {
			return false;
		}
		if (this.agent.agentId === undefined) {
			return false;
		}
		const authAgentId = this.authService.getAuthAgent().agentId;
		if (authAgentId === undefined) {
			return false;
		}
		return this.permissionToModify && authAgentId !== this.agent.agentId;
	}

	editRoles() {
		this.rolesFormData = new AgentByAgentIdFormData(Section.AGENT_ROLES, 0, this.dynamicFieldMgr, this.agentService, this.agent.agentId);
	}

	editPersonalDetails() {
		this.edittingPersonalDetails = !this.edittingPersonalDetails;
		if (this.edittingPersonalDetails === true) {
			console.log("updating personal details form data");
			this.personalDetailsFormData = new AgentByAgentIdFormData(Section.AGENT_DETAILS, 0, this.dynamicFieldMgr, this.agentService, this.agent.agentId);
		}
	}

	editAddressDetails() {
		this.edittingAddressDetails = !this.edittingAddressDetails;
		if (this.edittingAddressDetails === true) {
			this.addressDetailsFormData = new AgentByAgentIdFormData(Section.AGENT_ADDRESS_DETAILS, 0, this.dynamicFieldMgr, this.agentService, this.agent.agentId);
		}
	}

	editPermissions() {
		this.parentComponent.changeTab(TAB_CONFIG_LABEL.PERMISSIONS);
	}

	editDevices() {

	}

	editSIMs() {
		if (this.permissionToModify) {
			this.edittingSIMs = !this.edittingSIMs;
			if (this.edittingSIMs === true) {
				this.simsFormData = new AgentByAgentIdFormData(Section.AGENT_SIMS, 0, this.dynamicFieldMgr, this.agentService, this.agent.agentId);
			}
		} else {
			this.notifier.warn(NO_PERMISSION_FOR_ACTION);
		}
	}

	editContactInformation() {
		this.edittingContactInformation = !this.edittingContactInformation;
		if (this.edittingContactInformation === true) {
			this.contactInformationFormData = new AgentByAgentIdFormData(Section.AGENT_CONTACT_INFORMATION, 0, this.dynamicFieldMgr, this.agentService, this.agent.agentId);
		}
	}

	save(formComponent: FormComponent) {

		/* Check if role has changed from orginal value */
		if (formComponent.dynamicLink.sectionId === SectionUtils.getSectionId(Section.AGENT_DETAILS)) {

		} else if (formComponent.dynamicLink.sectionId === SectionUtils.getSectionId(Section.AGENT_ROLES)) {

			formComponent.confirmSave = "Updating the Agent's roles will reset their permissions. Continue?";
		}
		formComponent.save(undefined);
	}

	get confirmSaveDetails() {
		return this.agentService['confirmSaveDetails'];
	}

	set confirmSaveDetails(confirmSave: string) {
		this.agentService['confirmSaveDetails'] = confirmSave;
	}

	saveSuccessful() {
		if (this.parentComponent !== undefined) {
			this.parentComponent.updatePermissions();
			this.parentComponent.getUser(this.agent.agentId, this.buildComplete());
		}
	}

	showError(data: any): boolean {
		console.log(data);

		if (data.error !== undefined) {
			if (data.error.indexOf('SIM checksum cannot be verified') > -1) {
				// We need to delete the bad SIM from the list
				const indexOfSimStart = data.error.indexOf("[") + 1;
				const indexOfSimEnd = data.error.indexOf("]");
				const sim = data.error.substring(indexOfSimStart, indexOfSimEnd);
				this.addButtonDeleteItem(this.appFormSIMs.addedItemsList, this.appFormSIMs.getCardName(0), sim);

				this.notifier.error("SIM checksum cannot be verified.");
				return true;
			} else if (data.error.indexOf('SIM length is too short') > -1) {
				// We need to delete the bad SIM from the list
				const indexOfSimStart = data.error.indexOf("[") + 1;
				const indexOfSimEnd = data.error.indexOf("]");
				const sim = data.error.substring(indexOfSimStart, indexOfSimEnd);
				console.log("trying to delete this sim");
				console.log(sim);
				this.addButtonDeleteItem(this.appFormSIMs.addedItemsList, this.appFormSIMs.getCardName(0), sim);

				this.notifier.error("SIM length is too short.");
				return true;
			}
		}
		return false;
	}

	buildComplete() {

		setTimeout(() => {
			this.agent = this.parentComponent.getAgent();
		}, 2500);

		setTimeout(() => {
			if (this.edittingPersonalDetails === true) {
				this.dynamicCardAgentDetails.refresh();
				this.editPersonalDetails();
			} else if (this.edittingAddressDetails === true) {
				this.dynamicCardAddressDetails.refresh();
				this.editAddressDetails();
			} else if (this.edittingContactInformation === true) {
				this.dynamicCardContactDetails.refresh();
				this.editContactInformation();
			} else if (this.edittingSIMs === true) {
				this.editSIMs();
			}
			this.editRoles();

			this.cdRef.detectChanges();
		}, 500);
	}

	/**
	 * For the 'add_button' inputType, this method allows us to collect the added items so we can do server call
	 */
	addButtonAddItem(currentList, cardName, inputValue) {

		const itemName = cardName.replace(/\s/g, "");

		if (this.addItemLists[itemName] === undefined) {
			this.addItemLists[itemName] = (currentList !== undefined) ? currentList : [];
		} else {
			this.addItemLists[itemName] = currentList;
		}

		if (this.addItemLists[itemName].indexOf(inputValue) < 0) {
			this.addItemLists[itemName].push(inputValue);
		}

		return this.addItemLists[itemName];
	}

	addButtonDeleteItem(currentList, cardName, inputValue) {
		const itemName = cardName.replace(/\s/g, "");

		if (this.addItemLists[itemName] === undefined) {
			this.addItemLists[itemName] = (currentList !== undefined) ? currentList : [];
		} else {
			this.addItemLists[itemName] = currentList;
		}

		if (this.addItemLists[itemName].indexOf(inputValue) >= 0) {
			const i = this.addItemLists[itemName].indexOf(inputValue);
			this.addItemLists[itemName].splice(i, 1);
		}

		return this.addItemLists[itemName];
	}

	getAddedItemsList(cardName) {
		const itemName = cardName.replace(/\s/g, "");
		return (this.addItemLists[itemName] !== undefined) ? this.addItemLists[itemName] : [];
	}

	setAddedItemsList(cardName, list: any[]) {
		const itemName = cardName.replace(/\s/g, "");
		this.addItemLists[itemName] = list;
	}

	cancelRoles() {
		this.appFormRoles.resetDragnDrop();
	}

	saveRoles() {
		this.save(this.appFormRoles);
	}
	get canEdit(): boolean {
		if (!this.agent) {
			return true;
		}
		// console.log(`Can Edit.  Agent:[${this.agent.agentId}] : AuthAgent:[${this.authService.getAuthAgent().agentId}]`, this.authService.getAuthAgent());
		return this.agent.agentId !== this.authService.getAuthAgent().agentId;
	}
	isDmsEnabled(): Observable<boolean> {
		return this.dmsService.isDmsEnabled();
	}

	saveDms(selected) {
		if (!selected) {
			return;
		}
		if (this.dmsNodeSelected && this.dmsNodeSelected.axonId === selected.axonId) {
			return;
		}
		this.dmsService.linkAgentToDmsNode(this.agent.agentId, selected.axonId).subscribe(result => {
			this.dmsNodeSelected = selected;
		});
	}


	get isDmsReadOnly(): boolean {
		if (!this.permissionToModify) {
			return false;
		}
		if (!this.agent) {
			return true;
		}
		return this.agent.agentId === this.authService.getAuthAgent().agentId;
	}

}
