import { HttpErrorResponse } from "@angular/common/http";

export abstract class HttpError implements Error {
	private errorMessage: string;
	readonly name: string;

	constructor(protected error: HttpErrorResponse, private errorCode: Number, private defaultMessage: string) { }
	get code(): Number {
		return this.errorCode;
	}
	set message(message: string) {
		this.errorMessage = message;
	}
	get message(): string {
		if (this.errorMessage) {
			return this.errorMessage;
		}
		return this.defaultMessage;
	}
}

export class HttpErrorIllegibleFile extends HttpError {
	constructor(error: HttpErrorResponse) {
		super(error, 1, "Uploaded file illegible and cannot be processed");
	}
}

export class HttpErrorInternalServerError extends HttpError {
	constructor(error: HttpErrorResponse) {
		super(error, 99, "Internal Server Error");
	}
}

export class HttpErrorBadRequest extends HttpError {
	constructor(error: HttpErrorResponse) {
		super(error, null, "Bad Request");
	}
}

export class HttpErrorUnathorized extends HttpError {
	constructor(error: HttpErrorResponse) {
		super(error, null, "Unauthorized");
	}
}

export class HttpErrorTimeout extends HttpError {
	constructor(error: HttpErrorResponse) {
		super(error, null, "Timeout");
	}
}