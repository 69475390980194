import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { AxonUtils } from '../../utils/axon-utils';
import { Agent } from '../../dto/dtos';

@Component({
    selector: 'app-full-layout',
    templateUrl: './full.component.html',
    styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit, OnDestroy {

    isApproval = false;

    authAgent: Agent;

    observer: any;

    constructor(public router: Router, public authService: AuthService) { 
        this.authAgent = authService.getAuthAgent();
    }

    ngOnInit() {
        if (this.router.url === '/') {
            this.router.navigate(['/dashboard/system']);
        }

        this.setPage();

        this.observer = this.router.events.subscribe((val) => {
            if ( val instanceof NavigationEnd ) {
                this.setPage();
            }
        });
    }

    private setPage() {
        
        console.log( 'Previous URL [' + AxonUtils.getPreviousUrl() + ']' );

        const hasPermission = AxonUtils.checkUrlPermission( this.authService, this.router );

        if ( hasPermission ) {
            AxonUtils.setPreviousUrl( this.router.url );
            console.log( 'Current URL [' + AxonUtils.getPreviousUrl() + ']' );
            this.isApproval = this.router.url === '/approvals';
        }

        
    }

    ngOnDestroy() {
        if ( this.observer ) {
            this.observer.unsubscribe();
        }
    }
}
