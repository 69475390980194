import { NotifierOptions } from 'angular-notifier';

export const today: Date = new Date();

export const LOCAL_STORAGE_PREFIX: String = 'axon-';

export const DEFAULT_ROUTE = '/dashboard/systemsummary';

export const NO_PERMISSION_FOR_ACTION = 'You do not have sufficient permissions to perform this action';

export const DOB_START_DATE = new Date(1980, 0, 1);

export const SECTION_MAP = new Map<Section, number>();

// export enum Section_ {
//     NEW_PREPAID_INDIV                = 1,
//     NEW_PREPAID_CORP                 = 2,
//     NEW_POSTPAID_INDIV               = 3,
//     NEW_POSTPAID_CORP                = 4,
// 	NEW_HYBRID_INDIV                 = 28,
//     NEW_HYBRID_CORP                  = 29,
//     MODIFY_PREPAID_INDIV             = 5,
//     MODIFY_PREPAID_CORP              = 6,
//     MODIFY_POSTPAID_INDIV            = 7,
//     MODIFY_POSTPAID_CORP             = 8,
//     MODIFY_HYBRID_INDIV              = 30,
//     MODIFY_HYBRID_CORP               = 31,
//     EXISTING_ADD_PROD_PREPAID_INDIV  = 9,
//     EXISTING_ADD_PROD_PREPAID_CORP   = 10,
//     EXISTING_ADD_PROD_POSTPAID_INDIV = 11,
//     EXISTING_ADD_PROD_POSTPAID_CORP  = 12,
//     TRANSFER_REG                     = 17,
//     TERMINATE_SERVICE_INDIV          = 20,
//     TERMINATE_SERVICE_CORP           = 21,
//     AGENT_ROLES                      = 95,
//     AGENT_FORGOT_PASSWORD            = 96,
//     AGENT_FORGOT_USERNAME            = 97,
//     AGENT_DETAILS                    = 98,
//     AGENT_SIGN_UP                    = 99,
//     AGENT_RESET_PASSWORD             = 100,
//     AGENT_CHANGE_PASSWORD            = 101,
//     AGENT_MEMORABLE_QUESTIONS        = 102,
//     AGENT_SIGN_IN                    = 103,
//     AGENT_CONTACT_INFORMATION        = 105,
//     AGENT_ADDRESS_DETAILS            = 106,
//     AGENT_LOCATIONS                  = 107,
//     AGENT_SIMS                       = 108,
//     AGENT_PERMISSIONS                = 109,
//     AGENT_ACTIONS                    = 110,
//     CUSTOMER_PERSONAL_DETAILS        = 111,
//     SETTINGS_SECURITY_QUESTIONS      = 112,
// 	NEW,
// 	EXISTING,
// 	MOMO,
// 	MODIFY,
// 	MODIFY_MOMO
// }

export enum Section {
    NEW_PREPAID_INDIV                ,
    NEW_PREPAID_CORP                 ,
    NEW_POSTPAID_INDIV               ,
    NEW_POSTPAID_CORP                ,
	NEW_HYBRID_INDIV                 ,
    NEW_HYBRID_CORP                  ,
    MODIFY_PREPAID_INDIV             ,
    MODIFY_PREPAID_CORP              ,
    MODIFY_POSTPAID_INDIV            ,
    MODIFY_POSTPAID_CORP             ,
    MODIFY_HYBRID_INDIV              ,
    MODIFY_HYBRID_CORP               ,
    EXISTING_ADD_PROD_PREPAID_INDIV  ,
    EXISTING_ADD_PROD_PREPAID_CORP   ,
    EXISTING_ADD_PROD_POSTPAID_INDIV ,
    EXISTING_ADD_PROD_POSTPAID_CORP  ,
    TRANSFER_REG                     ,
    TERMINATE_SERVICE_INDIV          ,
    TERMINATE_SERVICE_CORP           ,
    AGENT_ROLES                      ,
    AGENT_FORGOT_PASSWORD            ,
    AGENT_FORGOT_USERNAME            ,
    AGENT_DETAILS                    ,
    AGENT_SIGN_UP                    ,
    AGENT_RESET_PASSWORD             ,
    AGENT_CHANGE_PASSWORD            ,
    AGENT_MEMORABLE_QUESTIONS        ,
    AGENT_SIGN_IN                    ,
    AGENT_CONTACT_INFORMATION        ,
    AGENT_ADDRESS_DETAILS            ,
    AGENT_LOCATIONS                  ,
    AGENT_SIMS                       ,
    AGENT_PERMISSIONS                ,
    AGENT_ACTIONS                    ,
    CUSTOMER_PERSONAL_DETAILS        ,
    SETTINGS_SECURITY_QUESTIONS      ,
	NEW                              ,
	EXISTING                         ,
	OFFNET                           ,
	MOMO                             ,
	MODIFY                           ,
	MODIFY_MOMO          			 ,
	TRANSFER_REGISTRATION_INDIV		 ,
	TRANSFER_REGISTRATION_CORP		 ,
	SELF_REG           				 ,
	SIM_SWAP           				 ,
	REIDENTIFICATION   				 ,
	ISP								 ,
	ADD_SIM							 ,
	STARTER_KIT						 ,
	SPONSOR_REG						 ,
	MNP								 ,
	API_CORPORATE_REG				 ,
	MOMOKASH_AGENT_CREATION          ,
	MOMOKASH_HANDLER_POS_CREATION    ,
	MOMOKASH_MERCHANT_CREATION       ,
	MOMOKASH_UPDATE_AGENT_ACCOUNT    ,
	MOMOKASH_UPDATE_POS_NUMBER       ,
	MOMOKASH_ATTACH_AGENT_HIERARCHY  ,
	MOMOKASH_AGENT_MODIFY            ,
	MOMOKASH_HANDLER_POS_MODIFY      ,
	MOMOKASH_MERCHANT_MODIFY         ,
	MNP_MODIFY                       ,
	SPONSOR_MODIFY                   ,
	STARTER_KIT_MODIFY               ,
	NEW_INDIVIDUAL                   ,
	NEW_CORPORATE                    ,
	MODIFY_INDIVIDUAL                ,
	MODIFY_CORPORATE                 ,
	DMS_RDS							 ,
	DMS_OFFICE						 ,
	DMS_BRANCH						 ,
	DMS_DEALER						 ,
	DMS_SUB_DEALER					 ,
	DMS_RDS_MODIFY					 ,
	DMS_OFFICE_MODIFY				 ,
	DMS_BRANCH_MODIFY				 ,
	DMS_DEALER_MODIFY				 ,
	DMS_SUB_DEALER_MODIFY			 ,
	VOTING_NEW_VOTER				 ,
	VOTING_EDIT_VOTER				 ,
	VOTING_NEW_CANDIDATE			 ,
	VOTING_EDIT_CANDIDATE			 ,
	VOTING_NEW_ATTENDEE			 	 ,
	VOTING_EDIT_ATTENDEE			 ,
}

export function buildDefaultMap() {
	SECTION_MAP.set(Section.AGENT_ROLES                , 95);
	SECTION_MAP.set(Section.AGENT_FORGOT_PASSWORD      , 96);
	SECTION_MAP.set(Section.AGENT_FORGOT_USERNAME      , 97);
	SECTION_MAP.set(Section.AGENT_DETAILS              , 98);
	SECTION_MAP.set(Section.AGENT_SIGN_UP              , 99);
	SECTION_MAP.set(Section.AGENT_RESET_PASSWORD       , 100);
	SECTION_MAP.set(Section.AGENT_CHANGE_PASSWORD      , 101);
	SECTION_MAP.set(Section.AGENT_SIGN_IN              , 103);
	SECTION_MAP.set(Section.AGENT_CONTACT_INFORMATION  , 105);
	SECTION_MAP.set(Section.AGENT_ADDRESS_DETAILS      , 106);
	SECTION_MAP.set(Section.AGENT_LOCATIONS            , 107);
	SECTION_MAP.set(Section.AGENT_SIMS                 , 108);
	SECTION_MAP.set(Section.AGENT_PERMISSIONS          , 109);
	SECTION_MAP.set(Section.AGENT_ACTIONS              , 110);
	SECTION_MAP.set(Section.SETTINGS_SECURITY_QUESTIONS, 112);
	SECTION_MAP.set(Section.VOTING_NEW_VOTER		   , 201);
	SECTION_MAP.set(Section.VOTING_EDIT_VOTER		   , 202);
	SECTION_MAP.set(Section.VOTING_NEW_CANDIDATE	   , 203);
	SECTION_MAP.set(Section.VOTING_EDIT_CANDIDATE	   , 204);
	SECTION_MAP.set(Section.VOTING_NEW_ATTENDEE		   , 205);
	SECTION_MAP.set(Section.VOTING_EDIT_ATTENDEE	   , 206);
	// SECTION_MAP.set(Section.DMS_OFFICE_MODIFY		   , 207);
	// SECTION_MAP.set(Section.DMS_BRANCH_MODIFY		   , 208);
	// SECTION_MAP.set(Section.DMS_DEALER_MODIFY		   , 209);
	// SECTION_MAP.set(Section.DMS_SUB_DEALER_MODIFY	   , 210);
}


export enum Card {
    AGENT_PERMISSIONS                         = 33,
    AGENT_SIGN_IN_USERNAME                    = 36,
    AGENT_SIGN_IN_PASSWORD                    = 37,
    AGENT_SIGN_UP_USERNAME                    = 38,
    AGENT_SIGN_UP_PASSWORD_MSISDN             = 39,
    AGENT_SIGN_UP_PASSWORD_EMAIL              = 40,
    AGENT_SIGN_UP_SECURITY_QUESTIONS          = 41,
    AGENT_FORGOT_USERNAME_MSISDN              = 42,
    AGENT_FORGOT_USERNAME_EMAIL               = 43,
    AGENT_FORGOT_PASSWORD_MSISDN              = 44,
    AGENT_FORGOT_PASSWORD_EMAIL               = 45,
    AGENT_FORGOT_PASSWORD_OTP                 = 46,
    AGENT_FORGOT_PASSWORD_SECURITY_QUESTIONS  = 47,
    AGENT_FORGOT_PASSWORD_SET_PASSWORD        = 48,
    AGENT_ROLES                               = 49,
}

export enum Field {
    AGENT_AGE_RANGE                           = 340,
    FIRST_NAME                                = 279,
    MIDDLE_NAME                               = 280,
    LAST_NAME                                 = 281
}

export enum DynamicCardType {
    DRAGNDROP = 'dragndrop',
	ADDBUTTON = 'add_button',
}

export enum DynamicInputField {
    CAMERA           = 'camera',
    DATE             = 'date',
	DATE_MANUAL      = 'date_manual',
    DROPDOWN         = 'dropdown',
	DROPDOWN_FILTER  = 'dropdown_filter',
    INPUT            = 'input',
    PASSWORD         = 'password',
    PASSWORD_CURRENT = 'password_current',
    SIGNBOX          = 'signbox',
    TICKBOX          = 'tickbox',
    MAP              = 'map',
    CHOOSER          = 'chooser',
    ADD_BUTTON       = 'add_button',
    BUTTON           = 'button',
    LINK             = 'link',
	DISPLAY_CONTRACT = 'display_contract',
}

export enum DynamicCharType {
    ALPHA        = 'alpha',
    ALPHANUMERIC = 'alphanumeric',
    BLOB         = 'blob',
    DATE         = 'date',
    EMAIL        = 'email',
    LOV          = 'lov',
    MSISDN       = 'msisdn',
    NUMERIC      = 'numeric',
    PASTDATE     = 'pastdate',
    FUTUREDATE   = 'futuredate',
    BIRTHDATE    = 'birthdate',
    JSON         = 'json',
}

export enum LinkedListType {
    CASCADE = 'cascade',
    FLAT    = 'flat'
}

export enum ProfileType {
    INDIVIDUAL = 'individual',
    CORPORATE  = 'corporate'
}

export enum ContractType {
    PREPAID  = 'prepaid',
    POSTPAID = 'postpaid',
    HYBRID   = 'hybrid'
}

export enum ApprovalStatus {
    OPEN     = 'OPEN',
    PENDING  = 'PENDING',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
}

/**
 * Enums derived from DYNAMIC_FIELD_ACTION_RULE table,
 * TYPE column.
 * These are the rules the calling code must apply to the
 * given dynamic field
 */
export enum ActionRule {
    /* Make the field mandatory */
    MANDATORY     = "MANDATORY",
    /* Make the field non-mandatory */
    NON_MANDATORY = "NON-MANDATORY",
    /* Regex to apply on the field */
    REGEX         = "REGEX",
    /* Set the minimum value on the field */
    MIN           = "MIN",
    /* Set the maximum value on the field */
    MAX           = "MAX",
    /* Set the field to enabled */
    ENABLE        = "ENABLE",
    /* Set the field to disabled */
    DISABLE       = "DISABLE",
    /* Hide the field */
    HIDE          = "HIDE",
    /* Show the field */
    SHOW          = "SHOW",
    /* Indicates that the validation failed, and
    user must resolve before proceeding */
    INVALID       = "INVALID",
	/* Change the properties of a dynamic field */
	CHANGE_PROP   = "CHANGE PROP",
	/* Indicates that server side validation must execute - uses dynamic_validation / dynamic_validation_mappipng*/
	VALIDATE      = "VALIDATE",
}

/**
 * Operands derived from DYNAMIC_FIELD_ACTION_CONDITION table,
 * OPERAND column
 */
export enum Operand {
    IN           = "IN",
    EQUAL        = "EQUAL",
    NOT_EQUAL    = "NOT_EQUAL",
    GREATER      = "GREATER",
    LESS         = "LESS",
    GREATER_AGE  = "GREATER_AGE",
    LESS_AGE     = "LESS_AGE",
    GREATER_DATE = "GREATER_DATE",
    LESS_DATE    = "LESS_DATE",
    /* CI-730 - needed 2 more operands: IS_NULL and IS_NOT_NULL */
    IS_NULL      = "IS_NULL",
    IS_NOT_NULL  = "IS_NOT_NULL",
}

export enum Permission {
    /* Agent permissions */
    AGENT_ADMIN                     = 'Agent Admin',
    AGENT_ADMIN_BLOCK_RESET_UNBLOCK = 'Agent Admin - Block,Reset,Unblock',
    AGENT_ADMIN_ENABLE_DISABLE      = 'Agent Admin - Enable,Disable',
    AGENT_ADMIN_EXPORT              = 'Agent Admin - Export',
    AGENT_ADMIN_MODIFY              = 'Agent Admin - Modify',
    AGENT_ADMIN_PERMISSION          = 'Agent Admin - Permission',
    AGENT_ADMIN_PROVISIONING        = 'Agent Admin - Provisioning',
    /* Customers */
    CUSTOMERS           = 'Customers',
	CUSTOMERS_ADD       = 'Customers - Add',
	CUSTOMERS_EXPORT    = 'Customers - Export',
	CUSTOMERS_BULK_EDIT = 'Customers - Bulk Edit',
	CUSTOMERS_MODIFY    = 'Customers - Modify',
	REGISTER_POSTPAID = 'Register Postpaid',
	SIM_SWAP = 'SIM Swap',
	CHANGE_OWNERSHIP = 'Change Ownership',
    /* Approvals */
    APPROVAL         = 'Approval',
	APPROVE_BY_BATCH = 'Approve by Batch',
	APPROVAL_REVIEW  = 'Approval Review',
	APPROVAL_SWITCH = 'Approval Switch',
	APPROVAL_MARK_AS_PENDING = 'Approval Mark As Pending',
	APPROVAL_OVERRIDE = 'Approval Override',
	APPROVAL_ENABLE_SKIP_BUTTON = 'Approval - Enable Skip Button',

	/* Add SIM */
	ADD_SIM_MULTIPLE_TARGET_SIMS = 'Add SIM - Multiple Target SIMs',
	BYPASS_ADD_SIM_MASTER_SIM_VALIDATION = 'Bypass Add SIM Master SIM Validation',
	BYPASS_ADD_SIM_TARGET_SIM_VALIDATION = 'Bypass Add SIM Target SIM Validation',

	/* Momokash */
    MERCHANT_ADMIN     = 'Merchant Admin',
	MERCHANT_APPROVALS = 'Merchant Approval',

	/* Dealer Management System */
	DMS_ADMIN     = 'DMS Admin',

	/* Churn / Unchurn */
	CHURN_UNCHURN = 'Churn Unchurn',

    /* Device */
    DEVICE_ADMIN                   = 'Device Admin',
    DEVICE_ADMIN_DELETE_ADD_MODIFY = 'Device Admin - Delete,Add,Modify',
    DEVICE_MANAGE_BLOCK_UNBLOCK    = 'Device Manage - Block,Unblock',
    /* Import */

    /* Login */
    LOGIN       = 'Login',
    LOGIN_GUI   = 'Login GUI',

    /* Roles and permissions */

    /* Reporting */
    REPORTING = 'Reporting',
    /* System */
    SYSTEM = 'System',
	MANAGE_ROLE = 'Manage Role',
	/* Individual permisions for each report */
	REPORT_APPROVAL_AGING_LIST = 'Report - Approval Aging List',
	REPORT_APPROVAL_REVIEW = 'Report - Approval Review',
	REPORT_APPROVAL_PERFORMANCE = 'Report - Approval Performance',
	REPORT_APPROVAL_REPORT = 'Report - Approval Report',
	REPORT_CORRECTION_STATISTICS = 'Report - Correction Statistics',
	REPORT_PROFILE_OPEN_STATUS_DURATION = 'Report - Profile Open Status Duration',
	REPORT_REJECTED_CORRECTION = 'Report - Rejected Correction',
	REPORT_REJECTION_REASONS = 'Report - Rejection Reasons',
	REPORT_AGENT_LOGIN_LOGOUT_REPORT = 'Report - Agent Login/Logout Report',
	REPORT_AGENT_REPORT = 'Report - Agent Report',
	REPORT_DEVICE_REPORT = 'Report - Device Report',
	REPORT_SALES_ACTIVITY = 'Report - Sales Activity',
	REPORT_SALES_SUBMISSION_DETAILED = 'Report - Sales Submission Detailed',
	REPORT_SALES_SUBMISSION_SUMMARY = 'Report - Sales Submission Summary',
	REPORT_SUMMARY_REPORT = 'Report - System Summary Report',
	REPORT_DEALER_COMMISSION = 'Report - Dealer Commission',
	REPORT_SIGNED_ECAF_UPDATE = 'Report - Signed Ecaf Update',
	REPORT_CCD_CCI_SUBMISSION_STATUS = 'Report - CCD/CCI Submission Status Report',
	REPORT_BETA = 'Report - Beta',
	REPORT_TOTAL_REG_PER_DAY = 'Report - Total Registrations Per Day',
	REPORT_APPROVALS_PER_DAY = 'Report - Approval Per Day',
	REPORT_REJECTION_PER_DAY = 'Report - Rejections Per Day',

}

export enum GROUPLIST {
	IDX_MSISDN_PREFIX = 14,
	IDX_SERVICE_TYPE_PREPAID = 127,
	IDX_SERVICE_TYPE_POSTPAID = 128,
	IDX_SERVICE_TYPE_ISP = 221,
	IDX_SERVICE_TYPE_STARTER_KIT = 227,
	IDX_SERVICE_TYPE_MNP = 235,
	IDX_SERVICE_TYPE_HYBRID = 252,
    IDX_REJECTION_REASON_ID_COPY = 254,
    IDX_REJECTION_REASON_PICTURE = 255,
    IDX_REJECTION_REASON_ID_COPY_BACK = 256,
    IDX_REJECTION_REASON_SIGNATURE = 257,
    IDX_REJECTION_REASON_SUPPORT_DOCS = 258,
	IDX_CUSTOMER_TYPE = 276,
	IDX_CONTRACT_TYPE = 277,
	IDX_MOMOKASH_USER_TYPE = 292,
	IDX_MOMOKASH_CONFIG_TYPE = 293,
	IDX_SERVICE_TYPE_SELFREG = 303,
}

export enum ROLERULES {
    NO_USER_BLOCK = "NO USER BLOCK",
    NO_USER_DISABLE = "NO USER DISABLE",
    NO_USER_RESET = "NO USER RESET"
}

/**
 * Custom angular notifier options
 * https://www.npmjs.com/package/angular-notifier
 * Types of Notifications:
 * - default
 * - info
 * - success
 * - warning
 * - error
 */
export const customNotifierOptions: NotifierOptions = {
    position: {
        horizontal: {
            /* Define the position of the alert popup */
            /* Can be {'left', 'middle', 'right'} */
            position: 'middle',
            /* Define the distance in px of how far the alert popup sits off the frame */
            distance: 12
        },
        vertical: {
            /* Define the position of the alert popup */
            /* Can be {'top', 'bottom'} */
            position: 'top',
            /* Define the distance in px of how far the alert popup sits off the frame */
            distance: 12,
            /* Define the gap in px for the space between alert popups */
            gap: 10
        }
    },
    theme: 'material',
    behaviour: {
        /* Define the the 'autoHide' of the alert popup */
        /* Can be { number | false } */
        autoHide: 5000,
        /* Define the action when clicking on the notification */
        /* Can be { 'hide' | flase } */
        onClick: 'hide',
        /* Define what happens when you hover over the alert popup */
        /* Can be { 'pauseAutoHide' | 'resetAutoHide' | false } */
        onMouseover: 'pauseAutoHide',
        showDismissButton: true,
        /* Define the stack number for the amount of alert popups at a time */
        /* Can be { number | false } */
        stacking: 10
    },
    animations: {
        /* Defines whether all (!) animations are enabled or disabled */
        enabled: true,
        show: {
            /* Defines the animation preset that will be used to animate a new notification in */
            /* Can be { 'fade' | 'slide' } */
            preset: 'slide',
            /* Defines how long it will take to animate a new notification in (in ms) */
            speed: 300,
            /* Defines which easing method will be used when animating a new notification in */
            /* Can be { 'linear' | 'ease' | 'ease-in' | 'ease-out' | 'ease-in-out' } */
            easing: 'ease'
        },
        hide: {
            /* Defines the animation offset used when hiding multiple notifications at once (in ms) */
            /* Can be {number | false} */
            preset: 'fade',
            /* Defines how long it will take to animate a new notification out (in ms) */
            speed: 300,
            /* Defines which easing method will be used when animating a new notification in */
            /* Can be { 'linear' | 'ease' | 'ease-in' | 'ease-out' | 'ease-in-out' } */
            easing: 'ease',
            /* Defines the animation offset used when hiding multiple notifications at once (in ms) */
            offset: 50
        },
        shift: {
            /* Defines how long it will take to shift a notification around (in ms) */
            speed: 300,
            /* Defines which easing method will be used when shifting a notification around */
            /* Can be { string } All standard CSS easing methods work */
            easing: 'ease'
        },
        /* Defines the overall animation overlap, allowing for much smoother looking animations (in ms) */
        /* Can be { number | false } */
        overlap: 150
    }
};
