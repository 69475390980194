import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Node } from '../../../dto/dtos';

export function LinkedListValidator(listValues: Array<Node>, key: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } | null => {

        let found = false;
        listValues.forEach( (item) => {
            if ( item.value === control.value ) {
                found = true;
            }
        });

        return (found) ? null : { List: key + " must have a value from the list" };
    };
}
