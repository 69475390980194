import { DmsNodeInfoDto } from './../../dto/dtos';
import { filter, switchMap } from 'rxjs/operators';
import { DmsService } from './../../dms/service/dms.service';
import { Component, OnInit, Input, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { Agent, DataRequest } from '../../dto/dtos';
import { AxonComponent } from '../../axon.component';
import { AgentService } from '../../services/agents/agent.service';
import { ServerError } from '../../utils/server-errors';
import { AuthService } from '../../services/auth/auth.service';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { Notifier } from '../../utils/notifier';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { PasswordInputComponent } from '../../component/passwordinput/password-input.component';
import { Subscription, Observable } from 'rxjs';

@Component({
    selector: 'app-profile',
    templateUrl: './profile-dynamic-card.component.html',
    styleUrls: ['./profile-dynamic-card.component.scss']
})
export class ProfileDynamicCardComponent extends AxonComponent implements AfterViewInit, OnInit, OnDestroy {

    agentId: number;
    agent: Agent;
    username: string;
	dmsNodeSelected: DmsNodeInfoDto;

    @Input() showImageUrl: boolean;
    @Input() parentComponent: AxonComponent;
    @Input() heading: string;

    @ViewChild('currentPasswordInput') currentPasswordInputComponent: PasswordInputComponent;
    @ViewChild('newPasswordInput') newPasswordInputComponent: PasswordInputComponent;

    instance: ProfileDynamicCardComponent;
    isLoading: boolean;

    form: FormGroup;
    formControls: Array<FormControl>;

    formSubscription: Subscription;

    btnOpts: MatProgressButtonOptions = {
        active: false,
        text: 'Test',
        // text: this.getBtnTitle(field.field),
        spinnerSize: 19,
        raised: true,
        stroked: false,
        flat: false,
        fab: false,
        // buttonColor: 'accent',
        // spinnerColor: 'accent',
        fullWidth: false,
        disabled: false,
        customClass: 'mt-2 mb-2 btn btn-axon-save btn-md text-uppercase waves-effect waves-light',
        mode: 'indeterminate',
    };

    constructor(private agentService: AgentService,
        private router: Router,
        private authService: AuthService,
        private notifier: Notifier,
        private _location: Location,
        private formBuilder: FormBuilder,
		public dmsService: DmsService) {

        super();

        this.agent = this.authService.getAuthAgent();
        this.isLoading = true;
        this.btnOpts.active = false;
        this.btnOpts.text = "Save";

        this.agentId = this.authService.getAuthAgent().agentId;

        if (this.authService.getAuthAgent().permissions) {
            console.log('Received agent with ID: ' + this.agentId + '. Fetching full agent details...');
            this.getUser();
        }

        this.instance = this;

        const formControlCurrent = new FormControl(
            this.formBuilder.group({
                currentPassword: ['', Validators.required]
            })
        );

        const formControlConfirm = new FormControl(
            this.formBuilder.group({
                password: ['', Validators.required],
                confirmPassword: ['', Validators.required]
            })
        );

        this.formControls = [formControlCurrent, formControlConfirm];
        this.form = this.formBuilder.group(this.formControls);

        this.formSubscription = this.form.valueChanges.subscribe(val => {
            this.btnOpts.disabled = this.form.invalid;
        });
    }
	ngOnInit(): void {

		this.dmsService.isDmsEnabled()
		.pipe(
			filter(item => item === true),
			switchMap(item => this.dmsService.getAgentsDmsNode(this.authService.getAuthAgent().agentId))
		)
		.subscribe(results => {
			this.dmsNodeSelected = results;
		});
	}
    ngAfterViewInit() {
        this.isLoading = false;

        setTimeout(() => {
            if (this.form.controls.currentPassword.value !== '') {
                this.newPasswordInputComponent.focus();
            } else {
                this.currentPasswordInputComponent.focus();
            }
        }, 2000);
    }

    ngOnDestroy() {
        if ( this.formSubscription ) {
            this.formSubscription.unsubscribe();
        }
    }

    /**
      * Fetches full agent data from the server
      */
    public getUser() {

        console.log("profile - getUser( " + this.agentId + " )");
        this.agentService.getAgent(this.agentId).subscribe(resp => {

            if (resp.success) {

                console.log(resp.data);
                this.agent = resp.data;
            } else {
                ServerError.printError(resp);
            }
        });
    }

    invalidatePasswordControl(controlName: string) {
        const formControl = this.form.controls[controlName];
        formControl.setErrors(this.form.errors);
    }

    save() {

        if (this.form.invalid) {
            this.notifier.warn("please correct the errors before saving");
            return;
        }
        this.btnOpts.active = true;

        const request: DataRequest = {
            username: this.agent.username,
            password: this.form.controls.password.value,
            currentPassword: this.form.controls.currentPassword.value
        };

        this.agentService.getDataResponse(request, '/updateagent').subscribe(response => {

            if (response.error !== undefined) {
                this.notifier.error(response.error);
            } else {
                this.notifier.success("Password updated successfully.");
                this.reloadComponent();
                this.router.navigateByUrl('/profile');
            }

            this.btnOpts.active = false;
        }, err => {
            this.btnOpts.active = false;
        });
    }

    reloadComponent() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/profile']);
    }

    close() {
        this._location.back();
    }


	isDmsEnabled(): Observable<boolean> {
		return this.dmsService.isDmsEnabled();
	}
}
