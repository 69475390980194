import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-axon-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})
export class AxonSpinnerComponent {

	@Input('message') message: string;

  	constructor(public activeModal: NgbActiveModal) { }

}