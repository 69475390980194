import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { AgentService } from '../../services/agents/agent.service';
import { DataRequest } from '../../dto/dtos';
import { ServerError } from '../../utils/server-errors';
import { Notifier } from '../../utils/notifier';

export const CONFIRM = "confirm";
export const CANCEL = "cancel";

@Component({
    selector: 'app-password-reset-modal',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetModalComponent {

	@Input() title: string = 'Your account password has expired. Please enter your current password, and choose a new password';
	@Input() username: string;

	currentPassword: string;
	newPassword: string;
	confirmPassword: string;
    
    constructor(public activeModal: NgbActiveModal, private agentService: AgentService, private notifier: Notifier) {

    }

    confirm() {
		this.activeModal.close(true);
    }

    cancel() {
        this.activeModal.close(false);
    }

	resetPassword() {

		if (!this.currentPassword) {
			this.notifier.error("Please enter current password");
			return;
		}

		if (!this.newPassword) {
			this.notifier.error("Please enter the new password");
			return;
		}

		if (!this.confirmPassword) {
			this.notifier.error("Please confirm the new password");
			return;
		}

		if (this.newPassword !== this.confirmPassword) {
			this.notifier.error("Please make sure new password and confirm passwords are the same");
			return;
		}

		const dataRequest: DataRequest = { 
			username: this.username,
			password: this.currentPassword,
			newPassword: this.newPassword,
			confirmPassword: this.confirmPassword
		};

		this.agentService.getDataResponse(dataRequest, '/resetpassword', '/auth')
                .subscribe(
                    result => {
                        if (result.success === true) {
							this.confirm();
							this.notifier.info("Your password has been reset");
                        } else {
							this.notifier.error(result.error);
							ServerError.printError(result);
						}
                    },
                    error => {
                        console.log('Error resetting password: ' + JSON.stringify(error));
                    }
                );

		
	}
}
