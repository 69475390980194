import { DomSanitizer } from "@angular/platform-browser";
import { Role, DynamicLink, DynamicField, DynamicCard, Token, Agent, Permission, LinkedLists, Node, Customer, RoleRule, MomokashUserAgent, AbstractMomokashUser, MomokashUserHandlerPos, MomokashUserMerchant } from "../dto/dtos";
import { KEY_OPEN_MODALS, Storage, KEY_ADDED_AGENT_IDS, KEY_TOKEN, KEY_TOKEN_EXPIRY, KEY_AGENT, KEY_AGENT_PERMISSIONS } from "./storage";
import { AxonModal } from "../component/modal-container/modal-container.component";
import { MatDialog } from "@angular/material";
import { FormGroup } from "@angular/forms";
import { Notifier } from "./notifier";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ActionRule, Permission as PermissionEnum, NO_PERMISSION_FOR_ACTION, ROLERULES } from "./constants";
import { AuthService } from "../services/auth/auth.service";
import { DynamicFieldManager } from "../settings/dynamic-fields/dynamic-field-manager";
import { Location } from "@angular/common";
declare let L;

const iconRetinaUrl = 'assets/leaflet/images/marker-icon-2x.png';
const iconUrl = 'assets/leaflet/images/marker-icon.png';
const shadowUrl = 'assets/leaflet/images/marker-shadow.png';
const iconDefault = L.icon({
	iconRetinaUrl,
	iconUrl,
	shadowUrl,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	tooltipAnchor: [16, -28],
	shadowSize: [41, 41]
});
L.Marker.prototype.options.icon = iconDefault;

export class AxonUtils {

	static domSanitizer: DomSanitizer;
	static previousUrl: string;

	/* Johannesburg */
	static DEFAULT_LATITUDE = "-26.2041";
	static DEFAULT_LONGITUDE = "28.0473";

	/**
	 * Helper to remove the time and locale from a date, and return
	 * only the date portion of the string
	 *
	 * i.e. if input is 2017-02-18T22:00:00.000+0000 output will be 2017-02-18
	 * @param date
	 */
	static getDate(date: any): string {

		return date;

		// if (date) {
		// 	return new Date(date).toLocaleDateString();
		// }
	}

	static copyDynamicLink(dynamicLink: DynamicLink) {
		const ret: DynamicLink = {
			sectionId: dynamicLink.sectionId,
			section: dynamicLink.section,
			cards: dynamicLink.cards
		};

		return ret;
	}

	/**
	 * Returns all the fields in the cards
	 */
	static getAllFields(dynamicLink: DynamicLink): Array<DynamicField> {
		const allFields = new Array<DynamicField>();
		for (const card of dynamicLink.cards) {
			for (const field of card.fields) {
				allFields.push(field);
			}
		}

		return allFields;
	}

	/**
	 * Adds an open modal to the memory storage
	 * @param storage
	 * @param dialog
	 */
	static addOpenModal(storage: Storage, dialog: MatDialog) {
		let axonModal: AxonModal = storage.getLocalItem(KEY_OPEN_MODALS);

		if (!axonModal) {
			axonModal = new AxonModal();
		}
		axonModal.addModal(dialog);
		storage.saveLocalItem(KEY_OPEN_MODALS, axonModal);
	}

	/**
	 * Removes a modal from the memory storage
	 * @param storage
	 * @param dialog
	 */
	static removeOpenModal(storage: Storage, dialog: MatDialog) {
		const axonModal: AxonModal = storage.getLocalItem(KEY_OPEN_MODALS);
		if (axonModal) {
			axonModal.removeModal(dialog);
			storage.saveLocalItem(KEY_OPEN_MODALS, axonModal);
		}
	}

	/**
	 * Closes all modals stored in memory, and deletes the AxonModal object from memory
	 * @param storage
	 */
	static closeAllModals(storage: Storage) {
		console.log('Closing all modals');
		const axonModal: AxonModal = storage.getLocalItem(KEY_OPEN_MODALS);
		if (axonModal) {
			for (const dialog of axonModal.getModals()) {
				dialog.closeAll();
			}

			storage.deleteLocalItem(KEY_OPEN_MODALS);
		}
	}

	/**
	 * Adds a leaflet map that displays a marker at the given co-ordinates.
	 * NOTE: Example usage:
	 * 1. In the component html, create a div: <div id="map"></div>
	 * 2. Call this method with param mapId set to "map" i.e. mapId must match the div id in HTML
	 * @param latitude
	 * @param longitude
	 * @param showPopup - if true, a popup is displayed when user clicks the marker,
	 *                  with value of popupMsg or, by default, co-ords are displayed
	 * @param popupMsg - overrides default popup msg of co-ordinates
	 * @param zoom - the zoom level - default is 20
	 */
	static addMap(mapId: string, latitude: string, longitude: string, showPopup?: boolean, popupMsg?: string, zoom?: number): any {
		if (latitude && longitude) {
			if (!zoom) {
				zoom = 20;
			}
			L.Control.geocoder({
				placeholder: "Searching...",
				showResultIcons: true,
				errorMessage: "Nothing found"
			});

			const map = L.map(mapId).setView([latitude, longitude], zoom);

			L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
				attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
			}).addTo(map);

			const marker = L.marker([latitude, longitude]).addTo(map);

			if (showPopup) {

				if (!popupMsg) {
					popupMsg = '<b>Latitude: </b>' + latitude + '<br/><b>Longitude: </b>' + longitude;
				}

				var popup = marker.bindPopup(popupMsg);
			}

			return map;
		}
	}

	static updateMap(map, mapId, latitude?, longitude?) {

		if (map !== undefined) {
			map.off();
			map.remove();
		}

		if (latitude !== null && latitude !== undefined) {
			return this.addMap(mapId, latitude, longitude, true);
		} else {
			return this.addMap(mapId, this.DEFAULT_LATITUDE, this.DEFAULT_LONGITUDE, true);
		}

	}

	/**
	 * Geocodes an address to latitude and longitude, and calls the given callback
	 * to provide results
	 * @param address - the address to geocode to lat / lng
	 * @param callback - the callback function that will process the results
	 * @param context - the component / instance this is called from
	 */
	static geocode(address: string, callback: CallableFunction, context: any) {
		const geocoder = new L.Control.Geocoder.Nominatim();
		geocoder.geocode(address, callback, context);
	}

	/**
	 * Reverse geocodes latitude and longitude into a physical address
	 * @param latitude
	 * @param longitude
	 * @param callback
	 * @param context
	 */
	static reverseGeocode(latitude: string, longitude: string, callback: CallableFunction, context: any) {
		const geocoder = new L.Control.Geocoder.Nominatim();
		const latlng = new L.LatLng(latitude, longitude);
		geocoder.reverse(latlng, 10, callback, context);
	}

	/**
	 * Constructs an address string with the given params. Excludes nulls / empty strings from the address.
	 * @param address
	 */
	static constructAddress(...address: string[]) {
		let addressStr = '';

		if (address) {
			let prefix = '';
			for (const a of address) {
				if (a && a != null && a.length > 0) {
					addressStr += prefix + a;
					prefix = ' ';
				}
			}
		}

		return addressStr;
	}

	static parseAddressObject(addressObj: any): Array<any> {

		let addressLine1 = "";
		let suburb = "";
		let townCity = "";
		let province = "";

		if (addressObj.road !== undefined) {
			addressLine1 += addressObj.road;
		}
		if (addressObj.suburb !== undefined) {
			suburb += addressObj.suburb;
		}
		if (addressObj.city !== undefined) {
			townCity += addressObj.city;
		}
		if (addressObj.state !== undefined) {
			province += addressObj.state;
		}
		if (addressObj.country !== undefined) {
			province += (", " + addressObj.country);
		}

		return [addressLine1, suburb, townCity, province];
	}

	static getFullAddress(values: any) {

		const fullAddress = [];

		Object.keys(values).map(key => {
			if (values[key] !== null && ['Address Line 1', 'Suburb', 'Town / City', 'Province'].indexOf(key) > -1) {
				fullAddress.push(values[key]);
			}
		});

		const fullAddressStr = fullAddress.join(", ");
		return fullAddressStr;
	}

	static markAllAsTouched(form: FormGroup) {
		Object.keys(form.controls).forEach(field => { // {1}
			const control = form.get(field); // {2}
			control.markAsTouched({ onlySelf: true }); // {3}
		});
	}

	/**
	 * Returns the field from the given DynamicLink object matching
	 * the given fieldId
	 * @param fieldId
	 * @param dynamicLink
	 */
	static getFieldById(fieldId: number, dynamicLink: DynamicLink): DynamicField {

		for (const card of dynamicLink.cards) {
			for (const field of card.fields) {
				if (field.id === fieldId) {
					return field;
				}
			}
		}
	}

	static getFieldFromCardById(fieldId: number, dynamicCard: DynamicCard): DynamicField {

		for (const field of dynamicCard.fields) {
			if (field.id === fieldId) {
				return field;
			}
		}
	}

	/**
	 * Returns the card from the given dynamicLinks matching the given cardId
	 * @param cardId
	 * @param dynamicLinks
	 */
	static getCardById(cardId: number, dynamicLinks: DynamicLink[]): DynamicCard {
		for (const dynamicLink of dynamicLinks) {
			for (const card of dynamicLink.cards) {
				if (card.id === cardId) {
					return card;
				}
			}
		}
	}

	/**
	 * Stores the added agent ids for retrieval in the agents table component
	 * @param storage
	 * @param addedAgentIds
	 */
	static setAgentIds(storage: Storage, addedAgentIds: string[]) {
		storage.saveLocalItem(KEY_ADDED_AGENT_IDS, addedAgentIds);
	}

	/**
	 * Retrieves the added agent ids to display those agents in the agents table component
	 * @param storage
	 */
	static getAgentIds(storage: Storage): string[] {
		let addedAgentIds: string[] = storage.getLocalItem(KEY_ADDED_AGENT_IDS);
		if (!addedAgentIds) {
			addedAgentIds = [];
		}
		return addedAgentIds;
	}

	/**
	 * Fetches the auth token from local storage
	 */
	static getAuthToken(storage: Storage): Token {

		const token: Token = {
			token: storage.getItem(KEY_TOKEN),
			status: 'VALID',
			expiry: new Date(storage.getItem(KEY_TOKEN_EXPIRY)),
		};

		/* If no token exists, return null */
		if (token.token == null) {
			return null;
		}

		/* If token expired, return null */
		if (token.expiry.getTime() < new Date().getTime()) {
			return null;
		}

		return token;
	}

	/**
	 * Fetches the agent who has authed from local storage
	 */
	static getAuthAgent(storage: Storage): Agent {
		try {
			const agent: Agent = {
				agentId: Number(storage.getItem(KEY_AGENT)),
				elementId: Number(storage.getItem(KEY_AGENT)),
				permissions: JSON.parse(storage.getSecureItem(KEY_AGENT_PERMISSIONS))
			};

			return agent;
		} catch (e) {
			/* Any issues retrieving the agent, return -1 agent ID */
			return {
				agentId: 1,
				elementId: 1
			};
		}
	}

	static saveAuth(token: Token, agent: Agent, storage: Storage) {
		if (token != null) {
			// console.log('******************************** SERVER RESPONSE JWT TOKEN: '+ token.token);
			storage.saveItem(KEY_TOKEN, token.token);
			storage.saveItem(KEY_TOKEN_EXPIRY, String(token.expiry));
			storage.saveItem(KEY_AGENT, String(agent.agentId));
			if (agent.permissions) {
				storage.saveSecureItem(KEY_AGENT_PERMISSIONS, JSON.stringify(agent.permissions));
			}
		}
	}

	/**
	 * Clears the auth token and agent from local storage
	 */
	static deleteAuth(storage: Storage) {
		console.log('Deleting auth - permissions');
		storage.deleteItem(KEY_TOKEN);
		storage.deleteItem(KEY_TOKEN_EXPIRY);
		storage.deleteItem(KEY_AGENT);
		storage.deleteItem(KEY_AGENT_PERMISSIONS);
	}

	/**
	 * Used in case session expiry extension failed - logs user out
	 * and shows them an error
	 */
	static doLogoutAndRedirectWithError(router: Router, notifier: Notifier, storage: Storage, error: string) {
		this.doLogoutAndRedirect(router, notifier, storage, false);

		notifier.error(error);
	}

	static doLogoutAndRedirect(router: Router, notifier: Notifier, storage: Storage, showWarning?: boolean) {
		AxonUtils.deleteAuth(storage);
		// this.userIdle.stopTimer();
		// this.userIdle.stopWatching();
		// if ( this.sessionModalExtension ) {
		//     this.sessionModalExtension.close();
		//     this.sessionModalExtension = null;
		// }
		/* Close any open modals */
		AxonUtils.closeAllModals(storage);

		router.navigate(['/authentication/login']);

		if (showWarning === undefined || showWarning) {
			// notifier.warn('Your session has expired');
		}
	}

	/**
	 * Returns the enum's key, based on the given value.
	 * This key can be used to get an instance of the enum via the
	 * call Enum[key] i.e. ActionRule[getEnumKeyByEnumValue(ActionRule, 'MANDATORY')]
	 * @param myEnum
	 * @param enumValue
	 */
	static getEnumKeyByEnumValue(myEnum, enumValue) {
		const keys = Object.keys(ActionRule).filter(x => myEnum[x] === enumValue);
		return keys.length > 0 ? keys[0] : null;
	}

	static calculateAgeFromBirthDate(birthdate: Date) {
		const today = new Date();
		let age = today.getFullYear() - birthdate.getFullYear();
		const m = today.getMonth() - birthdate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
			age--;
		}
		return age;
	}

	static getAgentFullnameWithParts(firstname: string, middlename: string, surname: string): string {
		const agent: Agent = {
			name: firstname,
			othernames: middlename,
			surname: surname
		};

		return AxonUtils.getAgentFullname(agent);
	}

	/**
	 * Returns the agent's fullname always using the middle name where available
	 */
	static getAgentFullname(agent: Agent): string {
		let out = '';
		let seperator = '';
		if (agent && agent.name) {
			out += seperator + agent.name;
			seperator = ' ';
		}
		if (agent && agent.othernames) {
			out += seperator + agent.othernames;
			seperator = ' ';
		}
		if (agent && agent.surname) {
			out += seperator + agent.surname;
			seperator = ' ';
		}
		// console.log("Agent: getAgentFullname", out, agent);
		return out;
	}

	/**
	 * Returns the customer's fullname always using the middle name where available
	 */
	static getCustomerFullname(customer: Customer): string {
		let out = '';
		let seperator = '';
		if (customer && customer.name) {
			out += seperator + customer.name;
			seperator = ' ';
		}
		if (customer && customer.otherName) {
			out += seperator + customer.otherName;
			seperator = ' ';
		}
		if (customer && customer.surname) {
			out += seperator + customer.surname;
			seperator = ' ';
		}
		return out;
	}

	/**
	 * Returns the momokash user's fullname
	 */
	static getMomokashUserFullname(momokash: AbstractMomokashUser): string {

		let momokashUser = null;
		if (momokash.type === 'AGENT') {
			momokashUser = momokash as MomokashUserAgent;
		} else if (momokash.type === 'HANDLER / POS') {
			momokashUser = momokash as MomokashUserHandlerPos;
		} else if (momokash.type === 'MERCHANT') {
			momokashUser = momokash as MomokashUserMerchant;
			return momokashUser.companyName;
		} else {
			return "Cannot determine momokash user type";
		}

		let out = '';
		let seperator = '';
		if (momokashUser && momokashUser.firstname) {
			out += seperator + momokashUser.firstname;
			seperator = ' ';
		}
		if (momokashUser && momokashUser.lastname) {
			out += seperator + momokashUser.lastname;
			seperator = ' ';
		}
		return out;
	}

	static hasPermission(perm: PermissionEnum, permissions: Array<Permission>): boolean {

		if (!permissions || permissions === undefined) {
			return false;
		}
		for (const p of permissions) {
			if (p.permission === perm) {
				// console.log('p.permission', p.permission, ' perm', perm);
				return true;
			}
		}

		return false;

	}

	/**
	 * Used to check if the user can access a particular URL
	 */
	static checkUrlPermission(authService: AuthService, router: Router): boolean {
		/* Check user has permission */
		const permissions = authService.getAuthAgent().permissions;

		let permission: PermissionEnum;
		switch (router.url) {
			case "/profile":
				permission = PermissionEnum.LOGIN_GUI;
				break;
			case "/agents":
				permission = PermissionEnum.AGENT_ADMIN;
				break;
			case "/customers":
				permission = PermissionEnum.CUSTOMERS;
				break;
			case "/churn":
				permission = PermissionEnum.CHURN_UNCHURN;
				break;
			case "/devices":
				permission = PermissionEnum.DEVICE_ADMIN;
				break;
			case "/approvals":
				permission = PermissionEnum.APPROVAL;
				break;
			case "/system":
				permission = PermissionEnum.SYSTEM;
				break;
			case "/reports-list":
				permission = PermissionEnum.REPORTING;
				break;
		}

		if (router.url.indexOf('/report/') !== -1) {
			permission = PermissionEnum.REPORTING;
		}

		if (router.url.indexOf('/approvals/review') !== -1) {
			permission = PermissionEnum.APPROVAL_REVIEW;
		}

		// console.log('Checking if user has permission [' + permission + ']');
		if (permission !== undefined && !AxonUtils.hasPermission(permission, permissions)) {
			// console.log('User does not have required permission. Cannot continue');
			router.navigate(['/unauthorized']);
			return false;
		}
	}

	/**
	 * Used to check if the user can perform a particular action - this is used when permission checking
	 * cannot be done solely on the URL
	 */
	static checkActionPermission(
		permission: PermissionEnum, authService: AuthService, notifier: Notifier, displayMessage?: boolean): boolean {

		if (displayMessage === undefined) {
			displayMessage = true;
		}

		/* Check user has permission */
		const permissions = authService.getAuthAgent().permissions;

		// console.log('Checking if user has permission [' + permission + ']');
		if (!AxonUtils.hasPermission(permission, permissions)) {
			// console.log('User does not have required permission. Cannot continue');

			if (displayMessage) {
				notifier.warn(NO_PERMISSION_FOR_ACTION);
			}

			return false;
		}

		return true;
	}

	static checkRoleRule(rule: ROLERULES, agentRoles: Array<Role>, dynamicFieldMgr: DynamicFieldManager): boolean {
		const roleRules: Array<RoleRule> = dynamicFieldMgr.getRoleRules();

		/* Make a string only array of agent's roles so we can do an indexOf search on it */
		const agentRolesAsList: Array<string> = [];
		agentRoles.forEach(role => {
			agentRolesAsList.push(role.role);
		});

		if (roleRules) {
			/* Check if the agent has a role that has a rule that says they may not be blocked */
			for (const roleRule of roleRules) {
				if (agentRolesAsList.indexOf(roleRule.role) > -1) {
					if (roleRule.rules.indexOf(rule) > -1) {
						return false;
					}
				}
			}
		}

		return true;
	}

	static getPreviousUrl(): string {
		return this.previousUrl;
	}

	static setPreviousUrl(_previousUrl: string) {
		this.previousUrl = _previousUrl;
	}

	static getChooserValue(field: DynamicField, dynamicFieldMgr: DynamicFieldManager): string {
		const props = field.params.split(";");
		const linkedListName = props[0].split("=")[1];
		const type = props[1].split("=")[1];

		let returnType = null;
		if (props[3]) {
			returnType = props[3].split("=")[1];
		}

		/* Flat LinkedList soetimes needs an ID return type value */
		if (props.length > 2) {
			props.forEach(prop => {
				if (prop.indexOf('RETURN_TYPE') > -1) {
					returnType = prop.split("=")[1];
				}
			});
		}

		const linkedListData: LinkedLists = dynamicFieldMgr.getLinkedListData();
		let linkedList: Array<Node>;

		/* The parent list is ready to go, as it's already formatted into a hierarchy from the server */
		Object.entries(linkedListData).forEach(entry => {
			if (entry[0] === linkedListName) {
				linkedList = entry[1];
			}
		});

		if (linkedList) {

			let returnValue = "";
			linkedList.forEach((node: Node) => {

				if (node !== undefined && node.children) {
					for (const nodeChild of node.children) {
						if (nodeChild.id === field.value || nodeChild.axonInternalId === field.value) {
							returnValue = node.value + " / " + nodeChild.value;
						}
						if (nodeChild.children) {
							for (const nodeGrandChild of nodeChild.children) {
								if (nodeGrandChild.id === field.value || nodeGrandChild.axonInternalId === field.value) {
									returnValue = node.value + " / " + nodeChild.value + " / " + nodeGrandChild.value;
								}
								if (nodeGrandChild.children) {
									for (const nodeGreatGrandChild of nodeGrandChild.children) {
										if (nodeGreatGrandChild.id === field.value || nodeGreatGrandChild.axonInternalId === field.value) {
											returnValue = node.value + ' / ' + nodeChild.value + ' / ' + nodeGrandChild.value + ' / ' + nodeGreatGrandChild.value;
										}
									}
								}
							}
						}
					}
				} else {
					if (node.id === field.value) {
						returnValue = node.value;
					}
				}
			});

			if (returnValue !== "") {
				return returnValue;
			}
		}
		return field.value;
	}











	static navigateKeepQueryParams(router: Router, route: ActivatedRoute, path: any[]) {
		const params = {};
		route.snapshot.queryParamMap.keys.forEach(key => {
			console.log(`Param:: ${key}`, route.snapshot.queryParamMap.get(key));
			params[key] = route.snapshot.queryParamMap.get(key);
		});
		// params['filter-state-agent-list'] = JSON.stringify(event.state);
		console.log("Navigate: ", path);
		router.navigate(path, { queryParams: params });
	}
	static navigateWithAppendedQueryParam(router: Router, route: ActivatedRoute, path: any[], paramName: string, paramValue: any) {
		const params = this.getAppendedQueryParam(route, paramName, paramValue);
		router.navigate(path, { queryParams: params });
	}
	static navigateWithAppendedQueryParams(router: Router, route: ActivatedRoute, path: any[], queryParams: { name: string, value: any }[]) {
		console.log("DMS:: navigateWithAppendedQueryParams", queryParams);
		const params = this.getAppendedQueryParams(route, queryParams);
		router.navigate(path, { queryParams: params });
	}
	static getAppendedQueryParam(route: ActivatedRoute, paramName: string, paramValue: any): any {
		const params = {};
		route.snapshot.queryParamMap.keys.filter(item => item !== paramName).forEach(key => {
			console.log(`Param:: ${key}`, route.snapshot.queryParamMap.get(key));
			params[key] = route.snapshot.queryParamMap.get(key);
		});
		if (paramValue !== null) {
			params[paramName] = JSON.stringify(paramValue);
			if (typeof paramValue === 'string') {
				params[paramValue] = paramValue;
			} else {
				params[paramName] = JSON.stringify(paramValue);
			}
		}
		return params;
	}
	static getAppendedQueryParams(route: ActivatedRoute, queryParams: { name: string, value: any }[]): any {
		const params = {};
		queryParams.forEach(queryParam => {
			route.snapshot.queryParamMap.keys.filter(item => item !== queryParam.name).forEach(key => {
				console.log(`Param:: ${key}`, route.snapshot.queryParamMap.get(key));
				params[key] = route.snapshot.queryParamMap.get(key);
			});
			if (queryParam.name !== null) {
				if (typeof queryParam.value === 'string') {
					params[queryParam.name] = queryParam.value;
				} else {
					params[queryParam.name] = JSON.stringify(queryParam.value);
				}
			}
		});
		return params;
	}
	static navigateWithRemovedQueryParam(router: Router, route: ActivatedRoute, path: any[], paramName: string) {
		const params = {};
		route.snapshot.queryParamMap.keys.filter(item => item !== paramName).forEach(key => {
			console.log(`Param:: ${key}`, route.snapshot.queryParamMap.get(key));
			params[key] = route.snapshot.queryParamMap.get(key);
		});
		router.navigate(path, { queryParams: params });
	}

	static updateUrlKeepQueryParams(location: Location, route: ActivatedRoute, path: string) {
		let query = "";
		let seperator = "?";
		route.snapshot.queryParamMap.keys.forEach(key => {
			console.log(`Param:: ${key}`, route.snapshot.queryParamMap.get(key));
			query += seperator + key + "=" + encodeURIComponent(route.snapshot.queryParamMap.get(key));
			seperator = "&";
		});
		location.go(path, query);
	}
	static updateUrlWithAppendedQueryParam(location: Location, route: ActivatedRoute, path: string, paramName: string, paramValue: any) {
		let query = "";
		let seperator = "?";
		route.snapshot.queryParamMap.keys.filter(item => item !== paramName).forEach(key => {
			console.log(`Param:: ${key}`, route.snapshot.queryParamMap.get(key));
			query += seperator + key + "=" + encodeURIComponent(route.snapshot.queryParamMap.get(key));
			seperator = "&";
		});
		if (paramValue !== null) {
			query += seperator + paramName + "=" + encodeURIComponent(JSON.stringify(paramValue));
		}
		if (path == null) {
			const index = location.path(true).indexOf('?');
			if (index === -1) {
				path = location.path(true);
			} else {
				path = location.path(true).substring(0, index);
			}
		}

		location.go(path, query);
	}
	static updateUrlWithRemovedQueryParam(location: Location, route: ActivatedRoute, path: string, paramName: string) {
		let query = "";
		let seperator = "?";
		route.snapshot.queryParamMap.keys.filter(item => item !== paramName).forEach(key => {
			console.log(`Param:: ${key}`, route.snapshot.queryParamMap.get(key));
			query += seperator + key + "=" + encodeURIComponent(route.snapshot.queryParamMap.get(key));
			seperator = "&";
		});
		if (path == null) {
			const index = location.path(true).indexOf('?');
			if (index === -1) {
				path = location.path(true);
			} else {
				path = location.path(true).substring(0, index);
			}
		}

		location.go(path, query);
	}

	static sort(prop) {
		return function (a, b) {
			if (a[prop] > b[prop]) {
				return 1;
			} else if (a[prop] < b[prop]) {
				return -1;
			}
			return 0;
		}
	}
}
