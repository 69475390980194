import { Pipe, PipeTransform } from '@angular/core';

export enum NormalSuffix {
    YEARS = "year(s)",
    MONTHS = "month(s)",
    WEEKS = "week(s)",
    DAYS = "day(s)",
    HOURS = "hour(s)",
    MINS = "min(s)",
    SECS = "sec(s)",
}

export enum ShortSuffix {
    YEARS = "yr",
    MONTHS = "mt",
    WEEKS = "wk",
    DAYS = "d",
    HOURS = "hr",
    MINS = "mi",
    SECS = "s",
}

/**
 * Takes millis and converts them to a meaningful time representation i.e:
 * seconds, or
 * minutes, or
 * hours, or
 * days, or
 * weeks, or
 * months, or
 * years
 */
@Pipe({ name: 'millisToTimeAgo' })
export class MillisToTimeAgoPipe implements PipeTransform {

    format: 'short' | 'normal' = 'normal';

    transform(millis: number, args?: any): any {

        if (args === 'short') {
            this.format = "short";
        }

        if (millis > 1000) {
            // Possibly seconds
            const seconds = Math.round(millis / 1000);

            if (seconds > 59) {
                // Possibly minutes
                const minutes = Math.round(seconds / 60);

                if (minutes > 59) {
                    // Possibly hours
                    const hours = Math.round(minutes / 60);

                    if (hours > 23) {
                        // Possibly days
                        const days = Math.round(hours / 24);

                        if (days > 7) {
                            // Possibly weeks
                            const weeks = Math.round(days / 7);

                            if (weeks > 3) {
                                // Possibly months
                                const months = Math.floor(weeks / 4);

                                if (months > 12) {
                                    // Possibly years
                                    const years = Math.floor(months / 12);

                                    return years + this.getSuffix(NormalSuffix.YEARS);

                                } else {
                                    return months + this.getSuffix(NormalSuffix.MONTHS);
                                }

                            } else {
                                return weeks + this.getSuffix(NormalSuffix.WEEKS);
                            }

                        } else {
                            return days + this.getSuffix(NormalSuffix.DAYS);
                        }

                    } else {
                        return hours + this.getSuffix(NormalSuffix.HOURS);
                    }

                } else {
                    return minutes + this.getSuffix(NormalSuffix.MINS);
                }

            } else {
                return seconds + this.getSuffix(NormalSuffix.SECS);
            }
        } else {
            return Math.round(millis / 1000) + this.getSuffix(NormalSuffix.SECS);
        }

    }

    private getSuffix(suffix: NormalSuffix): string {

        let suffixStr: string;
        if (this.format === 'short') {
            switch (suffix) {
                case NormalSuffix.YEARS:
                    
                    suffixStr = ShortSuffix.YEARS;
                    break;

                case NormalSuffix.MONTHS:

                    suffixStr = ShortSuffix.MONTHS;
                    break;

                case NormalSuffix.WEEKS:

                    suffixStr = ShortSuffix.WEEKS;
                    break;

                case NormalSuffix.DAYS:

                    suffixStr = ShortSuffix.DAYS;
                    break;

                case NormalSuffix.HOURS:

                    suffixStr = ShortSuffix.HOURS;
                    break;

                case NormalSuffix.MINS:

                    suffixStr = ShortSuffix.MINS;
                    break;

                case NormalSuffix.SECS:
                default:

                    suffixStr = ShortSuffix.SECS;
                    break;
            }
        } else {
            suffixStr = suffix;
        }

        return ' ' + suffixStr;

    }
}