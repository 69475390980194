import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from "rxjs";
import { TimerObservable } from "rxjs/observable/TimerObservable";

@Component({
    selector: 'app-timer-component',
    template: '{{ tick | millisToTimeAgo: \'short\'}}',
})
export class TimerComponent implements OnInit, OnDestroy {

    @Input() initialSeconds: number;
    tick: number = 0;
    private subscription: Subscription;
    private initialSet: boolean;
    private tickTime = 1000;

    private status: "running" | "paused" = "running";

    constructor() {
    }

    ngOnInit() {

        if ( this.initialSeconds ) {
            this.tick = this.initialSeconds * 1000;
        }

        const timer = TimerObservable.create(2000, this.tickTime);
        this.subscription = timer.subscribe(t => {

            /* Convert seconds to milliseconds for easier usage in MillisToTimeAgo pipe */
            if ( this.tick === 0 ) {
                this.tick = t * this.tickTime;
            } else {
                this.tick = this.tick + this.tickTime;
            }

        });
        this.status = "running";
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.status = "paused";
    }

    getSeconds() {
        return this.tick / 1000;
    }

    pauseTimer() {
        if ( this.status === "running" ) {
            this.ngOnDestroy();
        }
    }

    resumeTimer() {
        if ( this.status === "paused" ) {
            this.ngOnInit();
        }
    }

    resetTimer() {
        this.tick = 0;
        this.ngOnDestroy();
        this.ngOnInit();
    }
}