import { NotifierService } from "angular-notifier";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
/**
 * Helper class to easily provide various notifications
 */
export class Notifier {
    constructor(private notifierService: NotifierService) {}

    default(msg: string) {
        this.notifierService.notify( 'default', msg );
    }

    info(info: string) {
        this.notifierService.notify( 'info', info );
    }

    success(success: string) {
        this.notifierService.notify( 'success', success );
    }

    warn(warning: string) {
        this.notifierService.notify( 'warning', warning );
    }

    error(error: string) {
        this.notifierService.notify( 'error', error );
    }
}