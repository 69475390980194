import { Injectable } from '@angular/core';
import { Storage, KEY_GROUP_LISTS_VERSION, KEY_GROUP_LISTS } from '../../utils/storage';
import { GroupLists } from '../../dto/dtos';
import { group } from '@angular/animations';

/* List of rejection reasons for display in the modal popup */
export const IDX_REJECTION_REASONS    = 40;
export const IDX_MARK_PENDING_REASONS = 250;

@Injectable({
    providedIn: 'root'
})
/**
 * Responsible for storing and fetching group lists
 */
export class GroupListsManager {

    constructor(private storage: Storage) {}

    /**
     * Returns the version of group lists.
     * NOTE: Returns 0 if the group lists are not present.
     */
    getVersion(): number {

        let version = Number(this.storage.getItem(KEY_GROUP_LISTS_VERSION));

        /* Make sure the fields are present */
        if ( !this.getGroupListsData() ) {
            version = 0;
        }

        return version;
    }

    /**
     * Saves the given dynamic fields, cards, sections and links
     */
    saveGroupListsData( groupListsVersion: number, groupLists: Map<number, Array< GroupLists > > ) {

        console.log( 'Saving group lists data of size [' + groupLists.size + ']');

        this.storage.saveItem(KEY_GROUP_LISTS_VERSION, '' + groupListsVersion);
        this.storage.saveSecureItem(KEY_GROUP_LISTS, JSON.stringify(groupLists));

    }

    /**
     * Returns decrypted group list data.
     */
    private getGroupListsData(): Map<number, Array< GroupLists > > {
        try {
            return JSON.parse(this.storage.getSecureItem(KEY_GROUP_LISTS));
        } catch (e) {
            return null;
        }
    }

    /**
     * Fetches a list of GroupLists objects linked to the given ID
     * @param groupListsId 
     */
    getGroupList( groupListsId: number ): Array< GroupLists > {
        const groupListMap = this.getGroupListsData();

        return groupListMap[groupListsId];
    }

	/**
     * Fetches a list of GroupLists objects linked to the given ID and language
     * @param groupListsId 
     */
	 getGroupListIdLang( groupListsId: number, lang: string ): Array< GroupLists > {
        const groupListMap = this.getGroupListsData();
        const base = groupListMap[groupListsId];
		const ret = new Array< GroupLists >();

		for ( const grpLst of base) {
			if ( grpLst.language === lang ) {
				ret.push(grpLst);
			}
		}

		return ret;
    }
    
}
