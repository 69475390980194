import { Component, OnInit, ViewChild, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Image } from '../../../../../dto/dtos';
import { UploadComponent } from '../../../../upload/upload.component';
import { Notifier } from '../../../../../utils/notifier';


@Component({
  selector: 'app-dynamic-image-upload',
  templateUrl: 'dynamic-image-upload.component.html',
  styleUrls: ['./dynamic-image-upload.component.scss'],
})
export class DynamicImageUploadComponent implements OnInit, AfterViewInit {

	@Input() image: Image;
	@Output() sendImage = new EventEmitter();
	
    status = "";
    isLoading = true;
    @ViewChild(UploadComponent) child;
    
	constructor(private _location: Location, public activeModal: NgbActiveModal, public notifier: Notifier ) {}

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this.isLoading = false;
    }

	uploadSuccess(event) {
		this.sendImage.emit(event);
		// this.closeModal();
	}

	uploadError(event) {
		console.log('Upload error event', event);
		this.notifier.error(event.error);
	}

	closeModal() {
        this.activeModal.close();
    }
}
