import { Injectable, Component, OnInit, AfterViewInit, OnChanges } from "@angular/core";
import { FormControl, FormGroup, FormBuilder, ValidatorFn, Validators } from "@angular/forms";
import { ChurnService } from "../services/churn/churn.service";
import { ServerError } from "../utils/server-errors";
import { Notifier } from "../utils/notifier";
import { ChurnBatch } from "../dto/dtos";
import * as fileSaver from 'file-saver';
import { ChurnFileUploadComponent } from "../services/churn/churn-file-upload.component";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "../../environments/environment";

@Injectable({
	providedIn: 'root'
})
@Component({
	// selector: 'app-users',
	templateUrl: './churn.component.html',
	styleUrls: ['./churn.component.scss'],
})
export class ChurnComponent implements OnInit {
    
    churnFormGroup: FormGroup;
    searchTypeFormCtrl = new FormControl();
    batchListFormCtrl = new FormControl();
    textFormCtrl = new FormControl();
    churnTypeFormCtrl = new FormControl();

	env = environment;

    isSearching: boolean;

    searchControlType: 'list' | 'msisdn' | 'batchid' = 'list';

    batchList: Array<ChurnBatch>;
    churnBatchToDisplay: Array<ChurnBatch>;

    SEARCH_TYPE_SELECT   = 1;
    SEARCH_TYPE_BATCH_ID = 2;
    SEARCH_TYPE_MSISDN   = 3;

    CHURN_SEARCH_ALL     = 1;
    CHURN_SEARCH_CHURN   = 2;
    CHURN_SEARCH_UNCHURN = 3;

    DOWNLOAD_CHURN_FILE = 'CHURN_FILE';
    DOWNLOAD_OUTCOME    = 'OUTCOME';
    DOWNLOAD_FAILURE    = 'FAILURE';

    uploadModal: NgbModalRef;

    constructor(
        private formBuilder: FormBuilder, 
        private churnService: ChurnService, 
        private notifier: Notifier,
        private modalService: NgbModal) {
    }

    ngOnInit() {
        this.getListOfBatches();
    }

    private getListOfBatches() {
        this.churnService.getListOfBatches().subscribe(
            resp => {
                console.log('Churn get list of batches ? [' + resp.success + ']');
                if ( resp.success ) {
                    console.log('Batches churn list: ' + JSON.stringify(resp.dataList));
                    
                    this.batchList = resp.dataList;
                    this.createForm();
                    
                } else {
                    ServerError.printError(resp);
                    this.notifier.error(ServerError.formatError(resp));
                }
            },
            error => {
                this.notifier.error( ServerError.formatHttpError( error ) );
            }
        );
    }

    private createForm() {

        const validatorList: Array<ValidatorFn> = new Array();
        validatorList.push(
            Validators.pattern('[0-9]*')
        );

        this.textFormCtrl = new FormControl(
            {},
            validatorList,
        );

		this.searchTypeFormCtrl = new FormControl();
		this.batchListFormCtrl = new FormControl();
		this.textFormCtrl = new FormControl();
		this.churnTypeFormCtrl = new FormControl();

		this.searchTypeFormCtrl.setValue(this.SEARCH_TYPE_SELECT);
		this.churnTypeFormCtrl.setValue(this.CHURN_SEARCH_ALL);
		
        this.churnFormGroup = this.formBuilder.group({
            searchTypeFormCtrl: this.searchTypeFormCtrl,
            batchListFormCtrl: this.batchListFormCtrl,
            textFormCtrl: this.textFormCtrl,
            churnTypeFormCtrl: this.churnTypeFormCtrl           
        });

		this.changeSearch(null);
    }

    changeSearch(event: any) {
        console.log( 'this.searchType.value', this.searchTypeFormCtrl.value );
        console.log( 'this.formGroup.searchType', this.churnFormGroup.value.searchTypeFormCtrl);

        switch ( this.searchTypeFormCtrl.value ) {
            case this.SEARCH_TYPE_SELECT:
                this.searchControlType = 'list';
                this.batchListFormCtrl.reset();
            break;
            case this.SEARCH_TYPE_BATCH_ID:
                this.searchControlType = 'batchid';
                this.textFormCtrl.reset();
            break;
            case this.SEARCH_TYPE_MSISDN:
                this.searchControlType = 'msisdn';
                this.textFormCtrl.reset();
            break;
        }
        
    }

    search() {
        console.log( 'this.formGroup.value', this.churnFormGroup.value);
        let searchType = this.churnFormGroup.value.searchTypeFormCtrl;

		if ( searchType === null || searchType === undefined ) {
			searchType = this.SEARCH_TYPE_SELECT;
		}
        
        let batchId;
        let msisdn;
        let serviceType;
        switch (searchType) {
			case this.SEARCH_TYPE_SELECT:
				batchId = this.churnFormGroup.value.batchListFormCtrl;

				if ( batchId === null || batchId === undefined ) {
					this.notifier.error("Please select a Batch from the list");
					return;
				}
			break;
			case this.SEARCH_TYPE_BATCH_ID:
				batchId = this.churnFormGroup.value.textFormCtrl;

				if ( batchId === null || batchId === undefined ) {
					this.notifier.error("Please enter a Batch ID");
					return;
				}
			break;
			case this.SEARCH_TYPE_MSISDN:
				msisdn = this.churnFormGroup.value.textFormCtrl;

				if ( msisdn === null || msisdn === undefined ) {
					this.notifier.error("Please enter an MSISDN");
					return;
				}
			break;
		}

        const churnType = this.churnFormGroup.value.churnTypeFormCtrl;
        if ( churnType ) {
            switch ( churnType ) {
                case this.CHURN_SEARCH_CHURN:
                    serviceType = 'Churn';
                break;
                case this.CHURN_SEARCH_UNCHURN:
                    serviceType = 'Unchurn';
                break;
            }
        }

        console.log( 'Batch ID [' + batchId + '] - MSISDN [' + msisdn + '] - Type [' + serviceType + ']' );

		this.isSearching = true;
        this.churnBatchToDisplay = null;
        this.churnService.getChurnBatch(batchId, msisdn, serviceType).subscribe(
            resp => {
                try {
                    console.log('Churn get list of batches ? [' + resp.success + ']');
                    if ( resp.success ) {
                        console.log('Batches churn list: ' + JSON.stringify(resp.dataList));
                        
                        this.churnBatchToDisplay = resp.dataList;
                        
                    } else {
                        ServerError.printError(resp);
                        this.notifier.error(ServerError.formatError(resp));
                    }
                } finally {
                    this.isSearching = false;
                }
            },
            error => {
                this.notifier.error( ServerError.formatHttpError( error ) );
                this.isSearching = false;
            }
        );
    }

    download( batchId: number, fileType: string ) {
        console.log( "Downloading file [" + fileType + "] for batch ID [" + batchId + "]" );
        this.churnService.getChurnFile(batchId, fileType).subscribe( response => {
  
			let ext = '.csv';
			if ( fileType === this.DOWNLOAD_CHURN_FILE ) {
				ext = '.txt';
			}
			console.log('Response:', response);

            // fileSaver.saveAs(response, fileType + '-' + batchId + ext);

			// var url= window.URL.createObjectURL(response);
			// fileSaver.saveAs(new Blob(['4003594a-d912-48d8-8934-5d70ec5bde8d'], { type: 'text/plain' }), fileType + '-' + batchId + ext);
  			// window.open(url);

			var reader = new FileReader();
			reader.onload = function(){
				fileSaver.saveAs(new Blob([reader.result], { type: 'text/plain' }), fileType + '-' + batchId + ext);
			}
			reader.readAsText(response);
        });
    }

    uploadChurnFile() {
        // if ( AxonUtils.checkActionPermission(permission, this.authService, this.notifier) ) {
            this.uploadModal = this.modalService.open(ChurnFileUploadComponent, { windowClass : "modal-bulkblock" });
            this.uploadModal.componentInstance.action = 'Churn';
            this.uploadModal.componentInstance.parent = this;
        // }
    }

    uploadUnchurnFile() {
        // if ( AxonUtils.checkActionPermission(permission, this.authService, this.notifier) ) {
            this.uploadModal = this.modalService.open(ChurnFileUploadComponent, { windowClass : "modal-bulkblock" });
            this.uploadModal.componentInstance.action = 'Unchurn';
            this.uploadModal.componentInstance.parent = this;
        // }
    }

    uploadSuccessful() {
        this.uploadModal.close();
        this.getListOfBatches();
    }

}