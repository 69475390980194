import { ReportParameterDto } from './../../../dto/dtos';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-report-parameter-filter-text',
	templateUrl: './report-parameter-filter-text.component.html',
	styleUrls: ['./report-parameter-filter-text.component.css']
})
export class ReportParameterFilterTextComponent implements OnInit {
	@Input() parameter: ReportParameterDto;
	@Input() initValue: string;
	@Output() change = new EventEmitter();
	_value: string;

	constructor() { }

	ngOnInit() {
		if (this.initValue) {
			this._value = this.initValue;
		}
	}
	get name(): string {
		return this.parameter.name;
	}
	get value(): string {
		return this._value;
	}
	set value(input: string) {
		this._value = input;
		this.change.emit({parameter: this.parameter, value: this._value});
	}
}
