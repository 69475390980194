import { catchError, map, startWith, switchMap, debounceTime, filter, } from 'rxjs/operators';
import { Component, ViewChild, AfterViewInit, Input, OnInit, OnChanges, OnDestroy, ElementRef, Output, EventEmitter, } from '@angular/core';
import { MatPaginator, MatSort, MatDialogConfig, MatDialog, MatTableDataSource, MatDialogRef, MatCheckboxChange } from '@angular/material';
import { merge, of as observableOf, of, Subscription, fromEvent } from 'rxjs';
import { ModalContainerComponent } from '../modal-container/modal-container.component';
import { TableData } from './table-data/table-data';
import { Notifier } from '../../utils/notifier';
import { ServerError } from '../../utils/server-errors';
import { AxonModel, DataResponse, Customer } from '../../dto/dtos';
import { AxonComponent } from '../../axon.component';
import { DialogData, DialogComponent } from '../dialog/dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { MaterialTableData } from './material-table-data';
import { MillisToTimeAgoPipe } from '../../pipes/millis-to-time-ago.pipe';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomersComponent } from '../../customers/customers.component';
import { ArchiveDataComponent } from '../../customers/modal/archive-data/archive-data.component';
import { RegistrationHistoryComponent } from '../../customers/modal/registration-history/registration-history.component';
import { AgentListComponent } from '../../agents/agent-list/agent-list.component';
import { CustomerViewRegistrationHistoryListComponent } from '../../customers/customer-view-registration-history-list/customer-view-registration-history-list.component';
import { Location } from '@angular/common';
import { AxonUtils } from '../../utils/axon-utils';
import { ApprovalReviewListComponent } from '../../approvals/quality-review/review-list.component';
import { environment } from '../../../environments/environment';

/*
Filters for the table
NOTE: order is important as filters can be added to filter the table, thus removing
them from the available list of filters. Likewise, an added filter can be removed,
thus re-adding them back to the list of available filters. The order preserves the
position of the filter in the available list.
*/
export interface FilterOption {
	order?: number;
	filter: string;
	suffix?: string;
	type: 'dateSince' | 'deviceType' | 'agentApprovalHistoryStatus' | 'customerIDType' | 'agentStatus' | 'search' | 'text' | 'date' | 'number' | 'dateRange' | 'select_role' | 'select_shop' | 'select_region';
	value?: any;
	dynamicAlias?: any;
}
export interface TableLoadDataSettings {
	sortActive?: any;
	sortDirection?: any;
	pageIndex?: number;
	pageSize?: number;
	filters?: [string, any];
}


const FREE_TEXT_SEARCH_DEBOUNCE_TIME = 1200;

/**
 * @title Table retrieving data through HTTP
 */
@Component({
	selector: 'app-material-table',
	styleUrls: ['material-table.component.scss'],
	templateUrl: 'material-table.component.html',
})
export class MaterialTableComponent extends AxonComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {
	/** ================================================================================================================================== */
	/** INPUTS */
	/** ================================================================================================================================== */
	/* The implementing modal class - this is the class used to display a modal when a row is clicked */
	@Input() modal: ModalContainerComponent;
	/* The column structure / details. This array contains 3 properties per index: columnDef, header and the cell to data object mapping */
	@Input() columns: any[];
	/* The list of filtering options */
	@Input() filters: FilterOption[];
	@Input() filterUrlParamName: string;
	/* The implementing TableData class - responsible for fetching data from the server for display / pagination */
	@Input() tableData: TableData;
	@Input() parentComponent: AxonComponent;
	@Input() fullPage: boolean;
	@Input() dateRange: boolean;
	@Input() dateRangeMax: number;
	@Input() startDateLabel = 'From';
	@Input() endDateLabel = 'To';
	@Input() isFilteredTable = true;
	@Input() hideNoResultsMsg = false;
	@Input() fullSortingEnabled = false;
	/* if this is true, and there are approval buttons, the approval button that matches current approval status will be disabled */
	@Input() restrictApprovalButtons = false;
	@Input() loadDataBeforeFilterSet = true;
	@Input() allowSearch = true;

	/** ================================================================================================================================== */
	/** OUTPUTS */
	/** ================================================================================================================================== */
	@Output() onRecordClicked = new EventEmitter();
	@Output() onNoResultsFound = new EventEmitter();
	@Output() onResubmitTpRequest = new EventEmitter();

	/** ================================================================================================================================== */
	/** VIEWCHILD */
	/** ================================================================================================================================== */
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	@ViewChild('freeSearch') freeSearchRef: ElementRef;

	env = environment;

	/** ================================================================================================================================== */
	/** VARIABLES */
	/** ================================================================================================================================== */

	/* The columns to be displayed - this contains a list of the columnDef attribute */
	displayedColumns: any[];
	/* Results obtained from the server via the implementing TableData class */
	dataResults: any[] = [];
	dataSource: MatTableDataSource<any[]>;
	resultsLength = 0;
	isLoadingResults = true;
	selectable: boolean;
	isAllSelected: boolean = false;
	/* This is the bound input field */
	filterInput: string;
	/* This is the list of options for filtering */
	filterOptions: Array<FilterOption> = [];
	/* If true, no dialog is displayed - if false, dialog is displayed */
	quickApply: boolean;
	/* Keeps track of how many rows are selected */
	rowsSelected = 0;
	defaultPageSize = 20;
	/* Free text search */
	isSearch = true;
	freeTextInputEventSubscription: Subscription;
	freeTextFilter: FilterOption = {
		filter: "Search",
		type: "search"
	};
	/* Free text search */
	selectionSubscription: Subscription;
	sortChangeSubscription: Subscription;
	dialogSubscription: Subscription;
	dataList: any[];
	dateFrom;
	dateTo;
	dateSinceOptions = ["Today", "Yesterday", "7 Days", "This Month", "30 Days", "60 Days", "90 Days", "6 Months"];
	deviceTypeOptions = ["pc", "phone", "tablet"];
	/**
	 * Blocked
	 * Authenticated
	 * New
	 */
	agentStatus = ["Blocked", "Authenticated", "New"];
	/**
	 * OPEN
	 * PENDING
	 *
	 * REJECTED
	 * REJECTED_FINAL
	 * REJECTED_CAN_NOT_FIX
	 *
	 * APPROVED
	 * APPROVED_ROLLBACK
	 * APPROVED_AUTO
	 */
	agentApprovalHistoryStatus = ["Open / Pending", "Rejected / Rejected Final", "Approved / Approved Auto"];
	customerIDType = [
		"Driving Licence",
		"Trade licence",
		"Employee ID",
		"Other",
		"House Number",
		"TIN No.",
		"NationalID",
		"Corporate Letter",
		"KebeleID",
		"Passport",
		"Student ID"
	];
	mockData = [];
	showingMockFilter = false;
	selection: SelectionModel<AxonModel>;
	instance: MaterialTableComponent;
	modalDialogRef: MatDialogRef<any>;
	private setting = {
		element: {
			dynamicDownload: null as HTMLElement
		}
	};





	// ==================================================================================================================================




















































	constructor(
		private dialog: MatDialog,
		private notifier: Notifier,
		private router: Router,
		private route: ActivatedRoute,
		private location: Location) {
		super();
		this.instance = this;
	}

	ngOnInit() {
		console.log("MatTable: ", this.sort);
		if (this.filterUrlParamName && this.route.snapshot.queryParamMap.get(this.filterUrlParamName)) {
			const initialValues = <TableLoadDataSettings>JSON.parse(this.route.snapshot.queryParamMap.get(this.filterUrlParamName));
			if (initialValues.filters) {
				// removeFromAvailableFilters
				initialValues.filters.forEach(item => {
					const matchingFilters = this.filters.filter(f => f.filter === item[0]);
					if (matchingFilters.length > 0) {
						const filter = matchingFilters[0];
						this.filters.splice(this.filters.indexOf(filter), 1);
						filter.value = item[1];
						this.filterOptions.push(filter);
					}
				});
			}
		}
		this.resetSelection();
	}
	private resetSelection() {
		if (this.selectionSubscription != null) {
			this.selectionSubscription.unsubscribe();
		}
		this.selection = new SelectionModel<AxonModel>(true, []);
		this.selectionSubscription = this.selection.changed.subscribe(x => {
			for (let item of x.added) {
				this.isChecked(item);
			}
			for (let deselectedItem of x.removed) {
				this.onDeselect(deselectedItem);
			}
			if (this.selection.isEmpty()) {
				this.parentComponent.hideGroupActions = true;
			}
		});
	}


	addDateFilters() {

		// add the dates to the filterOptions
		const fromFilter: FilterOption = {
			order: 11,
			filter: 'usedLastDateFrom',
			type: 'date',
			value: this.toDateString(this.dateFrom)
		};

		this.filterOptions.push(fromFilter);

		const toFilter: FilterOption = {
			order: 12,
			filter: 'usedLastDateEnd',
			type: 'date',
			value: this.toDateString(this.dateTo)
		};

		this.filterOptions.push(toFilter);
	}

	isDate(date) {
		return (date instanceof Date && !isNaN(date.valueOf()));
	}

	getDate(dateStr) {
		return new Date(dateStr);
	}

	isTimeAgo(date) {
		if (date != null) {
			if (date instanceof Date && !isNaN(date.valueOf()) && (date.getTime() < Date.now())) {
				return true;
			} else {
				return false;
			}
		}
	}

	initializeDates() {
		if (!this.isDate(this.dateFrom)) {
			this.dateFrom = new Date();
			this.dateTo = new Date();
			this.dateFrom.setTime(this.dateTo.getTime() - (this.dateRangeMax * 86400000));
		}
	}

	loadDataByDate() {

		this.initializeDates();
		const dateDiffInDays = Math.floor((this.dateTo - this.dateFrom) / (1000 * 60 * 60 * 24));

		if (dateDiffInDays <= 0) {
			this.notifier.error('Start Date can not be after End Date');
			return;
		}

		if (isNaN(dateDiffInDays)) {
			this.notifier.error('Invalid Dates Detected.');
			return;
		}

		if (dateDiffInDays > this.dateRangeMax) {
			this.notifier.error('Invalid Date Range. Difference between days can not be more than ' + this.dateRangeMax);
			return;
		}

		this.addDateFilters();
		this.loadData();

		// remove the dates from the filterOptions
		this.filterOptions.pop();
		this.filterOptions.pop();
	}

	applyDateSinceFilter(filter, dateSinceValue) {
		this.calculateDateRange(dateSinceValue);
		this.filterInput = this.formatFiltersDate(new Date(this.dateFrom));
		this.quickApply = true;
		this.applyFilter(filter);
		// this.loadData();
	}

	applyDeviceTypeFilter(filter, deviceTypeValue) {
		this.filterInput = deviceTypeValue;
		this.quickApply = true;
		this.applyFilter(filter);
		// this.loadData();
	}

	applyAgentApprovalHistoryFilter(filter, agentApprovalHistoryStat) {
		this.filterInput = agentApprovalHistoryStat;
		this.quickApply = true;
		this.applyFilter(filter);
		// this.loadData();
	}

	applyAgentStatusFilter(filter, agentStat) {
		this.filterInput = agentStat;
		this.quickApply = true;
		this.applyFilter(filter);
		// this.loadData();
	}

	applyCustomerIDTypeFilter(filter, customerIDTypeField) {
		this.filterInput = customerIDTypeField;
		this.quickApply = true;
		this.applyFilter(filter);
		// this.loadData();
	}

	applyFreeTextFilter(val: string) {


		/* Only one of these can exist at a time */
		this.removeFreeTextFilter();

		this.freeTextFilter.value = val;
		this.freeTextFilter.order = this.getFreeTextFilterOrder(p => p.order);

		this.filterOptions.push(this.freeTextFilter);

		/* Remove the filter from the available list */
		this.removeFromAvailableFilters(this.freeTextFilter);
		/* Reload the data */
		this.loadData();
		/* Reset the input field */
		this.filterInput = null;
		/* Clear the suffix of the remaining menu item filters */
		this.adjustFilters();

		/* Reset to page 1 so that user does not see empty results if they are on another page */
		this.paginator.pageIndex = 0;
	}

	getFreeTextFilterOrder<T>(prop: (c: FilterOption) => T): number {

		const allFilters: FilterOption[] = this.filters.concat(this.filterOptions);

		allFilters.sort((a, b) => {
			if (prop(a) < prop(b)) {
				return -1;
			} else if (prop(a) > prop(b)) {
				return 1;
			}

			return 0;
		});

		allFilters.reverse();
		return allFilters[0].order + 1;
	}

	removeFreeTextFilter() {
		const index = this.filterOptions.indexOf(this.freeTextFilter);
		if (index >= 0) {
			this.filterOptions.splice(index, 1);
			/* The filter has been removed - it needs to be added back to the available filter list */
			this.filters.splice(this.freeTextFilter.order, 0, this.freeTextFilter);
		}
	}

	addMockFilter(name: string, value: string) {

		this.showingMockFilter = true;
		const mockFilter: FilterOption = {
			filter: name,
			value: value,
			type: "text"
		};
		this.quickApply = true;
		this.filterOptions.push(mockFilter);
		this.removeFromAvailableFilters(mockFilter);
	}

	calculateDateRange(dateSinceValue: string) {
		//Match each of the options above: i.e "Today", "Yesterday", "7 Days", "This Month","30 Days", "60 Days", "90 Days", "6 Months"
		this.dateFrom = new Date();
		if (dateSinceValue === '6 Months') {
			this.dateFrom.setMonth(this.dateFrom.getMonth() - 6);
		}
		if (dateSinceValue === '90 Days') {
			this.dateFrom.setDate(this.dateFrom.getDate() - 90);
		}
		if (dateSinceValue === '60 Days') {
			this.dateFrom.setDate(this.dateFrom.getDate() - 60);
		}
		if (dateSinceValue === '30 Days') {
			this.dateFrom.setDate(this.dateFrom.getDate() - 30);
		}
		if (dateSinceValue === 'This Month') {
			var firstDayOfThisMonth = new Date();
			firstDayOfThisMonth.setDate(1);
			firstDayOfThisMonth.setMonth(firstDayOfThisMonth.getMonth());
			firstDayOfThisMonth.setFullYear(firstDayOfThisMonth.getFullYear());
			this.dateFrom = firstDayOfThisMonth;
		}
		if (dateSinceValue === '7 Days') {
			this.dateFrom.setDate(this.dateFrom.getDate() - 7);
		}
		if (dateSinceValue === 'Yesterday') {
			this.dateFrom.setDate(this.dateFrom.getDate() - 1);
		}
		if (dateSinceValue === 'Today') {
			//this.dateFrom.setDate(this.dateFrom.getDate()); Really Now?
		}

		// Always take the hour to midnight of the day in question
		this.dateFrom.setHours(0, 0, 0, 0);
	}

	/**
	 * Applies the filter selected, and sends the list of existing filters,
	 * along with this newly added filter, to the server for pagination.
	 *
	 * Additionally, the filter applied is removed from the list of available filters.
	 *
	 * NOTE: If the quickApply is true (set in adjustFields - when the user types in the input field)
	 * then the dialog is not displayed - instead the filter is automatically applied.
	 * @param filter
	 */
	applyFilter(filter: FilterOption) {

		console.log('Adding filter: ' + JSON.stringify(filter) + ' - Filter input [' + this.filterInput + ']');

		if (filter.filter === 'Not Used') {
			this.quickApply = true;
		}

		if (this.quickApply) {

			/* Check for text search filter and apply using special method */
			if (filter.type.toLocaleLowerCase() === 'search') {
				this.search();
				this.freeSearchRef.nativeElement.value = this.filterInput;
				this.applyFreeTextFilter(this.filterInput);
				return;
			}

			filter.value = this.filterInput;
			this.filterOptions.push(filter);

			if (this.dateRange) {
				// add the dates to the filterOptions
				this.initializeDates();
				this.addDateFilters();
			}
			/* Remove the filter from the available list */
			this.removeFromAvailableFilters(filter);

			if (this.dateRange) {
				// remove the dates from the filterOptions
				this.filterOptions.pop();
				this.filterOptions.pop();
			}

			/* Reset the input field */
			this.filterInput = null;
			/* Clear the suffix of the remaining menu item filters */
			this.adjustFilters();

			/* Reload the data */
			this.loadData();
			/* Reset to page 1 so that user does not see empty results if they are on another page */
			this.paginator.pageIndex = 0;
		} else {

			/* Not in quickApply mode - so display a dialog for the user to apply the filter */
			const filterType = (filter.dynamicAlias !== undefined) ? filter.dynamicAlias.trim() : filter.filter.trim();

			const data: DialogData = {
				title: 'Filter by ' + filterType,
				type: filter.type
			};

			const dialogConfig = new MatDialogConfig();
			dialogConfig.disableClose = false;

			const matData: MaterialTableData = {
				data: data
			};
			dialogConfig.data = matData;
			dialogConfig.autoFocus = true;
			console.log('Open Dialog.', dialogConfig);

			const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
			this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {

				if (result) {
					console.log('Received input from dialog: ' + result.input);

					/* Set the filter's value, to the value of the input from DialogData */
					filter.value = result.input;

					/* Check for text search filter and apply using special method */
					if (filter.type.toLocaleLowerCase() === 'search') {
						this.search();
						this.freeSearchRef.nativeElement.value = result.input;
					}

					this.filterOptions.push(filter);

					/* Remove the filter from the available list */
					this.removeFromAvailableFilters(filter);
				}

				if (this.dateRange) {
					// add the dates to the filterOptions
					this.initializeDates();
					this.addDateFilters();
				}

				if (this.dateRange) {
					// remove the dates from the filterOptions
					this.filterOptions.pop();
					this.filterOptions.pop();
				}

				/* Reload the data */
				this.loadData();
				/* Reset to page 1 so that user does not see empty results if they are on another page */
				this.paginator.pageIndex = 0;
			});
		}

	}

	/**
	 * Once a filter has been applied, it needs to be removed from the available
	 * list of filters - so user cannot add the same filter twice
	 * @param filter
	 */
	private removeFromAvailableFilters(filter: FilterOption) {
		console.log('Removing filter from list of available filters: ' + JSON.stringify(filter));

		const index = this.filters.indexOf(filter);
		if (index >= 0) {
			this.filters.splice(index, 1);
		}
	}

	/**
	 * The applied filters can be removed - this handles that.
	 * Additionally, a filter that is removed needs to be added back to the list
	 * of available filters.
	 * @param filter
	 */
	removeFilter(filter: FilterOption) {

		/* Check for text search filter and apply using special method */
		if (filter.type.toLocaleLowerCase() === 'search') {
			this.freeSearchRef.nativeElement.value = '';
		}

		if (this.showingMockFilter === true) {
			this.showingMockFilter = false;
			this.filterOptions = [];
			this.quickApply = false;
		}

		console.log('Removing filter: ' + JSON.stringify(filter));

		const index = this.filterOptions.indexOf(filter);
		if (index >= 0) {
			this.filterOptions.splice(index, 1);
			/* The filter has been removed - it needs to be added back to the available filter list */
			filter.suffix = '';
			this.filters.splice(filter.order, 0, filter);
		}

		/* Reset to page 1 so that user does not see empty results if they are on another page */
		this.paginator.pageIndex = 0;

		this.loadData();
	}

	/**
	 * When the user starts typing, the filter's suffix is adjusted to include the
	 * wordinbg 'starts with' and the user's input.
	 * This switches the filtering mode to quickApply which means clicking on
	 * the menu item 'Name starts with "JC"' will auto apply the filter without
	 * displaying the dialog
	 */
	adjustFilters() {
		console.log('Adjusting filters - current value [' + this.filterInput + ']');
		this.filters.forEach((filter) => {
			/* If input is null / empty - clear the suffix */
			if (this.filterInput === null || this.filterInput.trim() === '') {
				filter.suffix = '';
				this.quickApply = false;
			} else if (filter.type !== 'dateSince') {
				/* If input is NOT null / empty - create the suffix */
				filter.suffix = ' starts with "' + this.filterInput + '"';
				this.quickApply = true;
			}
		});
	}

	clearFilters() {
		this.filters = [];
		this.filterOptions = [];
		this.loadData();
	}

	ngOnChanges() {
		this.displayedColumns = this.columns.map(c => c.columnDef);
	}

	ngAfterViewInit() {
		if (this.filterUrlParamName && this.route.snapshot.queryParamMap.get(this.filterUrlParamName)) {
			const initialValues = <TableLoadDataSettings>JSON.parse(this.route.snapshot.queryParamMap.get(this.filterUrlParamName));
			if (initialValues.pageIndex) {
				this.paginator.pageIndex = initialValues.pageIndex;
			}
			if (initialValues.pageSize) {
				this.paginator.pageSize = initialValues.pageSize;
			}
			if (initialValues.sortActive) {
				this.sort.active = initialValues.sortActive;
			}
			if (initialValues.sortDirection) {
				this.sort.direction = initialValues.sortDirection;
			}
		}




		this.sortChangeSubscription = this.sort.sortChange.subscribe(() => {
			// If the user changes the sort order, reset back to the first page.
			this.paginator.pageIndex = 0;
		});

		/* Subscribe to key up on free text search with debounce time */
		if (this.filters) {
			this.filters.push(this.freeTextFilter);
		}
		if (this.freeSearchRef) {
			this.freeTextInputEventSubscription = fromEvent(this.freeSearchRef.nativeElement, 'input').pipe(
				map((i: any) => i.currentTarget.value),
				debounceTime(FREE_TEXT_SEARCH_DEBOUNCE_TIME)
			).subscribe(val => {
				if (val.trim() !== "") {
					this.applyFreeTextFilter(val);
				} else {
					this.removeFreeTextFilter();
					this.loadData();
				}
			});
		}

		this.loadData();

		this.setFullPage(this.fullPage);
	}

	ngOnDestroy() {
		if (this.selectionSubscription) {
			this.selectionSubscription.unsubscribe();
		}

		if (this.sortChangeSubscription) {
			this.sortChangeSubscription.unsubscribe();
		}

		if (this.dialogSubscription) {
			this.dialogSubscription.unsubscribe();
		}

		if (this.freeTextInputEventSubscription) {
			this.freeTextInputEventSubscription.unsubscribe();
		}
	}

	refreshData() {
		this.loadData();
	}

	/**
	 * Fetches the data from the server
	 */
	private loadData() {
		const filterOptions = this.filterOptions;
		const dontLoadData = filterOptions.length === 0 && !this.loadDataBeforeFilterSet;
		const hideNoResultsMsg = dontLoadData || this.hideNoResultsMsg;
		const processEmptyDataList = dontLoadData;

		// merge(this.sort.sortChange, this.paginator.page)
		/* Only fetch data when pagination happens, not sorting - not sure yet what effect this might have */
		/* I have added fullSorting on the entire data set, if it is implemented for your data and you pass fullSortingEnabled = true as an input to the table */
		merge((this.fullSortingEnabled) ? this.sort.sortChange : [], this.paginator.page)
			.pipe(
				startWith({}),
				switchMap(
					() => {
						this.isLoadingResults = true;
						if (this.showingMockFilter) {

							const dataList = this.mockData.slice(
								this.paginator.pageSize * this.paginator.pageIndex,
								this.paginator.pageSize * (this.paginator.pageIndex + 1));

							const totalCount = this.mockData.length;
							const dataResponse: DataResponse = {
								totalCount: totalCount,
								dataList: dataList,
								success: true
							};

							return of(dataResponse);

						} else {
							if (this.filterUrlParamName) {
								AxonUtils.updateUrlWithAppendedQueryParam(this.location, this.route, null, this.filterUrlParamName, this.tableLoadDataSettingSnapshot);
							}
							if (dontLoadData) {
								return of({ totalCount: 0, dataList: [], success: true });
							}
							console.log('tableData: ', this.tableData);
							return this.tableData.getTableData(
								this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize, filterOptions
							);
						}
					}
				),
				map(
					(data: DataResponse) => {

						if (!this.showingMockFilter) {
							// Flip flag to show that loading has finished.
							this.isLoadingResults = false;
							this.resultsLength = data.totalCount;

							if (!data.success) {
								ServerError.printError(data);
								this.notifier.error(ServerError.formatError(data));
								return null;
							} else if (this.resultsLength === 0) {

								if ( this.onNoResultsFound ) {
									this.onNoResultsFound.emit();
								}

								if ( !hideNoResultsMsg) {
									this.notifier.warn('No results found');
									return null;
								}
							}
							console.log('Data: ', data.dataList);

							this.dataList = data.dataList;

							return data.dataList;
						} else {
							this.resultsLength = data.totalCount;
							this.isLoadingResults = false;
							return data.dataList;
						}
					}
				),
				catchError(
					(err) => {
						this.isLoadingResults = false;
						console.log('Unknown Error: ' + err.message);
						console.log('Error details', err);
						if ( err.message !== 'Unauthorized' ) {
							this.notifier.error('Error fetching table data: ' + err.message);
						}
						return observableOf([]);
					}
				)
			).subscribe(data => {
				if (data === null) {
					data = [];
				}
				if (processEmptyDataList || this.resultsLength >= 0) {
					console.log(`Changed DataSource data: FilterLength:[${filterOptions.length}]`, data);
					this.resetSelection();
					this.dataResults = data;
					this.dataSource = new MatTableDataSource(this.dataResults);

					if (!this.showingMockFilter) {
						/*
						In order to implement sorting, we needed to switch back to a MatTableDataSource
						and set our own MatSort, and sortingDataAccessor
						*/
						this.dataSource = new MatTableDataSource(this.dataResults);
						this.dataSource.sort = this.sort;
					}

					this.dataSource.sortingDataAccessor = (item, property) => {
						// property = this.sortBy;
						// console.log('item: '+JSON.stringify(item)+' '+' property: '+ property);

						if (item[property] === null) {
							return null;
						} else if (typeof item[property] === 'string') {
							/* Sort by lower case - to prevent sorting by case */
							return item[property].toLowerCase();
						} else {
							return item[property];
						}
					};
				}
			});
	}
	get tableLoadDataSettingSnapshot(): TableLoadDataSettings {
		const out: TableLoadDataSettings = {
			sortActive: this.sort.active,
			sortDirection: this.sort.direction,
			pageIndex: this.paginator.pageIndex,
			pageSize: this.paginator.pageSize,
			filters: <[string, any]>this.filterOptions.map(item => [item.filter, item.value])
		};
		if (out.sortActive !== undefined && out.sortActive !== null && out.sortActive.length > 0) {
			return out;
		}
		if (out.sortDirection !== undefined && out.sortDirection !== null && out.sortDirection.length > 0) {
			return out;
		}
		if (out.pageIndex !== undefined && out.pageIndex !== null && out.pageIndex > 0) {
			return out;
		}
		if (out.pageSize !== undefined && out.pageSize !== null && out.pageSize > 0 && out.pageSize !== this.paginator.pageSizeOptions[0]) {
			return out;
		}
		if (out.filters !== undefined && out.filters !== null && out.filters.length > 0) {
			return out;
		}
		return null;
	}
	/**
	 * Used for changing out the data with some custom rows eg. Add agents in bulk result list
	 * @param data
	 */
	refreshWithNewData(data) {
		this.mockData = data;
		this.showingMockFilter = true;
		this.dataResults = data;

		this.resultsLength = data.length;

		this.loadData();
	}

	isChecked(row: AxonModel): boolean {
		const found = this.selection.selected.find(el => el.elementId === row.elementId);
		this.parentComponent.setCheckboxState(this.selection.selected);
		console.log(`Selected: ${(found !== undefined)}`, row);
		return (found !== undefined);
	}

	onDeselect(row: AxonModel) {
		this.isAllSelected = false;
		this.parentComponent.setCheckboxState(this.selection.selected);
		console.log(`Deselected`, row);
	}

	/**
	 * Opens the modal supplied in the Input.
	 * The actual data object linked to the row that was clicked is passed in
	 * for assigning to the modal's config object. This allows the modal
	 * access to the data object in the row being clicked.
	 * @param obj
	 */
	clickedRecord(obj: any) {
		this.onRecordClicked.emit({ 'record': obj, 'state': this.tableLoadDataSettingSnapshot });
		if (this.modal) {
			this.openModal(obj, this.modal.getType());
		}
	}

	openModal(obj: any, type: any, tab?: number) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;

		const matData: MaterialTableData = {
			data: obj,
			parent: this.parentComponent,
			tableParent: this.instance
		};

		/* For changing tab after opening the customer modal */
		if (tab !== null) {
			matData.tab = tab;
		}

		dialogConfig.data = matData;
		dialogConfig.autoFocus = true;
		dialogConfig.panelClass = 'largeModal';
		this.modalDialogRef = this.dialog.open(type, dialogConfig);
	}

	private dynamicDownloadTxt(fileName: string, text: string) {
		if (!this.setting.element.dynamicDownload) {
			this.setting.element.dynamicDownload = document.createElement('a');
		}
		const element = this.setting.element.dynamicDownload;
		const fileType = fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
		element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(text)}`);
		element.setAttribute('download', fileName);

		element.dispatchEvent(new MouseEvent("click"));
	}

	private downloadPdf(pinref: number) {
		console.log(`DownloadPdf: ${pinref}`);
		(<CustomerViewRegistrationHistoryListComponent>(<any>this.parentComponent)).downloadPdf(pinref);
	}
	// private downloadPdf(url: string, title: string){
	// 	const date = new Date();
	// 	const timestamp = date.getTime();

	// 	/* Open the PDF in a new window */
	// 	// window.open(this.pdfUrl, '_blank', undefined, true);
	// 	const a = document.createElement('a');
	// 	a.href = url;
	// 	a.download = title + "_" + timestamp + ".pdf";
	// 	a.target = "_blank";
	// 	a.textContent = "Download PDF";

	// 	a.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));

	// 	setTimeout(() => {
	// 		window.URL.revokeObjectURL(url);
	// 		a.remove();
	// 	}, 150);
	// }

	/**
	 * Returns a date as a string in a format like so, `dd-MM-yyyy`.
	 *
	 * @param d
	 */
	toDateString(d: Date) {
		const year = d.getFullYear() + "";
		const mm = d.getMonth() + 1;
		const month = mm < 10 ? ("0" + mm) : (mm + "");
		const day = d.getDate() < 10 ? ("0" + d.getDate()) : (d.getDate() + "");

		return day + "-" + month + "-" + year;
	}

	/**
	 * Formats a given date to something like, `dd/MM/yyyy HH24:mm:ss`.
	 *
	 * @param date
	 */
	private formatFiltersDate(date): string {
		return this.leftpad(date.getDate(), 2)
			+ '/' + this.leftpad(date.getMonth() + 1, 2)
			+ '/' + date.getFullYear()
			+ ' ' + this.leftpad(date.getHours(), 2)
			+ ':' + this.leftpad(date.getMinutes(), 2)
			+ ':' + this.leftpad(date.getSeconds(), 2);
	}

	/**
	 * Helper method for the formatting of date to `dd/MM/yyyy HH24:mm:ss` that provides
	 * 0-padding to single digit values in the date fields
	 */
	leftpad(val, resultLength = 2, leftpadChar = '0'): string {
		return (String(leftpadChar).repeat(resultLength)
			+ String(val)).slice(String(val).length);
	}
	/**
	 * Returns the item whose checkbox is selected
	 */
	getCheckedItem(row: AxonModel): any {
		return this.selection.selected.find(el => el.elementId === row.elementId);
	}

	/**
	 * Used for columns that are marked as isTimeAhead - to provide relative time for future based times.
	 * @param date
	 */
	getFutureDiffInMillis(date: Date): any {

		const now = new Date();

		const millisTimeAgo: MillisToTimeAgoPipe = new MillisToTimeAgoPipe();
		return millisTimeAgo.transform(date.getTime() - now.getTime());

	}

	expireRecord(row: any, column: any) {
		row.isExpired = true;
		column.isExpired = true;
	}

	openAgentModal(agentId: number) {
		(<AgentListComponent>this.parentComponent).fetchAndDisplayAgent(agentId);
	}

	openCustomerModal(axonId: number) {
		(<CustomersComponent>this.parentComponent).fetchAndDisplayCustomer(axonId);
	}

	openCustomerModalForArchive(axonId: number) {
		(<ArchiveDataComponent>this.parentComponent).fetchAndDisplayCustomer(axonId);
	}

	openCustomerApprovalReviewModal(axonId: number) {
		(<ApprovalReviewListComponent>this.parentComponent).fetchAndDisplayCustomer(axonId);
	}

	handleButtonClick(row: any, label: string) {
		console.log('Button click at row [' + JSON.stringify(row) + '] and label [' + label + ']');
		this.parentComponent.handleButtonClick(row, label);
	}

	fireResubmitButton(row: any, label: string) {
		console.log('Button click at row [' + JSON.stringify(row) + '] and label [' + label + ']');
		if ( this.onResubmitTpRequest ) {
			this.onResubmitTpRequest.emit(row);
		}
	}

	/* Free text search - search box focussing */
	search() {
		this.isSearch = !this.isSearch;

		if (this.isSearch !== true) {
			setTimeout(() => {
				this.freeSearchRef.nativeElement.focus();
			}, 500);
		}
	}

	/* Free text search - search box unfocussing */
	undoSearch() {
		setTimeout(() => {
			const inputVal = this.freeSearchRef.nativeElement.value;
			console.log(inputVal);
			if (inputVal.trim() === "") {
				this.search();
			}
		}, 500);
	}

	blockApprovalButton(row: any, label: string) {
		if (this.restrictApprovalButtons) {
			try {
				const customer: Customer = row;
				switch (label) {
					case 'Approve':
						return customer.approvalStatus === 'APPROVED' ||
							customer.approvalStatus === 'APPROVED_AUTO' ||
							customer.approvalStatus === 'APPROVED_ROLLBACK';
						break;
					case 'Reject':
					case 'Reject Final':
						return customer.approvalStatus === 'REJECTED' || customer.approvalStatus === 'REJECTED_FINAL';
						break;
					case 'Open':
						return customer.approvalStatus === 'OPEN';
						break;

				}
			} catch (exception) {
				console.error(exception);
			}
		}
	}

	closeModal() {
		this.modalDialogRef.close();
	}

	changeTableData(tableData) {
		this.tableData = tableData;
	}

	public toggleAll(event: MatCheckboxChange) {
		console.log("Toggle all", event);
		if (event.checked) {
			this.dataResults.forEach(item => this.selection.select(item));
			this.isAllSelected = true;
		} else {
			this.dataResults.forEach(item => this.selection.deselect(item));
			this.isAllSelected = false;
		}
	}

	public fuckyou(obj: any) {
		console.log('JSON shit of data:', obj)
	}
}
