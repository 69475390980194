import { Component, Input, AfterViewInit } from '@angular/core';
import { Customer, Msisdn, DynamicLink, DynamicCard, DynamicField, Card, RegInfoApprovalData } from '../../../dto/dtos';
import { ModalContainerComponent } from '../../../component/modal-container/modal-container.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AxonComponent } from '../../../axon.component';
import { AxonUtils } from '../../../utils/axon-utils';
import { Section, ProfileType, ContractType } from '../../../utils/constants';
import { CustomerService } from '../../../services/customers/customer.service';
import { ServerError } from '../../../utils/server-errors';
import { Notifier } from '../../../utils/notifier';
import { Exporter } from '../../../shared/file-exporter/exporter.component';
import { environment, SectionUtils } from '../../../../environments/environment';

declare let L;

@Component({
    selector: 'app-customer-details',
    templateUrl: './customer-details.component.html',
    styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent extends AxonComponent implements AfterViewInit {

    // roles = AxonUtils.AGENT_ROLES;
    loading = true;

    @Input() modal: ModalContainerComponent;
    @Input() customer: Customer;
    @Input() parentComponent: AxonComponent;
    /* Removes edit buttons if true. Used by historic customer modal */
    @Input() historic: false;

    private dynamicCards: Array<DynamicCard>;
    private firstDynamicCard: DynamicCard;
    private secondDynamicCard: DynamicCard;

	private regInfoApprovalData: RegInfoApprovalData;

    private dynamicLink: DynamicLink;
    private exporter: Exporter = new Exporter();

    env = environment;

    simColumns = [
        // tslint:disable: max-line-length
        { columnDef: 'msisdn'            , header: 'MSISDN'         , cell: (element: Msisdn) => `${element.msisdn}`, isMsisdn: true },
        { columnDef: 'iccid'             , header: 'ICCID'          , cell: (element: Msisdn) => `${element.iccid}` },
        { columnDef: 'contractType'      , header: 'Contract'       , cell: (element: Msisdn) => `${element.contractType}` },
        { columnDef: 'serviceType'       , header: 'Service'        , cell: (element: Msisdn) => `${element.serviceType}` },
        { columnDef: 'status'            , header: 'Status'         , cell: (element: Msisdn) => `${element.status}`, isSIMStatus: true },
        { columnDef: 'approvalStatus'    , header: 'Approval Status', cell: (element: Msisdn) => `${element.approvalStatus}`, isApprovalStatus: true },
        { columnDef: 'activation'        , header: 'Activation'     , cell: (element: Msisdn) => `${element.activation}` },
        { columnDef: 'activationDatetime', header: 'Act. Date'      , cell: (element: Msisdn) => `${element.activationDatetime}`, isDateTime: true },
        { columnDef: 'activationError'   , header: 'Act. Error'     , cell: (element: Msisdn) => `${element.activationError}`, isPopupView: true, fullHeader: 'Activation Error' },
        { columnDef: 'agent'             , header: 'Agent'          , cell: (element: Msisdn) => `${element.captureAgent ? AxonUtils.getAgentFullname(element.captureAgent) : ''}` }
    ];

    cardColumns = [
        // tslint:disable: max-line-length
        { columnDef: 'id'                 , header: 'ID'         , cell: (element: Card) => `${element.id}`                                    },
        { columnDef: 'idType'             , header: 'ID Type'    , cell: (element: Card) => `${element.idType}`                                },
        { columnDef: 'cardPrintDatetime'  , header: 'Print'      , cell: (element: Card) => `${element.cardPrintDatetime}`  , isDateTime: true },
        { columnDef: 'cardReprintDatetime', header: 'Reprint'    , cell: (element: Card) => `${element.cardReprintDatetime}`, isDateTime: true },
        { columnDef: 'cardStatus'         , header: 'Status'     , cell: (element: Card) => `${element.cardStatus}`                            },
        { columnDef: 'cardStatusDatetime' , header: 'Status Date', cell: (element: Card) => `${element.cardStatusDatetime}` , isDateTime: true },
    ];

    constructor(private modalService: NgbModal, private customerService: CustomerService, private notifier: Notifier) {
        super();
    }

    ngAfterViewInit() {

        this.loading = true;

        let section: Section;

        /* Get dynamic data for this section */
        switch ( this.customer.profileType.toLowerCase() ) {
            case ProfileType.INDIVIDUAL:
                switch ( this.customer.type.toLowerCase() ) {
                    case ContractType.PREPAID:
                        console.log('INDIVIDUAL PREPAID SECTION');
                        section = Section.MODIFY_PREPAID_INDIV;
                    break;
                    case ContractType.POSTPAID:
                        console.log('INDIVIDUAL POSTPAID SECTION');
                        section = Section.MODIFY_POSTPAID_INDIV;
                    break;
                    case ContractType.HYBRID:
                        console.log('INDIVIDUAL HYBRID SECTION');
                        section = Section.MODIFY_HYBRID_INDIV;
                    break;
                }
            break;
            case ProfileType.CORPORATE:
                switch ( this.customer.type.toLowerCase() ) {
                    case ContractType.PREPAID:
                        console.log('CORPORATE PREPAID SECTION');
                        section = Section.MODIFY_PREPAID_CORP;
                    break;
                    case ContractType.POSTPAID:
                        console.log('CORPORATE POSTPAID SECTION');
                        section = Section.MODIFY_POSTPAID_CORP;
                    break;
                    case ContractType.HYBRID:
                        console.log('CORPORATE HYBRID SECTION');
                        section = Section.MODIFY_HYBRID_CORP;
                    break;
                }
            break;
        }

		this.regInfoApprovalData = {
			axonId: this.customer.axonId,
			approvalStatus: this.customer.approvalStatus,
			approvedNote: this.customer.approvedNote,
			approvedDatetime: this.customer.approvedDatetime,
			originalDatetime: this.customer.originalDatetime,
			cards: this.customer.cards,
			rejectedFields: this.customer.rejectedFields,
			captureDeviceId: this.customer.captureDeviceId,
			captureApp: this.customer.captureApp,
			captureVersion: this.customer.captureVersion,
			captureIp: this.customer.captureIp,
			recordAction: this.customer.recordAction,
			originalAgent: this.customer.originalAgent,
			captureAgent: this.customer.captureAgent,
			approvalAgent: this.customer.approvalAgent,
			viewInApprovalPath: '/approvals/view/' + this.customer.axonId,
			title: 'Customer'
		}

        this.buildMaps();

        this.customerService.getCustomerDynamicDataByAxonId(SectionUtils.getSectionId(section), this.customer.axonId, 0).subscribe(
            data => {
                try {
                    if ( !data.success ) {
                        ServerError.printError(data);
                        this.notifier.error(ServerError.formatError(data));
                        return null;
                    }

                    this.dynamicLink = data.data;
                    console.log( "Dynamic Link Data: ", this.dynamicLink );

                    if ( this.customer.rejectedFields ) {
                        console.log( "Assigning rejected reasons to fields" );
                        for ( const rejectedField of this.customer.rejectedFields ) {
                            const field: DynamicField = AxonUtils.getFieldById( rejectedField.fieldId, this.dynamicLink );
                            field.rejectionReason = rejectedField.fieldReason;
                            console.log( "Field ID [" + field.id + "] - field [" + field.field + "] rejection reason [" + field.rejectionReason + "]" );
                        }
                    }


                    this.buildCards();
                } finally {
                    this.loading = false;
                }
            }
        );


    }

    buildCards() {
        this.dynamicCards = new Array();

        for ( const card of this.dynamicLink.cards ) {
            this.dynamicCards.push(card);
        }

        this.firstDynamicCard  = this.dynamicCards[0];
        this.secondDynamicCard = this.dynamicCards[1];

        this.dynamicCards.splice(0, 2);
    }

    /**
     * Handles map creation for the registration location and the customer address
     */
    private buildMaps() {
        this.customer.addressFound = false;

        if ( this.customer.originalLatitude && this.customer.originalLongitude ) {

            console.log('Fetching registration location for latitude [' + this.customer.originalLatitude + '] ' +
                'and longitude [' + this.customer.originalLongitude + ']');

            AxonUtils.addMap('regMap', this.customer.originalLatitude, this.customer.originalLongitude, true, null, 15);
            const address = AxonUtils.constructAddress(
                this.customer.address1, this.customer.address2, this.customer.address3, this.customer.addressTown,
                this.customer.addressCity, this.customer.addressProvince
            );

            console.log('Reverse geocoding latitude and longitude');

            AxonUtils.reverseGeocode(this.customer.originalLatitude, this.customer.originalLongitude, this.parseReverseGeocodeResults, this);

            this.customer.addressFull = address;

            console.log('Geocoding customer address [' + this.customer.addressFull + ']');

            AxonUtils.geocode(this.customer.addressFull, this.parseGeocodeResults, this);
        }
    }
    /**
     * Callback method to parse the results of the reverse geocoding
     * @param results
     */
    private parseReverseGeocodeResults( results ) {
        if ( results[0] ) {
            this.customer.originalLatLngAddress = results[0].name;
            console.log('Reverse geocoded latitude and longitude to address [' + this.customer.originalLatLngAddress + ']');
        }
    }

    /**
     * Callback method to parse the geocoding results - extracts lat / lng
     * @param results
     */
    private parseGeocodeResults( results ) {
        if ( results[0] ) {
            const lat = results[0].center.lat;
            const lng = results[0].center.lng;
            this.customer.addressFound = true;
            AxonUtils.addMap('addrMap', lat, lng, true, null, 15);
            console.log("Found latitude [" + lat + "] and longitude [" + lng + "] of customer's physical address");
        }
    }

    exportToPDF() {
        this.exporter.dataType = 'Customer Details';
        const columns = [
            { columnDef: 'key' ,    header: 'Description'   },
            { columnDef: 'value',   header: 'Value/Status'  }
        ];
        // this.exporter.showData = true;
        this.exporter.columns = columns;
        this.exporter.arrayOfArrays = this.populateCustomerDetailsTable();
        this.exporter.createTwoColumnPDF();
    }

    /**
     * This is a verbose way to do it, but the one way to return the properties
     * of an interface an array of strings is is very hacky
     */
    populateCustomerDetailsTable(): any {
        const originalDateTime = this.customer.originalTime? this.customer.originalTime : '';
        const captureAgent = this.customer.captureAgent? this.customer.captureAgent.name + " " + this.customer.captureAgent.surname + "(" + this.customer.captureAgent.username + ")" : ''
        const approvalAgent = this.customer.approvalAgent? this.customer.approvalAgent.name + " " + this.customer.approvalAgent.surname + "(" + this.customer.approvalAgent.username + ")" : '';
        var msisdns = "";
        for (let msisdn of this.customer.msisdns) {
            msisdns = msisdns + msisdn.msisdn + "    ";
        }
        return [
            ["Axon ID", this.customer.axonId],
            ["Pinref", this.customer.pinref],
            ["Profile Type", this.customer.profileType],
            ["Type", this.customer.type],
            ["Name", this.customer.name],
            ["Surname", this.customer.surname],
            ["Other Name", this.customer.otherName],
            ["ID", this.customer.id],
            ["ID Type", this.customer.idType],
            ["ID Expiry", this.customer.idExpiry],
            ["Birth Date", this.customer.birthDate],
            ["Birth Place", this.customer.birthPlace],
            ["Title", this.customer.title],
            ["Gender", this.customer.gender],
            ["Address Line 1", this.customer.address1],
            ["Address Line 2", this.customer.address2],
            ["Address Line 3", this.customer.address3],
            ["Town", this.customer.addressTown],
            ["City", this.customer.addressCity],
            ["Province", this.customer.addressProvince],
            ["Full Address", this.customer.addressFull],
            ["Address Found", this.customer.addressFound],
            ["Approval Status", this.customer.approvalStatus],
            ["Approval Note", this.customer.approvedNote],
            ["Approval Datetime", this.customer.approvedDatetime],
            ["Original Datetime", this.customer.originalDatetime],
            ["Original Agent", this.customer.originalAgent.name + " " + this.customer.originalAgent.surname + "(" + this.customer.originalAgent.username + ")"],
            ["Original Longitude", this.customer.originalLongitude],
            ["Original Langitude", this.customer.originalLatitude],
            ["Original Lat-Lng Address", this.customer.originalLatLngAddress],
            ["Original Time", originalDateTime],
            ["Capture Agent", captureAgent],
            ["Capture Datetime", this.customer.submissionDatetime],
            ["Approval Agent", approvalAgent],
            ["Submission Time", this.customer.submissionTime],
            ["Record Action", this.customer.recordAction],
            ["Record Type", this.customer.recordType],
            ["Channel", this.customer.channel],
            ["Upload Status", this.customer.uploadStatus],
            ["Comment", this.customer.comment],
            ["MSISDN", this.customer.msisdn],

            ["MSISDNs", msisdns],
            ["Time to Approve", this.customer.timeToApproveMillis],

            ["Rejected Fields", this.customer.rejectedFields],
            ["Expiry Seconds", this.customer.expirySeconds],
            ["Date Added", this.customer.dateAdded],
            ["Link Expiry", this.customer.linkExpiry],
            ["Approval Expired", this.customer.approvalExpired]
        ];
    }

    getNameForAgent(sim: Msisdn) {
        if (sim.captureAgent !== undefined) {
            return sim.captureAgent.name + " " + sim.captureAgent.surname;
        }
    }
}
