import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
	selector: 'app-highcharts-graph',
	templateUrl: './highcharts-graph.component.html',
	styleUrls: ['./highcharts-graph.component.css']
  })
export class HighchartsGraphComponent implements OnInit {
	@Input() options: Highcharts.Options;
	@Input() displayHeight: string = '75vh';

	constructor() { }

	ngOnInit() {
		console.log("Highcharts:", this.options);
		Highcharts.chart('highcharts-container', this.options);
	}
}
