import { Component, Injectable, Input, ViewChild, AfterViewInit } from "@angular/core";
import { Customer } from "../../../dto/dtos";
import { AxonComponent } from "../../../axon.component";
import { MaterialTableComponent } from "../../../component/material-table/material-table.component";
import { ArchiveDataTableData } from "../../../component/material-table/table-data/customers/archive-data-table-data";
import { CustomerService } from "../../../services/customers/customer.service";
import { FormControl } from "@angular/forms";
import { CustomerModalComponent, TAB_CONFIG } from "../customer-modal.component";

@Injectable({
	providedIn: 'root'
})

@Component({
	selector: 'app-archive-data',
	templateUrl: './archive-data.component.html',
	styleUrls: ['./archive-data.component.scss'],
})
export class ArchiveDataComponent extends AxonComponent implements AfterViewInit {
    @Input() customer: Customer;

    public instance: ArchiveDataComponent;
    tableData: ArchiveDataTableData;

    @ViewChild(MaterialTableComponent) table: MaterialTableComponent;

    formControl: FormControl;
    currentMsisdn;

    columns = [
        // tslint:disable: max-line-length
        { columnDef: 'owner'       , header: 'Owner'         , cell: (element: any) => this.getOwner(element), isCustomerLink: true, customerLinkId: (element: any) => `${element.customerAxonId}` },
        { columnDef: 'status'      , header: 'Status'        , cell: (element: any) => `${element.status}` },
        { columnDef: 'datetime'    , header: 'Churn Date'    , cell: (element: any) => `${element.churnDate}`, isDateTime: true },
        { columnDef: 'action'      , header: 'Action'        , cell: (element: any) => `${element.recordAction}` },
        { columnDef: 'id'          , header: 'Customer ID'   , cell: (element: any) => `${element.customerId}` },
        { columnDef: 'idtype'      , header: 'ID Type'       , cell: (element: any) => `${element.customerIdType}` },
    ];

    constructor(
        private customerService: CustomerService) {

            super();
            this.instance = this;
            this.formControl = new FormControl({}, [], []);
    }

    getOwner(entry) {
        return entry.customerFirstname + ' ' + entry.customerOthername + ' ' + entry.customerLastname;
    }

    ngAfterViewInit() {
        
    }

    loadData() {
        let phone: string = this.customer.msisdn;
        if (this.customer !== undefined && this.customer.msisdns !== undefined && this.customer.msisdns.length > 0 && this.customer.msisdns[0].msisdn !== undefined) {
            phone = this.customer.msisdns[0].msisdn;
        }
        this.currentMsisdn = phone;
        this.tableData = new ArchiveDataTableData(phone, this.customerService);
        
        if ( this.table ) {
            this.table.refreshData();
        }
    }

    handleFieldChange() {
        this.currentMsisdn = this.formControl.value;
        console.log(this.formControl.value);
        this.tableData = new ArchiveDataTableData(this.formControl.value, this.customerService);

        setTimeout(() => {
            this.table.changeTableData(this.tableData);
            this.loadData();
        }, 2400);
    }

    fetchAndDisplayCustomer(axonId) {

        this.customerService.getCustomer(axonId).subscribe(resp => {
            console.log('Customer fetch successful ? [' + resp.success + ']');
            if ( resp.success ) {
                this.table.openModal(resp.data, CustomerModalComponent, TAB_CONFIG.REG_HISTORY);
            }
        });
    }
}
