import { Pipe, PipeTransform } from '@angular/core';
import { Http, RequestOptions, Headers, ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { AuthService } from '../services/auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AxonUtils } from '../utils/axon-utils';

@Pipe({ name: 'securedLoadImg' })
export class SecuredImagePipe implements PipeTransform {
	constructor(private http: HttpClient, private sanitizer: DomSanitizer, private authService: AuthService) { }

	async transform(src: string): Promise<string | SafeUrl> {
		if (src.startsWith('/axonresource/aiu')) {
			return Promise.resolve(src);
		}
		if (src.startsWith('data:') && src.indexOf('base64:') > -1) {
			return Promise.resolve(AxonUtils.domSanitizer.bypassSecurityTrustUrl(src));
		}

		const startTime = new Date();
		const token = this.authService.getAuthToken().token;
		const headers = new HttpHeaders({ 'axon-token': token, 'Content-Type': 'image/*' });
		try {
			const imageBlob = await this.http.get(src, { headers, responseType: 'blob' }).toPromise();
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onloadend = () => {
					resolve(reader.result as string);
				};
				reader.readAsDataURL(imageBlob);
			});
		} catch {
			return '/assets/images/background/error-bg.jpg';
		}
	}



}