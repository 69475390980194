import { Component, OnInit, Input, ViewChild, AfterViewInit, ChangeDetectorRef, ElementRef, HostListener } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDropList, CdkDrag } from '@angular/cdk/drag-drop';
import { AxonComponent } from '../../../axon.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from "moment";
import { DynamicField } from '../../../dto/dtos';
import { ErrorStateMatcher } from '@angular/material';
import { AxonUtils } from '../../../utils/axon-utils';
import { Notifier } from '../../../utils/notifier';

export enum DIRECTION {
  LEFT, RIGHT
}

@Component({
  selector: 'app-dragndrop-list',
  templateUrl: './dragndrop-list.component.html',
  styleUrls: ['./dragndrop-list.component.scss']
})
export class DragndropListComponent implements AfterViewInit {

  @Input("leftList") leftList: string[];
  @Input("rightList") rightList: string[];
  @Input("parent") parent: AxonComponent;
  @Input("leftListHeader") leftListHeader: string;
  @Input("rightListHeader") rightListHeader: string;
  @Input("mandatory") mandatory: boolean;
  @Input('showDate') showDate: boolean;
  @Input('leftMax') leftMax: number;
  @Input('rightMax') rightMax: number;
  @Input('leftListItemsDeletable') leftListItemsDeletable: boolean;
  @Input('editable') editable: boolean = true;
  @Input('notEditableMessage') notEditableMessage: string;

  @ViewChild("left") leftCdkDropList: CdkDropList;
  @ViewChild("right") rightCdkDropList: CdkDropList;


  @Input() allowSearch1 = true;
  isSearch1 = true;

  @Input() allowSearch2 = true;
  isSearch2 = true;
  search2Value = "";

  /* For maintaining original state of list for filtering */
  leftListOriginal;
  rightListOriginal = new Array<string>();
  rightListUpdated = new Array<string>();

  leftListIsPrimary;
  rightListIsPrimary;

  rightListDates = [];

  itemForm: FormGroup;
  errors: any;

  tries = 0;

  leftSelected = [];
  rightSelected = [];
  isDragging = false;
  hidden = [];

  constructor(private cdRef: ChangeDetectorRef, private formBuilder: FormBuilder, private notifier: Notifier) {
      this.reset = this.reset.bind(this);
  }

  ngAfterViewInit(): void {
      setTimeout(() => {
          console.log('setting the original values for lists');
          this.leftListOriginal = Object.assign([], this.leftList);
          this.rightListOriginal = Object.assign([], this.rightList);
		  this.rightListUpdated = Object.assign([], this.rightList);
          this.parent.validateDragndrop();
      });
  }

  search1() {
      this.isSearch1 = !this.isSearch1;
  }

  search2() {
      this.isSearch2 = !this.isSearch2;
  }

  applyFilter1(filterValue: string) {
      this.leftList = this.leftListOriginal.filter( it => {
          return it.toLowerCase().includes(filterValue.toLowerCase());
      });
  }

  applyFilter2(filterValue: string) {
      this.rightList = this.rightListOriginal.filter( it => {
          return it.toLowerCase().includes(filterValue.toLowerCase());
      });
  }

  isValid(field: DynamicField) {

      if (this.leftListFirstIsPrimary && this.leftListFirstIsPrimary === true) {
          if (this.leftCdkDropList.data.length === 0) {
              return { invalidDragndrop: this.leftListHeader + " must have a Primary entry set." };
          }
      }

      if (this.rightListFirstIsPrimary && this.rightListFirstIsPrimary === true) {
          if (this.rightCdkDropList.data.length === 0) {
            return { invalidDragndrop: this.rightListHeader + " must have a Primary entry set." };
          }
      }

      if (field.min !== undefined && field.min > 0) {
          if (this.rightCdkDropList.data.length <= 0 || this.rightCdkDropList.data.length < field.min) {
              return { invalidDragndrop: this.rightListHeader + " must have at least " + field.min + " entries set." };
          }
      }

      if (field.max !== undefined && field.max > 0) {
          if (this.rightCdkDropList.data.length <= 0 || this.rightCdkDropList.data.length > field.max) {
              return { invalidDragndrop: this.rightListHeader + " must have no more than " + field.max + " entries set." };
          }
      }

      /* Validate future dates */
      if (this.rightListDates !== null && this.rightListDates !== undefined) {

          let error = null;

          this.rightListDates.forEach( entrySet => {

              if (error === null) {
                  const [ perm, date ] = entrySet;

                  if (date !== null && date !== undefined) {
                      let dateMoment = moment(date);
                      if (dateMoment.isValid() === false) {
                          dateMoment = moment(new Date(date));
                      }
                      if (dateMoment.isBefore(moment())) {
                          error = { invalidDragndrop: perm + " - expiry date must be in the future." };
                      }
                  }
              }
          });

          if (error !== null) {
              return error;
          }
      }

      return true;
  }

  /**
   * By hiding the other items that are selected, it creates the illusion of multiple items being dragged
   * The dragged item gets a card-stack css style - .multidrag
   * @param $event
   * @param dragSide left or right
   * @param start start = true, stop = false
   */
  multiDrag($event, dragSide, start: boolean) {
      const element: ElementRef = $event.source.element;
      let text: string = element.nativeElement.innerText;
      text = text.split('\n')[0].trim();
      console.log(text);

      if (this.isSelected(text, dragSide) === true) {
          // Hide/Show other selected items while dragging this one
          this.toggleHiddenItems(start, dragSide, (start === true) ? text : null);
      }
  }

  /**
   * Show or hide items for the stacked card effect in multi select dragging
   * @param hide hide = true, unhide = false
   * @param side left or right
   * @param item Used when hiding items to not hide the item being dragged
   */
  toggleHiddenItems(hide: boolean, side, item) {
      if (hide === true) {
          if (side === 'left') {
              this.leftSelected.forEach(it => {
                  if (it.trim() !== item) {
                      this.hidden.push(it.trim());
                  }
              });
          } else {
              this.rightSelected.forEach(it => {
                  if (it.trim() !== item) {
                      this.hidden.push(it.trim());
                  }
              });
          }
      } else {
          if (side === 'left') {
              this.leftSelected.forEach(it => {
                  const i = this.hidden.indexOf(it.trim(), 0);
                  if (i > -1) {
                      this.hidden.splice(i, 1);
                  }
              });
          } else {
              this.rightSelected.forEach(it => {
                  const i = this.hidden.indexOf(it.trim(), 0);
                  if (i > -1) {
                      this.hidden.splice(i, 1);
                  }
              });
          }
      }
  }

  drop(event: any, side) {
      

      /* Check if what was just dropped is multiselected items */
      let item = event.item.element.nativeElement.innerText;
      item = item.split('\n')[0].trim();
      const dragSide = (side === 'left') ? 'right' : 'left';
      const multiDrop = (this.isSelected(item, dragSide) === true);

	  console.log('drop -->');
      console.log(side);
      console.log(event);
	  console.log('item:', item);
      console.log('drop <--');

      /* Sometimes we just want to view the dragndrop state but not allow it to be editable */
      if (this.editable === false) {
          this.notifier.warn(this.notEditableMessage);
          return;
      }

      if (event.previousContainer === event.container) {
          moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      } else {

          /**
           * Handle maximum nuumber of items on either side of list
           */
          if (side === 'left') {
              const numberItemsDragged = (multiDrop === false) ? 1 : this.leftSelected.length;
              const totalWillBeInList = numberItemsDragged + this.leftCdkDropList.data.length;
              if (this.leftMax !== undefined && this.leftMax > 0 && totalWillBeInList > this.leftMax) {
                  this.notifier.warn("You can only have up to " + this.leftMax + " items in this list.");
                  return;
              }
          } else {
              const numberItemsDragged = (multiDrop === false) ? 1 : this.rightSelected.length;
              const totalWillBeInList = numberItemsDragged + this.rightCdkDropList.data.length;
              if (this.rightMax !== undefined && this.rightMax > 0 && totalWillBeInList > this.rightMax) {
                  this.notifier.warn("You can only have up to " + this.rightMax + " items in this list.");
                  return;
              }
          }

          if (multiDrop === false) {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
          } else {
              if (dragSide === 'left') {
                  this.leftSelected.forEach( (it, index) => {
                      const i = this.leftCdkDropList.data.indexOf(it.trim(), 0);
                      if (i > -1) {
                          transferArrayItem(this.leftCdkDropList.data, this.rightCdkDropList.data, i, event.currentIndex + index);
                      }
                  });

              } else {
                  this.rightSelected.forEach( (it, index) => {
                      const i = this.rightCdkDropList.data.indexOf(it.trim(), 0);
                      if (i > -1) {
                          transferArrayItem(this.rightCdkDropList.data, this.leftCdkDropList.data, i, event.currentIndex + index);
                      }
                  });
              }
              this.leftSelected = [];
              this.rightSelected = [];
          }
      }
      this.parent.dragNDropUpdated();
      this.parent.validateDragndrop();

	  if ( side === 'left' ) {

		let newRightListUpdated = new Array<string>();
		for ( let rightListItem of this.rightListUpdated ) {
			if ( rightListItem !== item ) {
				newRightListUpdated.push(rightListItem);
			}
		}

		this.rightListUpdated = newRightListUpdated;
	  } else {
	  	this.rightListUpdated = this.rightList;
	  }
  }

  moveToOtherList($event, index, clickSide) {
      console.log('moveToOtherList');
      if (this.editable) {
          if (clickSide === 'left') {
              transferArrayItem(this.leftCdkDropList.data, this.rightCdkDropList.data, index, 0);
          } else {
              transferArrayItem(this.rightCdkDropList.data, this.leftCdkDropList.data, index, 0);
          }
          this.parent.validateDragndrop();
      }
	  this.rightListUpdated = this.rightList;
  }

  addToSelected($event: MouseEvent, index, clickSide) {

      $event.preventDefault();
      $event.stopPropagation();
      $event.stopImmediatePropagation();

      if ($event.ctrlKey === true || $event.shiftKey === true) {

          /* Check if multiselection from user has changed sides */
          if (clickSide === 'left' && this.rightSelected.length > 0) {
              this.rightSelected = [];
          }
          if (clickSide === 'right' && this.leftSelected.length > 0) {
              this.leftSelected = [];
          }

          if (clickSide === 'left') {
              console.log("CTRL KEY ON LEFT");
              const item = this.leftList[index].trim();
              if (this.isSelected(item, clickSide) === true) {
                  const i = this.leftSelected.indexOf(item, 0);
                  if (i > -1) {
                      this.leftSelected.splice(i, 1);
                  }
              } else {
                  this.leftSelected.push(item);
              }
          } else {
              console.log("CTRL KEY ON RIGHT");
              const item = this.rightList[index].trim();
              if (this.isSelected(item, clickSide) === true) {
                  const i = this.rightSelected.indexOf(item, 0);
                  if (i > -1) {
                      this.rightSelected.splice(i, 1);
                  }
              } else {
                  this.rightSelected.push(item);
              }
          }
      } else {
          this.leftSelected = [];
          this.rightSelected = [];
      }
      console.log('finished');
      this.cdRef.detectChanges();
  }

  isSelected(item, side) {
      if (side === 'left') {
          const i = this.leftSelected.indexOf(item.trim(), 0);
          return (i > -1);
      } else {
          const i = this.rightSelected.indexOf(item.trim(), 0);
          return (i > -1);
      }
  }

  isMultidrag(side) {
      if (side === 'left') {
          return this.leftSelected.length > 0;
      } else {
          return this.rightSelected.length > 0;
      }
  }

  isHidden(item) {
      const i = this.hidden.indexOf(item.trim(), 0);
      return (i > -1);
  }

  set leftListFirstIsPrimary(isPrimary: boolean) {
      this.leftListIsPrimary = isPrimary;
      this.cdRef.detectChanges();
  }

  get leftListFirstIsPrimary() {
      return this.leftListIsPrimary;
  }

  set rightListFirstIsPrimary(isPrimary: boolean) {
      this.rightListIsPrimary = isPrimary;
      this.cdRef.detectChanges();
  }

  get rightListFirstIsPrimary() {
      return this.rightListIsPrimary;
  }

  getItemID(item: string) {
      return "item-" + item.replace(/\s+/g, '-').toLowerCase();
  }

  setRightListDateValues(listWithDates) {

      this.tries++;
      if (listWithDates !== undefined) {
          this.rightListDates = listWithDates;
      }

      let continueAdding = true;
      listWithDates.forEach( ([item, date]) => {
          if (date !== null && date !== undefined && continueAdding) {

              const dd = moment(date).format("M/D/YYYY");

              const el = (<any> document.getElementById( this.getItemID(item) ));
              if (el !== null) {
                  el.value = dd;
              } else {
                  continueAdding = false;
              }
          }
      });
      if (!continueAdding && this.tries < 3) {
          setTimeout(() => {
              this.setRightListDateValues(listWithDates);
          });
      }
  }

  	resetLatestRightList() {
		this.search2Value = "";
		this.rightList = this.rightListUpdated;
	}


  	getRightListWithDates(stringify?: boolean) {

		console.log('getting right list data');

		// Reset the filter 
		this.resetLatestRightList();

		// return;

		const rightListWithDates = [];
		this.rightList.forEach(item => {
			const el = (<any> document.getElementById( this.getItemID(item) ));
			const dateValue = (el === null || el === undefined ? null : el.value);

			if (stringify === true || stringify === undefined) {
				rightListWithDates.push( JSON.stringify([item, dateValue]) );
			} else {
				rightListWithDates.push( [item, dateValue] );
			}
		});

		this.rightListDates = rightListWithDates;
		return rightListWithDates;
	}

  setErrors(errors) {
      this.errors = errors;
  }

  dateChanged(type, $event) {

      this.getRightListWithDates(false);
      this.parent.validateDragndrop();
  }

  addAll() {
      console.log('addAll');
      if (this.editable) {
          const leftList = [...this.leftList];
          leftList.reverse().forEach( (item, index) => {
              this.moveToOtherList(null, index, 'left');
          });
      }
  }

  removeAll() {
      console.log('removeAll');
      if (this.editable) {
          const rightList = [...this.rightList];
          rightList.reverse().forEach( (item, index) => {
              this.moveToOtherList(null, index, 'right');
          });
          this.rightList = this.rightListDates = [];
          this.setRightListDateValues(this.rightList);
      }
  }

  setLeftList(leftList: string[]) {
      this.leftList = leftList;
  }

  setRightList(rightList: string[]) {
      this.rightList = rightList;
  }

  setLeftListHeader(header: string) {
      this.leftListHeader = header;
  }

  setRightListHeader(header: string) {
      this.rightListHeader = header;
  }

  deleteLeftListItem(item: any) {
      console.log('deleting ' + item + ' from left list');
      const newLeftList = [];
      this.leftList.forEach( (it, index) => {
          if (item !== it) {
              newLeftList.push(it);
          }
      });
      this.setLeftList(newLeftList);
  }

  reset() {

      let transferLeftToRight = [];
      let transferRightToLeft = [];

      this.leftCdkDropList.data.forEach( (item, index) => {
          let i = this.leftListOriginal.indexOf(item);
          if (i < 0) {
              i = this.rightListOriginal.indexOf(item);
              if (i > -1) {
                  const indexArr = [ index, i ];
                  transferLeftToRight.push(indexArr);
              }
          }
      });

      this.rightCdkDropList.data.forEach( (item, index) => {
          let i = this.rightListOriginal.indexOf(item);
          if (i < 0) {
              i = this.leftListOriginal.indexOf(item);
              if (i > -1) {
                  const indexArr = [ index, i ];
                  transferRightToLeft.push(indexArr);
              }
          }
      });

      transferLeftToRight.forEach( indexArr => {
          transferArrayItem(this.leftCdkDropList.data, this.rightCdkDropList.data, indexArr[0], indexArr[1]);
      });

      transferRightToLeft.forEach( indexArr => {
          transferArrayItem(this.rightCdkDropList.data, this.leftCdkDropList.data, indexArr[0], indexArr[1]);
      });
  }
}
