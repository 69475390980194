import { Injectable } from '@angular/core';
import { environment, SectionUtils } from '../../../environments/environment';
import { DynamicLink, DynamicCard, DynamicField, LinkedLists, DynamicFieldAction, DynamicFieldActionTarget, RoleRule, DynamicValidationDto } from '../../dto/dtos';
import { Section } from '../../utils/constants';
import { Storage, KEY_DYN_FIELD_VERSION, KEY_DYN_FIELD_DATA, KEY_LINKED_LISTS, KEY_DYN_FIELD_ACTIONS, KEY_DYN_FIELD_VALIDATIONS, KEY_ROLE_RULES } from '../../utils/storage';

@Injectable({
	providedIn: 'root'
})
/**
 * Responsible for storing and fetching dynamic fields
 */
export class DynamicFieldManager {

	constructor(private storage: Storage) { }

	/**
	 * Returns the version of dynamic fields.
	 * NOTE: Returns 0 if the dynamic fields are not present.
	 */
	getVersion(): number {

		let version = Number(this.storage.getItem(KEY_DYN_FIELD_VERSION));

		/* Make sure the fields are present */
		if (!this.getDynamicFieldData()) {
			version = 0;
		}

		return version;
	}

	/**
	 * Saves the given dynamic fields, cards, sections and links, and dynamic field actions
	 */
	saveDynamicSettings(version: number, dynamicLinks: Array<DynamicLink>,
		dynamicActions: Array<DynamicFieldAction>, dynamicValidation: Array<DynamicValidationDto>) {

		console.log('Saving dynamic field settings of size [' + dynamicLinks.length + '], ' +
			'actions of size [' + dynamicActions.length + '], ' +
			'validations of size [' + (dynamicValidation ? dynamicValidation.length : 'N/A') + ']');

		this.storage.saveItem(KEY_DYN_FIELD_VERSION, '' + version);
		this.storage.saveSecureItem(KEY_DYN_FIELD_DATA, JSON.stringify(dynamicLinks));
		this.storage.saveSecureItem(KEY_DYN_FIELD_ACTIONS, JSON.stringify(dynamicActions));
		if (dynamicValidation) {
			this.storage.saveSecureItem(KEY_DYN_FIELD_VALIDATIONS, JSON.stringify(dynamicValidation));
		}
	}

	/**
	 * Returns an array of DynamicLink's decrypted dynamic fields,
	 * sections, cards and links.
	 */
	getDynamicFieldData(): Array<DynamicLink> {
		try {
			console.log(JSON.parse(this.storage.getSecureItem(KEY_DYN_FIELD_DATA)));
			return JSON.parse(this.storage.getSecureItem(KEY_DYN_FIELD_DATA));
		} catch (e) {
			return null;
		}
	}

	/**
	 * Returns an array of DynamicFieldAction's decrypted dynamic field actions.
	 */
	private getDynamicFieldActions(): Array<DynamicFieldAction> {
		try {
			return JSON.parse(this.storage.getSecureItem(KEY_DYN_FIELD_ACTIONS));
		} catch (e) {
			return null;
		}
	}

	/**
	 * Returns an array of DynamicValidation's decrypted dynamic field validations.
	 */
	private getDynamicFieldValidation(): Array<DynamicValidationDto> {
		try {
			const storageItem = this.storage.getSecureItem(KEY_DYN_FIELD_VALIDATIONS);
			if (!storageItem) {
				console.warn('Warning: Dynamic Validations not configured');
				return null;
			}

			return JSON.parse(storageItem);
		} catch (e) {
			return null;
		}
	}

	/**
	 * Returns an array of DynamicFieldAction's linked to the given field ID
	 */
	getDynamicFieldActionsByFieldId(fieldId: number): Array<DynamicFieldAction> {
		try {
			const dynamicActions = this.getDynamicFieldActions();

			const fieldDynamicActions = new Array<DynamicFieldAction>();

			for (const action of dynamicActions) {
				if (action.srcFieldId === fieldId) {
					fieldDynamicActions.push(new DynamicFieldAction(action));
				}
			}

			return fieldDynamicActions;
		} catch (e) {
			return null;
		}
	}

	/**
	 * Returns an array of DynamicValidation's linked to the given validation ID
	 */
	getDynamicValidation(validationId: number): DynamicValidationDto {
		const dynValidations = this.getDynamicFieldValidation();
		if (dynValidations) {
			const out = dynValidations.filter(item => item.id === validationId);

			if (out.length > 0) {
				return out[0];
			}
		}

		return null;
	}

	/**
	 * Fetches the links, cards, fields and the section linked to the given Section ID
	 * @param sectionId
	 */
	getFieldsBySectionId(sectionId: number): DynamicLink {

		const dynamicLinks = this.getDynamicFieldData();

		if (dynamicLinks !== null) {
			for (const dynamicLink of dynamicLinks) {
				if (dynamicLink.sectionId === sectionId) {
					return dynamicLink;
				}
			}
		}
	}

	getFieldsBySectionIdCardId(sectionId: number, cardId: number) {

		console.log('Fetching fields by sectionId [' + sectionId + '] and cardId [' + cardId + ']');

		const base = new DynamicLink(this.getFieldsBySectionId(sectionId));

		for (const card of base.cards) {

			if (cardId == card.id) {
				const dynLink = new DynamicLink(base)
				dynLink.cards = new Array<DynamicCard>(card);
				return dynLink;
			}
		}
		return null;
	}

	/**
	 * Fetches the linked list data
	 */
	getLinkedListData(): LinkedLists {
		const jsonStr = this.storage.getSecureItem(KEY_LINKED_LISTS);
		if (jsonStr !== undefined) {
			const json = JSON.parse(jsonStr);
			return json;
		}
		return null;
	}

	/**
	 * Fetch the role rules for agents
	 */
	getRoleRules(): Array<RoleRule> {
		const jsonStr = this.storage.getSecureItem(KEY_ROLE_RULES);
		if (jsonStr !== undefined) {
			const json = JSON.parse(jsonStr);
			return json;
		}
		return null;
	}

	/**
	 * Prints dynamic fields, cards and section details for the given DynamicLink
	 * @param dynamicLink
	 */
	printFields(dynamicLink: DynamicLink) {

		console.log('******************** Section ID [' + dynamicLink.sectionId + ']' +
			' - Name [' + dynamicLink.section + '] ********************');

		/* Iterate through the cards and fields */
		for (const card of dynamicLink.cards) {

			console.log('******************** Card ID [' + card.id + '] - Name [' + card.card + '] ********************');

			/* Now the fields */
			for (const field of card.fields) {
				console.log('******************** Field ID [' + field.id + '] - Name [' + field.field + '] ********************');
				console.log('chartype: ' + field.charType);
				console.log('inputfield: ' + field.inputField);
				console.log('min: ' + field.min);
				console.log('max: ' + field.max);
				console.log('allowspaces: ' + field.allowSpaces);
				console.log('charactersallowed: ' + field.charsAllowed);
				console.log('lov: ' + field.lov);
				console.log('mandatory: ' + field.mandatory);
				console.log('editable: ' + field.editable);
				console.log('hidden: ' + field.hidden);
				console.log('facedetect: ' + field.faceDetect);
				console.log('facedetecttype: ' + field.faceDetectType);
			}

		}
	}
}
