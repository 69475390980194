import { Injectable } from "@angular/core";
import { AxonService } from "../axon-service";
import { HttpDataService } from "../http/http-data.service";
import { AuthService } from "../auth/auth.service";
import { FilterOption } from "../../component/material-table/material-table.component";
import { Observable } from "rxjs";
import { DataResponse, DataRequest } from "../../dto/dtos";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Notifier } from "../../utils/notifier";

@Injectable({
    providedIn: 'root'
})
export class OrganizationsService extends AxonService {

    private httpService: HttpDataService;

    constructor(_httpClient: HttpClient, _authService: AuthService, _notifier: Notifier) {
        super(_httpClient, _authService, _notifier);
    }

    /**
     * Fetches orgs for table data
     * @param pageNum
     * @param pageMax
     * @param filterOptions
     */
    public getOrgs(pageNum: number, pageMax: number, filterOptions: FilterOption[]): Observable<DataResponse> {
        const dataRequest: DataRequest = {
            pageNum: pageNum,
            pageMax: pageMax,
            filterOptions: filterOptions
        };

        return this.getDataResponse(dataRequest, '/getorgs');
    }

    /**
     * Fetches orgs for table data
     * @param pageNum
     * @param pageMax
     * @param filterOptions
     */
    public getOrgsByType(type: string): Observable<DataResponse> {
        return this.getDataResponse({}, `/getorgs/type:${type}`);
    }


    /**
     * Helper to get the observable containing the response
     * @param dataRequest
     * @param subpath - the subpath following the rootpath
     * @param rootpath - if null, will be set to /org
     */
    public getDataResponse(dataRequest: DataRequest, subpath: string, rootpath?: string): Observable<DataResponse> {

        /* If not defined, set to /org i.e. the default root path */
        if (!rootpath) {
            rootpath = '/org';
        }

        this.httpService = new HttpDataService(this.httpClient, this.authService, rootpath, this.notifier);
        return this.httpService.sendData(dataRequest, subpath).pipe(
            map(response => {

                console.log(response);

                if (response.dataList) {
                    response.dataList.forEach(row => {
                        row.elementId = row.id;
                    });
                }

                return response;
            })
        );
    }
}
