import { Directive, Output, HostListener, EventEmitter, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({
  selector: '[appSubmitIfValid]'
})

/**
 * This directive can be used with our reactive forms to prevent submit buttons from submitting the form until validation errors are resolved
 * This allows the submit button to not be disabled (bad UX), and also to allow fields to be "untouched" until the button is pushed
 * Usage is  [appSubmitIfValid]="formReference" (valid)="onSubmit()"
 * Pass the FormGroup reference into [appSubmitIfValid] and the submit function reference into (valid) attribute
 * Examples can be found in the authentication templates
 */
export class SubmitIfValidDirective {

    // tslint:disable-next-line:no-input-rename
    @Input('appSubmitIfValid') formRef: FormGroup;
    // tslint:disable-next-line:no-output-rename
    @Output() valid = new EventEmitter<void>();

    constructor() {}

    @HostListener('submit')
    handleClick() {
        this.markFieldsAsDirty();
        this.emitIfValid();
    }

    private markFieldsAsDirty() {
        Object.keys(this.formRef.controls)
          .forEach(fieldName =>
              this.formRef.controls[fieldName].markAsDirty()
          );
    }

    private emitIfValid() {
        if (this.formRef.valid) {
            this.valid.emit();
        }
    }
}
