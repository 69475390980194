import { Component, OnInit, Input, AfterViewInit, ViewChild } from '@angular/core';
import { Agent, Range, AxonModel } from '../../../dto/dtos';
import { AgentModalComponent } from '../agent-modal.component';
import { AxonComponent } from '../../../axon.component';
import { FormControl, Validators, ValidatorFn, FormGroup, FormBuilder, FormGroupDirective, NgForm } from '@angular/forms';
import { AgentService } from '../../../services/agents/agent.service';
import { Notifier } from '../../../utils/notifier';
import { isNumeric } from 'rxjs/util/isNumeric';
import { ErrorStateMatcher } from '@angular/material';

// https://www.npmjs.com/package/big-js
import Big from '../../../../../node_modules/big.js';
import { BasicTableComponent } from '../../../component/basic-table/basic-table.component';
import { ServerError } from '../../../utils/server-errors';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent, CONFIRM } from '../../../component/form/confirm-modal/confirm-modal.component';

export class AxonErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

@Component({
	selector: 'app-agent-allocate-sims',
	templateUrl: './agent-allocate-sims.component.html',
	styleUrls: ['./agent-allocate-sims.component.scss']
})
export class AgentAllocateSIMsComponent extends AxonComponent {

    @Input() agent: Agent;
    @Input() parentComponent: AgentModalComponent;
    @ViewChild(BasicTableComponent) table: BasicTableComponent;

    matcher = new AxonErrorStateMatcher();
    
    private rangeStartControl: FormControl;
    private rangeEndControl: FormControl;
    private iccidStartControl: FormControl;
    private iccidEndControl: FormControl;

    private rangePatternError = "Range must be an MSISDN value";
    private iccidPatternError = "ICCID must be a numeric value";

    private form: FormGroup;
    formBuilt = false;

    ranges: Array<Range>;
    private cols = [
        { columnDef: 'checkbox'  , header: ''           , cell: (element: Range) => `${element.selected}`, isCheckbox: true     },
        { columnDef: 'rangeStart', header: 'Range Start', cell: (element: Range) => `${element.rangeStart}` },
        { columnDef: 'rangeEnd'  , header: 'Range End'  , cell: (element: Range) => `${element.rangeEnd}` },
        { columnDef: 'iccidStart', header: 'ICCID Start', cell: (element: Range) => `${element.iccidStart}` },
        { columnDef: 'iccidEnd'  , header: 'ICCID End'  , cell: (element: Range) => `${element.iccidEnd}` },
    ];

    processing: boolean = false;

    private instance: AgentAllocateSIMsComponent;

    private clickedRanges: Array<Range>;

	constructor( 
        private agentService: AgentService, 
        private notifier: Notifier, 
        private formBuilder: FormBuilder, 
        private modalService: NgbModal ) { 
        super();

        this.instance = this;
    }

	fetchData() {

        this.buildForm();
        if ( this.table ) {
            this.table.setDataList( new Array<any>() );
        }

        this.agentService.getAgentRanges(this.agent.agentId)
        .subscribe(response => {
            if (response.success === true) {
                this.ranges = response.dataList;
                if ( this.table ) {
                    this.table.setDataList( this.ranges );
                }
            } else {
                this.notifier.error( "An error occurred fetching the agent's ranges" );
            }
        },
        err => {
            this.notifier.error( "An error occurred fetching the agent's ranges" );
        });
    }

    handleCheckboxClick(row: Range) {

        row.selected = !row.selected;

        console.log( 'Clicked range [' + JSON.stringify(row) + ']' );

        if ( !this.clickedRanges ) {
            this.clickedRanges = new Array<Range>();
        }

        if ( !row.selected ) {
            
        }
        if ( row.selected ) {
            this.clickedRanges.push( row );
        } else {
            let idx: number = 0;
            let rowId: number = 0;
            for ( const range of this.clickedRanges ) {
                if ( range.id === row.id ) {
                    /* The selected range already exists in clickedRanges - remove it outside of this loop using idx */
                    rowId = range.id;
                    break;
                }
                idx++;
            } 
            console.log ('removing unselected range id [' + rowId + '] at idx [' + idx + ']' );
            this.clickedRanges.splice( idx, 1 );
        }

        console.log( 'count [' + this.clickedRanges.length + ']' );

    }

    public add() {

        if ( this.processing ) {
            return;
        }

        if ( !this.form.valid ) {
            console.log('Form contains invalid data. User needs to check inputs');
            this.notifier.error('Form contains invalid data. Please check your inputs');
            return;
        }

        const range: Range = {
            rangeStart: this.rangeStartControl.value,
            rangeEnd  : this.rangeEndControl.value,
            iccidStart: this.iccidStartControl.value,
            iccidEnd  : this.iccidEndControl.value
        };

        if ( this.validate( range ) ) {
            console.log( 'Validation passed' );
            this.processing = true;
            this.agentService.addAgentRange(this.agent.agentId, range)
            .subscribe(response => {
                if (response.success === true) {
                    this.notifier.success( "Range added successfully" );
                    this.ranges.push( range );
                    this.table.setDataList( this.ranges );
                    this.form.reset();
                } else {
                    this.notifier.error(ServerError.formatError(response));
                }
                this.processing = false;
            },
            err => {
                this.notifier.error( "An error occurred adding the agent's ranges" );
                this.processing = false;
            });
        }

    }

    /**
     * Can only edit one range at a time
     */
    public edit() {
        if ( !this.clickedRanges || this.clickedRanges.length !== 1 ) {
            this.notifier.warn( "Please select only one row from the 'Applied ranges' to edit" );
        }
    }

    /**
     * Can remove one or more ranges at a time
     */
    public remove() {
        if ( !this.clickedRanges || this.clickedRanges.length === 0 ) {
            this.notifier.warn( "Please select one or many rows from the 'Applied ranges' to remove" );
        }

        const confirm = this.modalService.open(ConfirmModalComponent, 
            { 
                windowClass : "modal-dialog-standard", 
                backdrop  : 'static',
                keyboard  : false
            }
        );
        confirm.componentInstance.title = 'Remove Range(s)';
        confirm.componentInstance.message = 
            'Are you sure you would like to remove the selected ranges?';
        confirm.componentInstance.confirmButton = 'Remove';
        confirm.componentInstance.cancelButton = 'Cancel';
        confirm.componentInstance.isHtml = true;

        confirm.result.then((result) => {
            if ( result === CONFIRM ) {
                this.performRemove();
            }
        }, (result) => {
            confirm.close();
        });
    }

    public performRemove() {
        this.processing = true;
        this.agentService.deleteAgentRange(this.agent.agentId, this.clickedRanges)
        .subscribe(response => {
            if (response.success === true) {
                this.notifier.success( "Range(s) removed successfully" );

                /* Remove them from the local list */
                for ( const remove of this.clickedRanges ) {
                    let idx: number = 0;
                    for ( const range of this.ranges ) {

                        if ( remove.id === range.id ) {
                            this.ranges.splice( idx, 1 );
                        }
                        idx++;
                    }
                } 

                this.table.setDataList( this.ranges );
            } else {
                this.notifier.error(ServerError.formatError(response));
            }
            this.processing = false;
        },
        err => {
            this.notifier.error( "An error occurred adding the agent's ranges" );
            this.processing = false;
        });
    }

    public validate( range: Range ): boolean {

        const rangeStart = Number( range.rangeStart );
        const rangeEnd   = Number( range.rangeEnd );
        const iccidStart: Big = new Big( range.iccidStart );
        const iccidEnd: Big   = new Big( range.iccidEnd );

        console.log( 'Range Start [' + rangeStart + '] - Range End [' + rangeEnd + '] - ' + 
            'ICCID Start [' + iccidStart + '] - ICCID End [' + iccidEnd + ']' );

        /* Basic existence and numeric checks */
        if ( !rangeStart && !rangeEnd && !iccidStart && !iccidEnd ) {
            this.notifier.error('Please capture all fields before adding the range');
            return false;
        } else if ( !isNumeric( rangeStart ) ) {
            this.notifier.error('Range start must contain only numbers');
            return false;
        } else if ( !isNumeric( rangeEnd ) ) {
            this.notifier.error('Range end must contain only numbers');
            return false;
        } else if ( !isNumeric( iccidStart ) ) {
            this.notifier.error('ICCID start must contain only numbers');
            return false;
        } else if ( !isNumeric( iccidEnd ) ) {
            this.notifier.error('ICCID end must contain only numbers');
            return false;
        }

        /* Range checks */
        if ( rangeStart >= rangeEnd ) {
            this.notifier.error('Range start must be less than Range end');
            return false;
        } else if ( (<Big>iccidEnd).minus(<Big>iccidStart) <= 0 ) {
            this.notifier.error('ICCID start must be less than ICCID end');
            return false;
        }

        const diff = (<Big>iccidEnd).minus(iccidStart);
        /* Ensure number of MSISDNs between Range, matches number of SIMs between ICCID, and vice versa */
        console.log( 'Range diff [' + (rangeEnd - rangeStart) + '] - ICCID diff [' + Number(diff) + ']' );
        if ( rangeEnd - rangeStart !== Number( diff ) ) {
            this.notifier.error('The number of MSISDNs within Range start and end, must be ' + 
            'the same amount of SIMs between ICCID start and end');
            return false;
        }

        /* Check for overlaps against existing ICCIDs */
        for ( const existing of this.ranges ) {

            const existingRangeStart = Number( existing.rangeStart );
            const existingRangeEnd   = Number( existing.rangeEnd );
            
            if ( ( rangeEnd - existingRangeStart ) * (existingRangeEnd - rangeStart) >= 0 ) {
                this.notifier.error('Range start and / or end overlaps an existing range');
                return false;
            }

            const existingIccidStart: Big = new Big( existing.iccidStart );
            const existingIccidEnd: Big   = new Big( existing.iccidEnd );
            const diff1: Big = (<Big>iccidEnd).minus(existingIccidStart);
            const diff2: Big = (<Big>existingIccidEnd).minus(iccidStart);
            if ( diff1.times(diff2) >= 0 ) {
                this.notifier.error('ICCID start and / or end overlaps an existing range');
                return false;
            }
        }

        return true;
    }
    
    public save() {
        console.log( 'Saved allocation SIMs' );
    }

    public cancel() {
        console.log( 'Cancel allocation SIMs' );
    }

    private buildForm() {
        this.formBuilt = false;

        this.clickedRanges = null;

        console.log( 'Building form...' );
        const formControlList = new Array<FormControl>();
        const rangeValidators = new Array<ValidatorFn>();

        rangeValidators.push(
            Validators.pattern("^[0-9]{9,9}$")
        );
        rangeValidators.push( 
            Validators.required
        );

        this.rangeStartControl = new FormControl();
        this.rangeStartControl.setValidators( rangeValidators );
        this.rangeEndControl = new FormControl();
        this.rangeEndControl.setValidators( rangeValidators );


        const iccidValidators = new Array<ValidatorFn>();

        iccidValidators.push(
            Validators.pattern("^[0-9]{19,19}$")
        );
        iccidValidators.push( 
            Validators.required
        );

        this.iccidStartControl = new FormControl();
        this.iccidStartControl.setValidators( iccidValidators );
        this.iccidEndControl = new FormControl();
        this.iccidEndControl.setValidators( iccidValidators );

        formControlList.push( this.rangeStartControl );
        formControlList.push( this.rangeEndControl   );
        formControlList.push( this.iccidStartControl );
        formControlList.push( this.iccidEndControl   );

        this.form = this.formBuilder.group(formControlList);
        console.log( 'Form built' );
        this.formBuilt = true;
    }

}
