import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { AxonComponent } from "../../../axon.component";
import { CustomerService } from "../../../services/customers/customer.service";
import { Notifier } from "../../../utils/notifier";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DownloadPdfComponent } from "../download-pdf/download-pdf.component";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import {CustomerViewDownloadPDFComponent} from '../../customer-view-download-pdf/customer-view-download-pdf.component';

@Component({
  selector: 'app-enter-email',
  templateUrl: './enter.email.component.html',
  styleUrls: ['./enter.email.component.scss']
})
export class EnterEmailComponent extends AxonComponent implements OnInit {

  enterEmailForm: FormGroup;
  instance: EnterEmailComponent;
  @Input('parent') parent: CustomerViewDownloadPDFComponent;
  @ViewChild('recipientEmailInput') recipientEmailInputRef: ElementRef;

  btnOpts: MatProgressButtonOptions = {
      active: false,
      text: 'Send Email',
      // text: this.getBtnTitle(field.field),
      spinnerSize: 19,
      raised: true,
      stroked: false,
      flat: false,
      fab: false,
      // buttonColor: 'accent',
      // spinnerColor: 'accent',
      fullWidth: true,
      disabled: false,
      customClass: 'mt-4 mb-4 btn btn-axon-info btn-lg btn-block text-uppercase waves-effect waves-light btn-axon-info',
      mode: 'indeterminate',
  };

  constructor(
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private notifier: Notifier,
    public activeModal: NgbActiveModal) {
    super();
    this.instance = this;
  }

  ngOnInit() {
    this.enterEmailForm = this.formBuilder.group({
      recipientEmail: ['', Validators.required]
    });

    setTimeout(() => {
      this.recipientEmailInputRef.nativeElement.focus();
    });
  }

  get recipientEmail() {
    return this.enterEmailForm.get('recipientEmail');
  }

  sendEmail() {
    if (this.enterEmailForm.get('recipientEmail')) {
      const recipient = this.enterEmailForm.get('recipientEmail').value;

      this.btnOpts.active = true;
      this.doneSendingEmail = this.doneSendingEmail.bind(this);
      this.parent.sendEmail(recipient, this.doneSendingEmail);

      this.close();
    }
  }

  doneSendingEmail() {
      this.btnOpts.active = false;
  }

  close() {
    setTimeout(() => {
      this.activeModal.dismiss();
    });
  }

  onSubmit() {
    this.sendEmail();
    this.close();
  }
}