import { ConfigService } from './../../services/config/config.service';
import { AxonUtils } from './../../utils/axon-utils';
import { DataResponse, DataRequest, DmsNodeInfoDto, DmsNodeTypeDto, DynamicLink, Image } from './../../dto/dtos';
import { Observable, ReplaySubject } from 'rxjs';
import { FilterOption } from './../../component/material-table/material-table.component';
import { Notifier } from './../../utils/notifier';
import { AuthService } from './../../services/auth/auth.service';
import { HttpDataService } from './../../services/http/http-data.service';
import { AxonService } from './../../services/axon-service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class DmsService extends AxonService {
	private httpService: HttpDataService;
	private enabled: boolean;
	private $enabled = new ReplaySubject<boolean>();

	constructor(_httpClient: HttpClient, _authService: AuthService, _notifier: Notifier, private configService: ConfigService) {
		super(_httpClient, _authService, _notifier);
		this.httpService = new HttpDataService(this.httpClient, this.authService, '/dms', this.notifier);

		this.configService.getFeaturesList()
			.subscribe(featureList => {
				this.enabled = featureList.filter(item => item.toLowerCase() === "dms").length > 0;
				this.$enabled.next(this.enabled);
			});
	}

	public getDmsNodeList(sort: string, order: string, pageNum: number, pageMax: number, filterOptions: FilterOption[]): Observable<DataResponse> {
		const dataRequest: DataRequest = {
			pageNum: pageNum,
			pageMax: pageMax,
			sort: sort,
			order: order,
			filterOptions: filterOptions
		};
		return this.getDataResponse('/node/', dataRequest);
	}
	public getDmsNodesForTree(): Observable<DataResponse> {
		return this.getDataResponse('/node/forTree', {});
	}
	public getDmsNodeImages(dmsNodeId: number): Observable<Image[]> {
		return this.httpService.doGet('/node/' + dmsNodeId + "/images");
	}

	public getDmsNodeDynamicDataByAxonId(sectionId: number, identifierNum: number, cardId: number): Observable<DataResponse> {
		const dataRequest: DataRequest = {
			sectionId: sectionId,
			cardId: cardId,
			identifierNum: identifierNum
		};

		return this.getDataResponse(`/dynamic/axonid`, dataRequest);
	}
	public addDmsNode(sectionId: number, parentId: number, dynamicLink: DynamicLink): Observable<DataResponse> {
		const dataRequest: DataRequest = {
			sectionId: sectionId,
			dmsNodeId: parentId,
			dynamicLink: dynamicLink
		};
		return this.getDataResponse('/dynamic/add', dataRequest);
	}
	public modifyDmsNode(sectionId: number, axonId: number, dynamicLink: DynamicLink): Observable<DataResponse> {
		const dataRequest: DataRequest = {
			sectionId: sectionId,
			identifierNum: axonId,
			dynamicLink: dynamicLink
		};
		return this.getDataResponse('/dynamic/add', dataRequest);
	}




	public getDmsNode(id: number): Observable<DmsNodeInfoDto> {
		return this.httpService.doGet('/node/' + id);
	}
	public getDmsNodesForAgent(id?: number): Observable<DmsNodeInfoDto[]> {
		if (id) {
			return this.httpService.doGet('/node/availableForAgent/' + id);
		} else {
			return this.httpService.doGet('/node/availableForAgent');
		}
	}
	public getAgentsDmsNode(id: number): Observable<DmsNodeInfoDto> {
		return this.httpService.doGet('/node/agent/' + id);
	}
	public getCustomersDmsNode(id: number): Observable<DmsNodeInfoDto> {
		return this.httpService.doGet('/node/customer/' + id);
	}


	private getDataResponse(path: string, dataRequest: DataRequest): Observable<DataResponse> {
		return this.httpService.sendData(dataRequest, path).pipe(
			map(response => {
				/* Put the elementId on for the tables, to allow for maintaining selected/checkbox state */
				if (response.dataList) {
					response.dataList.forEach(row => {
						row.elementId = row.agentId;
					});
				}
				return response;
			}));
	}

	public getDmsNodeType(id: number): Observable<DmsNodeTypeDto> {
		return this.httpService.doGet('/node_type/' + id);
	}

	public isDmsEnabled(): Observable<boolean> {
		return this.$enabled;
	}
	public isDmsEnabledSnapshot(): boolean | null {
		return this.enabled;
	}
	public linkAgentToDmsNode(agentId: number, dmsNodeId: number): Observable<any> {
		return this.httpService.doPost(`/node/agent/${agentId}/link/${dmsNodeId}`, {});
	}
}
