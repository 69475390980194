import { Component, Input, OnInit } from '@angular/core';
import { ReportQueryResultDto } from '../../../dto/dtos';

@Component({
	selector: 'app-report-display-heatmap',
	templateUrl: './report-display-heatmap.component.html',
	styleUrls: ['./report-display-heatmap.component.css']
})
export class ReportDisplayHeatmapComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

	@Input()
	set data(input: ReportQueryResultDto) {
	}

}
