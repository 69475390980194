import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { FormData } from './form-data';
import { DataResponse, DynamicLink } from '../../../dto/dtos';
import { AgentService } from '../../../services/agents/agent.service';
import { AxonService } from '../../../services/axon-service';
import { Section } from '../../../utils/constants';
import { SectionUtils } from '../../../../environments/environment';
import { DynamicFieldManager } from '../../../settings/dynamic-fields/dynamic-field-manager';

/**
 * Used to create new forms i.e not editing an object.
 * Just contains the section ID needed to build the form
 */
export class NewFormData extends FormData {

    constructor(private _section: Section, private _cardId: number, private dynamicFieldManager: DynamicFieldManager, private _axonService: AxonService) {
        super(_section, _cardId, dynamicFieldManager, _axonService);
    }

    getFormData(): Observable<DataResponse> {
        return null;
    }

    saveFormData(identifierNum: 0, dynamicLink: DynamicLink, agentService: AgentService): Observable<DataResponse> {
        return agentService.saveFormData(SectionUtils.getSectionId(this.section), identifierNum, dynamicLink);
    }
}

