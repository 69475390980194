import { Component, Input, ViewChild, AfterViewInit, OnInit, Output, EventEmitter } from '@angular/core';
import { MultiTierDropdownComponent } from './multi-tier-dropdown/multi-tier-dropdown.component';
import { AutoCompleteDropdownComponent } from './auto-complete-dropdown/auto-complete-dropdown.component';
import { Node } from '../../dto/dtos';
import { FormControl } from '@angular/forms';
import { AxonComponent } from '../../axon.component';
import { Observer } from 'rxjs';

/**
 * For auto complete dropdowns
 */
export interface ListValue {
    id: number;
    value: string;
}
export const RETURN_TYPE_ID    = "ID";
export const RETURN_TYPE_VALUE = "VALUE";

@Component({
    selector: 'app-linked-list-dropdown',
    templateUrl: './linked-list-dropdown.component.html',
    styleUrls: ['./linked-list-dropdown.component.scss']
})
export class LinkedListDropdownComponent extends AxonComponent implements OnInit, AfterViewInit {

    /* These 2 inputs are required for auto complete dropdowns */
    @Input() placeholder: string;
    @Input() listValues: Array<ListValue>;

    /* These 4 inputs are required for 3 tiered dropdowns. NOTE: placeholder above is also used */
    @Input() parentList: Array<Node>;
    @Input() formCtrl: FormControl;
    @Input() fieldId?: number;
    @Input() mandatory: boolean;
	@Input() initValue: number;
	@Output() change = new EventEmitter();

    returnType: string = RETURN_TYPE_VALUE;
    parentPlaceholder: string;
    childPlaceholder: string;
    grandChildPlaceholder: string;
    greatGrandChildPlaceholder: string;

    @ViewChild(MultiTierDropdownComponent) multiTierDropdownComponent: MultiTierDropdownComponent;
    @ViewChild(AutoCompleteDropdownComponent) autoCompleteComponent: AutoCompleteDropdownComponent;

    constructor() {
        super();
    }

    ngOnInit() {
        this.initPlaceholders();
    }

    ngAfterViewInit() {
        this.initPlaceholders();
    }

    initPlaceholders() {
        if ( this.parentList ) {
            const placeholders = this.placeholder.split(":");
            if ( placeholders[0] ) {
                this.parentPlaceholder = placeholders[0];
            }

            if ( placeholders[1] ) {
                this.childPlaceholder = placeholders[1];
            }

            if ( placeholders[2] ) {
                this.grandChildPlaceholder = placeholders[2];
            }

            if ( placeholders[3] ) {
                this.greatGrandChildPlaceholder = placeholders[3];
            }
        }
    }
	setSelectedValue(value: string){
		this.change.emit(value);
	}

    setReturnType( returnType: string ) {
        if ( returnType ) {
            this.returnType = returnType;
        }
    }

    getValueForId(id: any): any {
        if (this.autoCompleteComponent) {
            console.log(this.autoCompleteComponent.getValueForId(id));
            return this.autoCompleteComponent.getValueForId(id);
        }
    }

    getValue(): any {
        if ( this.multiTierDropdownComponent ) {
            switch ( this.returnType ) {
                case RETURN_TYPE_ID:
                    return this.multiTierDropdownComponent.getIdValue();
                case RETURN_TYPE_VALUE:
                    return this.multiTierDropdownComponent.getValue();
            }

        } else {
            if (this.returnType) {
                switch ( this.returnType ) {
                    case RETURN_TYPE_ID:
                        return this.autoCompleteComponent.getIdValueFromValue(this.autoCompleteComponent.getValue());
                    case RETURN_TYPE_VALUE:
                        return this.autoCompleteComponent.getValue();
                }
            }
            return this.autoCompleteComponent.getValue();
        }
    }

    setValue(value: any) {

        console.log( 'linked list dropdown component received value [' + value + ']' );
        console.log( 'this.multiTierDropdownComponent  [' + this.multiTierDropdownComponent  + ']' );
        console.log( 'this.returnType  [' + this.returnType  + ']' );
        console.log( 'this.autoCompleteComponent [' + this.autoCompleteComponent + ']' );
        if ( this.multiTierDropdownComponent ) {

            switch ( this.returnType ) {
                case RETURN_TYPE_ID:
                    this.multiTierDropdownComponent.setValueById( value );
                    break;
                case RETURN_TYPE_VALUE:
                    this.multiTierDropdownComponent.setValue( value );
                    break;
            }
        }

        if ( this.autoCompleteComponent) {

            setTimeout(() => {
              console.log("Seting autocomplete value to [" + value + "]");
              this.autoCompleteComponent.setValue( value );
            });

        }
    }

    parseValue(value: any) {
        if ( this.multiTierDropdownComponent ) {
            switch ( this.returnType ) {
                case RETURN_TYPE_ID:
                    return this.multiTierDropdownComponent.getIdValue();
                case RETURN_TYPE_VALUE:
                    return this.multiTierDropdownComponent.getValue();
            }
            return this.multiTierDropdownComponent.getValue();
        } else if ( this.autoCompleteComponent && this.autoCompleteComponent.getValue() ) {
            return this.autoCompleteComponent.filterValues(value);
        }
    }


    getFieldId() {
        if (this.fieldId !== undefined) {
            return this.fieldId;
        }
    }

    /**
     * Returns true to caller if this component was initalised with input property mandatory set to true
     */
    isMandatory() {
        return this.mandatory;
    }

    /**
     * Returns true, if the multi tier dropdown component has fullySelected set to true
     */
    isFullySelected() {
        if ( this.multiTierDropdownComponent ) {
            return this.multiTierDropdownComponent.isFullySelected();
        }
        return false;
    }
}
