import { Pipe, PipeTransform } from '@angular/core';
import { isNumber } from 'util';

/**
 * If a value's length is greater than arg length, 
 * the value is snipped to the length, and ellipsis (...) is appended
 * indicating data is snipped.
 * 
 * If no arg length supplied, default is 20
 */
@Pipe({ name: 'textSnipper' })
export class TextSnipperPipe implements PipeTransform {
    transform(value: string, length?: number): any {
        
        if (!length) {
            /* Default length */
            length = 10;
        }

        if ( value.length > length ) {
            return value.slice(0, length) + '...';
        } else {
            return value;
        }
    }
}