import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ReportQueryResultDto } from '../../../dto/dtos';
import * as Highcharts from 'highcharts';
import * as moment from "moment";

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);



@Component({
	selector: 'app-report-display-pie',
	templateUrl: './report-display-pie.component.html',
	styleUrls: ['./report-display-pie.component.css']
})
export class ReportDisplayPieComponent implements OnInit {
	public options: Highcharts.Options;
	@Input() title = '';
	@Input() data: ReportQueryResultDto;

	@ViewChild('container') container: ElementRef;

	constructor() { }

	ngOnInit() {
		const that = this;

		const options = {
			chart: {
				type: 'pie'
			},
			title: {
				text: this.title
			},
			credits: {
				enabled: false
			},
			tooltip: {
				pointFormat: '<b>{series.name}:</b><br/>{point.y:.1f} ( {point.percentage:.1f}% )'
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true,
						format: '<b>{point.name}</b>: {point.y:.1f} - {point.percentage:.1f} %'
					}
				}
			},
			series: this.getDataSeries(this.data)
		};
		this.options = options as Highcharts.Options;
		console.log("Highcharts:", this.options);
		Highcharts.chart(this.container.nativeElement, this.options);
	}

	private getDataSeries(data: ReportQueryResultDto): Highcharts.SeriesOptionsType[] {
		const details = this.extractDetails(data);
		const out: Highcharts.SeriesOptionsType[] = [];

		for (let i = 0; i < data.columns.length; i++) {
			if (i === details.index) {
				continue;
			}

			const seriesData = { name: data.columns[i], colorByPoint: true, data: [] };
			for (let j = 0; j < data.rows.length; j++) {
				seriesData.data.push({
					name: details.type === 'category' ? data.rows[j].data[details.index] : moment(data.rows[j].data[details.index], 'YYYY-MM-DD HH:mm:ss').toDate(),
					y: +data.rows[j].data[i]
				});
			}
			out.push(seriesData as Highcharts.SeriesOptionsType);
		}
		return out;
	}
	private extractDetails(data: ReportQueryResultDto): { type: 'datetime' | 'category', name: string, index: number } {
		for (let i = 0; i < data.types.length; i++) {
			if (data.types[i].toLowerCase() === 'timestamp') {
				return { type: 'datetime', name: data.columns[i], index: i };
			}
			if (data.types[i].toLowerCase() === 'datetime') {
				return { type: 'datetime', name: data.columns[i], index: i };
			}
			if (data.types[i].toLowerCase() === 'string') {
				return { type: 'category', name: data.columns[i], index: i };
			}
		}
	}
}
