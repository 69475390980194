import { Component, OnInit, Input, HostBinding, OnChanges, SimpleChange, SimpleChanges, OnDestroy } from '@angular/core';
import { AxonUtils } from '../../utils/axon-utils';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../services/auth/auth.service';
import { map, tap, catchError } from 'rxjs/operators';

class SecuredImgComponentMode {
	mode: 'width' | 'height' | 'width_and_height' | 'none';
}
@Component({
	selector: 'app-secured-img',
	templateUrl: './secured-img.component.html',
	styleUrls: ['./secured-img.component.css']
})
export class SecuredImgComponent implements OnInit, OnChanges, OnDestroy {
	@Input() private src: string;
	@Input() width: number;
	@Input() height: number;

	@Input() private withWidth: number;
	@Input() private withHeight: number;
	@Input() private withMaxWidth: number;
	@Input() private withMaxHeight: number;
	@Input() private withRotation: number;
	@Input() class: string;
	@Input() ngClass;

	url: string | SafeUrl | Blob;
	mode: SecuredImgComponentMode;
	state: 'loading' | 'loaded' | 'failed';
	private subscription: Subscription;


	constructor(private http: HttpClient, private sanitizer: DomSanitizer, private authService: AuthService) { }

	ngOnInit() {
		this.ngOnChanges(null);
	}

	ngOnChanges(changes: SimpleChanges) {
        
		if (this.subscription != null) {
			this.subscription.unsubscribe();
			this.subscription = null;
		}
		if (this.src.startsWith('data:') && this.src.indexOf('data:') > -1) {
			this.mode = this.calculateMode(this.width, this.height);
			this.url = AxonUtils.domSanitizer.bypassSecurityTrustUrl(this.src);
			this.state = 'loaded';
			return;
		}

		let seperator = this.src.indexOf('?') === -1 ? '?' : '&';
		let url = this.src;
		if (this.withWidth && this.withWidth > 0) {
			url += seperator + 'width=' + this.withWidth;
			seperator = '&';
		}
		if (this.withHeight && this.withHeight > 0) {
			url += seperator + 'height=' + this.withHeight;
			seperator = '&';
		}
		if (this.withMaxWidth && this.withMaxWidth > 0) {
			url += seperator + 'max-width=' + this.withMaxWidth;
			seperator = '&';
		}
		if (this.withMaxHeight && this.withMaxHeight > 0) {
			url += seperator + 'max-height=' + this.withMaxHeight;
			seperator = '&';
		}
		if (this.withRotation && this.withRotation > 0) {
			url += seperator + 'rotation=' + this.withRotation;
			seperator = '&';
		}
		if (url.startsWith('/axonresource/aiu')) { //Auth In URL
			this.mode = this.calculateMode(this.width, this.height);
			this.url = url;
			this.state = 'loaded';
			return;
		}
		//Auth With Headers

		this.state = 'loading';
		this.url = null;//'/assets/images/background/socialbg.jpg';
		this.mode = this.calculateMode(
			this.getFirstSize(this.width, this.withWidth, this.withMaxWidth),
			this.getFirstSize(this.height, this.withHeight, this.withMaxHeight));

		if (url.indexOf('base64') === -1) {
			const parts = url.split('?');
			url = parts[0] + 'base64';
			seperator = '?';
			for (let i = 1; i < parts.length; i++) {
				url += seperator + parts[i];
				seperator = '&';
			}
		}

		const token = this.authService.getAuthToken().token;
		const headers = new HttpHeaders({ 'axon-token': token });
		this.subscription = this.http.get(url, { headers: headers, responseType: 'text' }).pipe(
			map(response => {
				this.state = 'loaded';
				this.mode = this.calculateMode(this.width, this.height);
				this.url = AxonUtils.domSanitizer.bypassSecurityTrustUrl(response);
				return this.url;
			}),
			catchError(error => {
				this.state = 'failed';
				this.url = null;//'/assets/images/background/error-bg.jpg';
				this.mode = this.calculateMode(
					this.getFirstSize(this.width, this.withWidth, this.withMaxWidth),
					this.getFirstSize(this.height, this.withHeight, this.withMaxHeight));
				throw error;
			})
		)
		.subscribe();
	}

	getFirstSize(s1: number, s2: number, s3: number): number {
		if (s1 && s1 > 0) {
			return s1;
		}
		if (s2 && s2 > 0) {
			return s2;
		}
		if (s3 && s3 > 0) {
			return s3;
		}
	}

	private calculateMode(width: number, height: number): SecuredImgComponentMode {
		if (width && height) {
			return { mode: 'width_and_height' };
		} else if (width) {
			return { mode: 'width' };
		} else if (height) {
			return { mode: 'height' };
		} else {
			return { mode: 'none' };
		}
    }
    
    ngOnDestroy() {
        if ( this.subscription ) {
            this.subscription.unsubscribe();
        }
    }
}
