import { Component, OnInit, Input, AfterViewInit, Renderer, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { AxonComponent } from '../../../axon.component';

export interface ListValue {
    id: number;
    value: string;
}
@Component({
    selector: 'app-auto-complete-dropdown',
    templateUrl: './auto-complete-dropdown.component.html',
    styleUrls: ['./auto-complete-dropdown.component.scss']
})
export class AutoCompleteDropdownComponent extends AxonComponent implements OnInit, AfterViewInit, OnChanges {

    @Input() placeholder: string;
    @Input() listValues: Array<ListValue>;
    @Input() formCtrl: FormControl;
	@Output() change = new EventEmitter();

    filteredValues: Observable<ListValue[]>;
    matchFound: boolean;
    inputValue: string;
    levels = 0;

    value: any;
    tmp: string;

    constructor(private renderer: Renderer) {
        super();
    }

    ngOnInit() {
        this.initFilteredValues();
	}

    ngAfterViewInit(): void {
        this.initFilteredValues();
    }

    ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
        this.initFilteredValues();
    }

    initFilteredValues() {
        this.filteredValues = this.formCtrl.valueChanges
          .pipe(
              startWith(''),
              map(value => value ? this.filterValues(value) : this.listValues.slice())
          );
    }

    public filterValues(value: string): ListValue[] {
        if (value === null) {
          return this.listValues;
        }
        const filterValue = value.toLowerCase();

        const values = this.listValues.filter(
            item => item.value.toLowerCase().indexOf(filterValue) > -1
        );
        this.matchFound = values.length > 0;

        return values;
    }

    /**
     * Callers can use this to get the selected value
     */
    getValue() {
        return this.value;
    }

    getIdValueFromValue(value: string) {

        let returnId = 0;
        this.listValues.forEach( (listValue: ListValue) => {
            if (listValue.value === value) {
                returnId = listValue.id;
            }
        });
        return returnId;
    }

    getValueForId(id: any) {
        let value = "";
        this.listValues.forEach( (listValue: ListValue) => {
            if (listValue.id.toString() === id.toString()) {
                value = listValue.value;
            }
        });
        return value;
    }

    /* Added a tmp variable and onblur, onfocus events to allow for the whole dropdown to show when clicking on the select */
    setValue(value: string) {

        this.formCtrl.setValue( value );
        this.value = value;
        const nativeElement: HTMLElement = ( <any> this.formCtrl ).nativeElement;
        // nativeElement.blur();
		this.change.emit(value);
    }

    /* When the input is focused then the value is stored in tmp and the input is reset */
    clearTemporarily() {
        if (this.getValue() !== undefined && this.getValue().trim() !== "" && this.filterValues(this.getValue()) !== undefined && this.filterValues(this.getValue()) !== null) {
            this.tmp = this.getValue();
            this.formCtrl.reset();
        }
    }

    /* When the input is blurred then the value is restored if the tmp value is not empty */
    restoreTempValue($event) {
        setTimeout(() => {

            const currentValue = $event.srcElement.value;

            if (this.tmp !== undefined && currentValue !== "" && this.getIdValueFromValue(currentValue) === 0) {
                this.setValue(this.tmp);
            } else if (this.tmp === undefined) {
                this.tmp = "";
                this.setValue(this.tmp);
            }
        }, 1500);
    }
}
