import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthComponent } from './auth.component';
import { NotFoundComponent } from './404/not-found.component';
import { TermsComponent } from './signup/terms.component';
import { AxonMaterialModule } from '../material-module';
import { AuthenticationRoutingModule } from './authentication.routing';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SubmitIfValidDirective } from '../component/form/directives/submitifvalid.directive';
import { TrackCapsDirective } from '../component/form/directives/capslock.directive';
import { AxonSharedModule } from '../axon-shared.module';
import { MatProgressButtonsModule } from 'mat-progress-buttons';
import { BasicTableComponent } from '../component/basic-table/basic-table.component';
import { InfoDialogComponent } from '../component/info-dialog/info-dialog.component';
import { DownloadExpiredComponent } from './download-expired-component/download-expired-component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AxonSharedModule,
    ReactiveFormsModule,
    AuthenticationRoutingModule,
	AxonMaterialModule,
	MatProgressButtonsModule,
  ],
  declarations: [
    AuthComponent,
    NotFoundComponent,	
    TrackCapsDirective,
    SubmitIfValidDirective,
    TermsComponent,
	DownloadExpiredComponent,
  ],
  providers: [
    InfoDialogComponent
  ],
  entryComponents: [
    TermsComponent
  ],
  bootstrap: [AuthComponent]
})
export class AuthenticationModule {}
