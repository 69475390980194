import { Component, Input, AfterViewInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';

export interface AdvancedSearchCriteria {
    axonId?: number;
    msisdn?: string;
    iccid?: string;
    id?: string;
    thirdPartyId?: string;
    name?: string;
    othernames?: string;
    surname?: string;
    corpName?: string;
    corpTin?: string;
    agentUsername?: string;
    agentMsisdn?: string;
    customerType?: string;
    customerProfileType?: string;		
    dateFrom?: Date;
    dateTo?: Date;
	customerCategory?: "VOTER" | "CANDIDATE";
}

@Component({
  selector: 'app-approval-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements AfterViewInit {

    @Input() searchCriteria: AdvancedSearchCriteria;

    /* Default dates */
    private dateFrom: Date;
    private dateTo: Date;

    /* Form controls */
    axonIdFormControl: FormControl = new FormControl();
    msisdnFormControl: FormControl = new FormControl();
    iccidFormControl: FormControl = new FormControl();
    idFormControl: FormControl = new FormControl();
    thirdPartyIdFormControl: FormControl = new FormControl();
    nameFormControl: FormControl = new FormControl();
    otherNamesFormControl: FormControl = new FormControl();
    surnameFormControl: FormControl = new FormControl();
    corpNameFormControl: FormControl = new FormControl();
    corpTINFormControl: FormControl = new FormControl();
    agentUsernameFormControl: FormControl = new FormControl();
    agentMSISDNFormControl: FormControl = new FormControl();
    dateFromFormControl: FormControl = new FormControl();
    dateToFormControl: FormControl = new FormControl();
    customerTypeFormControl: FormControl = new FormControl();
    customerProfileTypeFormControl: FormControl = new FormControl();

    customerType: Array< string >;
    customerProfileType: Array< string >;

    constructor(public activeModal: NgbActiveModal) { 
        this.setDefaults();
    }

    ngAfterViewInit() {
        this.setSearchCriteria();
    }

    private setDefaults() {

        this.customerType = new Array();
        this.customerType.push( 'Any' );
        this.customerType.push( 'Prepaid' );
        this.customerType.push( 'Postpaid' );
        this.customerTypeFormControl.setValue( this.customerType[0] ); 

        this.customerProfileType = new Array();
        this.customerProfileType.push( 'Any' );
        this.customerProfileType.push( 'Individual' );
        this.customerProfileType.push( 'Corporate' );
        this.customerProfileTypeFormControl.setValue( this.customerProfileType[0] );

        this.dateFrom = new Date();
        this.dateFrom.setDate( this.dateFrom.getDate() - 7 );
        this.dateTo   = new Date();

        /* Dates are at the bottom of the search, out of user's view. So don't default them */
        // this.dateFromFormControl.setValue( this.dateFrom );
        // this.dateToFormControl.setValue( this.dateTo );
    }

    private setSearchCriteria() {

        if ( this.searchCriteria ) {
            this.axonIdFormControl.setValue( this.searchCriteria.axonId );
            this.msisdnFormControl.setValue( this.searchCriteria.msisdn );
            this.iccidFormControl.setValue( this.searchCriteria.iccid );
            this.idFormControl.setValue( this.searchCriteria.id );
            this.thirdPartyIdFormControl.setValue( this.searchCriteria.thirdPartyId );
            this.nameFormControl.setValue( this.searchCriteria.name );
            this.otherNamesFormControl.setValue( this.searchCriteria.othernames );
            this.surnameFormControl.setValue( this.searchCriteria.surname );
            this.corpNameFormControl.setValue( this.searchCriteria.corpName );
            this.corpTINFormControl.setValue( this.searchCriteria.corpTin );
            this.agentUsernameFormControl.setValue( this.searchCriteria.agentUsername );
            this.agentMSISDNFormControl.setValue( this.searchCriteria.agentMsisdn );
            this.dateFromFormControl.setValue( this.searchCriteria.dateFrom );
            this.dateToFormControl.setValue( this.searchCriteria.dateTo );
            this.customerTypeFormControl.setValue( this.searchCriteria.customerType );
            this.customerProfileTypeFormControl.setValue( this.searchCriteria.customerProfileType );
        }
    }

    search() {

        this.searchCriteria = {
            axonId:              this.axonIdFormControl.value,
            msisdn:              this.msisdnFormControl.value,
            iccid:               this.iccidFormControl.value,
            id:                  this.idFormControl.value,
            thirdPartyId:        this.thirdPartyIdFormControl.value,
            agentMsisdn:         this.agentMSISDNFormControl.value,
            agentUsername:       this.agentUsernameFormControl.value,
            corpName:            this.corpNameFormControl.value,
            corpTin:             this.corpTINFormControl.value,
            customerType:        this.customerTypeFormControl.value,
            customerProfileType: this.customerProfileTypeFormControl.value,
            name:                this.nameFormControl.value,
            othernames:          this.otherNamesFormControl.value,
            surname:             this.surnameFormControl.value,
            dateFrom:            this.dateFromFormControl.value,
            dateTo:              this.dateToFormControl.value,
			customerCategory:	 "VOTER",
        };

        this.activeModal.close(this.searchCriteria);
    }

    cancel() {
        this.activeModal.close();
    }
}
