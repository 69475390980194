import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Injectable({
	providedIn: 'root'
})
export class AgentFormService {

	constructor() {}

	form: FormGroup = new FormGroup({
		$key: new FormControl(null),
		Title: new FormControl(''),
		Role: new FormControl(''),
		Name: new FormControl(''),
		Surname: new FormControl(''),
		DateOfBirth: new FormControl(''),
		Age: new FormControl(''),
		Gender: new FormControl(1)
	});
}