import { Injectable, Component } from "@angular/core";
import { AxonComponent } from "../../axon.component";
import { ChartRow, Chart } from "../../dto/dtos";
import { DashBoardService } from "../../services/dashboard/dashboard.service";

@Injectable({
    providedIn: 'root'
})

@Component({
    // selector: 'app-users',
    templateUrl: './system-dashboard.component.html',
    styleUrls: ['./system-dashboard.component.scss'],
})
export class SystemDashboardComponent extends AxonComponent {

    charts: Array< ChartRow >;
    
    constructor(private dashBoardService: DashBoardService) {
        super();

        this.getCharts();
    }

    getCharts() {
        this.dashBoardService.getCharts().subscribe(resp => {
            console.log('System dashboard chart fetch successful ? [' + resp.success + ']');
            if ( resp.success ) {
                const chartList: Array< Chart > = resp.dataList;
                console.log( JSON.stringify( chartList ) );

                this.charts = new Array();

                for ( const chart of chartList ) {
                    
                    const row = chart.row;
                    let foundRow = false;
                    for ( const chartRow of this.charts ) {
                        if ( chartRow.row === row ) {
                            const chartsInRow = chartRow.charts;
                            chartsInRow.push( chart );
                            foundRow = true;
                        }
                    }

                    if ( !foundRow ) {
                        const chartsInRow = new Array< Chart >();
                        chartsInRow.push( chart );
                        const chartRow: ChartRow = {
                            row: chart.row,
                            charts: chartsInRow
                        };
                        this.charts.push( chartRow );
                    }
                }

                for ( const chartRow of this.charts ) {
                    console.log( "Row [" + chartRow.row + "] - Number of charts [" + chartRow.charts.length + "]" );
                    for ( const chart of chartRow.charts ) {
                        console.log( 'Chart [' + chart.title + '] - type [' + chart.type + ']' );
                    }
                }
            }
        });
    }
}