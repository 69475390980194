import { Component, OnInit, Input } from '@angular/core';
import { Customer } from '../../dto/dtos';
import { environment } from '../../../environments/environment';
import { CustomerService } from '../../services/customers/customer.service';
import { SafeResourceUrl } from '@angular/platform-browser';
import { AxonUtils } from '../../utils/axon-utils';
import { EnterEmailComponent } from '../modal/enter-email-address/enter.email.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Notifier } from '../../utils/notifier';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-customer-view-download-pdf',
	templateUrl: './customer-view-download-pdf.component.html',
	styleUrls: ['./customer-view-download-pdf.component.scss']
})
export class CustomerViewDownloadPDFComponent implements OnInit {
	@Input() customer: Customer;

	isLoading = false;
	isQueueing = false;
	pdfBlob: Blob;
	pdfUrl: SafeResourceUrl;

	constructor(
		private datePipe: DatePipe,
		private customerService: CustomerService,
		private modalService: NgbModal,
		private notifier: Notifier) {
	}

	ngOnInit() {
		this.isLoading = true;
		this.customerService.getPDFFormFilled(this.customer.axonId).subscribe(response => {
			const pdfBlob = new Blob([<any>response], { type: 'application/pdf' });
			const pdfUrl = URL.createObjectURL(pdfBlob);

			this.pdfUrl = AxonUtils.domSanitizer.bypassSecurityTrustResourceUrl(pdfUrl);
			this.pdfBlob = pdfBlob;

			this.isLoading = false;
			if (!this.showPdfInFrame) {
				this.download();
			}
		});
	}
	download() {
		/* Open the PDF in a new window */
		// window.open(this.pdfUrl, '_blank', undefined, true);
		const a = document.createElement('a');
		const url = window.URL.createObjectURL(this.pdfBlob);

		const date = new Date();
		const timestamp = date.getTime();

		a.href = url;
		a.download = "form_" + this.customer.name + "_" + this.customer.surname + "_" + timestamp + ".pdf";
		a.target = "_blank";
		a.textContent = "Download PDF";

		a.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));

		setTimeout(() => {
			window.URL.revokeObjectURL(url);
			a.remove();
		}, 150);
	}
	showEmailModal() {
		const modalRef = this.modalService.open(EnterEmailComponent, { windowClass: "modal-show-email", backdropClass: "modal-show-email-backdrop" });
		modalRef.componentInstance.parent = this;
	}
	async sendEmail(recipient: string, callback?: any) {
		this.isQueueing = true;
		/* Send the PDF URL and the recipient email address to the mail server */
		const date = new Date();
		const timestamp = date.getTime();
		const frmData: FormData = new FormData();
		frmData.append('email', recipient);
		frmData.append('subject', "PDF details for " + this.customer.name + " " + this.customer.surname + " as at " + this.datePipe.transform(timestamp, 'yyyy/MM/dd HH:mm:ss'));
		frmData.append('message', "Dear sir/madam \nPlease find attached, the PDF document for " + this.customer.name + " " + this.customer.surname + ". \nWarm Regards");
		frmData.append('attachment', this.pdfBlob);
		frmData.append('attachmentFilename', "form_" + this.customer.name + "_" + this.customer.surname + "_" + timestamp + ".pdf");
		this.customerService.emailDocument(frmData).subscribe(response => {
			if (response.success !== undefined) {
				if (response.success === true) {
					this.notifier.success("Successfully queued email");
					// console.log("Successfully queued email!!!");
				} else {
					console.log("Something went wrong while queueing email!!!");
					console.log(JSON.stringify(response));
					if (response.error) {
						this.notifier.error("An error occurred emailing the PDF: " + response.error);
					}
				}
			}

			if (callback !== undefined) {
				callback();
			}

		});
		this.isQueueing = false;
	}




	get showPdfInFrame(): boolean {
		return environment.show_pdf_in_frame;
	}
}
