import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	Token,
	Agent,
	LoginRequest,
	AuthResponse,
	LinkedLists,
	DataRequest,
	DataResponse,
} from '../../dto/dtos';
import { Storage, KEY_LINKED_LISTS, KEY_ROLE_RULES } from '../../utils/storage';
import { Observable, } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbstractHttpService } from '../http/http-abstract-service';
import { DynamicFieldManager } from '../../settings/dynamic-fields/dynamic-field-manager';
import { Router } from '@angular/router';
import { AxonUtils } from '../../utils/axon-utils';
import { Notifier } from '../../utils/notifier';
import { environment } from '../../../environments/environment';
import { GroupListsManager } from '../../settings/grouplists/group-lists-manager';
import { SessionMonitorService } from '../session/session-monitor.component';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	terms: string;
	username: string;
	securityQuestions: any;
	maskedMsisdn: string;
	maskedEmail: string;
	msisdn: string;
	deliberateUserLogout: boolean = false;

	constructor(
		private httpClient: HttpClient,
		private storage: Storage,
		private dynamicFieldMgr: DynamicFieldManager,
		private groupListsMgr: GroupListsManager,
		private router: Router,
		private notifier: Notifier,
		private sessionMonitor: SessionMonitorService) { }

    /**
     * Fetches the auth token from local storage
     */
	public getAuthToken(): Token {
		return AxonUtils.getAuthToken(this.storage);
	}

    /**
     * Fetches the agent who has authed from local storage
     */
	public getAuthAgent(): Agent {
		return AxonUtils.getAuthAgent(this.storage);
	}

    /**
     * Saves the token and agent to the local storage
     * @param token
     */
	public saveAuth(token: Token, agent: Agent) {
		AxonUtils.saveAuth(token, agent, this.storage);
	}

    /**
     * Clears the auth token and agent from local storage
     */
	public deleteAuth() {
		AxonUtils.deleteAuth(this.storage);
	}

    /**
     * Saves the linked list data to the local storage
     * @param token
     */
	public saveLinkedLists(linkedLists: LinkedLists) {
		if (linkedLists) {
			this.storage.saveSecureItem(KEY_LINKED_LISTS, JSON.stringify(linkedLists));
		}
	}

    /**
     * Save role rules for agents to the local storage
     */
	public saveRoleRules(roleRules: any) {
		if (roleRules) {
			console.log('Saving role rules');
			console.log('Role rule data:', JSON.stringify(roleRules));
			this.storage.saveSecureItem(KEY_ROLE_RULES, JSON.stringify(roleRules));
		}
	}

	public isAuthenticated(): boolean {
		// TODO:  get token to from store and validate
		return this.getAuthToken() != null;
	}

	// public getAuthDataResponse(dataRequest: DataRequest): Observable<AuthResponse> {
	//     return this.httpClient.post<AuthResponse>(AbstractHttpService.BASE_URL + '')
	// }

	public doLogin(loginRequest: LoginRequest, monitorSession: boolean): Observable<AuthResponse> {

		/* As we are logging in, clear any auth tokens */
		this.deleteAuth();
		loginRequest.companyId = environment.company_id;
		loginRequest.browserToken = this.storage.getItem('browser_token','localStorage');
		console.log('Setting browser token:: ', this.storage.getItem('browser_token','localStorage'));

		return this.httpClient.post<AuthResponse>(AbstractHttpService.BASE_URL + '/auth', loginRequest).pipe(
			map(response => {

				console.log(response);
				console.log('=================SERVER RESPONSE====================');
				console.log('Success [' + response.success + ']');
				console.log('Error [' + response.error + ']');
				console.log('Error Category [' + response.errorCategory + ']');
				console.log('Error Code [' + response.errorCode + ']');
				console.log('==================================================');

				if (response.success === true) {

					if (response.dynamicLinks && response.dynamicActions) {
						this.dynamicFieldMgr.saveDynamicSettings(
							response.dynamicFieldVersion, 
							response.dynamicLinks, 
							response.dynamicActions, 
							response.dynamicValidations
						);
					}

					if (response.groupLists) {
						this.groupListsMgr.saveGroupListsData(response.groupListsVersion, response.groupLists);
					}

					this.saveLinkedLists(response.linkedLists);

					this.saveRoleRules(response.roleRules);

					console.log('Logged in agent: ', response.agent);
					// console.log('Saving browser token:: ', response.browserToken);
					this.storage.saveItem('browser_token', response.browserToken,'localStorage');
					this.saveAuth(response.token, response.agent);

					if(monitorSession) {
						this.sessionMonitor.startSessionMonitor(response.token.expiryInSeconds);
					}
					// this.sessionMonitor.connectToSessionWebSocket();

					if (response.killedOtherSession === true) {
						this.notifier.info("You have an existing session that has now been expired. If you have any security concerns contact your administrator");
					}
				}
				return response;
			})
		);
	}

	doLogoutAndRedirect(showWarning?: boolean) {
		console.log("Logging out and redirecting to logout");

		try {
			this.logLogoutAction();
		} catch (exception) {
			console.error(exception);
		} finally {
			this.sessionMonitor.stopSessionMonitor();
			AxonUtils.doLogoutAndRedirect(this.router, this.notifier, this.storage, showWarning);
		}
	}

	logLogoutAction() {

		const agentId = this.getAuthAgent().agentId;

		const dataRequest: DataRequest = {
			agentId: agentId,
			description: 'browser',
			userAction: this.deliberateUserLogout
		};

		this.httpClient.post<DataResponse>(AbstractHttpService.BASE_URL + '/auth/logout', dataRequest).pipe(
			map(response => { return response })
		).subscribe(result => {
			console.log(result);
		});
	}
}
