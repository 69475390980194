import { Component, Inject, ViewChild, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatTabChangeEvent, MatDialogRef } from '@angular/material';
import { ModalContainerComponent } from '../../../component/modal-container/modal-container.component';
import { ServerError } from '../../../utils/server-errors';
import { Customer, Agent } from '../../../dto/dtos';
import { CustomerService } from '../../../services/customers/customer.service';
import { Storage } from '../../../utils/storage';
import { AgentService } from '../../../services/agents/agent.service';
import { MaterialTableData } from '../../../component/material-table/material-table-data';
import { AxonComponent } from '../../../axon.component';
import { Notifier } from '../../../utils/notifier';
import { MaterialTableComponent } from '../../../component/material-table/material-table.component';
import { CustomerModalContainerComponent } from '../../../component/modal-container/customer-modal-container.component';

@Component({
  selector: 'app-customer-modal-historic',
  templateUrl: './customer-modal-historic.component.html',
  styleUrls: ['./customer-modal-historic.component.scss']
})
export class CustomerModalHistoricComponent extends CustomerModalContainerComponent {

    customer: Customer;
    isLoadingResults = true;
    private instance: CustomerModalHistoricComponent;
	private approvalAgent: Agent;
    private parentComponent: AxonComponent;
    private savedSuccessfully: boolean;
    private tableComponent: MaterialTableComponent;

    constructor(
            public dialog: MatDialog,
            @Inject(MAT_DIALOG_DATA) public matData: MaterialTableData,
            private customerService: CustomerService,
            private agentService: AgentService,
            public storage: Storage,
            private notifier: Notifier) {

        super( dialog, storage );

		if ( matData.data ) {
			const pinref = matData.data.pinref;
            this.parentComponent = matData.parent;
            this.tableComponent = matData.tableParent;

			if ( pinref ) {
				console.log('Received customer with pinref: ' + pinref + ". Fetching historic customer entry...");
				this.getCustomer(pinref);
			}
		}

        this.instance = this;
    }

    /**
     * Returns this Component as a Component type - required for opening modals
     */
    getType() {
        return CustomerModalHistoricComponent;
    }

    /**
     * Fetches historic customer data from the server
     * @param agentId
     */
    private getCustomer( pinref: number ) {
        this.isLoadingResults = true;
        this.customerService.getCustomerHistoric(pinref).subscribe(
            resp => {
                console.log('Customer historic fetch successful ? [' + resp.success + ']');
                if ( resp.success ) {
                    console.log('Customer data: ' + JSON.stringify(resp.data));
                    this.customer = resp.data;

                    if ( this.customer.approvalAgent ) {
                        console.log( "Fetching approval agent details" );
                        this.agentService.getApprovalAgentDetails(this.customer.approvalAgent.agentId).subscribe(

                            agentResp => {
                                if ( agentResp.success ) {
                                    this.approvalAgent = agentResp.data;
                                } else {
                                    ServerError.printError(agentResp);
                                }
                            }
                        );
                    }

                } else {
                    ServerError.printError(resp);
                    this.onNoClick();
                    this.notifier.error(ServerError.formatError(resp));
                }
                this.isLoadingResults = false;
            },
            error => {
                this.onNoClick();
                this.notifier.error( ServerError.formatHttpError( error ) );
            }
        );
    }

    onNoClick() {
        this.tableComponent.closeModal();
        if ( this.savedSuccessfully ) {
            this.parentComponent.saveSuccessful();
        }
    }
}
