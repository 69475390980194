import { AxonComponent } from "../axon.component";
import { TermsComponent } from './signup/terms.component';
import { OnInit, Component, OnDestroy } from "@angular/core";
import { AgentService } from "../services/agents/agent.service";
import { DynamicLink, DynamicCard, DynamicField, DataRequest, LoginRequest } from "../dto/dtos";
import { FormGroup, FormControl, ValidatorFn, AsyncValidatorFn, Validators, FormBuilder } from "@angular/forms";
import { AxonErrorStateMatcher } from "../component/form/form.component";
import { DynamicInputField, DynamicCharType, Card, Section } from "../utils/constants";
import { DEFAULT_ROUTE } from '../utils/constants';
import { Notifier } from "../utils/notifier";
import { Storage, KEY_TOKEN_TYPE, VALUE_TOKEN_TYPE_AUTH_ONLY } from '../utils/storage';
import { ActivatedRoute, Router } from "@angular/router";
import { DynamicFieldManager } from "../settings/dynamic-fields/dynamic-field-manager";
import { GroupListsManager } from "../settings/grouplists/group-lists-manager";
import { AuthService } from "../services/auth/auth.service";
import { AxonUtils } from "../utils/axon-utils";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { Subscription } from "rxjs";
import { InfoDialogComponent } from "../component/info-dialog/info-dialog.component";
import { Agent } from "https";
import { ApprovalService } from "../services/approvals/approval.service";
import { ServerError } from "../utils/server-errors";
import { environment, SectionUtils } from "../../environments/environment";
import { PasswordResetModalComponent } from "./password-reset/password-reset.component";

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.scss']
})
export class AuthComponent extends AxonComponent implements OnInit, OnDestroy {

    routeParamsSubscription: Subscription;

    email: string;
    msisdn: string;
    showBackButton = true;
    memorableQuestionsTitle: string = undefined;

    constructor(
        private notifier: Notifier,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private storage: Storage,
        private modalService: NgbModal,
        private dynamicFieldMgr: DynamicFieldManager,
        private groupListsMgr: GroupListsManager,
        private approvalService: ApprovalService,
        // private infoDialogComponent: InfoDialogComponent,
        private agentService: AgentService,
        private authService: AuthService) {

        super();
        this.instance = this;

        this.routeParamsSubscription = this.route.queryParams.subscribe(params => {
            this.returnUrl = params['returnUrl'];
        });
    }

    dynamicLinks: DynamicLink[];
    currentCard: DynamicCard;
    previousCard: DynamicCard;

    form: FormGroup;
    formControlList: Array<FormControl>;
    matcher = new AxonErrorStateMatcher();
    instance: AuthComponent;
    queryParams = {};
    isLoading = false;
    when = {
        sendByMSISDN: true
    };
    cntPasswords = 0;
    SIGN_IN_PASSWORD_CARD: number = Card.AGENT_SIGN_IN_PASSWORD;
    SIGN_IN_USERNAME_CARD: number = Card.AGENT_SIGN_IN_USERNAME;
    SIGN_UP_SECURITY_QUESTIONS_CARD: number = Card.AGENT_SIGN_UP_SECURITY_QUESTIONS;

    returnUrl: string;

    originalSecurityQuestionLovValues: string[] = [];

    btnOpts: MatProgressButtonOptions = {
        active: false,
        text: 'Test',
        // text: this.getBtnTitle(field.field),
        spinnerSize: 19,
        raised: true,
        stroked: false,
        flat: false,
        fab: false,
        // buttonColor: 'accent',
        // spinnerColor: 'accent',
        fullWidth: true,
        disabled: false,
        customClass: 'mt-4 mb-4 btn btn-axon-info btn-lg btn-block text-uppercase waves-effect waves-light btn-axon-info',
        mode: 'indeterminate',
    };

    ngOnInit() {

        /* Fetch the dynamic links for auth from storage first, show the current card */
        this.dynamicLinks = JSON.parse(this.storage.getItem("authfields"));
        if (this.dynamicLinks !== undefined && this.dynamicLinks !== null) {
            this.isLoading = false;

            const linkIndex = this.getLinkIndexForSection(Section.AGENT_SIGN_IN);
            const cardIndex = this.getCardIndexForCard(linkIndex, this.SIGN_IN_USERNAME_CARD);

            if (cardIndex > -1) {
                this.currentCard = this.dynamicLinks[linkIndex].cards[cardIndex];
                this.showDynamicCard(this.dynamicLinks[linkIndex].cards[cardIndex], true);
            }
        }

        /* Now fetch the dynamic links for auth from server, update the card */
        this.agentService.getDataResponse({}, '/authfields', '/dynamicdata').subscribe(result => {
            if (result.dataList !== undefined) {
                this.dynamicLinks = result.dataList;
                this.storage.saveItem("authfields", JSON.stringify(this.dynamicLinks));
                this.isLoading = false;

                const linkIndex = this.getLinkIndexForSection(Section.AGENT_SIGN_IN);
                const cardIndex = this.getCardIndexForCard(linkIndex, this.SIGN_IN_USERNAME_CARD);

                if (cardIndex > -1) {
                    this.currentCard = this.dynamicLinks[linkIndex].cards[cardIndex];

                    this.showDynamicCard(this.dynamicLinks[linkIndex].cards[cardIndex], true);
                }
            }
        });
    }

    ngOnDestroy() {
        if (this.routeParamsSubscription) {
            this.routeParamsSubscription.unsubscribe();
        }
    }

    showDynamicCard(card: DynamicCard, init?: boolean) {
        if(card.id === Card.AGENT_SIGN_UP_SECURITY_QUESTIONS) {
			if(environment.disabledMemorableQuestions === true){
				location.reload();
				return;
			}
            this.showBackButton = false;
			this.memorableQuestionsTitle = "Memorable Questions";
        }
        
        this.btnOpts.active = false;

        /* We want to set the current card in ngOnInit so that we can avoid the expressionChangedAfterItWasChecked error */
        if (init === undefined || init === false) {
            this.previousCard = this.currentCard;
            this.currentCard = card;
        }

        /* Clear all the form controls */
        if (this.form !== undefined && this.form.controls !== undefined) {
            for (let i = 0; i < Object.keys(this.form.controls).length; i++) {
                const controlName = Object.keys(this.form.controls)[i];
                this.form.reset(controlName);
            }
        }
        this.formControlList = new Array<FormControl>();

        /* Count password fields so correct building of password / confirm password can happen */
        this.cntPasswords = 0;
        for (const f of this.currentCard.fields) {
            if (f.inputField === 'password') {
                this.cntPasswords++;
            }
        }

        /* Special for AGENT_SIGN_UP_SECURITY_QUESTIONS - to hold the original lovValues while changing the dropdown fields lovValues */
        if (this.currentCard.id === Card.AGENT_SIGN_UP_SECURITY_QUESTIONS) {
            this.currentCard.fields.forEach(field => {
                if (field.inputField === DynamicInputField.DROPDOWN) {
                    this.originalSecurityQuestionLovValues = field.lovValues;
                }
            });
        }

        for (const field of this.currentCard.fields) {
            if (field.inputField === 'button' && this.getBtnType(field.field) !== 'back') {
                this.btnOpts.text = this.getBtnTitle(field.field);
            }

            const validatorList: Array<ValidatorFn> = new Array();
            const aysncValidatorList: Array<AsyncValidatorFn> = new Array();

            /* Define field minimum */
            if (field.min > 0) {
                validatorList.push(
                    Validators.minLength(field.min)
                );
            }

            /* Define field maximum */
            if (field.max > 0) {
                validatorList.push(
                    Validators.maxLength(field.max)
                );
            }

            /* Define mandatory / optional */
            if (field.mandatory === 1) {
                validatorList.push(
                    Validators.required
                );
            }

            /* Build a regex pattern for input fields based on the dynamic field settings */
            if (field.inputField === DynamicInputField.INPUT) {

                const blockedList = new Array<string>();
                let patternError = "";

                /* Allow letters if field is ALPHA or ALPHANUMERIC. If not, add error to blocked list */
                let pattChars = "";
                if (field.charType === DynamicCharType.ALPHA
                    || field.charType === DynamicCharType.ALPHANUMERIC
                    || field.charType === DynamicCharType.EMAIL) {
                    pattChars += "a-zA-Z";
                } else {
                    blockedList.push("Letters");
                }

                if (field.charType === DynamicCharType.EMAIL) {
                    validatorList.push(Validators.email);
                }

                /* Allow numbers if field is ALPHANUMERIC, MSISDN or NUMERIC. If not, add error to blocked list */
                if (field.charType === DynamicCharType.ALPHANUMERIC
                    || field.charType === DynamicCharType.MSISDN
                    || field.charType === DynamicCharType.NUMERIC
                    || field.charType === DynamicCharType.EMAIL) {
                    pattChars += "0-9";
                } else {
                    blockedList.push("Numbers");
                }

                /* Allow spaces if field allows spaces. If not, add error to blocked list */
                if (field.allowSpaces) {
                    pattChars += " ";
                } else {
                    blockedList.push("Spaces");
                }

                /* Allow special characters if field allows chars. If not, add error to blocked list */
                if (field.charsAllowed) {
                    pattChars += "*#@$%&^!)({}{}';<,>?/\\_.=-";
                } else {
                    blockedList.push("Special characters");
                }

                /*
                TODO - Get sequential chars, which is actually consecutive chars regex to work
                0 = allow any number of sequential chars
                > 1 = allow x number of sequential chars
                */
                // if ( field.sequentialChar > 0 ) {
                //     pattChars += "\\" + field.sequentialChar;
                // }

                // pattChars = "(^[" + pattChars + ")]\\1";


                const pattern = "^[" + pattChars + "]{" + field.min + "," + field.max + "}$";
                // const pattern = "^(?!.*?[ '.-]{2})[A-Za-z0-9 '.-]{1,30}$";

                /* Build the pattern error list */
                let prefix = "";
                for (const blocked of blockedList) {
                    patternError += prefix + blocked;
                    prefix = ", ";
                }

                patternError += " are not allowed. Please check your input";
                field.patternError = patternError;

                validatorList.push(
                    Validators.pattern(pattern)
                );
            }

            /* Assign the form control to the field */
            if (field.inputField === 'password' && this.cntPasswords > 1 && field.field.toLowerCase().indexOf('confirm') > -1) {
                field.formControl = new FormControl(
                    this.formBuilder.group({
                        password: ['', Validators.required],
                        confirmPassword: ['', Validators.required]
                    })
                );
            } else if (field.inputField === 'password' && this.cntPasswords === 1) {
                field.formControl = new FormControl(
                    this.formBuilder.group({
                        password: ['', Validators.required]
                    })
                );
            } else if (field.inputField === 'input') {
                field.formControl = new FormControl(
                    '',
                    validatorList,
                    aysncValidatorList
                );
            } else if (field.inputField === 'tickbox') {
                field.formControl = new FormControl(
                    false, Validators.required
                );
            } else if (field.inputField === 'dropdown') {
                field.formControl = new FormControl(
                    false, Validators.required
                );
            }

            /* Set the form controls value */
            if (field.formControl) {
                if (field.inputField !== 'tickbox') {
                    field.value = (field.value === undefined) ? "" : field.value;
                    field.formControl.setValue(field.value);
                }
                this.formControlList[field.id] = field.formControl;
            }

        }

        /* Create the form group, containing the list of form controls */
        this.form = this.formBuilder.group(this.formControlList);

        /* Show any notifications if there are any */
        for (const field of this.currentCard.fields) {
            if (field.inputField === 'notification') {
                this.showNotification(field);
            }
        }

        if (this.currentCard.id === Card.AGENT_SIGN_UP_SECURITY_QUESTIONS) {

            /* Set the labels for the answer inputs to be the same as the questions selected from the dropdown above it */
            this.setInputLabelsForSecurityQuestionCard();

        } else {

            /* set the labels for the inputs for all other cards except agent sign up security questions */
            this.currentCard.fields.forEach(field => {
                if (field.inputField === DynamicInputField.INPUT) {
                    field.placeholder = field.field;
                }
            });
        }

        if (this.currentCard.id === Card.AGENT_SIGN_UP_PASSWORD_MSISDN || this.currentCard.id === Card.AGENT_SIGN_UP_PASSWORD_EMAIL) {
            const isMSISDN = (this.currentCard.id === Card.AGENT_SIGN_UP_PASSWORD_MSISDN);
            this.sendSignUpOTP(isMSISDN).then(success => { });
        } else if (this.currentCard.id === Card.AGENT_FORGOT_PASSWORD_MSISDN) {
            this.getMaskedMSISDN().then(data => {
                if (data !== undefined && data.trim() !== '') {
                    this.currentCard.fields.forEach(field => {
                        if (field.inputField === 'text') {
                            field.field = field.field.replace('[maskedMSISDN]', data);
                        }
                    });
                }
            });
        } else if (this.currentCard.id === Card.AGENT_FORGOT_PASSWORD_EMAIL) {
            this.getMaskedEmail().then(data => {
                if (data !== undefined && data.trim() !== '') {
                    this.currentCard.fields.forEach(field => {
                        if (field.inputField === 'text') {
                            field.field = field.field.replace('[maskedEmail]', data);
                        }
                    });
                }
            });
        } else if (this.currentCard.id === Card.AGENT_FORGOT_PASSWORD_SECURITY_QUESTIONS) {
            this.getAgentSecurityQuestions().then(data => {
                if (data !== undefined && data.length > 2) {

                    if (data[0] === 'Answer 1') {
                        this.notifier.error('Agent has not set any security questions');
                        this.showCard(Section.AGENT_FORGOT_PASSWORD, Card.AGENT_FORGOT_PASSWORD_MSISDN);
                        return;
                    }

                    let i = 0;
                    this.currentCard.fields.forEach(field => {
                        if (field.inputField === 'input') {
                            field.field = data[i];
                            field.placeholder = data[i];
                            i++;
                        }
                    });
                }
            });
        }
    }

    getBtnTitle(buttonJson: string): string {
        const parsed = JSON.parse(buttonJson);
        return (parsed['text'] === undefined) ? '' : parsed['text'];
    }

    getBtnType(buttonJson: any): string {
        const parsed = JSON.parse(buttonJson);
        return (parsed['type'] === undefined) ? '' : parsed['type'];
    }

    login() {
        if (!this.validateAndMarkDirty()) {
            return;
        }
        this.processQueryParams();

        this.doLogin().then(success => {
            if (success) {

                setTimeout(() => {
                    this.showCurrentDayApprovalStats();
                }, 5000);

                this.router.navigate([this.returnUrl || DEFAULT_ROUTE]);
                this.notifier.success('Welcome ' + this.queryParams['username']);
            }
        });
    }

    next(buttonJson: any) {

        if (!this.validateAndMarkDirty()) {
            return;
        }
        this.processQueryParams();

        this.btnOpts.active = true;

        const parsed = JSON.parse(buttonJson);
        const card_id_to_link_to = 1 * ((parsed.card_id !== undefined) ? parsed.card_id : 0);

        if (card_id_to_link_to !== 0) {

            this.dynamicLinks.forEach((dynamicLink) => {
                dynamicLink.cards.forEach((card) => {
                    if (card.id === card_id_to_link_to) {

                        /* Here we make necessary network calls and either proceed to card or not */
                        if (this.currentCard.id === Card.AGENT_SIGN_IN_USERNAME) {
                            this.checkUsername().then(success => {
                                if (success) {
                                    this.showDynamicCard(card);
                                }
                                this.btnOpts.active = false;
                            });
                        } else if (this.currentCard.id === Card.AGENT_SIGN_UP_USERNAME) {
                            this.signUpUsername().then(success => {
                                if (success) {
                                    this.showDynamicCard(card);
                                }
                                this.btnOpts.active = false;
                            });
                        } else if (this.currentCard.id === Card.AGENT_SIGN_UP_PASSWORD_EMAIL
                            || this.currentCard.id === Card.AGENT_SIGN_UP_PASSWORD_MSISDN) {

                            this.authUserByOTP(false).then(success => {
                                if (success) {
                                    this.updatePassword().then(success2 => {
                                        if (success2) {
                                            this.showDynamicCard(card);
                                        }
                                    });
                                }

                                this.btnOpts.active = false;
                            });
                        } else if (
                            this.currentCard.id === Card.AGENT_FORGOT_USERNAME_MSISDN
                            || this.currentCard.id === Card.AGENT_FORGOT_USERNAME_EMAIL) {

                            this.forgotUsername().then(success => {
                                if (success) {
                                    this.showDynamicCard(card);
                                }
                                this.btnOpts.active = false;
                            });
                        } else if (this.currentCard.id === Card.AGENT_FORGOT_PASSWORD_MSISDN
                            || this.currentCard.id === Card.AGENT_FORGOT_PASSWORD_EMAIL) {

                            this.sendForgotPasswordOTP().then(success => {
                                if (success) {
                                    this.showDynamicCard(card);
                                }
                                this.btnOpts.active = false;
                            });
                        } else if (this.currentCard.id === Card.AGENT_FORGOT_PASSWORD_OTP) {
                            this.authUserByOTP(false).then(success => {
                                if (success) {
                                    this.showDynamicCard(card);
                                }
                                this.btnOpts.active = false;
                            });
                        } else if (this.currentCard.id === Card.AGENT_FORGOT_PASSWORD_SECURITY_QUESTIONS) {
                            this.authUserBySecurityQuestions().then(success => {
                                if (success) {
                                    this.showDynamicCard(card);
                                }
                                this.btnOpts.active = false;
                            });
                        }
                    }
                });
            });
        } else {
            if (this.currentCard.id === Card.AGENT_SIGN_UP_SECURITY_QUESTIONS) {
                this.saveSecurityQuestions().then(success => {
                    if (success) {
                        this.goToReturnUrlOrDefault();
                    }
                    this.btnOpts.active = false;
                });
            } else if (this.currentCard.id === Card.AGENT_FORGOT_PASSWORD_SET_PASSWORD) {
                this.updatePassword().then(success => {
                    if (success) {
                        this.goToReturnUrlOrDefault();
                    }
                    this.btnOpts.active = false;
                });
            }
        }
    }

    showNotification(field: DynamicField) {
        const notificationObject = JSON.parse(field.field);

        if (notificationObject.when !== undefined) {
            let when: string = notificationObject.when;
            const isNot = when.indexOf("!") > -1;
            when = when.replace('!', '');
            if (this.when[when] !== undefined && isNot === true && this.when[when] === false) {
                this.notifier.success(notificationObject.notification);
            }
            if (this.when[when] !== undefined && isNot === false && this.when[when] === true) {
                this.notifier.success(notificationObject.notification);
            }
        } else {
            this.notifier.success(notificationObject.notification);
        }
    }

    processQueryParams() {

        this.currentCard.fields.forEach((field) => {
            if (field.inputField === 'input') {
                this.queryParams[field.field.toLowerCase()] = field.formControl.value;
            }
        });
    }

    sendForgotPasswordOTP(): Promise<Boolean> {

        return new Promise<Boolean>(resolve => {

            let dataRequest: DataRequest = { username: this.queryParams['username'] };

            let fieldName = "";
            if (this.currentCard.id === Card.AGENT_FORGOT_PASSWORD_MSISDN || this.currentCard.id === Card.AGENT_FORGOT_PASSWORD_EMAIL) {

                this.currentCard.fields.forEach(field => {
                    if (field.inputField === 'input') {
                        fieldName = field.field.toLowerCase().trim();
                    }
                });
            }

            if ( fieldName === 'email' && this.queryParams[fieldName] ) {
                this.email = this.queryParams[fieldName];
            } else if ( ( fieldName === 'msisdn' || fieldName === 'service number') && this.queryParams[fieldName] ) {
                this.msisdn = this.queryParams[fieldName];
            }

            dataRequest = (fieldName !== 'email')
                ? { ...dataRequest, msisdn: this.msisdn }
                : { ...dataRequest, identifierStr: this.email };

            this.agentService.getDataResponse(dataRequest, "/confirmmsisdnoremail", '/auth').subscribe(result => {
                if (result.success === true) {
                    this.notifier.success('OTP sent to your registered ' + fieldName + '.');
                    resolve(true);
                } else if (result.error === "MSISDN entered does not match" || result.error === 'Email entered does not match') {
                    this.notifier.error(result.error);
                    resolve(false);
                } else if (result.error === "No email or MSISDN provided") {
                    this.notifier.error("No email or MSISDN provided");
                    resolve(false);
                } else {
                    this.notifier.error('The ' + fieldName + ' given was not found.');
                    resolve(false);
                }
            });
        });
    }

    getAgentSecurityQuestions(): Promise<any> {

        return new Promise<any>(resolve => {

            const dataRequest: DataRequest = { username: this.queryParams['username'] };

            this.agentService.getDataResponse(dataRequest, '/forgotpassword', '/auth').subscribe(result => {
                if (result.errorCode === 0) {
                    resolve(result.data);
                } else if (result.error === 'Agent blocked') {
                    this.notifier.error('Your account has been blocked. Please contact support to be unblocked.');
                    resolve(false);
                } else {
                    this.notifier.error('The username given was not found.');
                    resolve(false);
                }
            });
        });
    }

    getMaskedMSISDN(): Promise<string> {
        return new Promise<string>(resolve => {

            const dataRequest: DataRequest = {
                username: this.queryParams['username']
            };

            this.agentService.getDataResponse(dataRequest, "/fetchmaskedmsisdn", '/auth').subscribe(result => {
                if (result.errorCode === 0) {
                    resolve(result.data);
                } else {
                    if (result.error === 'Agent blocked') {
                        this.notifier.error('Your account has been blocked. Please contact support to be unblocked.');
                    } else if (result.error === 'Agent MSISDN is empty') {
                        this.notifier.error("The agent's MSISDN is empty");
                        this.showCard(Section.AGENT_FORGOT_PASSWORD, Card.AGENT_FORGOT_PASSWORD_EMAIL);
                    } else {
                        this.notifier.error('The username given was not found.');
                    }
                    resolve(undefined);
                }
            });
        });
    }

    getMaskedEmail(): Promise<string> {
        return new Promise<string>(resolve => {

            const dataRequest: DataRequest = {
                username: this.queryParams['username']
            };

            this.agentService.getDataResponse(dataRequest, "/fetchmaskedemail", '/auth').subscribe(result => {
                if (result.errorCode === 0) {
                    resolve(result.data);
                } else {
                    if (result.error === 'Agent blocked') {
                        this.notifier.error('Your account has been blocked. Please contact support to be unblocked.');
                    } else if (result.error === 'Agent email is empty') {
                        this.notifier.error("Agent has not set an email");
                        this.showCard(Section.AGENT_FORGOT_PASSWORD, Card.AGENT_FORGOT_PASSWORD_MSISDN);
                    } else {
                        this.notifier.error('The username given was not found.');
                    }
                    resolve(undefined);
                }
            });
        });
    }

    forgotUsername(): Promise<Boolean> {
        return new Promise<Boolean>(resolve => {

            let dataRequest: DataRequest;
            let fieldName = "";

            if (this.currentCard.id === Card.AGENT_FORGOT_USERNAME_MSISDN) {
                let nameForParam = 'msisdn';
                this.currentCard.fields.forEach(field => {
                    if (field.fieldMapping !== undefined && field.fieldMapping.columnName !== undefined && field.fieldMapping.columnName.toLowerCase().indexOf('msisdn') > -1) {
                        nameForParam = field.field.toLowerCase();
                    }
                });
                dataRequest = { msisdn: this.queryParams[nameForParam] };
                fieldName = nameForParam;
            } else {
                dataRequest = { identifierStr: this.queryParams['email'] };
                fieldName = "email";
            }

            this.agentService.getDataResponse(dataRequest, '/forgotusername', '/auth').subscribe(result => {
                if (result.errorCode === 0) {
                    this.notifier.success('Your username has been sent to your registered ' + fieldName + '.');
                    resolve(true);
                } else if (result.error === 'Agent blocked') {
                    this.notifier.error('Your account has been blocked. Please contact support to be unblocked.');
                    resolve(false);
                } else {
                    this.notifier.error('The ' + fieldName + ' given was not found.');
                    resolve(false);
                }
            });
        });
    }

    saveSecurityQuestions(): Promise<Boolean> {

        const answers = Object.create(null);
        this.currentCard.fields.forEach(field => {
            if (field.inputField === 'input') {
                answers[field.placeholder] = this.queryParams[field.field.toLowerCase()];
            }
        });

        return new Promise<Boolean>(resolve => {
            const dataRequest: DataRequest = { answers, username: this.queryParams['username'] };

            this.agentService.getDataResponse(dataRequest, '/updateagent').subscribe(result => {
                if (result.errorCode === 0) {
                    /* Now that user has completed updating their security questions, we can remove this flag */
                    this.storage.deleteItem(KEY_TOKEN_TYPE);
                    this.notifier.success('Welcome ' + this.queryParams['username']);
                    resolve(true);
                } else {
                    this.notifier.error('Saving security questions failed.');
                    resolve(false);
                }
            });
        });
    }

    /**
     * After auth has been done with otp, then update the agents password
     */
    updatePassword(): Promise<Boolean> {
        return new Promise<Boolean>(resolve => {

            let password;
            if (this.form.controls && this.form.controls.password !== undefined) {
                password = this.form.controls.password.value;
            }

            const dataRequest: DataRequest = { password };

            this.agentService.getDataResponse(dataRequest, '/updateagent').subscribe(result => {
                if (result.errorCode === 0 && result.success === true) {
                    /* Now that user has completed updating their password, we can remove this flag */
                    this.storage.deleteItem(KEY_TOKEN_TYPE);
                    this.notifier.success('Welcome ' + this.queryParams['username']);
                    resolve(true);
                } else if (result.error === "Password strength check failed") {
                    this.notifier.error('Password strength failed. Please try a different password');
                    resolve(false);
                } else if (result.error === "Password history check failed") {
                    this.notifier.error('You must use a password that you have not used before. Please try another password');
                    resolve(false);
                } else if (result.error === 'Agent blocked') {
                    this.notifier.error('Your account has been blocked. Please contact support to be unblocked.');
                    resolve(false);
                } else {
                    this.notifier.error('Password update failed.');
                    resolve(false);
                }
            });
        });
    }

    authUserBySecurityQuestions(): Promise<Boolean> {

        const answers = Object.create(null);
        this.currentCard.fields.forEach(field => {
            if (field.inputField === 'input') {
                answers[field.field] = this.queryParams[field.field.toLowerCase()];
            }
        });

        const loginRequest: LoginRequest = {
            username: this.queryParams['username'],
            memorableAnswers: answers
        };

        return new Promise<Boolean>(resolve => {
            this.authService.doLogin(loginRequest, true).subscribe(result => {
                if (result.success === true) {
                    /* This needs to be unset when we have finished updating our new password */
                    this.storage.saveItem(KEY_TOKEN_TYPE, VALUE_TOKEN_TYPE_AUTH_ONLY);
                    resolve(true);
                } else {
                    if (result.loginAttempts !== null) {
                        if (result.loginAttempts <= 0 || result.error === 'Agent blocked') {
                            this.notifier.error('Your account has been blocked. Please contact support to be unblocked.');
                        } else {
                            const attempts = (result.loginAttempts !== 1) ? ' attempts' : ' attempt';
                            this.notifier.error('The answers provided were incorrect. You have '
                                + result.loginAttempts + attempts + ' remaining to answer your security questions.');
                        }
                    } else if (result.error === "No memorable questions") {
                        this.notifier.error(
                            "There are no memorable questions stored for this user. Please try another way to reset your password."
                        );

                    } else {
                        this.notifier.error("The answers provided were incorrect.");
                    }
                    resolve(false);
                }
            });
        });
    }

    authUserByOTP(monitorSession: boolean): Promise<Boolean> {

        const loginRequest: LoginRequest = {
            username: this.queryParams['username'],
            otp: this.queryParams['otp']
        };

        return new Promise<Boolean>(resolve => {
            this.authService.doLogin(loginRequest, monitorSession).subscribe(result => {
                if (result.success === true && result.error === undefined) {
                    this.storage.saveItem(KEY_TOKEN_TYPE, VALUE_TOKEN_TYPE_AUTH_ONLY);
                    resolve(true);
                } else if (result.error === 'Agent blocked') {
                    this.notifier.error('Your account has been blocked. Please contact support to be unblocked.');
                    resolve(false);
                } else {
                    this.notifier.error(ServerError.formatError(result));
                    resolve(false);
                }
            });
        });
    }

    sendSignUpOTP(isMSISDN: boolean): Promise<Boolean> {

        const dataRequest: DataRequest = {
            identifierStr: ((isMSISDN) ? "SMS" : "EMAIL"),
            username: this.queryParams['username']
        };

        return new Promise<Boolean>(resolve => {
            this.agentService.getDataResponse(dataRequest, '/sendsignupotp', '/auth').subscribe(result => {
                if (result.errorCode === 0) {
                    resolve(true);
                } else if (result.error === 'Agent already signed up') {
                    this.notifier.error('This agent is already signed up.');
                    this.btnOpts.active = false;
                    this.showDynamicCard(AxonUtils.getCardById(Card.AGENT_SIGN_UP_USERNAME, this.dynamicLinks));
                    resolve(false);
                } else {
                    this.notifier.error('The username given was not found.');
                    this.btnOpts.active = false;
                    this.showDynamicCard(AxonUtils.getCardById(Card.AGENT_SIGN_UP_USERNAME, this.dynamicLinks));
                    resolve(false);
                }
            });
        });
    }

    signUpUsername(): Promise<Boolean> {

        const dataRequest: DataRequest = { username: this.queryParams['username'] };

        return new Promise<Boolean>(resolve => {
            this.agentService.getDataResponse(dataRequest, '/signupusername', '/auth').subscribe(result => {
                if (result.error === undefined && result.success === true) {
                    resolve(true);
                } else if (result.error === 'Agent blocked') {
                    this.notifier.error('Your account has been blocked. Please contact support to be unblocked.');
                    this.btnOpts.active = false;
                    resolve(false);
                } else if (result.error === "Agent already signed up") {
                    this.notifier.error('You are already signed up. Please sign in.');
                    this.btnOpts.active = false;
                    const signInCard = AxonUtils.getCardById(Card.AGENT_SIGN_IN_USERNAME, this.dynamicLinks);
                    this.showDynamicCard(signInCard);
                    resolve(false);
                } else if(result.error === "Agent not signed up") {
                    this.notifier.error('You have not completed the sign up process. Please sign up');
                    this.btnOpts.active = false;
                    const signInCard = AxonUtils.getCardById(Card.AGENT_SIGN_UP_USERNAME, this.dynamicLinks);
                    this.showDynamicCard(signInCard);
                    resolve(false);                
                } else {
                    this.notifier.error('The Username was not found.');
                    this.btnOpts.active = false;
                    resolve(false);
                }
            });
        });
    }

    checkUsername(): Promise<Boolean> {

        const dataRequest: DataRequest = { username: this.queryParams['username'] };
        // this.btnOpts.active = false;

        return new Promise<Boolean>(resolve => {
            this.agentService.getDataResponse(dataRequest, '/checkusername', '/auth')
                .subscribe(
                    result => {
                        if (result.success === true) {
                            resolve(true);
                        } else {
                            if (result.error !== undefined && result.error === 'Agent blocked') {
                                this.notifier.error('Your account has been blocked. Please contact support to be unblocked.');
                            } else if (result.error !== undefined && result.error.indexOf('Agent expired') > -1) {
                                this.notifier.error('This Agent is expired. Please contact support.')
                            } else if ( result.error !==   undefined && result.error  === 'Agent not signed up') {
                                console.log('You have not completed the sign up process. Please sign up');
                                this.notifier.error('You have not completed the sign up process. Please sign up');            
                                const signInCard = AxonUtils.getCardById(Card.AGENT_SIGN_UP_USERNAME, this.dynamicLinks);
                                this.showDynamicCard(signInCard);
                                resolve(false);  
							} else if ( result.error !==   undefined && result.error  === 'Password expired') {
                                console.log('Agent password has expired');
                                this.notifier.error('Your password has expired. Please reset your password.');
                                this.showPasswordReset(this.queryParams['username']);
                                resolve(false);                              
                            } else {
                                this.notifier.error('The Username was not found.');
                            }
                            resolve(false);
                        }
                    },
                    error => {
                        console.log('Error here is: ' + JSON.stringify(error));
                    }
                );
        });
    }

	showPasswordReset(username: string) {
		const options: NgbModalOptions = {
			backdrop: 'static',
			keyboard: false,
			// windowClass: "modal-editform-confirmclose",
			// backdropClass: "fraud-backdrop",
			centered: true,
		};
		const modalRef = this.modalService.open(PasswordResetModalComponent, options);
		modalRef.componentInstance.title = "Your '" + username + "' account password has expired. Please enter your current password, and choose a new password";
		modalRef.componentInstance.username = username;
		modalRef.result.then(item => {
			if (item === true) {
				console.log('User reset password');
				return;
			}

			console.log('User has not reset password')
		});
	}

    doLogin(): Promise<Boolean> {

        this.btnOpts.active = true;

        let password;
        if (this.form.controls && this.form.controls.password !== undefined) {
            password = this.form.controls.password.value;
        }

        const loginRequest: LoginRequest = {
            username: this.queryParams['username'],
            password: password,
            dynamicFieldVersion: this.dynamicFieldMgr.getVersion(),
            groupListsVersion: this.groupListsMgr.getVersion()
        };

        return new Promise<Boolean>(resolve => {
            this.authService.doLogin(loginRequest, true).subscribe(result => {
                if (result.success === true) {
                    resolve(true);
                } else {
                    if (result.error === 'Agent not signed up') {
                        this.notifier.error('This agent is not yet signed up.');
                        resolve(false);
                        this.btnOpts.active = false;
					} else if (result.error === 'Password expired') {
						this.notifier.error('Your password has expired. Please reset your password.');
						resolve(false);
						this.btnOpts.active = false;
                    } else if (result.loginAttempts <= 0 || result.error === 'Agent blocked') {
                        this.notifier.error('Your account has been blocked. Please contact support to be unblocked.');
                        resolve(false);
                        this.btnOpts.active = false;
                    } else if (result.loginAttempts !== undefined ) {
                        const attempts = (result.loginAttempts !== 1) ? ' attempts' : ' attempt';
                        this.notifier.error('Invalid credentials. You have '
                            + result.loginAttempts + attempts + ' remaining to enter valid credentials.');
                        resolve(false);
                        this.btnOpts.active = false;
                    } else {
						this.notifier.error(result.error);
                        resolve(false);
                        this.btnOpts.active = false;
					}
                }
            });
        });
    }

    getLinkPartText(linkJson, position) {
        const parsed = JSON.parse(linkJson);
        for (let i = 0; i < parsed.length; i++) {
            if (i === position) {
                return parsed[i].text;
            }
        }
    }

    goToLink(linkJson) {
        let parsed = JSON.parse(linkJson);
        if (!Array.isArray(parsed)) {
            parsed = [parsed];
        }

        for (let i = 0; i < parsed.length; i++) {
            if (parsed[i].card_id !== undefined) {
                const card_id_to_link_to = 1 * ((parsed[i].card_id !== undefined) ? parsed[i].card_id : 0);
                if (card_id_to_link_to !== 0) {
                    this.dynamicLinks.forEach((dynamicLink) => {
                        dynamicLink.cards.forEach((card) => {
                            if (card.id === card_id_to_link_to) {
                                this.showDynamicCard(card);
                            }
                        });
                    });
                } else {
                    if (this.currentCard.id === Card.AGENT_FORGOT_PASSWORD_OTP) {

                        this.sendForgotPasswordOTP().then(success => {
                            if (success) {
                                this.showDynamicCard(this.currentCard);
                            }
                        });
                    } else if (this.currentCard.id === Card.AGENT_SIGN_UP_PASSWORD_EMAIL
                        || this.currentCard.id === Card.AGENT_SIGN_UP_PASSWORD_MSISDN) {

                          if (parsed[0] && parsed[0]['text'].indexOf('OTP') > -1) {

                              const isMsisdn = (this.currentCard.id === Card.AGENT_SIGN_UP_PASSWORD_MSISDN);
                              this.sendSignUpOTP(true).then(success => {
                                  if (isMsisdn) {
                                      this.notifier.success('OTP sent to your registered MSISDN.');
                                  } else {
                                      this.notifier.success('OTP sent to your registered email.');
                                  }
                              });
                          } else {
                              this.openTerms();
                          }
                    } else {
                        this.showDynamicCard(this.previousCard);
                    }
                }
            }
        }
    }

    openTerms() {
        this.modalService.open(TermsComponent);
        // modalRef.componentInstance.name = 'markus';
    }

    getQueryParam(linkJson) {
        const parsed = JSON.parse(linkJson);
        for (let i = 0; i < parsed.length; i++) {
            if (parsed[i].field !== undefined && this.queryParams[parsed[i].field] !== undefined) {

                return this.queryParams[parsed[i].field];
            }
        }
    }

    getLinkParts(linkJson: any) {
        const parsed = JSON.parse(linkJson);
        const parts = [];
        for (let i = 0; i < parsed.length; i++) {
            parts.push(parsed[i]);
        }

        return parts;
    }

    goToReturnUrlOrDefault() {
        if (this.returnUrl !== undefined && this.returnUrl !== null && this.returnUrl.trim() !== '') {
            this.router.navigate([this.returnUrl]);
        } else {
            this.router.navigate([DEFAULT_ROUTE]);
        }
    }

    invalidatePasswordControl(controlName: string) {
        const formControl = this.form.controls[controlName];
        formControl.setErrors(this.form.errors);
    }

    validateAndMarkDirty() {

        if (!this.form.valid) {
            for (let i = 0; i < Object.keys(this.form.controls).length; i++) {
                const controlName = Object.keys(this.form.controls)[i];
                if (this.form.controls[controlName].invalid && this.form.controls[controlName].errors !== null) {
                    this.focusFirstInvalidField();
                    return false;
                }
            }
            if (this.form.errors.notSame) {
                this.focusFirstInvalidField();
                return false;
            }
        }
        return true;
    }

    focusFirstInvalidField() {

        let foundInvalidField = false;
        if (this.form.controls !== undefined) {
            for (let i = 0; i < Object.keys(this.form.controls).length; i++) {

                const controlName = Object.keys(this.form.controls)[i];

                if (this.form.controls[controlName].invalid) {
                    foundInvalidField = true;

                    const nativeElement = (<any>this.form.controls[controlName]).nativeElement;
                    setTimeout(() => {
                        (<any>this.form.controls[controlName]).markAsTouched();
                        nativeElement.focus();
                    });
                    // break after the first invalid field is found
                    break;
                }
            }
        }

        return foundInvalidField;
    }

    /**
    * Helper to return true if the field is a numeric based field
    * @param field
    */
    private isFieldNumeric(field: DynamicField): boolean {
        return field.charType === 'msisdn' || field.charType === 'numeric';
    }

    getCardIndexForCard(linkIndex: number, cardId: number): number {

        let cardIndex = -1;
        if (this.dynamicLinks !== undefined && this.dynamicLinks[linkIndex] !== undefined) {
            this.dynamicLinks[linkIndex].cards.forEach((card, index) => {
                if (card.id === cardId) {
                    cardIndex = index;
                }
            });
        }

        return cardIndex;
    }

    getLinkIndexForSection(section: Section): number {

        let linkIndex = -1;
        if (this.dynamicLinks !== undefined) {
            this.dynamicLinks.forEach((dynamicLink, index) => {
                if (dynamicLink.sectionId === SectionUtils.getSectionId(section)) {
                    linkIndex = index;
                }
            });
        }

        return linkIndex;
    }

    handleDropdownChange(field: DynamicField) {

        /* Remove this option from the other dropdowns */
        if (this.currentCard.id === Card.AGENT_SIGN_UP_SECURITY_QUESTIONS) {

            let availableSecurityQuestions = [...this.originalSecurityQuestionLovValues];
            this.currentCard.fields.forEach(f => {

                if (f.inputField === DynamicInputField.DROPDOWN && f.formControl !== undefined
                    && f.formControl.value !== undefined && f.formControl.value !== '') {

                    const index = availableSecurityQuestions.indexOf(f.formControl.value);
                    if (index > -1) {
                        availableSecurityQuestions.splice(index, 1);
                    }
                }
            });
            const avail = [...availableSecurityQuestions];

            for (let i = 0; i < this.currentCard.fields.length; i++) {
                if (this.currentCard.fields[i].inputField === DynamicInputField.DROPDOWN) {

                    if (this.currentCard.fields[i].formControl !== undefined) {

                        if (this.currentCard.fields[i].formControl.value !== undefined && this.currentCard.fields[i].formControl.value !== '') {
                            let newLovValues = [...avail];
                            newLovValues.push(this.currentCard.fields[i].formControl.value);
                            this.currentCard.fields[i].lovValues = newLovValues;
                        } else {
                            this.currentCard.fields[i].lovValues = [...avail];
                        }
                    }
                }
            }

            this.setInputLabelsForSecurityQuestionCard();
        }
    }

    setInputLabelsForSecurityQuestionCard() {
        let formControls: DynamicField[] = [];
        let count = 0;
        this.currentCard.fields.forEach(f => {
            if (f.inputField === DynamicInputField.INPUT && f.field.toLowerCase().indexOf('answer') > -1) {
                formControls[count] = f;
                count++;
            }
        });

        count = 0;
        this.currentCard.fields.forEach(f => {
            if (f.inputField === DynamicInputField.DROPDOWN && f.field.toLowerCase().indexOf('question') > -1) {

                if (f.formControl !== undefined && f.formControl.value !== undefined && f.formControl.value.trim() !== '') {
                    formControls[count].placeholder = f.formControl.value;
                } else {
                    formControls[count].placeholder = formControls[count].field;
                }
                count++;
            }
        });
    }

    showCard(section: Section, cardId) {
        const linkIndex = this.getLinkIndexForSection(SectionUtils.getSectionId(section));
        const cardIndex = this.getCardIndexForCard(linkIndex, cardId);

        if (cardIndex > -1) {
            this.currentCard = this.dynamicLinks[linkIndex].cards[cardIndex];
            this.showDynamicCard(this.dynamicLinks[linkIndex].cards[cardIndex], false);
        }
    }

    showCurrentDayApprovalStats() {
        const authAgent = this.authService.getAuthAgent();
        this.agentService.getAgent(authAgent.agentId).subscribe(
            resp => {
                console.log('Session agent fetch successful ? [' + resp.success + ']');
                if (resp.success) {
                    console.log('Agent data: ', resp.data);
                    // sessionAgent = resp.data;
                    if (resp.data.isPosCoordinator === true) {
                        // NOW YOU CAN CALL THE BACK_END FOR STATS
                        this.approvalService.getSummarisedApprovalStats().subscribe(
                            stats => {
                                console.log('Fetching summarised approval statistics ? [' + stats.success + ']');
                                if ( stats.success ) {
                                    console.log('Approval data: ' + JSON.stringify(stats.data));
                                    const dialog = this.modalService.open(InfoDialogComponent,
                                        {
                                            windowClass : "modal-standard"
                                        }
                                    );
                                    dialog.componentInstance.title = "Welcome " + resp.data.name;
                                    dialog.componentInstance.messages = [
                                          "Total number of approvals today: " + stats.data.approvalStatsLastHour.approved
                                        , "Total number of rejections today: "  + stats.data.approvalStatsLastHour.rejected
                                        ];
                                    dialog.componentInstance.showCloseButtonFooter = false;
                                    dialog.componentInstance.hasOneMessage = false;
                                    dialog.componentInstance.hasManyMessages = true;
                                } else {
                                    ServerError.printError(stats);
                                }
                            }
                        );
                    }
                } else {
                    // ServerError.printError(resp);
                }
                // this.isLoadingResults = false;
            }
        );
    }
}
