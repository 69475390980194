import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export const CONFIRM = "confirm";
export const CANCEL = "cancel";

@Component({
    selector: 'app-info-dialog',
    templateUrl: './info-dialog.component.html',
    styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent {

    @Input('showCloseButtonFooter') showCloseButtonFooter: boolean = true;
    @Input('title') title: string;
    @Input('hasOneMessage') hasOneMessage: boolean = true;
    @Input('message') message: string;
    @Input('hasManyMessages') hasManyMessages: boolean = false;
    @Input('messages') messages = [];

    constructor(public activeModal: NgbActiveModal) { }

    confirm() {
        this.activeModal.close(CONFIRM);
    }

    cancel() {
        this.activeModal.close(CANCEL);
    }

}
