import { DmsNodeInfoDto } from './../../../dto/dtos';
import { filter, switchMap } from 'rxjs/operators';
import { DmsService } from './../../service/dms.service';
import { Component, Input, OnInit } from '@angular/core';
import { Agent } from '../../../dto/dtos';
import { forkJoin } from 'rxjs';

@Component({
	selector: 'app-dms-breadcrumbs',
	templateUrl: './dms-breadcrumbs.component.html',
	styleUrls: ['./dms-breadcrumbs.component.css']
})
export class DmsBreadcrumbsComponent implements OnInit {

	@Input() agent: Agent;
	// selected: DmsNodeInfoDto;
	// allNodes: DmsNodeInfoDto[];
	// breadcrumbs: DmsNodeInfoDto[];

	// constructor(private dmsService: DmsService) { }

	ngOnInit() {
	// 	this.dmsService.isDmsEnabled()
	// 		.pipe(
	// 			filter(item => item === true),
	// 			switchMap(item => forkJoin([
	// 				this.dmsService.getAgentsDmsNode(this.agent.agentId),
	// 				this.dmsService.getDmsNodesForAgent(this.agent.agentId)
	// 			])))
	// 		.subscribe(results => {
	// 			this.selected = results[0];
	// 			this.allNodes = results[1];
	// 			this.breadcrumbs = this.calcBreadcrumbs()
	// 		});
	}
	// calcBreadcrumbs(): DmsNodeInfoDto[] {
	// 	let previousNode = this.selected;
	// 	const out: DmsNodeInfoDto[] = [this.selected];

	// 	let tmp: DmsNodeInfoDto;
	// 	while (tmp = this.allNodes.filter(item => item.axonId === previousNode.parentId)[0]) {
	// 		out.unshift(tmp);
	// 		previousNode = tmp;
	// 	}
	// 	return out;
	// }

}
