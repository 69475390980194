import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { GroupLists } from '../../../dto/dtos';
import { GroupListsManager } from '../../../settings/grouplists/group-lists-manager';

export const CONFIRM = "confirm";
export const CANCEL = "cancel";

@Component({
    selector: 'app-input-modal',
    templateUrl: './input-modal.component.html',
    styleUrls: ['./input-modal.component.scss']
})
export class InputModalComponent implements OnInit {

    @Input('confirmButton') confirmButton: string;
    @Input('cancelButton') cancelButton: string;
    @Input('title') title: string;
    @Input('message') message: string;
    @Input('label') label: string;
    @Input('error') error: string;
    @Input('groupListsId') groupListsId = 0;
    @Input('isHtml') isHtml: boolean;

    inputFormControl: FormControl;

    private grpValues: GroupLists[];

    constructor(public activeModal: NgbActiveModal, public groupListsMgr: GroupListsManager) {
    }

    ngOnInit() {
        this.inputFormControl = new FormControl();

        if ( this.groupListsId !== 0 ) {
            this.grpValues = this.groupListsMgr.getGroupList( this.groupListsId );
        }
    }

    confirm() {

        if (!this.inputFormControl.invalid) {
            this.activeModal.close(this.inputFormControl.value);
        }
    }

    cancel() {
        this.activeModal.close();
    }

}
