import { Injectable, Component, Input, HostListener, ViewChild, AfterViewInit, OnInit } from "@angular/core";
import { AxonComponent } from "../../axon.component";
import { GoogleChartComponent } from "angular-google-charts";
import { ChartData, Chart } from "../../dto/dtos";

@Injectable({
    providedIn: 'root'
})

@Component({
    selector: 'app-axon-chart',
    templateUrl: './axon-chart.component.html',
    styleUrls: ['./axon-chart.component.scss'],
})
export class AxonChartComponent extends AxonComponent implements OnInit {

    @Input() chart:  Chart;
    options: any;
    data:    Array< any >;

    @ViewChild(GoogleChartComponent) chartComp: GoogleChartComponent;

    constructor() {
        super();
    }

    ngOnInit() {
        console.log( '*****************************************************' );
        console.log( 'Building chart component [' + this.chart.title + ']' );

        console.log ( 'Column(s)', this.chart.columnNames );

        this.data = new Array();
        if ( this.chart.data ) {
            console.log( 'Using Standard Chart Data' );
            for ( const chartData of this.chart.data ) {
                console.log( 'Chart data [' + JSON.stringify( chartData ) + ']' );
                this.data.push(
                    [ chartData.title, chartData.value ]
                );
            }
        } else if ( this.chart.comboData ) {
            
            console.log( 'Using Combo Chart Data' );

            /* Need to add a column into the first index - as the first index is the aggregate */
            this.chart.columnNames.splice( 0, 0, 'Column' );

            for ( const comboData of this.chart.comboData ) {

                const arr = new Array< any >();

                for ( const val of comboData ) {
                    if ( isNaN( val ) ) {
                        arr.push ( val );
                    } else {
                        arr.push ( parseInt( val, 10 ) );
                    }
                }

                this.data.push( arr );
            }
        }
        
        const params: Array< string > = this.chart.params.split(";");

        this.options = {};
        for ( const p of params ) {
            if ( p ) {
                console.log( 'Param: [' + p + ']' );
                const name:  string       = p.split("=")[0];
                const value: Array< any > = p.split("=")[1].split(":");

                if ( name === 'colours' ) {
                    this.options.colors = value;
                } else if ( name === 'hAxis' ) {
                    this.options.hAxis = {
                        title: value[0]
                    };
                } else if ( name === 'vAxis' ) {
                    this.options.vAxis = {
                        title: value[0]
                    };
                } else if ( name === 'seriesType' ) {
                    this.options.seriesType = value[0];
                } else if ( name === 'series' ) {
                    this.options.series = {
                        2: { type: value[0] }
                    };
                }
            }
        }

        console.log( 'Options: ' + JSON.stringify(this.options) );
        console.log( '*****************************************************' );
    }

    // @HostListener('window:resize', ['$event'])
    // onWindowResize(event: any) {
    //     // let selection = this.chartComp.wrapper.getView().getSelection();
    //     // this.chartComp.wrapper.draw();
    //     // this.chartComp.wrapper.getView().setSelection(selection);
    //     // // you can remove two lines that preserve selection if you don't need them
    //     // console.log( 'screen resize' );
    // }

    
}