import { Component, Input } from "@angular/core";
import { AxonComponent } from "../../../../../axon.component";
import { Customer } from "../../../../../dto/dtos";

@Component({
	selector: 'app-address',
	templateUrl: './address.component.html',
	styleUrls: ['./address.component.scss']
})
export class AddressComponent extends AxonComponent {

    @Input() customer: Customer;
    
    constructor() {
        super();
    }
}