import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DynamicField, Image } from '../../../dto/dtos';
import { GroupListsManager } from '../../../settings/grouplists/group-lists-manager';
import { TermsConditionsSignature } from './terms-conditions-signature.component';


@Component({
  selector: 'app-display-terms-conditions-signing',
  templateUrl: 'display-terms-conditions-signing.component.html',
  styleUrls: ['./display-terms-conditions-signing.component.scss'],
})
export class DisplayTermsConditionsSigning {

	@Input() image: Image;
	@Output() acceptance = new EventEmitter();

	tcsClickLabel: string;
	tcsUrl: string;
	tcsTitle: string;

	constructor(private modalService: NgbModal, private grpListMgr: GroupListsManager) {}

	getTCsClickLabel(): String {

		if (!this.tcsClickLabel) {
			this.tcsClickLabel = this.grpListMgr.getGroupList(this.image.field.lov)[0].text;
		}
		return this.tcsClickLabel;
	}

	private getTCsTitle(): String {
		if ( !this.tcsTitle ) {
			this.tcsTitle = this.grpListMgr.getGroupList(this.image.field.lov)[2].text;
		}
		return this.tcsTitle;
	}

	openTCs() {

		if ( !this.tcsUrl ) {
			this.tcsUrl = this.grpListMgr.getGroupList(this.image.field.lov)[1].text;
		}

		let ngbModalOptions: NgbModalOptions = {
			backdrop : 'static',
			keyboard : false,
			windowClass: "modal-cust-img-upload"
	  	};

		const modalRef = this.modalService.open(TermsConditionsSignature, ngbModalOptions);
		modalRef.componentInstance.url = this.tcsUrl;
		modalRef.componentInstance.title = this.getTCsTitle();
		modalRef.componentInstance.acceptance.subscribe(($e) => {
			console.log('Customer acceptance of terms and conditions', $e);
			this.acceptance.emit({'image_field_id': this.image.fieldId, 'signature': $e.signature});
		});
	}
}
