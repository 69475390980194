import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Image } from '../../../dto/dtos';
import { GroupListsManager } from '../../../settings/grouplists/group-lists-manager';
import { SignatureComponent } from './signature.component';


@Component({
  selector: 'app-signature-box',
  templateUrl: 'signature-box.component.html',
  styleUrls: ['./signature-box.component.scss'],
})
export class SignatureBoxComponent {

	@Input() image: Image;
	@Output() signature = new EventEmitter();

	tcsTitle: string;

	constructor(private modalService: NgbModal, private grpListMgr: GroupListsManager) {}

	openSignature() {

		let ngbModalOptions: NgbModalOptions = {
			backdrop : 'static',
			keyboard : false,
			windowClass: "modal-cust-img-upload"
	  	};

		const modalRef = this.modalService.open(SignatureComponent, ngbModalOptions);
		modalRef.componentInstance.title = this.image.field.field;
		modalRef.componentInstance.signature.subscribe(($e) => {
			console.log('Customer signature', $e);
			this.signature.emit({'image_field_id': this.image.fieldId, 'signature': $e.signature});
		});
	}
}
