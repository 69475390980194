import { Injectable, Component, AfterViewInit } from "@angular/core";
import { AxonComponent } from "../../../axon.component";
import { DashBoardService } from "../../../services/dashboard/dashboard.service";
import { Chart, ChartRow } from "../../../dto/dtos";

export interface ChartExample {
    title: string;
    type: string;
    data: Array<Array<any>>;
    columnNames: Array<string>;
    options: any;
}

@Injectable({
    providedIn: 'root'
})

@Component({
    // selector: 'app-users',
    templateUrl: './chart-test.component.html',
    styleUrls: ['./chart-test.component.scss'],
})
export class ChartTestComponent extends AxonComponent {

    gaugeCanvasWidth: number = 0;

    gaugeMin: '0';
    gaugeMax: '500';

    gaugeRow:   Array< any >;
    chartsRow1: Array<ChartExample>;
    chartsRow2: Array<ChartExample>;

    charts: Array< ChartRow >;
    

    constructor(private dashBoardService: DashBoardService) {
        super();

        this.buildLocalGauges();
        this.buildLocalChart();
        this.getCharts();
    }

    getCharts() {
        this.dashBoardService.getCharts().subscribe(resp => {
            console.log('System dashboard chart fetch successful ? [' + resp.success + ']');
            if ( resp.success ) {
                const chartList: Array< Chart > = resp.dataList;
                // console.log( JSON.stringify( chartList ) );

                this.charts = new Array();

                for ( const chart of chartList ) {
                    
                    const row = chart.row;
                    let foundRow = false;
                    for ( const chartRow of this.charts ) {
                        if ( chartRow.row === row ) {
                            const chartsInRow = chartRow.charts;
                            chartsInRow.push( chart );
                            foundRow = true;
                        }
                    }

                    if ( !foundRow ) {
                        const chartsInRow = new Array< Chart >();
                        chartsInRow.push( chart );
                        const chartRow: ChartRow = {
                            row: chart.row,
                            charts: chartsInRow
                        };
                        this.charts.push( chartRow );
                    }
                }

                for ( const chartRow of this.charts ) {
                    console.log( "Row [" + chartRow.row + "] - Number of charts [" + chartRow.charts.length + "]" );
                    for ( const chart of chartRow.charts ) {
                        console.log( 'Chart [' + chart.title + '] - type [' + chart.type + ']' );
                    }
                }
            }
        });
    }

    buildLocalGauges() {
       
    }

    buildLocalChart() {
        const barChart = {
            title: 'Title',
            type: 'BarChart',
            data: [
                ['London', 8136000],
                ['New York', 8538000],
                ['Paris', 2244000],
                ['Berlin', 3470000],
                ['Kairo', 19500000],
            ],
            columnNames: [
                'City', 'Inhabitants'
            ],
            options: {
                colors: ['#e0440e', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6'],
                is3D: false,
                // width: '100%'
            }
        };

        const pieChart = {
            title: 'Title',
            type: 'PieChart',
            data: [
                ['London', 8136000],
                ['New York', 8538000],
                ['Paris', 2244000],
                ['Berlin', 3470000],
                ['Kairo', 19500000],
            ],
            columnNames: [
                'City', 'Inhabitants'
            ],
            options: {
                colors: ['#e0440e', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6'],
                is3D: false
            }
        };

        const lineChart = {
            title: 'Title',
            type: 'LineChart',
            data: [
                ['London', 8136000],
                ['New York', 8538000],
                ['Paris', 2244000],
                ['Berlin', 3470000],
                ['Kairo', 19500000],
            ],
            columnNames: [
                'City', 'Inhabitants'
            ],
            options: {
                colors: ['#e0440e', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6'],
                is3D: false
            }
        };

        const columnChart = {
            title: 'Title',
            type: 'ColumnChart',
            data: [
                ['London', 8136000],
                ['New York', 8538000],
                ['Paris', 2244000],
                ['Berlin', 3470000],
                ['Kairo', 19500000],
            ],
            columnNames: [
                'City', 'Inhabitants'
            ],
            options: {
                colors: ['#e0440e', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6'],
                is3D: false
            }
        };

        this.chartsRow1 = new Array();
        this.chartsRow2 = new Array();
        this.chartsRow1.push(barChart);
        this.chartsRow1.push(pieChart);
        this.chartsRow2.push(lineChart);
        this.chartsRow2.push(columnChart);
    }

    /*
    const chartWrapper = new google.visualization.ChartWrapper({
            chartType: 'PieChart',
            dataTable: new google.visualization.DataTable({
                "cols": [
                    { "label": "Country", "type": "string" },
                    { "label": "# of Devices", "type": "number" }
                ],
                "rows": [
                    { "c": [{ "v": "Canada" }, { "v": 33 }] },
                    { "c": [{ "v": "Mexico" }, { "v": 33 }] },
                    { "c": [{ "v": "USA" }, { "v": 34 }] }
                ]
            })
        });
        */

}