import { Component, OnInit, Input } from '@angular/core';
import { Customer } from '../../../dto/dtos';
import { AxonUtils } from '../../../utils/axon-utils';
import { CustomerService } from '../../../services/customers/customer.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EnterEmailComponent } from '../enter-email-address/enter.email.component';
import { Notifier } from '../../../utils/notifier';
import { DatePipe } from '@angular/common';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-download-pdf',
    templateUrl: './download-pdf.component.html',
    styleUrls: ['./download-pdf.component.scss']
})
export class DownloadPdfComponent implements OnInit {

    @Input() customer: Customer;

    isLoading = true;
    isQueueing = false;
    pdfUrl;
    safePdfUrl;

    env = environment;

    pdf: Blob;
    public recipientEmail: string;

    constructor(
        public datepipe: DatePipe,
        private customerService: CustomerService,
        private modalService: NgbModal,
        private notifier: Notifier) {
    }

    ngOnInit() { }

    loadData() {
        const mediaType = 'application/pdf';

        setTimeout(() => {
            this.customerService.getPDFFormFilled(this.customer.axonId).subscribe(response => {

                this.pdf = new Blob([<any>response], { type: mediaType });
                console.log(this.pdf);

                this.pdfUrl = URL.createObjectURL(
                    this.pdf
                );
                this.safePdfUrl = AxonUtils.domSanitizer.bypassSecurityTrustResourceUrl(this.pdfUrl);

                this.isLoading = false;

                if (this.env.show_pdf_in_frame === false) {
                    this.download();
                }
            });
        }, 1000);
    }

    download() {
        /* Open the PDF in a new window */
        // window.open(this.pdfUrl, '_blank', undefined, true);
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(this.pdf);

        const date = new Date();
        const timestamp = date.getTime();

        a.href = url;
        a.download = "form_" + this.customer.name + "_" + this.customer.surname + "_" + timestamp + ".pdf";
        a.target = "_blank";
        a.textContent = "Download PDF";

        a.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));

        setTimeout(() => {
            window.URL.revokeObjectURL(url);
            a.remove();
        }, 150);
    }

    showEmailModal() {
        const modalRef = this.modalService.open(EnterEmailComponent, { windowClass: "modal-show-email", backdropClass: "modal-show-email-backdrop" });
        modalRef.componentInstance.parent = this;
    }

    async sendEmail(callback?: any) {
        this.isQueueing = true;
        /* Send the PDF URL and the recipient email address to the mail server */
        const date = new Date();
        const timestamp = date.getTime();
        const frmData: FormData = new FormData();
        frmData.append('email', this.recipientEmail);
        frmData.append('subject', "PDF details for " + this.customer.name + " " + this.customer.surname + " as at " + this.datepipe.transform(timestamp, 'yyyy/MM/dd HH:mm:ss') );
        frmData.append('message', "Dear sir/madam \nPlease find attached, the PDF document for " + this.customer.name + " " + this.customer.surname + ". \nWarm Regards");
        console.log("*"+this.pdf.size);
        frmData.append('attachment', this.pdf);
        frmData.append('attachmentFilename', "form_" + this.customer.name + "_" + this.customer.surname + "_" + timestamp + ".pdf");
        this.customerService.emailDocument(frmData).subscribe(response => {
            if (response.success !== undefined) {
                if (response.success === true) {
                    this.notifier.success("Successfully queued email");
                    // console.log("Successfully queued email!!!");
                } else {
                    console.log("Something went wrong while queueing email!!!");
                    console.log(JSON.stringify(response));
                    if (response.error) {
						this.notifier.error("An error occurred emailing the PDF: " + response.error);
					}
                }
            }

            if (callback !== undefined) {
                callback();
            }

        });
        this.isQueueing = false;
    }
}
