import { AbstractHttpService } from './http-abstract-service';
import { DataRequest, DataResponse } from '../../dto/dtos';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Notifier } from '../../utils/notifier';

export class HttpDataService extends AbstractHttpService {

	constructor(httpClient: HttpClient, authService: AuthService, context: string, notifier: Notifier) {
		super(httpClient, authService, context, notifier);
	}

	getBlob(dataRequest: DataRequest, path?: string): Observable<Blob> {
		return super.createBlobPost(dataRequest, path);
	}
	sendData(dataRequest: DataRequest, path?: string, contentType?: string): Observable<DataResponse> {
		return super.createPost(dataRequest, path, contentType);
	}

	sendFile(formData: FormData, path?: string): Observable<DataResponse> {
		return super.createFilePost(formData, path);
	}
	doGet<T>(path: string): Observable<T> {
		return super.createGet(path);
	}

	doGetWithHeader<T>(path: string, headers: HttpHeaders): Observable<T> {
		return super.createGet(path, headers);
	}
	doPost<T>(path: string, payload: any): Observable<T> {
		return super.createPost(payload, path);
	}
}
