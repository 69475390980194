import { DynamicFieldManager } from './../../../settings/dynamic-fields/dynamic-field-manager';
import { FormControl } from '@angular/forms';
import { LinkedListDropdownComponent, RETURN_TYPE_ID, RETURN_TYPE_VALUE } from './../../../component/linked-list-dropdown/linked-list-dropdown.component';
import { Node, ReportParameterDto } from './../../../dto/dtos';
import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-report-parameter-filter-organization',
	templateUrl: './report-parameter-filter-organization.component.html',
	styleUrls: ['./report-parameter-filter-organization.component.css']
})
export class ReportParameterFilterOrganizationComponent implements OnInit {
	@Input() parameter: ReportParameterDto;
	@Input() initValue: string;
	@Output() change = new EventEmitter();
	@ViewChild(LinkedListDropdownComponent) orgLinkedList: LinkedListDropdownComponent;

	orgs: Array<Node>;
	formControl = new FormControl();
	selectedOrganizationId: number;
	selectedOrganizationName: string;

	constructor(private dynamicFieldMgr: DynamicFieldManager) { }

	ngOnInit() {
		console.log("Setting Org from params");
		this.orgs = this.dynamicFieldMgr.getLinkedListData().orgs;
		this.orgLinkedList.setReturnType(RETURN_TYPE_ID);
	}
	setSelectedValue(value: string): void {
		try {
			this.orgLinkedList.setReturnType(RETURN_TYPE_ID);
			const id = this.orgLinkedList.getValue();
			this.orgLinkedList.setReturnType(RETURN_TYPE_VALUE);
			const name = this.orgLinkedList.getValue();
			this.orgLinkedList.setReturnType(RETURN_TYPE_ID);

			this.selectedOrganizationId = id;
			this.selectedOrganizationName = name;
			this.change.emit({ parameter: this.parameter, value: id });
		} catch (e) {
			this.selectedOrganizationName = value;
		}
	}
}
