import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ReportQueryResultDto } from '../../../dto/dtos';
import * as Highcharts from 'highcharts';
import * as moment from "moment";

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);


@Component({
	selector: 'app-report-display-column-line',
	templateUrl: './report-display-column-line.component.html',
	styleUrls: ['./report-display-column-line.component.css']
})
export class ReportDisplayColumnLineComponent implements OnInit {
	public options: Highcharts.Options;
	@Input() title = '';
	@Input() data: ReportQueryResultDto;

	@ViewChild('container') container: ElementRef;

	constructor() { }

	ngOnInit(){
		console.log('Data', this.data);
		const that = this;
		const xAxisDetails = this.extractXAxisDetails(this.data);

		const options = {
			chart: {
				type: 'column'
			},
			title: {
				text: this.title
			},
			credits: {
				enabled: false
			},
			tooltip: {
				formatter: function (input) {
					return that.getTooltip(this, input);
				}
			},
			xAxis: {},
			yAxis: this.getYAxis(this.data, xAxisDetails.type, xAxisDetails.index),
			series: this.getDataSeries(this.data, xAxisDetails.type, xAxisDetails.index)
		};

		if (xAxisDetails.type === 'datetime') {
			options.xAxis = {
				type: xAxisDetails.type,
				labels: {
					formatter: function () {
						return Highcharts.dateFormat('%e %b %y', this.value);
					}
				}
			};
		} else if (xAxisDetails.type === 'category') {
			options.xAxis = {
				type: xAxisDetails.type,
				categories: that.getXAxisCategories(this.data, xAxisDetails.index)
			};
		}
		this.options = options as Highcharts.Options;
		console.log("Highcharts:", this.options);
		Highcharts.chart(this.container.nativeElement, this.options);
	}
	private getTooltip(point: any, input: Highcharts.Tooltip): string {
		console.log("Point", point);
		return `${point.x} : ${point.y}`;
	}
	private getXAxisCategories(data: ReportQueryResultDto, xAxisColumnIndex: number ): string[] {
		const out = [];

		for (let j = 0; j < data.rows.length; j++) {
			out.push(data.rows[j].data[xAxisColumnIndex]);
		}

		return out;
	}

	private getDataSeries(data: ReportQueryResultDto, xAxisDetailType: string, xAxisDetailIndex: number): Highcharts.SeriesOptionsType[] {
		const out: Highcharts.SeriesOptionsType[] = [];

		for (let i = 0; i < data.columns.length; i++) {
			if (i === xAxisDetailIndex) {
				continue;
			}
			const seriesData = { name: data.columns[i], data: [], type: 'column' };
			for (let j = 0; j < data.rows.length; j++) {
				seriesData.type = (out.length === 0 ? 'column' : 'spline');
				if (xAxisDetailType === 'datetime') {
					seriesData.data.push({
						x: moment(data.rows[j].data[xAxisDetailIndex], 'YYYY-MM-DD HH:mm:ss').toDate(),
						y: +data.rows[j].data[i]
					});
				} else {
					seriesData.data.push(+data.rows[j].data[i]);
				}
			}
			out.push(seriesData as Highcharts.SeriesOptionsType);
		}
		return out;
	}

	private getYAxis(data: ReportQueryResultDto, xAxisDetailType: string, xAxisDetailIndex: number) {
		const yAxis1 = xAxisDetailIndex === 0 ? 1 : (xAxisDetailIndex === 1 ? 0 : 2);
		const yAxis2 = xAxisDetailIndex === 0 ? 2 : (xAxisDetailIndex === 1 ? 2 : 1);
		return [
			{
				title: {
					text: data.columns[yAxis1],
					style: {
						color: Highcharts.getOptions().colors[0]
					}
				}
			},
			{
				title: {
					text: data.columns[yAxis2],
					style: {
						color: Highcharts.getOptions().colors[1]
					}
				},
				opposite: true
			}
		];
	}


	private extractXAxisDetails(data: ReportQueryResultDto): { type: 'datetime' | 'category' | 'linear' | 'logarithmic' | 'treegrid', name: string, index: number } {
		for (let i = 0; i < data.types.length; i++) {
			if (data.types[i].toLowerCase() === 'timestamp') {
				return { type: 'datetime', name: data.columns[i], index: i };
			}
			if (data.types[i].toLowerCase() === 'datetime') {
				return { type: 'datetime', name: data.columns[i], index: i };
			}
			if (data.types[i].toLowerCase() === 'string') {
				return { type: 'category', name: data.columns[i], index: i };
			}
		}
	}
}
