import { Component, OnInit, Inject, EventEmitter, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { ModalContainerComponent } from '../modal-container/modal-container.component';
import { Storage } from '../../utils/storage';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface DialogData {
    title: string;
    input?: string;
    negative?: string;
    positive?: string;
}

@Component({
    selector: 'app-confirm-dialog-2',
    templateUrl: './confirm-dialog-2.component.html',
    styleUrls: ['./confirm-dialog-2.component.scss']
})

export class ConfirmDialog2Component implements OnInit {

	@Input() question = 'Are you sure you wish to proceed?';
    @Input() title    = 'Confirm';
    @Input() negative = 'Cancel';
    @Input() positive = 'Apply';
	
    instance: ConfirmDialog2Component;

    // tslint:disable-next-line: max-line-length
    constructor(
		public activeModal: NgbActiveModal) {
    }

    ngOnInit() { }

    getType() {
        return ConfirmDialog2Component;
    }

    onNegative() {
        this.activeModal.close(false);
    }

    onPositive() {
        this.activeModal.close(true);
    }
}