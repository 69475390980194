import { Injectable, Component, Input, ViewChild, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { ReportComponent } from "./report.component";
import { Node, LinkedLists, Permission } from "../dto/dtos";
import { FormControl } from "@angular/forms";
import { LinkedListDropdownComponent, RETURN_TYPE_ID, RETURN_TYPE_VALUE } from "../component/linked-list-dropdown/linked-list-dropdown.component";
import { Notifier } from "../utils/notifier";
import { SystemService } from "../services/system/system.service";
import { ServerError } from "../utils/server-errors";
import { DynamicFieldManager } from "../settings/dynamic-fields/dynamic-field-manager";
import { Permission as PermissionEnum } from '../utils/constants';
import { AxonUtils } from '../utils/axon-utils';
import { AuthService } from "../services/auth/auth.service";
import { MetabaseReports, environment } from "../../environments/environment";

export interface ReportType {
    name: string;
	id:   number;
	permission: PermissionEnum;
}

export interface ReportCard {
    name: string;
    list: Array< ReportType >;
}

@Injectable({
	providedIn: 'root'
})
@Component({
	selector: 'app-report-list',
	templateUrl: './report-list.component.html',
	styleUrls: ['./report-list.component.scss'],
})
export class ReportTypeComponent implements AfterViewInit {

    private reportList: Array< ReportCard >;
    reportId: number;
	reportTitle: string;
	reportPermission: PermissionEnum;
	formControl: FormControl;
	readonly permissionEnum: PermissionEnum;

	showOrg = environment.show_orgs;

    orgs: Array< Node >;

    @ViewChild(LinkedListDropdownComponent) orgLinkedList: LinkedListDropdownComponent;

    constructor(private router: Router, private notifier: Notifier, private dynamicFieldMgr: DynamicFieldManager, private authService: AuthService) {

        this.formControl = new FormControl();

        this.orgs = new Array();
        this.orgs.push(
            {
                id: 100,
                value: 'First',
                children: [
                    {
                        id: 1000,
                        value: 'Second',
                        children: null,
                        axonInternalId: 1
                    }
                ],
                axonInternalId: 2
            }
        );

        this.buildList();

        const linkedListData: LinkedLists = this.dynamicFieldMgr.getLinkedListData();
        this.orgs = linkedListData.orgs;
    }

    ngAfterViewInit(): void {
        this.orgLinkedList.setReturnType( RETURN_TYPE_ID );
    }

	setReport(id: number, title: string, permission: PermissionEnum) {
        this.reportId = id;
		this.reportTitle = title;
		this.reportPermission = permission;
    }

    runReport() {

        if ( !this.reportId ) {
			this.notifier.warn( "Please choose a report" );
			return;
        }

		// Check permission
		if (!AxonUtils.hasPermission(this.reportPermission, this.authService.getAuthAgent().permissions)) {
			this.notifier.warn( "You do not have the necessary permission to run the selected report" );
			return;
		}

		let orgId = null;
		let orgName = null;
		if ( this.showOrg ) {
			orgId = this.orgLinkedList.getValue();
			this.orgLinkedList.setReturnType( RETURN_TYPE_VALUE );
			orgName = this.orgLinkedList.getValue();
			this.orgLinkedList.setReturnType( RETURN_TYPE_ID );
			console.log( "Running report ID [" + this.reportId + "]. Optional org ID [" + orgId + "]" );
		}
		this.router.navigate(['/report'], { queryParams: { id: this.reportId, org_id: orgId, org_name: orgName, title: this.reportTitle } } );

    }

    private buildList() {
		this.reportList = MetabaseReports.buildList();
    }

    public getReportList(): Array< ReportCard > {
        return this.reportList;
    }
}
