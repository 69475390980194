import { Injectable } from "@angular/core";
import { HttpDataService } from "../http/http-data.service";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "../auth/auth.service";
import { AxonService } from "../axon-service";
import { FilterOption } from "../../component/material-table/material-table.component";
import { Observable } from "rxjs";
import { DataResponse, DataRequest, Role, AgentRolePermission, RolesConfigRequest, LinkedLists, AuthResponse } from "../../dto/dtos";
import { map } from "rxjs/operators";
import { AxonUtils } from "../../utils/axon-utils";
import { Notifier } from "../../utils/notifier";


@Injectable({
    providedIn: 'root'
})

export class SystemRolesService extends AxonService {

    private httpService: HttpDataService;
    roles: any[];

    constructor(_httpClient: HttpClient, _authService: AuthService, _notifier: Notifier) {
        super(_httpClient, _authService, _notifier);
    }

    addNewRole(roleName: any, roleType: any, detailedDescription: any): Observable<DataResponse> {
        const dataRequest: DataRequest = {
            identifierStr: roleName,
            type: roleType,
            description: detailedDescription
        };

        return this.getDataResponse(dataRequest, '/addrole');
    }

    /**
     * Fetches all roles.
     *
     * @param filterOptions
     */
    public getRoles(filterOptions?: FilterOption[]): Observable<DataResponse> {
        const dataRequest: DataRequest = {
            filterOptions: filterOptions
        };

        return this.getDataResponse(dataRequest, '/getroles');
    }

    getPermissionsByRole(role: Role): Observable<DataResponse> {
        const dataRequest: DataRequest = {
            identifierStr: role.role
        };

        return this.getDataResponse(dataRequest, '/allpermissionsbyrole');
    }

    updateRolePermissions(role: Role, rightList: string[]): Observable<DataResponse> {
        const dataRequest: DataRequest = {
            identifierStr: role.role,
            identifierStringsList: rightList
        };

        return this.getDataResponse(dataRequest, '/updatepermissions');
    }

    updatePermissionsExpiryDates(requestObj: Role): Observable<DataResponse> {
        const dataRequest: RolesConfigRequest = {
            role: requestObj
        }

        return this.getDataResponse(dataRequest, '/updatepermsexpiry');
    }

    /**
     * Helper to do bulk delete actions. Kept flexible to allow addition of more actions in future
     * @param path
     * @param arg1
     * @param parsedRecords
     */
    public bulkAction(path: string, actionList: Role[], reason?: string): Promise<any> {
        let dataRequest: RolesConfigRequest = { bulkAction: path, roles: actionList , reason };
        return new Promise<any>(resolve => {
            this.getDataResponse(dataRequest, '/bulkrolesaction', '/rolesconfig').subscribe(result => {
                if (result.error === undefined && result.success === true) {
                    resolve('success');
                } else if (result.error && result.success === true) {
                    /* Some records were not actioned but some were */
                    resolve('partial');
                } else {
                    resolve('failed');
                }
            });
        });
    }

    /**
     * This will readd all the linked list data into storage from the server
     */
    updateRoles(): Observable<DataResponse> {
        const dataRequest: DataRequest = {};

        this.httpService = new HttpDataService(this.httpClient, this.authService, "/rolesconfig", this.notifier);
        return this.httpService.sendData(dataRequest, "/updateroles").pipe(
            map(response => {
                this.authService.saveLinkedLists( response.data );
                return response;
            })
        );
    }

    /**
     * Helper to get the observable containing the response
     *
     * @param dataRequest
     * @param subpath - the subpath following the rootpath
     * @param rootpath - if null, will be set to /rolesconfig
     */
    private getDataResponse(dataRequest: DataRequest, subpath: string, rootpath?: string): Observable<DataResponse> {
        /* If not defined, set to /rolesconfig i.e. the default root path */
        if (!rootpath) {
            rootpath = '/rolesconfig';
        }

        this.httpService = new HttpDataService(this.httpClient, this.authService, rootpath, this.notifier);
        return this.httpService.sendData(dataRequest, subpath).pipe(
            map(response => {

                console.log(response);

                // if (response.data) {
                //     if (response.data.dateProvisioned) {
                //         response.data.dateProvisioned = AxonUtils.getDate(response.data.dateProvisioned);
                //     }
                // }

                if (response.dataList) {
                    response.dataList.forEach(row => {
                        row.elementId = row.role;
                    });
                }

                return response;
            })
        );
    }

    // strMapToObj(strMap: any) {
    //     let obj = Object.create(null);
    //     for (let [k, v] of strMap) {
    //         // We don’t escape the key ''
    //         // which can cause problems on older engines
    //         obj[k] = v;
    //     }
    //     return obj;
    // }
}
