import { ReportParameterDto } from './../../../dto/dtos';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-report-parameter-filter-date',
	templateUrl: './report-parameter-filter-date.component.html',
	styleUrls: ['./report-parameter-filter-date.component.css']
})
export class ReportParameterFilterDateComponent implements OnInit {
	@Input() parameter: ReportParameterDto;
	@Input() initValue: string;
	@Output() change = new EventEmitter();
	_value: Date;

	constructor() { }

	ngOnInit() {
		if (this.initValue) {
			this._value = new Date(this.initValue);
		}
	}

	get name(): string {
		return this.parameter.name;
	}
	get value(): Date {
		return this._value;
	}
	set value(input: Date) {
		console.log('Value:', input);
		this._value = input;
		if (this._value && this._value !== null) {
			this.change.emit({ parameter: this.parameter, value: this._value.toISOString() });
		} else {
			this.change.emit({ parameter: this.parameter, value: null });
		}
	}

	get minDate(): Date {
		return this.getNowWithOffset(this.parameter.max, -1, 'min');
	}
	get maxDate(): Date {
		return this.getNowWithOffset(this.parameter.min, -1, 'max');
	}
	private getNowWithOffset(offset: number, direction: number, tmp: string): Date {
		if (offset === undefined || offset === null) {
			return undefined;
		}
		const offsetInMillis = offset * 1000 * 60 * 60 * 24 * direction;
		const now = new Date().getTime();
		const out = new Date(now + offsetInMillis);
		return out;
	}
}
