import { Injectable } from '@angular/core';
import * as constants from './constants';
import { Crypto } from './crypto';
import { environment } from '../../environments/environment';

/* Define all storage keys here */
export const KEY_TOKEN = "token";
export const KEY_TOKEN_EXPIRY = "token-expiry";
export const KEY_TOKEN_TYPE = "token-type";
export const VALUE_TOKEN_TYPE_AUTH_ONLY = "auth-only";
export const KEY_AGENT = "agent";
export const KEY_AGENT_PERMISSIONS = 'agent-perm';
export const KEY_DYN_FIELD_VERSION = 'dynamic-fields-version';
export const KEY_DYN_FIELD_DATA = 'dynamic-fields-data';
export const KEY_DYN_FIELD_ACTIONS = 'dynamic-fields-actions';
export const KEY_DYN_FIELD_VALIDATIONS = 'dynamic-fields-validations';
export const KEY_OPEN_MODALS = 'open-modals';
export const KEY_LINKED_LISTS = 'linked-lists';
export const KEY_ROLE_RULES = 'role-rules';
export const KEY_GROUP_LISTS_VERSION = 'group-lists-version';
export const KEY_GROUP_LISTS = 'group-lists';
export const KEY_ADDED_AGENT_IDS = 'added-agent-ids';
export const KEY_CONFIGURATION = 'configuration';
export const KEY_SYSTEM_INFO_VERSION = 'system-info-version';

@Injectable({
	providedIn: 'root'
})
export class Storage {

	private localItems: Map<string, object>;

	constructor( private crypto: Crypto ) {}

	/**
	 * Saves an item locally under the given key, in memory, not storage.
	 * Useful for JSON objects that cannot be stringified due to circular references.
	 * @param key
	 * @param obj
	 */
	public saveLocalItem(key: string, item: any) {
		if (!this.localItems) {
			this.localItems = new Map<string, object>();
		}

		this.localItems.set(constants.LOCAL_STORAGE_PREFIX + key, item);
	}

	/**
	 * Returns an object from memory storage linked to the given key
	 * @param key
	 */
	public getLocalItem(key: string): any {
		if (this.localItems) {
			return this.localItems.get(constants.LOCAL_STORAGE_PREFIX + key);
		}

		return null;
	}

	/**
	 * Deletes an item from memory
	 * @param key
	 */
	public deleteLocalItem(key: string) {
		if (this.localItems) {
			this.localItems.delete(constants.LOCAL_STORAGE_PREFIX + key);
		}
	}

	/**
	 * Helper method to save items to local storage
	 * @param key
	 * @param value
	 */
	public saveItem(key: string, value: string, storage?: 'localStorage'|'sessionStorage') {
		this.getStorage(storage).setItem(constants.LOCAL_STORAGE_PREFIX + key, value);
	}

	/**
	 * Helper method to fetch items from storage
	 * @param key
	 */
	public getItem(key: string, storage?: 'localStorage'|'sessionStorage'): string {
		return this.getStorage(storage).getItem(constants.LOCAL_STORAGE_PREFIX + key);
	}

	/**
	 * Removes an item from the local storage
	 * @param key
	 */
	public deleteItem(key: string) {
		this.getStorage().removeItem(constants.LOCAL_STORAGE_PREFIX + key);
	}

	/**
	 * Helper method to encrypt data and save items securely
	 * @param key
	 * @param value
	 */
	public saveSecureItem(key: string, value: string) {
		this.getStorage().setItem(constants.LOCAL_STORAGE_PREFIX + key, this.crypto.encryptData(value));
	}

	/**
	 * Helper method to return decrypted data stored securely
	 * @param key
	 */
	public getSecureItem(key: string): string {
		let data = this.getStorage().getItem(constants.LOCAL_STORAGE_PREFIX + key);
		if (data !== null) {
			return this.crypto.decryptData(data);
		}

		return null;
	}
	private getStorage(storage?: 'localStorage' | 'sessionStorage') {
		if (storage === undefined || storage === null) {
			storage = environment.storage as 'localStorage' | 'sessionStorage';
		}
		if (storage === 'localStorage') {
			return localStorage;
		}
		if (storage === 'sessionStorage') {
			return sessionStorage;
		}
		console.warn('Returning Storage (null)', storage);
		return null;
	}
}
