import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NotLoginAuthGuardService implements CanActivate {
	
	constructor(private authService: AuthService, private router: Router) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		console.log(`Accessing:[${state.url}]`);
		if (!this.authService.isAuthenticated()) {
			console.log(`Not Authenticated:: Showing:[${state.url}]`);
			return true;
		}
		console.log(`Authenticated.  Tried to access:[${state.url}]`);
		this.router.navigate(['']);
		return false;
	}
	
}
