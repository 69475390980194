import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ChurnService } from './churn.service';
import { ChurnComponent } from '../../churn/churn.component';
import { AxonComponent } from '../../axon.component';
import { AuthService } from '../auth/auth.service';
import { Notifier } from '../../utils/notifier';
import { UploadComponent } from '../../component/upload/upload.component';
import { ServerError } from '../../utils/server-errors';
import { environment } from '../../../environments/environment';
import { AxonSpinnerComponent } from '../../component/spinner/spinner.component';

export const ACTION_CHURN   = 'Churn';
export const ACTION_UNCHURN = 'Unchurn';

@Component({
  selector: 'app-churn-file-upload',
  templateUrl: 'churn-file-upload.component.html',
  styleUrls: ['./churn-file-upload.component.scss'],
})
export class ChurnFileUploadComponent extends AxonComponent implements OnInit {

    status: string = "";
    instance: ChurnFileUploadComponent;
    @ViewChild(UploadComponent) child;

	environment = environment;
	closeEcwAccount = false;
	isLoading = false;

    parsedRecords = [];

	MAX_MSISDNS_PER_FILE = 50000;

    @Input('action') action: 'Churn' | 'Unchurn' = 'Churn';
    @Input('parent') parent: ChurnComponent;

    title = 'Upload a TXT or CSV file of Customer MSISDNs seperated by a new line to churn.';
    errors;
    agentIds: any;

    ngOnInit(): void {
        if (this.action === ACTION_UNCHURN) {
            this.title = 'Upload a TXT or CSV file of Customer MSISDNs seperated by a new line to unchurn.';
        }
    }

    constructor(
        private churnService: ChurnService,
        private authService: AuthService,
        private notifier: Notifier,
        public activeModal: NgbActiveModal,
		private modalService: NgbModal) {
        super();
        this.instance = this;
     }

    sendFile($event) {
		this.child.setManualProgress(0);
        this.parsedRecords = [];
        /* Get all the agent records in the file contents */

		const lines = $event.result.match(/[^\r\n]+/g);;
		if (lines.length > this.MAX_MSISDNS_PER_FILE) {
			this.notifier.error('File must not exceed ' + this.MAX_MSISDNS_PER_FILE + ' entries. The uploaded file contains ' + lines.length);
			return;
		}

		this.child.setManualProgress(50);
        this.parsedRecords = lines;
		console.log('Received [' + this.parsedRecords.length + '] MSISDN(s)');
		// let idx = 0;
        // for (let i = lines.length - 1; i >= 0; i--) {
		// 	const l = lines[i];
		// 	// console.log("Line " + i, l.split(/[^0-9]/));

		// 	l.split(/[^0-9]/).map(item => item.trim()).filter(item => item.length > 0).forEach(item => {
		// 		// console.log("Line: " + i + " - SendFile contained MSISDN:", item);
		// 		console.log('Processing line: ', item);
        //         this.parsedRecords.push(item);
		// 	});
			
		// 	idx++;
		// 	let progress = Math.round(idx/lines.length * 100);
		// 	console.log('progress in churn:', progress);
		// 	this.child.setManualProgress(progress);
        // }

		

		// lines.forEach(item => {
		// 	console.log('Processing line: ', item);
        //     this.parsedRecords.push(item);
		// 	idx++;
		// 	let progress = Math.round(idx/lines.length * 100);
		// 	console.log('progress in churn:', progress);
		// 	this.child.setManualProgress(progress);
		// });

		console.log('Processed [' + this.parsedRecords.length + '] MSISDN(s)');

        this.child.setRecordCount(this.parsedRecords.length);
        this.child.setParseSuccess(this.parsedRecords.length > 0);

        /* Check for duplicate rows, show message when they are removed */
        // const unique = this.parsedRecords.filter(function(elem, index, self) {
        //     return index === self.indexOf(elem);
        // });
        // if (this.parsedRecords.length !== unique.length) {
        //     this.notifier.warn("Please note that duplicate MSISDNs were removed.");
        //     this.parsedRecords = unique;
        // }

        this.child.setManualProgress(100);
    }

    submit() {
		const spinnerModal = this.showSpinner("Creating churn batch...");
        this.churnService.createBatch(this.action, this.parsedRecords, this.closeEcwAccount).subscribe(
            resp => {
                try {
                    console.log('Created churn successfully ? [' + resp.success + ']');
                    if ( resp.success ) {
                        this.notifier.success(this.action + " batch created successfully");
                        this.parent.uploadSuccessful();
                    } else {
                        ServerError.printError(resp);
                        this.notifier.error(ServerError.formatError(resp));
                    }
                } finally {
                    spinnerModal.close();
                }
            },
            error => {
                this.notifier.error( ServerError.formatHttpError( error ) );
				spinnerModal.close();
            }
        );
    }

    showSpinner(message: string): NgbModalRef {
		const options: NgbModalOptions = {
			backdrop: 'static',
			centered: true,
			keyboard: false,
			windowClass: 'spinner-modal'
        };

        const spinnerModal = this.modalService.open(AxonSpinnerComponent, options);
        spinnerModal.componentInstance.message = message;
		return spinnerModal;
	}
}
