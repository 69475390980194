import { Component, OnInit } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { TableData } from '../../../component/table/table.component';
import { DataResponse } from '../../../dto/dtos';
import { AgentService } from '../../../services/agents/agent.service';

@Component({
	selector: 'app-table-test',
	templateUrl: './tabletest.component.html',
	styleUrls: ['./tabletest.component.css']
})
export class TableTestComponent {

    page: number = 1;
    pageMax: number = 10;
	resp: Observable<DataResponse>;
    tableTitle: string;

	constructor(private agentService: AgentService) {

		this.tableTitle = 'Users';
        //this.resp = this.getObservableTableData();
        // this.resp = this.userService.getData(this.page, this.pageMax);
    }
    
    private getObservableTableData(): Observable<DataResponse> {
        let out = new ReplaySubject<DataResponse>(1);

        console.log('Returning data for page [' + this.page + ']');

        if ( this.page == 1 ) {
            let dt: DataResponse = {
                tableData: this.tableData1
            }
            out.next(dt);
            return out;
        } else if ( this.page == 2 ) {
            let dt: DataResponse = {
                tableData: this.tableData2
            }
            out.next(dt);
            return out;
        } else {
            let dt: DataResponse = {
                tableData: this.tableData3
            }
            out.next(dt);
            return out;
        }

    }

    onPageChange(e) {
        if (e) {
            this.page = e;
            console.log('page [' + this.page + ']');

            if ( this.page >= 1 && this.page <= 3 ) {
                this.resp = this.getObservableTableData();
            }
        }
    }

    tableData1: TableData = {
        headers: [{cell: 'Name'}, {cell: 'Surname'}, {cell: 'MSISDN'}, {cell: 'Username'}, {cell: 'ID'}],
        rows: [
            [
                {cell: 'JC'},
                {cell: 'Wouters'},
                {cell: '0827211093'},
                {cell: 'jcwouters'},
                {cell: '151789798'}
            ],
            [
                {cell: 'Lisa'},
                {cell: 'Wouters'},
                {cell: '0741855593'},
                {cell: 'lrwouters'},
                {cell: '123456789'}
            ],
            [
                {cell: 'Yusuf'},
                {cell: 'Tammy'},
                {cell: '18917891789'},
                {cell: 'ytammywammy'},
                {cell: '123456789daf32432'}
            ],
            [
                {cell: 'Jason'},
                {cell: 'Kaplan'},
                {cell: '0827211093'},
                {cell: 'jcwouters'},
                {cell: '151789798'}
            ],
            [
                {cell: 'Markus'},
                {cell: 'Nel'},
                {cell: '0741855593'},
                {cell: 'lrwouters'},
                {cell: '123456789'}
            ],
            [
                {cell: 'Thys'},
                {cell: 'Herbst'},
                {cell: '18917891789'},
                {cell: 'ytammywammy'},
                {cell: '123456789daf32432'}
            ],
            [
                {cell: 'Shaun'},
                {cell: 'Clay'},
                {cell: '0827211093'},
                {cell: 'jcwouters'},
                {cell: '151789798'}
            ],
            [
                {cell: 'Vimbai'},
                {cell: 'Chatitai'},
                {cell: '0741855593'},
                {cell: 'lrwouters'},
                {cell: '123456789'}
            ],
            [
                {cell: 'Raps'},
                {cell: 'Maroane'},
                {cell: '18917891789'},
                {cell: 'ytammywammy'},
                {cell: '123456789daf32432'}
            ],
            [
                {cell: 'Justin'},
                {cell: 'Lipshitz'},
                {cell: '18917891789'},
                {cell: 'ytammywammy'},
                {cell: '123456789daf32432'}
            ]
        ],
        collectionSize: 10
    };

    tableData2: TableData = {
        headers: [{cell: 'Name'}, {cell: 'Surname'}, {cell: 'MSISDN'}, {cell: 'Username'}, {cell: 'ID'}],
        rows: [
            [
                {cell: '2nd page'},
                {cell: 'Wouters'},
                {cell: '0827211093'},
                {cell: 'jcwouters'},
                {cell: '151789798'}
            ],
            [
                {cell: 'Lisa'},
                {cell: 'Wouters'},
                {cell: '0741855593'},
                {cell: 'lrwouters'},
                {cell: '123456789'}
            ],
            [
                {cell: 'Yusuf'},
                {cell: 'Tammy'},
                {cell: '18917891789'},
                {cell: 'ytammywammy'},
                {cell: '123456789daf32432'}
            ],
            [
                {cell: 'Jason'},
                {cell: 'Kaplan'},
                {cell: '0827211093'},
                {cell: 'jcwouters'},
                {cell: '151789798'}
            ],
            [
                {cell: 'Markus'},
                {cell: 'Nel'},
                {cell: '0741855593'},
                {cell: 'lrwouters'},
                {cell: '123456789'}
            ],
            [
                {cell: 'Thys'},
                {cell: 'Herbst'},
                {cell: '18917891789'},
                {cell: 'ytammywammy'},
                {cell: '123456789daf32432'}
            ],
            [
                {cell: 'Shaun'},
                {cell: 'Clay'},
                {cell: '0827211093'},
                {cell: 'jcwouters'},
                {cell: '151789798'}
            ],
            [
                {cell: 'Vimbai'},
                {cell: 'Chatitai'},
                {cell: '0741855593'},
                {cell: 'lrwouters'},
                {cell: '123456789'}
            ],
            [
                {cell: 'Raps'},
                {cell: 'Maroane'},
                {cell: '18917891789'},
                {cell: 'ytammywammy'},
                {cell: '123456789daf32432'}
            ],
            [
                {cell: '2nd page'},
                {cell: 'Lipshitz'},
                {cell: '18917891789'},
                {cell: 'ytammywammy'},
                {cell: '123456789daf32432'}
            ]
        ],
        collectionSize: 10
    };

    tableData3: TableData = {
        headers: [{cell: 'Name'}, {cell: 'Surname'}, {cell: 'MSISDN'}, {cell: 'Username'}, {cell: 'ID'}],
        rows: [
            [
                {cell: '3rd'},
                {cell: 'Wouters'},
                {cell: '0827211093'},
                {cell: 'jcwouters'},
                {cell: '151789798'}
            ],
            [
                {cell: 'Lisa'},
                {cell: 'Wouters'},
                {cell: '0741855593'},
                {cell: 'lrwouters'},
                {cell: '123456789'}
            ],
            [
                {cell: 'Yusuf'},
                {cell: 'Tammy'},
                {cell: '18917891789'},
                {cell: 'ytammywammy'},
                {cell: '123456789daf32432'}
            ],
            [
                {cell: 'Jason'},
                {cell: 'Kaplan'},
                {cell: '0827211093'},
                {cell: 'jcwouters'},
                {cell: '151789798'}
            ],
            [
                {cell: 'Markus'},
                {cell: 'Nel'},
                {cell: '0741855593'},
                {cell: 'lrwouters'},
                {cell: '123456789'}
            ],
            [
                {cell: 'Thys'},
                {cell: 'Herbst'},
                {cell: '18917891789'},
                {cell: 'ytammywammy'},
                {cell: '123456789daf32432'}
            ],
            [
                {cell: 'Shaun'},
                {cell: 'Clay'},
                {cell: '0827211093'},
                {cell: 'jcwouters'},
                {cell: '151789798'}
            ],
            [
                {cell: 'Vimbai'},
                {cell: 'Chatitai'},
                {cell: '0741855593'},
                {cell: 'lrwouters'},
                {cell: '123456789'}
            ],
            [
                {cell: 'Raps'},
                {cell: 'Maroane'},
                {cell: '18917891789'},
                {cell: 'ytammywammy'},
                {cell: '123456789daf32432'}
            ],
            [
                {cell: '3rd'},
                {cell: 'Lipshitz'},
                {cell: '18917891789'},
                {cell: 'ytammywammy'},
                {cell: '123456789daf32432'}
            ]
        ],
        collectionSize: 10
    };

}
