import { Component, Input, OnInit, ElementRef } from '@angular/core';
import { FileUploader, FileItem } from 'ng2-file-upload';

@Component({
    selector: 'raw-file-upload',
    templateUrl: './raw-file-upload.component.html',
    styleUrls: ['./raw-file-upload.component.scss']
})
export class RawFileUploadComponent implements OnInit {

    @Input() public url: string;
    @Input() public maxFileSize: number;
    @Input() public allowedMimeTypes: string[];
    @Input() public manualProgressBar;
    @Input() parentComponent: any;

    recordCount = 0;
    parseSuccess = false;
    uploaded = false;
    operationCompleted = false;

    public uploader: FileUploader;
    reader: FileReader = new FileReader();
    public hasBaseDropZoneOver = false;
    public manualProgress = 1;

    selectedFiles: FileList;
    currentFile: File;

    public constructor(private eleRef: ElementRef) { }

    ngOnInit(): void {

        this.uploader = new FileUploader({ url: this.url, maxFileSize: this.maxFileSize, allowedMimeType: this.allowedMimeTypes });

        this.uploader.onAfterAddingFile = (fileItem: any) => {
            this.recordCount = 0;
            this.parseSuccess = false;
            this.uploaded = true;
            this.setManualProgress(50);

            this.uploader.uploadItem(fileItem);
            console.log(fileItem._file);
        };

        this.uploader.onWhenAddingFileFailed = (item: any, filter: any, options: any) => {

            if (filter && filter.name === 'mimeType') {
                let error = "Mime type uploaded is not allowed. Please use ";
                for (let i = 0; i < this.allowedMimeTypes.length; i++) {
                    error += (i < this.allowedMimeTypes.length - 2) ? this.allowedMimeTypes[i] + " or " : this.allowedMimeTypes[i];
                }
                this.parentComponent.sendError(error);
            }
        };

        this.uploader.uploadItem = (item: FileItem) => {
            this.currentFile = item._file;
            this.parentComponent.sendFile(this.currentFile);
        };
    }

    selectFile(event) {

    }

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    public browse() {
        this.eleRef.nativeElement.querySelector('#upload').click();
    }

    public setRecordCount(count: number) {
        this.recordCount = count;
    }

    public setParseSuccess(success: boolean) {
        this.parseSuccess = success;
    }

    public setManualProgress(percent: number) {
        this.manualProgress = percent;
    }
}
