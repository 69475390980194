import { GroupListRequest, AppDataRequest, PasswordStrengthRequest, DataRequest, DynamicField } from './../../dto/dtos';
import { Injectable } from '@angular/core';
import { AbstractHttpService } from './http-abstract-service';
import { DataResponse } from '../../dto/dtos';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Notifier } from '../../utils/notifier';

export class HttpSystemService extends AbstractHttpService {

  constructor(httpClient: HttpClient, authService: AuthService, context: string, notifier: Notifier) {
    super(httpClient, authService, context, notifier);
  }

  /**
   * Serves fetching multiple or individual agents. Depends on the path, and DataRequest
   * @param dataRequest
   * @param path
   */

  get(path: string): Observable<DataResponse> {
    return super.createGet(path);
  }
	
  post(data, path): Observable<DataResponse> {
    return super.createPost(data, path);
  }	

  getUnwrappedData<T>(path: string): Observable<T> {
    return super.createGet(path);
  }

  addNewGroupList(dr: GroupListRequest, path: string): Observable<DataResponse> {
    return super.createPost(dr, path);
  }

  removeGroupList(dr: GroupListRequest, path: string): Observable<DataResponse> {
    return super.createPost(dr, path);
  }

  updateGroupList(dr: GroupListRequest, path: string): Observable<DataResponse> {
    return super.createPost(dr, path);
  }

  updateAppData(dr: AppDataRequest, path: string): Observable<DataResponse> {
    return super.createPost(dr, path);
  }

  updatePasswordStrength(dr: PasswordStrengthRequest, path: string): Observable<DataResponse> {
    return super.createPost(dr, path);
  }

  searchAuditTrail(dr: DataRequest, path: string): Observable<DataResponse> {
    return super.createPost(dr, path);
  }

  postDynamicFieldRequest(dr: DynamicField, path: string): Observable<DataResponse> {
    return super.createPost(dr, path);
  }
}
