import { Input, EventEmitter, Output, Component } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Server } from "http";
import { forkJoin } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { DynamicCard, DynamicField, DynamicLink, DynamicValidationDataDto, DynamicValidationDto } from "../../../dto/dtos";
import { AuthService } from "../../../services/auth/auth.service";
import { DynamicValidationService } from "../../../services/dynamic-validation/dynamic-validation.service";
import { DynamicFieldManager } from "../../../settings/dynamic-fields/dynamic-field-manager";
import { ActionRule, DynamicInputField } from "../../../utils/constants";
import { Notifier } from "../../../utils/notifier";
import { DynamicActionService } from "../../form/dynamic-action/dynamic-action.service";
import { EMPTY } from "rxjs";

export class AddButtonData {
	dropdowns: Array<DynamicField>;
	value: DynamicField;
	label: string;
}


/**
 * Type(s) need to be dropdown
 * Value has to be text field
 * 
 * if multiple dropdowns, the "Type" would be set to {dropdown_value_1}/{dropdown_value_2}/{dropdown_value_3}
 */
 @Component({
    selector: 'app-add-button-component',
    templateUrl: './add-button.component.html',
    styleUrls: ['./add-button.component.scss']
})
export class AddButtonComponent {
	payload: DynamicCard;
	dropdowns: DynamicField[] = [];
	value: DynamicField;
	addButton: DynamicField;

	form: FormGroup;

	@Output() onChange = new EventEmitter<Array<DynamicField>>();

	validating = false;

	data: AddButtonData[] = [];
	

	constructor(
		private dynamicActionService: DynamicActionService,
		private dynamicFieldManager: DynamicFieldManager,
		private dynamicValidationService: DynamicValidationService,
		private authService: AuthService, 
		private formBuilder: FormBuilder,
		private notifier: Notifier) { }

	@Input()
	set dynamicCard(input: DynamicCard) {

		this.payload = input;
		this.dropdowns = input.fields
			.filter(item => item.inputField === DynamicInputField.DROPDOWN || item.inputField === DynamicInputField.DROPDOWN_FILTER)
			.map(item => new DynamicField(item));
		this.value = input.fields
			.filter(item => item.inputField === DynamicInputField.INPUT)
			.map(item => new DynamicField(item))[0];
		this.addButton = input.fields.filter(item => item.inputField === DynamicInputField.ADD_BUTTON)[0];

		const formControls = this.dropdowns.map(item => {
			item.formControl = new FormControl();

			return item.formControl;
		})

		this.value.formControl = new FormControl();
		formControls.push(this.value.formControl);

		this.form = this.formBuilder.group(formControls);
	}

	onClick($event) {

		this.changeInputState(false);

		const allFields = this.dropdowns.map(item => item);
		allFields.push(this.value);
		allFields.push(this.addButton);

		const instructions = this.dynamicActionService.getInstructions(this.addButton.id, null, allFields);

		const validations = instructions.filter(item => item.actionRule === ActionRule.VALIDATE);

		if (validations.length === 0) {
			this.add();
			return;
		}

		const data = allFields.map(item => {
			return {
				fieldId: item.id,
				value: '' + item.formControl.value
			}
		});

		this.validating = true;

		const observables = validations
			.map(item => this.dynamicFieldManager.getDynamicValidation(+item.value))
			.map(item => {
				if ( !item ) {
					console.log('No valid dynamic validation found');
					return EMPTY;
				}
				return this.dynamicValidationService.validate(this.authService.getAuthAgent().agentId, item.id, data);
			});
		
		forkJoin(observables)
			.pipe(
				catchError(error => {
					this.validating = false;
					console.log(error);
					this.notifier.error(error);
					this.changeInputState(true);
					throw error;
				})
			)
			.subscribe(result => {
				console.log('result', result)
				this.validating = false;
				this.changeInputState(true);
				if (result.filter(item => !item.valid).length === 0) {
					this.add();
					return;
				}
				this.value.formControl.setErrors({ 'luhn': true });
				this.notifier.error(result[0].message);
			});

	}


	private add() {
		this.data.unshift({
			dropdowns: this.dropdowns.map(item => {
				return { id: item.id, value: item.formControl.value }
			}),
			value: { id: this.value.id, value: this.value.formControl.value },
			label: this.dropdowns.map(item => item.formControl.value).join('/')
		});

		// reset the inputs (dropdowns and value)
		this.form.reset();

		this.fireEvent();
	}

	private changeInputState(editable: boolean) {
		this.value.editable = (editable ? 1 : 0);
		this.dropdowns.map(item => {
			item.editable = (editable ? 1 : 0);
		})
	}

	deleteItem(item: AddButtonData) {
		this.data.splice(this.data.indexOf(item), 1);

		this.fireEvent();
	}

	private fireEvent() {

		const fieldList: Array<DynamicField> = this.dropdowns.map(item => { return {id: item.id, lovValues: []} });
		fieldList.push({id: this.value.id, lovValues: []});

		this.data.forEach(item => {
			for (let i = 0; i < fieldList.length - 1; i++) { 
				fieldList[i].lovValues.push(item.dropdowns[i].value);
			}
			fieldList[fieldList.length - 1].lovValues.push(item.value.value);
		});
		this.onChange.emit(fieldList);
	}
}