import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-checked-pdf-download-link',
	templateUrl: './checked-pdf-download-link.component.html',
	styleUrls: ['./checked-pdf-download-link.component.scss']
})
export class CheckedPdfDownloadLinkComponent implements OnInit {
	@Output() clicked = new EventEmitter();
	checked = false;

	constructor() { }

	ngOnInit() {
	}
	onClick() {
		this.clicked.emit();
	}
}
