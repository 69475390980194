import { Component, OnInit, ViewChild, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SafeUrl } from '@angular/platform-browser';
import { AxonUtils } from '../../../utils/axon-utils';
import { SignaturePad } from '../../../shared/signature-pad.component';
import { Notifier } from '../../../utils/notifier';


@Component({
  selector: 'app-signature',
  templateUrl: 'signature.component.html',
  styleUrls: ['./signature.component.scss'],
})
export class SignatureComponent implements AfterViewInit {

	@Input() title: string;
	@Output() signature = new EventEmitter();

	isLoadingTerms = true;

	tcUrl: SafeUrl;

	@ViewChild(SignaturePad) signaturePad: SignaturePad;
	signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
		'minWidth': 5,
		'canvasWidth': 462,
		'canvasHeight': 245
	};
	    
	constructor(private modalService: NgbModal, public activeModal: NgbActiveModal, private notifier: Notifier) {}

	ngAfterViewInit() {
		
	}

	doneLoading() {
		this.isLoadingTerms = false;
	}

	closeModal() {
        this.activeModal.close();
    }

	ok() {
		
		if (this.signaturePad.isEmpty()) {
			this.notifier.warn("Please sign in the designated area to confirm your acceptance of the T&Cs");
			return;
		}

		this.signature.emit({ 'signature': this.signaturePad.toDataURL() });
		this.closeModal();
	}

	clear() {
		this.signaturePad.clear();
	}

	// drawComplete() {
		
	// }
	
	// drawStart() {
		
	// }
}
