import { Injectable } from '@angular/core';
import { ActionRule } from '../../../utils/constants';
import { DynamicField, DynamicFieldActionTarget, GroupLists } from '../../../dto/dtos';
import { DynamicFieldManager } from '../../../settings/dynamic-fields/dynamic-field-manager';
import { DynamicCondition } from './dynamic-condition';
import { AxonUtils } from '../../../utils/axon-utils';
import { GroupListsManager } from '../../../settings/grouplists/group-lists-manager';

/**
 * The instruction returned based on the rules of the dynamic action.
 * Contains the field ID, it's rule and any value that may be needed, that the field
 * should adhere to.
 * 
 * NOTE: See https://axonwireless.atlassian.net/wiki/spaces/DAT/pages/183402497/Dynamic+Field+Action for more info
 * 
 */
export interface Instruction {
    fieldId:    number;
    actionRule: ActionRule;
    /*
    The value is linked to the Action i.e. if Action = REGEX, the
    value would be the Regular expression. FIf Action = MIN or MAX,
    the value is the min / max allowed
    */
    value:      any;
	error:      string;
}

@Injectable({
	providedIn: 'root'
})
export class DynamicActionService {

    constructor(private dynMgr: DynamicFieldManager, private grouplistMgr: GroupListsManager) { }

    public getInstructions( fieldId: number, value: any, fields: Array< DynamicField > ): Array< Instruction > {
        const instructions = new Array< Instruction >();

        const dynamicActions = this.dynMgr.getDynamicFieldActionsByFieldId( fieldId );

        if ( dynamicActions === null || dynamicActions === undefined || dynamicActions.length === 0 ) {
            return instructions;
        }

        for ( const action of dynamicActions ) {
            for ( const condition of action.conditions ) {

                let testField: Field = null;

                if ( condition.fieldId === fieldId ) {
                    /* The field ID of the condition is the source field ID. Use it's value */
                    testField = {
                        id: fieldId,
                        value: value,
                    };
                } else {
                    /* The field ID of the condition is not the source. Find the field from the given list of fields */
                    for ( const field of fields ) {
                        if ( field.id === fieldId ) {
                            testField = {
                                id:    field.id,
                                label: field.field,
                                value: field.value,
                            };
                        }
                    }
                }

                const dynamicCondition = new DynamicCondition();
                const passed = dynamicCondition.testCondition( condition, testField );

                /*
                The condition should only fire if the result of the condition (true or false)
                matches the fireBool value. Think of fireBool as the condition of the condition firing.
                i.e. it should only fire if condition result = true (condition was satisfied) and fireBool = true
                conversely, it should only fire if condition result = false (condition was not satisfied) and fireBool = false
                */
                if ( passed === condition.fireBool ) {

                    for ( const target of action.targets ) {
                        const actionRule = AxonUtils.getEnumKeyByEnumValue(ActionRule, target.rule.type);

						this.processChangeProps(target, fields)

                        instructions.push(
                            {
                                fieldId: target.fieldId,
                                actionRule: ActionRule[actionRule],
                                value: target.rule.value,
								error: target.rule.error
                            }
                        );
                    }
                }
            }
        }

        return instructions;
    }

	private processChangeProps(target: DynamicFieldActionTarget, fields: Array< DynamicField >) {
		const actionRule = ActionRule[target.rule.type];
		const propsStr: string = target.rule.value; //Example: inputfield=x,min=1,max=10

		if ( target.rule.type === ActionRule.CHANGE_PROP ) {
			var properties = propsStr.split(",");
			const field: DynamicField = this.getField(target.fieldId, fields);

			for (var property of properties) {
				const propVal = property.split("=");
				const prop = propVal[0];
				const value = propVal[1];
				switch (prop) {
					case "chartype":
						field.charType = value;
						break;
					case "inputfield":
						field.inputField = value;
						break;
					case "min":
						field.min = Number(value);
						break;
					case "max":
						field.max = Number(value);
						break;
					case "allowspaces":
						field.allowSpaces = Number(value);
						break;
					case "charactersallowed":
						field.charsAllowed = Number(value);
						break;
					case "lov":
						field.lov = Number(value);
						const gl: Array< GroupLists > = this.grouplistMgr.getGroupListIdLang(field.lov, 'en');
						let lovValues: Array<string> = new Array();
						for ( var g of gl ) {
							lovValues.push(g.text);
						}
						field.lovValues = lovValues;
						break;
					case "containsvowels":
						field.containsVowels = Number(value);
						break;
					case "params":
						field.params = value;
						break;
				}
			}
		}
	}

	private getField(fieldId: number, fields: Array<DynamicField>): DynamicField {
		for ( var field of fields ) {
			if ( field.id === fieldId ) {
				return field;
			}
		}

		return null;
	}
}

export interface Field {
    id:     number;
    label?: string;
    value:  any;
}

