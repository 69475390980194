import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { DmsService } from './../../service/dms.service';
import { DmsNodeInfoDto } from './../../../dto/dtos';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { AxonUtils } from '../../../utils/axon-utils';
import { Location } from '@angular/common';
import { TreeNode } from '../../../component/tree/tree.component';

@Component({
	selector: 'app-dms-nav-bar',
	templateUrl: './dms-nav-bar.component.html',
	styleUrls: ['./dms-nav-bar.component.css']
})
export class DmsNavBarComponent implements OnInit {
	allNodes: TreeNode[] = [];
	breadcrumbs: DmsNodeInfoDto[] = [];
	@Input() required = false;
	@Input() readonly = false;
	@Input() updateLocationOnChange = true;
	@Input() selectableNodes: DmsNodeInfoDto[];
	selected: DmsNodeInfoDto;
	selectedInModal: DmsNodeInfoDto;
	@Output() onSelected = new EventEmitter<DmsNodeInfoDto>();

	constructor(
		private service: DmsService,
		private activatedRoute: ActivatedRoute,
		private modalService: NgbModal,
		private location: Location) { }

	ngOnInit() {
		const dmsId = this.activatedRoute.snapshot.queryParamMap.get('dms-id');

		this.service.getDmsNodesForTree().subscribe(result => {
			this.allNodes = result.dataList.map(item => {
				return {
					ref: item.axonId,
					parentRef: item.parentId,
					label: item.name,
					data: item,
					selectable: this.isSelectable(item)
				};
			});
			if (this.selected) {
				if (result.dataList.filter(item => item.axonId === this.selected.axonId).length === 0) {
					this.allNodes.unshift({
						ref: this.selected.axonId,
						parentRef: this.selected.parentId,
						label: this.selected.name,
						data: this.selected,
						selectable: true
					});
				}
				this.select(this.selected, true);
				return;
			}
			if (dmsId && this.updateLocationOnChange === true) {
				this.selected = result.dataList.filter(item => item.ref === +dmsId)[0];
			}
			if (this.selected === null) {
				return;
			}
			if (!this.selected) {
				this.selected = this.findParentMostItem(result.dataList);
			}
			this.select(this.selected, true); //To trigger url update and fire event back to calling component
		});
	}
	isSelectable(input: DmsNodeInfoDto): boolean {
		if (!input) {
			return false;
		}
		if (input.agentHasAccess === false) {
			return false;
		}
		if (!this.selectableNodes || this.selectableNodes === null) {
			return true;
		}
		return this.selectableNodes.filter(item => item.axonId === input.axonId).length > 0;
	}

	@Input()
	set initiallySelected(input: DmsNodeInfoDto) {
		if (!this.selected) {
			this.selected = input;
		}
	}
	openDialog(tree) {
		this.selectedInModal = this.selected;
		this.modalService.open(tree).result.then(
			result => {
				if (this.selectedInModal) {
					this.select(this.selectedInModal, false);
				}
			},
			rejection => { });
	}
	select(selected: DmsNodeInfoDto, force: boolean) {
		if (force === false) {
			if (this.readonly === true) {
				return;
			}
			if (!this.isSelectable(selected)) {
				return;
			}
		}
		this.selected = selected;
		this.breadcrumbs = this.calcBreadcrumbs();
		if (this.updateLocationOnChange === true) {
			AxonUtils.updateUrlWithAppendedQueryParam(this.location, this.activatedRoute, null, 'dms-id', this.selected.axonId);
		}
		this.onSelected.emit(this.selected);
	}

	findParentMostItem(input: DmsNodeInfoDto[]): DmsNodeInfoDto {
		let out = input.filter(item => item.parentId === null);
		if (out.length === 1) {
			return out[0];
		}
		out = input.filter(item => input.filter(i => item.parentId === i.axonId).length === 0);
		if (out.length === 1) {
			return out[0];
		}
		return null;
	}
	calcBreadcrumbs(): DmsNodeInfoDto[] {
		if (!this.selected) {
			return [];
		}
		let previousNode = this.selected;
		const out: DmsNodeInfoDto[] = [this.selected];

		let tmp: DmsNodeInfoDto;
		while (tmp = this.allNodes.map(item => item.data).filter(item => item.axonId === previousNode.parentId)[0]) {
			out.unshift(tmp);
			previousNode = tmp;
		}
		return out;
	}
}
