import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { DataRequest, DataResponse, DynamicLink, Agent, DynamicField, Range, AllocateRangesRequest, AgentTracingCoordinat } from '../../dto/dtos';
import { HttpDataService } from '../http/http-data.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AxonService } from '../axon-service';
import { FilterOption } from '../../component/material-table/material-table.component';
import { AxonUtils } from '../../utils/axon-utils';
import { environment, SectionUtils } from '../../../environments/environment';
import { Notifier } from '../../utils/notifier';
import { Section } from '../../utils/constants';
import { KEY_CONFIGURATION, Storage } from '../../utils/storage';

@Injectable({
  providedIn: 'root'
})
/**
 * Entry point for components requiring access to agent data
 */
export class ConfigService extends AxonService {

    private httpService: HttpDataService;

    constructor(_httpClient: HttpClient, _authService: AuthService, _notifier: Notifier, private storage: Storage) {
        super( _httpClient, _authService, _notifier );
    }

    public getAppdataConfig() {

		console.log('Fetching appdata configuration');

        this.getDataResponse({}, '', '/configuration').subscribe(
			result => {
				const configuration = {};
				result.data.forEach(function (data) {
					configuration[data.name] = data.value;
				});
				this.storage.saveItem(KEY_CONFIGURATION, JSON.stringify(configuration));
			}
		);
    }

	public getFeaturesList(): Observable<string[]> {
		console.log('Fetching list of features');
		const httpService = new HttpDataService(this.httpClient, this.authService, '', this.notifier);
		return httpService.doGet(`/feature`);
    }

	/**
     * Fetches fica organizations i.e. customers in ficainfo with customer_type = CORPORATE
     * @param axonId
     */
	 public getFicaOrganizationMinimalList(): Observable<DataResponse> {
        const dataRequest: DataRequest = {};

        return this.getDataResponse(dataRequest, '/getminimallist', '/ficaorganizations');
    }

    /**
     * Helper to get the observable containing the response
     * @param dataRequest
     * @param subpath - the subpath following the rootpath
     * @param rootpath - if null, will be set to /user
     */
    public getDataResponse( dataRequest: DataRequest, subpath: string, rootpath?: string ): Observable<DataResponse> {

        /* If not defined, set to /user i.e. the default root path */
        if ( !rootpath ) {
            rootpath = '/user';
        }

        this.httpService = new HttpDataService(this.httpClient, this.authService, rootpath, this.notifier);
        return this.httpService.sendData(dataRequest, subpath).pipe(
            map(response => {
                if ( response.data ) {
                    if ( response.data.birthdate ) {
                        response.data.birthdate = AxonUtils.getDate(response.data.birthdate);
                    }
                    if ( response.data.datetime ) {
                        response.data.datetime = AxonUtils.getDate(response.data.datetime);
                    }
				}

                /* Put the elementId on for the tables, to allow for maintaining selected/checkbox state */
                if (response.dataList) {
                    response.dataList.forEach(row => {
                        row.elementId = row.agentId;
                    });
                }
                return response;
            })
        );
    }
}
