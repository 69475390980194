
import { Observable } from 'rxjs';
import { DataResponse } from '../../../../dto/dtos';
import { TableData } from '../table-data';
import { Injectable } from '@angular/core';
import { FilterOption } from '../../material-table.component';
import { CustomerService } from '../../../../services/customers/customer.service';
import { filter, map } from 'rxjs/operators';

/**
 * Overrides getTableData by fetching registration history table data
 */
export class RegistrationHistoryTableData extends TableData {

	private axonId: number;
	private clientSideFilters: ((record: any) => boolean)[];

	constructor(axonId: number, private customerService: CustomerService) {
		super(customerService);
		this.axonId = axonId;
	}
	setClientSideFilters(clientSideFilters: ((record: any) => boolean)[]) {
		this.clientSideFilters = clientSideFilters;
	}

	getTableData(sort: string, order: string, page: number, pageSize: number, filterOptions: FilterOption[]): Observable<DataResponse> {
		const out = this.customerService.getRegistrationHistory(this.axonId, page, pageSize, filterOptions);
		if (this.clientSideFilters === null || this.clientSideFilters === undefined) {
			return out;
		}
		return out.pipe(map(item => {
			if (item === undefined) {
				return item;
			}
			item.dataList = item.dataList.filter(record => {
				for (const clientSideFilter of this.clientSideFilters) {
					if (clientSideFilter(record) === false) {
						return false;
					}
				}
				return true;
			});
			return item;
		}));
	}
}
