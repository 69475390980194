import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { DataRequest, DataResponse, Msisdn, DynamicLink } from '../../dto/dtos';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AxonService } from '../axon-service';
import { FilterOption } from '../../component/material-table/material-table.component';
import { AxonUtils } from '../../utils/axon-utils';
import { HttpDataService } from '../http/http-data.service';
import { Notifier } from '../../utils/notifier';

@Injectable({
  providedIn: 'root'
})
/**
 * Entry point for components requiring access to customer data
 */
export class ChurnService extends AxonService {

    private httpService: HttpDataService;

    constructor(_httpClient: HttpClient, _authService: AuthService, _notifier: Notifier) {
        super( _httpClient, _authService, _notifier );
    }

    /**
     * Fetches customers for table data
     * @param pageNum
     * @param pageMax
     * @param filterOptions
     */
    public getListOfBatches(): Observable<DataResponse> {

        const dataRequest: DataRequest = {};

        return this.getDataResponse(dataRequest, '/getbatches');
    }

    /**
     * Searches churn batches
     * 
     * @param batchId 
     * @param msisdn 
     * @param type 
     */
    public getChurnBatch(batchId?: number, msisdn?: string, type?: string): Observable<DataResponse> {

        let dataRequest: DataRequest;
        if ( batchId ) {
            dataRequest = {
                identifierNum: batchId,
                type: type
            };
        } else {
            dataRequest = {
                msisdn: msisdn,
                type: type
            };
        }

        return this.getDataResponse(dataRequest, '/getbatches');
    }

    /**
     * Downloads a churn file type
     * 
     * @param fileType
     */
    public getChurnFile(batchId: number, fileType: string): Observable<Blob> {

        const dataRequest: DataRequest = {
            type: fileType,
            identifierNum: batchId
        };

        // return this.getDataResponse(dataRequest, '/getchurnfile');
		const httpService = new HttpDataService(this.httpClient, this.authService, '/churn', this.notifier);

		//Endpoint was /ecafcustomerform
        return httpService.getBlob(dataRequest, '/getchurnfile').pipe(
            map(response => {
                return response;
            })
        );
    }

    /**
     * Searches churn batches
     * 
     * @param type 
     * @param records 
     */
    public createBatch(type: string, records: Array<string>, tpClose: boolean): Observable<DataResponse> {

        const dataRequest: DataRequest = {
            churnList: records,
            type: type,
			tpClose: tpClose
        };

        return this.getDataResponse(dataRequest, '/createbatch');
    }

    /**
     * Helper to get the observable containing the response
     * @param dataRequest
     * @param subpath - the subpath following the rootpath
     * @param rootpath - if null, will be set to /user
     */
    private getDataResponse( dataRequest: DataRequest, subpath: string, rootpath?: string ): Observable<DataResponse> {

        /* If not defined, set to /customer i.e. the default root path */
        if ( !rootpath ) {
            rootpath = '/churn';
        }

        this.httpService = new HttpDataService(this.httpClient, this.authService, rootpath, this.notifier);
        return this.httpService.sendData(dataRequest, subpath).pipe(
            map(response => {

                if ( response.data ) {
                    if ( response.data.birthDate ) {
                        response.data.birthDate = AxonUtils.getDate(response.data.birthDate);
                    }


                    // if (subpath === '/getcustomer') {
                    //     for ( let i = 1; i < 1000; i++ ) {
                    //         let msisdn: Msisdn = {
                    //             msisdn: response.data.msisdns[0].msisdn,
                    //             imsi: response.data.msisdns[0].imsi,
                    //             iccid: i + '',
                    //             activation: response.data.msisdns[0].activation,
                    //             activationDatetime: response.data.msisdns[0].activationDatetime,
                    //             activationError: response.data.msisdns[0].activationError
                    //         };
                    //         response.data.msisdns.push(msisdn);
                    //     }
                    // }
                    // response.data.originalDatetime = '2019-04-17T00:51:30.000+0000';

                    // if ( response.data.originalDatetime ) {
                    //     response.data.originalDatetime = AxonUtils.getDate(response.data.originalDatetime);
                    // }

                    /* Work out time difference in millis between registration and approval */
                    if ( response.data.originalDatetime && response.data.approvedDatetime ) {
                        const approve: Date = new Date(response.data.approvedDatetime);
                        const register: Date = new Date(response.data.originalDatetime);
                        response.data.timeToApproveMillis = Math.abs(approve.getTime() - register.getTime());
                    }

                }

                return response;
            })
        );
    }
}
