import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Agent } from '../../dto/dtos';
import { Permission } from '../../utils/constants';
import { AxonUtils } from '../../utils/axon-utils';
import { Storage, KEY_TOKEN_TYPE, VALUE_TOKEN_TYPE_AUTH_ONLY } from '../../utils/storage';

@Injectable({
	providedIn: 'root'
})
export class LoginAuthGuardService implements CanActivate {

	constructor(private authService: AuthService, private router: Router, private storage: Storage) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

      console.log(`Accessing:[${state.url}]`);

      /**
      On the auth pages, for forgotpassword and sign up,
      part of that is using the authByOTP & authBySecurityQuestions apis which set the token before they have finished that auth process.
      THis is required to give them permission to set their password or security questions
      So in that process I set a token type in storage so that,
      if they do not finish that process and try to go to another authGuarded route then it will not allow them.
      I can maybe send them back to whence they came from or just show an error of some kind.
      */
      const tokenType = this.storage.getItem(KEY_TOKEN_TYPE);
      const isAuthOnlyToken = (tokenType !== null && tokenType !== undefined && tokenType === VALUE_TOKEN_TYPE_AUTH_ONLY);
      if (this.authService.isAuthenticated() && isAuthOnlyToken) {
          this.storage.deleteItem(KEY_TOKEN_TYPE);
          this.authService.deleteAuth();
      }
      // console.log("##########                           -----------------                           ##########");
      // console.log('tokenType = ' + tokenType);

      if ( this.authService.isAuthenticated() ) {
          console.log(`Authenticated:: Showing:[${state.url}]`);

          return true;
      }

      console.log(`Not authenticated.  Tried to access:[${state.url}]`);
      this.router.navigate(['/authentication/login'], { queryParams: { returnUrl: state.url } });

      return false;

	}

}
