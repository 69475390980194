import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { DataRequest, DataResponse, ImageRequest } from '../../dto/dtos';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AxonService } from '../axon-service';
import { HttpDataService } from '../http/http-data.service';
import { Notifier } from '../../utils/notifier';

@Injectable({
  providedIn: 'root'
})
/**
 * Entry point for components requiring access to customer data
 */
export class ImageService extends AxonService {

    private httpService: HttpDataService;

    constructor(_httpClient: HttpClient, _authService: AuthService, _notifier: Notifier) {
        super( _httpClient, _authService, _notifier );
    }

    /**
     * Fetches an image by Axon ID and Image Type
     * @param pageNum
     * @param pageMax
     * @param filterOptions
     */
    public getImage(axonId: number, imageId: number, includeMultiscanThumbs: boolean, maxWidth?: number, maxHeight?: number): Observable<DataResponse> {

        const dataRequest: ImageRequest = {
            identifierNum: axonId,
            imageId: imageId,
			includeMultiscanThumbs: includeMultiscanThumbs,
			maxWidth: maxWidth,
			maxHeight: maxHeight,
        };

        return this.getDataResponse(dataRequest, '/getimage');
    }

    /**
     * Fetches an historic image by pinref and Image Type
     * @param pinref 
     * @param imageId 
     * @param includeMultiscanThumbs 
     */
    public getHistoricImageByPinref(pinref: number, imageId: number, includeMultiscanThumbs: boolean, maxWidth?: number, maxHeight?: number): Observable<DataResponse> {

        const dataRequest: ImageRequest = {
            identifierNum: pinref,
            imageId: imageId,
            includeMultiscanThumbs: includeMultiscanThumbs,
			maxWidth: maxWidth,
			maxHeight: maxHeight,
        };

        return this.getDataResponse(dataRequest, '/gethistoricimagebypinref');
    }

    /**
     * Fetches the image linked to the given multiscanId and pageNumber
     * @param imageId 
     * @param multiscanId 
     */
    public getMultiscanImage(multiscanId: number, pageNumber: number): Observable<DataResponse> {

        const dataRequest: ImageRequest = {
            multiscanId: multiscanId,
            pageNumber: pageNumber
        };

        return this.getDataResponse(dataRequest, '/getmultiscanimages');
    }

    public getRejectionViewImages(axonId: number, sectionId: number): Observable<DataResponse> {

        const dataRequest: DataRequest = {
            identifierNum: axonId,
            sectionId: sectionId
        };

        return this.getDataResponse(dataRequest, '/getrejectionimages');
    }

    /**
     * Helper to get the observable containing the response
     * @param dataRequest
     * @param subpath - the subpath following the rootpath
     * @param rootpath - if null, will be set to /user
     */
    public getDataResponse( dataRequest: DataRequest, subpath: string, rootpath?: string ): Observable<DataResponse> {

        /* If not defined, set to /image i.e. the default root path */
        if ( !rootpath ) {
            rootpath = '/image';
        }

        this.httpService = new HttpDataService(this.httpClient, this.authService, rootpath, this.notifier);
        return this.httpService.sendData(dataRequest, subpath).pipe(
            map(response => {
                return response;
            })
        );
    }
}
