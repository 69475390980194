import { Component, Input, AfterViewInit } from '@angular/core';
import { Customer, DynamicLink } from '../../dto/dtos';

import { AxonComponent } from '../../axon.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-rejection-modal',
  templateUrl: './rejection-modal.component.html',
  styleUrls: ['./rejection-modal.component.scss']
})
export class RejectionModalComponent extends AxonComponent implements AfterViewInit {
    
    @Input() regRejectionReason: string;
    @Input() dynamicLink: DynamicLink;
    @Input() customer: Customer;

    isLoadingResults = true;
    private parentComponent: AxonComponent;
    
    private rejectButton = 'Reject'; 

    constructor(
        public activeModal: NgbActiveModal) { 

		super();
		
    }

    ngAfterViewInit() {
        this.isLoadingResults = false;
    }

    confirm() {
        this.activeModal.close();
    }

    cancel() {
        this.activeModal.close();
    }

    
}
