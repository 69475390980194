import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { DataRequest, DataResponse, Msisdn, DynamicLink, FileRequest, DynamicValidationResultDto, DynamicValidationDto, DynamicValidationDataDto } from '../../dto/dtos';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AxonService } from '../axon-service';
import { FilterOption } from '../../component/material-table/material-table.component';
import { AxonUtils } from '../../utils/axon-utils';
import { HttpDataService } from '../http/http-data.service';
import { Notifier } from '../../utils/notifier';
import { Section } from '../../utils/constants';
import { SectionUtils } from '../../../environments/environment';
import { DynamicFieldManager } from '../../settings/dynamic-fields/dynamic-field-manager';

@Injectable({
  providedIn: 'root'
})
export class DynamicValidationService extends AxonService {

    private httpService: HttpDataService;

    constructor(_httpClient: HttpClient, _authService: AuthService, _notifier: Notifier, private dynMgr: DynamicFieldManager) {
        super( _httpClient, _authService, _notifier );
    }

    /**
     * Fetches validations
     * @param pageNum
     * @param pageMax
     * @param filterOptions
     */
    public validateDynamicLink(agentId: number, validationId: number, dynamicLink: DynamicLink): Observable<DynamicValidationResultDto> {

		const dynamicValidation: DynamicValidationDto = this.dynMgr.getDynamicValidation( validationId );

		const payload: Array<DynamicValidationDataDto> = [];

		for ( let card of dynamicLink.cards ) {
			for ( let field of card.fields ) {
				for ( let mapping of dynamicValidation.mappings ) {
					if ( field.id === mapping.fieldId ) {
						payload.push( {
							fieldId: field.id,
							value: field.value
						});
					}
				}
			}
		}

        return this.validate(agentId, validationId, payload);
    }

	public validate(agentId: number, validationId: number, payload: Array<DynamicValidationDataDto>): Observable<DynamicValidationResultDto> {

		const dynamicValidation: DynamicValidationDto = this.dynMgr.getDynamicValidation( validationId );

		const httpService = new HttpDataService(this.httpClient, this.authService, '/dynamic_validation', this.notifier);
		return httpService.doPost(`/${validationId}/${agentId}/${dynamicValidation.type.toLowerCase()}`, payload);
	}
}
