import { Component, OnInit, isDevMode, OnDestroy, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AxonUtils } from './utils/axon-utils';
import { Storage, KEY_CONFIGURATION, KEY_SYSTEM_INFO_VERSION } from './utils/storage';
import { AgentService } from './services/agents/agent.service';
import { SessionMonitorService } from './services/session/session-monitor.component';
import { ConnectionService } from 'ng-connection-service';
import { Notifier } from './utils/notifier';
import { MatDialog, MatDialogRef } from '@angular/material';
import { OfflineDialogComponent } from './component/offline-dialog/offline-dialog.component';
import { Subscription, timer } from 'rxjs';
import { SystemService } from './services/system/system.service';
import { switchMap } from 'rxjs/operators';
import { AxonComponent } from './axon.component';
import { ConfigService } from './services/config/config.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent extends AxonComponent implements OnInit, AfterViewInit, OnDestroy {
	title = 'app';
	isConnected = true;

	dialogRef: MatDialogRef<OfflineDialogComponent>;

	connectionSubscription: Subscription;

	constructor(
		private sanitizer: DomSanitizer,
		public notifier: Notifier,
		private dialog: MatDialog,
		private connectionService: ConnectionService,
		private sessionMonitor: SessionMonitorService,
		private configService: ConfigService) {
		super();
		AxonUtils.domSanitizer = sanitizer;

		this.connectionSubscription = this.connectionService.monitor().subscribe(isConnected => {
			this.isConnected = isConnected;
			if (this.isConnected) {
				if (this.dialogRef !== null && this.dialogRef !== undefined) {
					this.dialogRef.close();
				}
			} else {
				this.dialogRef = this.dialog.open(OfflineDialogComponent, {});
				this.dialogRef.disableClose = true;
			}
		});
	}

	ngOnInit() {

		super.initResizeListener();
		console.log('Angular running in [' + (isDevMode() ? 'Dev' : 'Prod') + '] mode');
		this.fetchAppData();

		this.sessionMonitor.startSessionMonitor();
	}

	ngAfterViewInit() {
		super.initResizeListener();
	}

	initStandardResizeListener() {
		super.initResizeListener();
	}

	initApprovalPageResizeListener() {
		setTimeout(() => {
			super.initApprovalResizeListener();
		}, 1000);
	}

	ngOnDestroy() {
		if (this.connectionSubscription) {
			this.connectionSubscription.unsubscribe();
		}
	}

	fetchAppData() {
		this.configService.getAppdataConfig();
	}

}
