
import { Observable } from 'rxjs';
import { DataResponse } from '../../../../dto/dtos';
import { AgentService } from '../../../../services/agents/agent.service';
import { TableData } from '../table-data';
import { Injectable } from '@angular/core';
import { FilterOption } from '../../material-table.component';

@Injectable({
	providedIn: 'root'
})
/**
 * Overrides getTableData by fetching agent table data
 */
export class AgentsTableData extends TableData {
	private dmsId: number;
	constructor(private agentService: AgentService) {
		super(agentService);
	}
	setDmsId(dmsId: number) {
		this.dmsId = dmsId;
	}
	getTableData(sort: string, order: string, page: number, pageSize: number, filterOptions: FilterOption[]): Observable<DataResponse> {
		console.log("Get Agent Data: ", this.dmsId);
		return this.agentService.getAgents(sort, order, page, pageSize, filterOptions, this.dmsId);
	}
}
