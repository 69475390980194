import { HttpClient } from "@angular/common/http";
import { AuthService } from "./auth/auth.service";
import { Notifier } from "../utils/notifier";

/**
 * Super class for all services. This is used by TableData for abstraction purposes.
 */
export class AxonService {

    constructor(protected httpClient: HttpClient, protected authService: AuthService, protected notifier: Notifier) { }

}