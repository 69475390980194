import { Component, Input } from "@angular/core";
import { Customer, Agent } from "../../../../../dto/dtos";

@Component({
	selector: 'app-registration-location',
	templateUrl: './registration-location.component.html',
	styleUrls: ['./registration-location.component.scss']
})
export class RegistrationLocationComponent {

    @Input() customer: Customer;
    @Input() agent: Agent;
    
    constructor() {}
}