import { Injectable, Component, Input, AfterViewInit, OnInit } from "@angular/core";
import { AxonComponent } from "../../axon.component";
import { Chart } from "../../dto/dtos";

@Injectable({
    providedIn: 'root'
})

@Component({
    selector: 'app-axon-gauge',
    templateUrl: './axon-gauge.component.html',
    styleUrls: ['./axon-gauge.component.scss'],
})
export class AxonGaugeComponent extends AxonComponent implements OnInit {

    @Input() chart: Chart;

    private needleValue:   number;
    private name:          string;
    private needleColour:  string;
    private arcColours:    Array<string>;
    private arcDelimiters: Array<number>;
    private range:         Array<string>;

    private hasNeedle = true;
    private needleUpdateSpeed = 1000;
    private needleStartValue = 50;
    private options: any;

    private centralLabel: string;

    private gaugeError: string;

    gaugeReady = false;

    constructor() {
        super();
    }

    ngOnInit() {

        console.log( '*****************************************************' );
        console.log( 'Building gauge chart' );

        this.name = this.chart.title;
        const params: Array< string > = this.chart.params.split(";");
        for ( const p of params ) {
            if ( p ) {
                const name:  string       = p.split("=")[0];
                const value: Array< any > = p.split("=")[1].split(":");

                if ( name === 'needleColour' ) {
                    this.needleColour = value[0];
                    console.log( 'needleColour = ' + JSON.stringify( this.needleColour ) );
                }

                if ( name === 'arcColours' ) {
                    this.arcColours = value;
                    console.log( 'arcColours = ' + JSON.stringify( this.arcColours ) );
                }

                if ( name === 'arcDelimiters' ) {
                    this.arcDelimiters = value;
                    console.log( 'arcDelimiters = ' + JSON.stringify( this.arcDelimiters ) );
                }

                if ( name === 'needleValue' ) {
                    this.needleValue = value[0];
                    console.log( 'needleValue = ' + JSON.stringify( this.needleValue ) );
                }

                if ( name === 'range' ) {
                    this.range = value;
                    console.log( 'range = ' + JSON.stringify( this.range ) );
                }
            }
        }

        this.centralLabel = this.needleValue + '';
        this.needleValue  = this.needleValue / parseInt(this.range[1], 10) * 100;

        if ( !this.range || this.range.length !== 2 ) {
            this.gaugeError = 'Error in Gauge config: Range must contain only 2 elements';
        } else {

            const min = this.range[0];
            const max = this.range[1];
            for ( const delim of this.arcDelimiters ) {
                if ( delim >= parseInt( max, 10 ) ) {
                    this.gaugeError = 'Error in Gauge config: Max must be greater than any arc delimiter';
                    break;
                } else if ( delim <= parseInt( min, 10 ) ) {
                    this.gaugeError = 'Error in Gauge config: Min must be less than any arc delimiter';
                    break;
                }
            }
        }

        if ( !this.gaugeError ) {

            this.options = {
                hasNeedle:         this.hasNeedle,
                needleColor:       this.needleColour,
                needleUpdateSpeed: this.needleUpdateSpeed,
                arcColors:         this.arcColours,
                arcDelimiters:     this.arcDelimiters,
                rangeLabel:        this.range,
                needleStartValue:  this.needleStartValue,
            };

            console.log( 'options = ' + JSON.stringify( this.options ) );
        }

        console.log( '*****************************************************' );

        this.gaugeReady = true;
    }

    
}