import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Notifier } from '../../../../utils/notifier';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NewFormData } from '../../../../component/form/form-data/new-form-data';
import { AgentService } from '../../../../services/agents/agent.service';
import { Section } from '../../../../utils/constants';
import { AgentDetailsComponent } from '../../agent-details/agent-details.component';
import { AgentModalComponent } from '../../agent-modal.component';
import { AxonModel } from '../../../../dto/dtos';
import { Agent } from 'http';
import { DynamicFieldManager } from '../../../../settings/dynamic-fields/dynamic-field-manager';

@Component({
    selector: 'app-agent-singular-action-modal',
    templateUrl: './agent-singular-action-modal.component.html',
    styleUrls: ['./agent-singular-action-modal.component.css']
})
export class AgentSingularActionModalComponent implements OnInit {

    instance: AgentSingularActionModalComponent;
    newFormData: NewFormData;
    agentModalComponent: AgentModalComponent;

    @Input('singular') singular;
    @Input('path') path;
    @Input('agent') agent;
    @Input('pastTense') pastTense;
    @Input('child') child;
    @Input('agentDynamicCardComponent') agentDynamicCardComponent;
    @ViewChild('appForm') appFormRef;
    @Input('action') action: string;

    constructor(
        private agentService: AgentService,
        private notifier: Notifier,
        public activeModal: NgbActiveModal,
		private dynamicFieldManager: DynamicFieldManager) {
        this.instance = this;
    }

    ngOnInit() {
        this.newFormData = new NewFormData(Section.AGENT_ACTIONS, 0, this.dynamicFieldManager, this.agentService);
    }

    async submit() {
        if (Object.keys(this.appFormRef.formControlList).length > 0) {
            const reasonValue = this.appFormRef.formControlList[this.appFormRef.dynamicLink.cards[0].fields[0].id].value;
            let result;
            switch (this.action) {
                case 'flag': result = await this.agentService.flagAgent(this.agent, reasonValue);
                    break;
                case 'unflag': result = await this.agentService.unflagAgent(this.agent, reasonValue);
                    break;
                case 'block': result = await this.agentService.agentBlock(this.agent.agentId, reasonValue);
                    break;
                case 'unblock': result = await this.agentService.agentUnblock(this.agent.agentId, reasonValue);
                    break;
                case 'disable': result = await this.agentService.agentDisable(this.agent.agentId, reasonValue);
                    break;
                case 'enable': result = await this.agentService.agentEnable(this.agent.agentId, reasonValue);
                    break;
                case 'reset': result = await this.agentService.agentReset(this.agent.agentId, reasonValue);
                    break;
            }
            if (result === 'success') {
                this.notifier.success("Agent has been " + this.pastTense);
                if (this.child) {
                    this.child.selection.clear();
                    this.child.refreshData();
                }
                // if (this.agentDynamicCardComponent) {
                //     (<AgentModalComponent>((<AgentDetailsComponent>(this.agentDynamicCardComponent.parentComponent)).parentComponent)).getUser(this.agentDynamicCardComponent.agent.agentId);
                // }
                this.activeModal.close(true);
            } else if (result.success === true) {
                this.notifier.success("Agent has been " + this.pastTense);
                if (this.agentModalComponent) {
                    this.agentModalComponent.getUser(this.agent.agentId);
                }
                this.activeModal.close(true);
            } else if (result.error) {
                this.notifier.error(result.error);
                this.activeModal.close(false);
            } else {
                this.notifier.error('Unable to ' + this.singular + ' specified agent.');
                this.activeModal.close(false);
            }
        }
    }

}
