import { Component, OnInit, Input, AfterViewInit, ViewChild, OnChanges, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialogConfig, MatDialog } from '@angular/material';
import { ModalContainerComponent } from '../modal-container/modal-container.component';
import { MaterialTableData } from '../material-table/material-table-data';
import { AxonComponent } from '../../axon.component';
import { Notifier } from '../../utils/notifier';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-basic-table',
    templateUrl: './basic-table.component.html',
    styleUrls: ['./basic-table.component.scss'],
})
export class BasicTableComponent extends AxonComponent implements OnInit, AfterViewInit, OnChanges {

	MAX_PER_PAGE = 50;
    /* The columns to be displayed - this contains a list of the columnDef attribute */
    displayedColumns: any[];
    @Input() parentComponent: AxonComponent;
    @Input() dataList: any[];
    @Input() columns: any[];
    @Input() cardHeading: string;
    @Input() paginate: boolean;
    @Input() allowSearch = true;
    @Input() allowShowOfDetails = true;
    @Input() clickResponsive = false;

    @Input() editable: boolean;
	@Output() onEditClick: EventEmitter<any> = new EventEmitter();
	@Output() onRowClick: EventEmitter<any> = new EventEmitter();
	@Output() onCheckBoxClick: EventEmitter<any> = new EventEmitter();
	

    @ViewChild(MatPaginator) paginator: MatPaginator;

    basicDataSource: MatTableDataSource<any[]>;

    @Input() limitHeight300: boolean;
    @Input() limitMaxHeight300: boolean;
    @Input() isSelectableRows = false;

    previousSelection: Element;

    isSearch = true;

	env = environment;

    public constructor( private router: Router, private modalService: NgbModal, private dialog: MatDialog ) {
        super();
    }

    applyFilter(filterValue: string) {
        this.basicDataSource.filter = filterValue.trim().toLowerCase();
    }

    ngOnInit() {
        this.basicDataSource = new MatTableDataSource(this.dataList);
    }

	ngAfterViewInit() {
		this.basicDataSource.paginator = this.paginator;
	}

    ngOnChanges() {
        this.displayedColumns = this.columns.map(c => c.columnDef);
    }

    search() {
        this.isSearch = !this.isSearch;
    }

    editClick() {
        this.onEditClick.emit();
    }

    onRowClicked(obj: any) {
		this.onRowClick.emit(obj);
        if (this.parentComponent && this.allowShowOfDetails && this.clickResponsive) {
            this.parentComponent.showDetails(obj);
        }
    }

    focus(obj: any) {
        if (this.isSelectableRows) {
            if (this.previousSelection) {
                this.previousSelection.removeAttribute("style");
            }
            console.log((<Element>(event.target)).innerHTML);
            (<Element>(event.target)).setAttribute("style", "color : green; font-size : 17px");
            this.previousSelection = <Element>(event.target);
        }
    }

    setDataList(data: any[]) {
        this.dataList = data;
        this.basicDataSource = new MatTableDataSource(this.dataList);
        this.ngOnChanges();
    }

    getList(columnValue: string) {
        const list = columnValue.split(',');
        return list;
    }

    getDate(dateStr) {
      return new Date(dateStr);
    }

    isSIMStatusColClickable( msisdnRow: any ): boolean {
        return msisdnRow.status === 'OUTGOING TRANSFER';
    }

    getSIMStatusColText( msisdnRow: any ) {

        if ( !msisdnRow.status ) {
            return 'ACTIVE';
        }
        return msisdnRow.status;
    }

    viewPendingTransferInApproval(msisdnRow: any) {
        this.dialog.closeAll();
        this.router.navigate(['/approvals/view/' + msisdnRow.recipientPendingTransferAxonId]);
    }

    isTimeAgo(date) {
      if (date != null) {
        if (date instanceof Date && !isNaN(date.valueOf()) && (date.getTime() < Date.now())) {
          return true;
        } else {
          return false;
        }
      }
    }

    handleExpiryChange(row: any, event: any) {
        this.parentComponent.dateChanged = true;
        this.parentComponent.handleDateChange(row, event.value);
    }


    handleButtonClick(row: any, label: string) {
        console.log ('Button click at row [' + JSON.stringify(row) + '] and label [' + label + ']' );
        this.parentComponent.handleButtonClick(row, label);
    }

    handleCheckboxClick(row: any) {
        this.parentComponent.handleCheckboxClick(row);
    }

	handleCheckboxEmitEvent(row: any) {
        this.onCheckBoxClick.emit(row);
    }

    /**
     * Displays a modal with given title and information
     * @param title 
     * @param data 
     */
    openInfoDialog( title: string, data: string )  {
        const dialog = this.modalService.open(InfoDialogComponent, 
            { 
                windowClass : "modal-standard"
            }
        );
        dialog.componentInstance.title = title;
        dialog.componentInstance.message = data;
    }

    enableButton(btn : any) {
        console.log(JSON.stringify(btn));
    }
}
