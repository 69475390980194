import { Component, OnInit, ViewChild, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SafeUrl } from '@angular/platform-browser';
import { AxonUtils } from '../../../utils/axon-utils';
import { SignaturePad } from '../../../shared/signature-pad.component';
import { Notifier } from '../../../utils/notifier';


@Component({
  selector: 'app-terms-conditions-signature',
  templateUrl: 'terms-conditions-signature.component.html',
  styleUrls: ['./terms-conditions-signature.component.scss'],
})
export class TermsConditionsSignature implements OnInit, AfterViewInit {

	@Input() url: string;
	@Input() title: string;
	@Output() acceptance = new EventEmitter();

	isLoadingTerms = true;

	tcUrl: SafeUrl;

	@ViewChild(SignaturePad) signaturePad: SignaturePad;
	signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
		'minWidth': 5,
		'canvasWidth': 462,
		'canvasHeight': 245
	};
	    
	constructor(private modalService: NgbModal, public activeModal: NgbActiveModal, private notifier: Notifier) {}

    ngOnInit(): void {
		this.url = window.location.origin + this.url.substr(this.url.indexOf("/files"), this.url.length);
		this.tcUrl = AxonUtils.domSanitizer.bypassSecurityTrustResourceUrl(this.url);
    }

	ngAfterViewInit() {
		
	}

	doneLoading() {
		this.isLoadingTerms = false;
	}

	closeModal() {
        this.activeModal.close();
    }

	accept() {
		
		if (this.signaturePad.isEmpty()) {
			this.notifier.warn("Please sign in the designated area to confirm your acceptance of the T&Cs");
			return;
		}

		this.acceptance.emit({ 'signature': this.signaturePad.toDataURL() });
		this.closeModal();
	}

	clear() {
		this.signaturePad.clear();
	}

	// drawComplete() {
		
	// }
	
	// drawStart() {
		
	// }
}
