import { ServerResponse } from "../dto/dtos";
import { HttpErrorResponse } from "@angular/common/http";
import { HttpError } from "./http-errors";

export class ServerError {

    /**
     * Uniform standard of printing errors to console - only prints if the ServerResponse contains an error
     * @param serverResponse 
     */
    static printError(serverResponse: ServerResponse): void {
        /* Only print if its an error */
        if ( !serverResponse.success ) {
            console.log( '****************** Error details ******************' );
            console.log( 'Error category: ' + serverResponse.errorCategory );
            console.log( 'Error code    : ' + serverResponse.errorCode );
            console.log( 'Error details : ' + serverResponse.error );
            console.log( 'User error    : ' + this.formatError( serverResponse ) );
            console.log( '***************************************************' );
        }
    }

    /**
     * Uniform standard of formating errors for user display - only returns formatted error if the ServerResponse contains an error
     * Format is:
     * [errorCategory]-[errorCode]: [error]
     * @param serverResponse 
     */
    static formatError(serverResponse: ServerResponse): string {
        /* Only print if its an error */
        if ( !serverResponse.success ) {
            return  'Oops an error occurred: ' + serverResponse.error + 
                '\nError details: ' + serverResponse.errorCategory + '-' + serverResponse.errorCode;
        }
        return null;
    }

    /**
     * Uniform standard of formating HTTP errors for user display
     * @param serverResponse 
     */
    static formatHttpError(httpError: HttpError): string {
        return  'Oops an error occurred: ' + httpError.message + 
            '\nError code: ' + httpError.code;
    }
}