import { Component, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { DataResponse } from '../../dto/dtos';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.css']
})
export class TableComponent implements OnChanges, OnDestroy {
    public tableData: TableData;
    @Input() datasource: Observable<DataResponse>;
    @Input() title: String;

    subscription: Subscription;

    ngOnChanges() {
        this.subscription = this.datasource.subscribe(
            data => {
                this.tableData = data.tableData;
            }
        );
    }

    ngOnDestroy() {
        if ( this.subscription ) {
            this.subscription.unsubscribe();
        }
    }
}

/**
 * All data required to construct the table.
 * headers represents cells within the header row
 * rows represents the cells within each data row.
 */
export interface TableData {
    headers: TableCell[];
    rows: TableCell[][];
    collectionSize: number;
}

/**
 * Represents a cell within a column / row
 */
export interface TableCell {
    cell: String;
}

