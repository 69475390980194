import { Component, Input, AfterViewInit, Output, EventEmitter, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Node } from '../../../dto/dtos';
import { AxonComponent } from '../../../axon.component';

export interface ListValue {
	id: number;
	value: string;
}
@Component({
	selector: 'app-multi-tier-dropdown',
	templateUrl: './multi-tier-dropdown.component.html',
	styleUrls: ['./multi-tier-dropdown.component.scss']
})
export class MultiTierDropdownComponent extends AxonComponent implements OnInit, AfterViewInit {

	@Input() parentPlaceholder: string;
	@Input() childPlaceholder: string;
	@Input() grandChildPlaceholder: string;
	@Input() greatGrandChildPlaceholder: string;
	@Input() parentList: Array<Node>;
	@Input() formCtrl: FormControl;
	@Input() initValue: number;
	@Output() change = new EventEmitter();

	childList: Array<Node>;
	grandChildList: Array<Node>;
	greatGrandChildList: Array<Node>;

	parentCtrl = new FormControl();
	childCtrl = new FormControl();
	grandChildCtrl = new FormControl();
	value: string;
	id: number;
	axonInternalId: number;

	/* Used to indicate if all tiers have been selected */
	fullySelected: boolean;

	constructor() {
		super();
	}

	ngOnInit() {
		console.log("set value by id");
		if (this.initValue) {
			this.setValueById(this.initValue);
		}
	}

	ngAfterViewInit(): void {
	}

	private getChildList(parentValue: string): Array<Node> {

		for (const parent of this.parentList) {
			if (parent.value === parentValue) {
				this.childList = parent.children;
				return this.childList;
			}
		}
	}

	private getGrandChildList(childValue: string): Array<Node> {
		if (this.childList) {
			for (const child of this.childList) {
				if (child.value === childValue) {
					return child.children;
				}
			}
		}
	}

	private getGreatGrandChildList(grandChildValue: string): Array<Node> {
		if (this.grandChildList) {
			for (const grandChild of this.grandChildList) {
				if (grandChild.value === grandChildValue) {
					return grandChild.children;
				}
			}
		}
	}

	private setParent(node: Node) {
		this.clearChild();
		if (this.parentCtrl.value) {
			this.value = this.parentCtrl.value;
			this.id = node.id;
			this.axonInternalId = node.axonInternalId;
		}
		this.childList = this.getChildList(node.value);
		this.change.emit(this.value);
	}

	private setChild(node: Node) {
		this.clearGrandChild();
		if (this.parentCtrl.value && this.childCtrl.value) {
			this.value = this.parentCtrl.value + "/" + this.childCtrl.value;
			this.id = node.id;
			this.axonInternalId = node.axonInternalId;
		}
		this.grandChildList = this.getGrandChildList(node.value);
		this.change.emit(this.value);
	}

	private setGrandChild(node: Node) {
		this.clearGreatGrandChild();

		let valueSet = false;
		if (this.parentCtrl.value && this.childCtrl.value && this.grandChildCtrl.value) {
			this.value = this.parentCtrl.value + "/" + this.childCtrl.value + "/" + this.grandChildCtrl.value;
			this.id = node.id;
			this.axonInternalId = node.axonInternalId;
			valueSet = true;
		}
		this.greatGrandChildList = this.getGreatGrandChildList(node.value);
		if (!this.greatGrandChildList && valueSet) {
			this.fullySelected = true;
		}
		this.change.emit(this.value);
	}

	private setGreatGrandChild(node: Node) {
		if (this.parentCtrl.value && this.childCtrl.value && this.grandChildCtrl.value && this.formCtrl.value) {
			this.value = this.parentCtrl.value + "/" + this.childCtrl.value + "/" + this.grandChildCtrl.value + "/" + this.formCtrl.value;
			this.id = node.id;
			this.axonInternalId = node.axonInternalId;

			this.fullySelected = true;
		}
		this.change.emit(this.value);
	}

	private clearChild() {
		this.childList = null;
		this.childCtrl.reset();
		this.clearGrandChild();
	}

	private clearGrandChild() {
		this.grandChildList = null;
		this.grandChildCtrl.reset();
		this.clearGreatGrandChild();
	}

	private clearGreatGrandChild() {
		this.greatGrandChildList = null;
		this.formCtrl.reset();
	}

	public getValue(): string {
		return this.value;
	}

	public getIdValue(): number {
		return this.axonInternalId;
	}

	public setValue(value: string) {
		const valueArr = value.split("/");

		if (valueArr[0]) {
			this.parentCtrl.setValue(valueArr[0]);
			this.setParent(
				{
					id: 0,
					value: valueArr[0],
					children: null,
					axonInternalId: 0
				}
			);
		}

		if (valueArr[1]) {
			this.childCtrl.setValue(valueArr[1]);
			this.setChild(
				{
					id: 0,
					value: valueArr[1],
					children: null,
					axonInternalId: 0
				}
			);
		}

		if (valueArr[2]) {
			this.grandChildCtrl.setValue(valueArr[2]);
			this.setGrandChild(
				{
					id: 0,
					value: valueArr[2],
					children: null,
					axonInternalId: 0
				}
			);
		}

		if (valueArr[3]) {
			this.formCtrl.setValue(valueArr[3]);
			this.setGreatGrandChild(
				{
					id: 0,
					value: valueArr[3],
					children: null,
					axonInternalId: 0
				}
			);
		}
	}

	/**
	 * If the field's param has RETURNVAL set to ID, then during edit, the field will have a numerical
	 * value, being the lowest ID of the user's previous selection. This ID will be sent to the server,
	 * stored, and returned. However, the multi tier component uses the string value in format:
	 * first_selection/second_selection/third_selection to auto select the list, so this function
	 * returns the string representation of the selection based on the given ID.
	 * @param id
	 */
	public setValueById(axonInternalId: number) {
		for (const node of this.parentList) {
			if (axonInternalId === node.axonInternalId) {
				this.setValue(node.value);
				break;
			}

			let val = this.findChildId(axonInternalId, node, node.children);
			if (val) {
				val = node.value + "/" + val;
				this.setValue(val);
				break;
			}
		}
	}

	/**
	 * Used to recursively find the value by digging into the node's children
	 */
	private findChildId(axonInternalId: number, root: Node, children: Array<Node>): string {
		if (!children) {
			return;
		}

		for (const node of children) {
			if (node.axonInternalId === axonInternalId) {
				return node.value;
			} else {
				const ret = this.findChildId(axonInternalId, node, node.children);
				if (ret) {
					return node.value + "/" + ret;
				}
			}
		}
	}

	isFullySelected() {
		return this.fullySelected;
	}

}
