import { Component } from '@angular/core';
import { MatDialog } from '@angular/material';

import { ModalContainerComponent } from './modal-container.component';
import { Storage } from '../../utils/storage';
import { Customer } from '../../dto/dtos';

export abstract class CustomerModalContainerComponent extends ModalContainerComponent {

    constructor(
            public dialog: MatDialog,
            public storage: Storage) {

        super( dialog, storage );
    }

    getApprovalClass(customer: Customer) {
        if ( customer ) {
            if ( customer.approvalStatus === 'APPROVED' || customer.approvalStatus === 'APPROVED_AUTO' ) {
                return 'approved';
            } else if ( customer.approvalStatus === 'REJECTED' || customer.approvalStatus === 'QUARANTINE' ) {
                return 'rejected';
            }

            return 'open';
        }
    }
}
