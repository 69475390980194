import { DynamicFieldActionCondition } from "../../../dto/dtos";
import { Operand } from "../../../utils/constants";
import { Field } from "./dynamic-action.service";
import { AxonUtils } from "../../../utils/axon-utils";
import * as moment from 'moment';

/* NOTE: See https://axonwireless.atlassian.net/wiki/spaces/DAT/pages/183402497/Dynamic+Field+Action for more info */

export class DynamicCondition {

    public testCondition( c: DynamicFieldActionCondition, testField: Field ): boolean {

        const operand = this.getOperand( c.operand );

        if ( operand === undefined || operand === null ) {
            throw new Error( 'Operand invalid or null' );
        }

        let conditionValue: any;
        if ( c.isStandardValue() ) {
            conditionValue = c.value;

        } else if ( c.isGrouplistValue() ) {
            throw new Error( 'TODO - Need value from grouplists' );
        } else if ( operand !== Operand.IS_NULL && operand !== Operand.IS_NOT_NULL ) {
            /*
            Only if the operand is NOT an IS NULL or IS NOT NULL then we need to throw an error.
            This is the only time the value can be null.
            */
            throw new Error( "An improper value has been defined for condition with ID [" + c.conditionId + "]" );
        } else {
            conditionValue = null;
        }

        return this.testValue( operand, testField.value, conditionValue );
    }

    private testValue( operand: Operand, value: any, conditionValue: any ): boolean {

        if ( conditionValue === null ) {

            // console.log( 'Testing null' );
            switch ( operand ) {
                case Operand.IS_NOT_NULL:

					if ( !value ) {
						return true;
					}
					return false;

                case Operand.IS_NULL:

                    if ( value ) {
						return false;
					}
					return true;

                default:
                    throw new Error( "Condition value is null, but operand is [" + operand.toString() + "]. " +
                    "Condition value can only be null in [" + Operand.IS_NOT_NULL.toString() + "] and" +
                    "[" + Operand.IS_NULL.toString() + "] cases." );
            }
		} else if ( value instanceof Date || value instanceof moment ) {

			if ( value instanceof moment ) {
				value = moment(value).toDate();
			}

			// console.log( 'Testing date', value );

			/* 
			For dates, the condition value could be a fixed point in time
			or it could be a numerical value i.e. such as years. So 
			cannot be strict on the type of conditionValue
			*/

			return this.testDateValue( operand, <Date> value, conditionValue );
        } else if ( typeof value === 'string' ) {

            // console.log( 'Testing string' );

            if ( ! ( typeof conditionValue === 'string' ) ) {
                throw new Error( "Condition value [" + conditionValue + "] is not of type String while value [" + value + "] is of type String.");
            }

            return this.testStringValue( operand, <string> value, <string> conditionValue );

        } else if ( typeof value === 'number' ) {

            // console.log( 'Testing number' );

            if ( ! ( typeof conditionValue === 'number' ) ) {
                throw new Error( "Condition value is not of type Number while value is of type Number.");
            }

            return this.testNumericValue( operand, <number> value, <number> conditionValue );

        } else if ( typeof value === 'boolean' ) {

            // console.log( 'Testing boolean' );

            switch ( conditionValue ) {
                case 'false':
                    conditionValue = false;
                    break;
                case 'true':
                    conditionValue = true;
                    break;
                default:
                    throw new Error( "Condition value [" + conditionValue + "] for boolean type is invalid. " + 
                        "Only 'true' or 'false' allowed.");
            }

            return conditionValue === value;

        } else {
            // console.log( 'Testing nothing' );
        }

        return false;

    }

    /**
     * Tests the String field value against the String conditionValue using the given operand
     * @param operand
     * @param value
     * @param conditionValue
     * @return true if the condition is satisfied
     */
    private testStringValue( operand: Operand, value: string, conditionValue: string ): boolean {
        
        switch ( operand ) {
            case Operand.IN:
                
                const conditions = conditionValue.split(";");

                for ( const condition of conditions ) {
                    if ( value === condition ) {
                        return true;
                    }
                }
                
                return false;

            case Operand.EQUAL:

                /* Direct match */
                return value === conditionValue;

            case Operand.NOT_EQUAL:

                /* Direct match */
                return value !== conditionValue;

            case Operand.GREATER:

                /* 
                Doing a greater match on String does not make a whole
                lot of sense, so just base it on the lengths
                */
                return value.length > conditionValue.length;

            case Operand.LESS:

                /* 
                Doing a less match on String does not make a whole
                lot of sense, so just base it on the lengths
                */
                return value.length < conditionValue.length;

            case Operand.GREATER_AGE:
            case Operand.LESS_AGE:
            case Operand.GREATER_DATE:
            case Operand.LESS_DATE:

                /* Not applicable for Strings */
                return false;
        }
        
        return false;
    }

    /**
     * Tests the number field value against the number conditionValue using the given operand
     * @param operand
     * @param value
     * @param conditionValue
     * @return true if the condition is satisfied
     */
    private testNumericValue( operand: Operand, value: number, conditionValue: number ): boolean {
        
        switch ( operand ) {
            case Operand.IN:
                /* Not applicable for Numerics */
                return false;

            case Operand.EQUAL:

                /* Direct match */
                return value === conditionValue;

            case Operand.NOT_EQUAL:

                /* Direct match */
                return value !== conditionValue;

            case Operand.GREATER:

                /* Value greater than conditionValue */
                return value > conditionValue;

            case Operand.LESS:

                /* Value less than conditionValue */
                return value < conditionValue;

            case Operand.GREATER_AGE:
            case Operand.LESS_AGE:
            case Operand.GREATER_DATE:
            case Operand.LESS_DATE:

                /* Not applicable for Numerics */
                return false;
        }
        
        return false;
    }

    /**
     * Tests the Long field value against the Long conditionValue using the given operand.
     * 
     * Date comparisions on purely on the date. Any time portions are set to zero before comparison.
     * 
     * If the conditionValue is set then it would be a fixed point in time. If it's null,
     * it is assumed the conditionValue must be the current date / time.
     * 
     * @param operand
     * @param value
     * @param conditionValue
     * @return true if the condition is satisfied
     */
    private testDateValue( operand: Operand, value: Date, conditionValue: any ): boolean {
        		
        const noTimeValue = AxonUtils.getDate( value );
        
        /* 
        The condition value might be a fixed point in time. If not, then
        the condition value must be set to the current date / time.
        */
        if ( conditionValue == null ) {
//            LOG.info( "Condition value Datetime is not set. Setting condition value to current date / time" );
            conditionValue = new Date();
        }
        
        /*
        If conditionValue is of type date, then following operands are supported
        EQUAL
        NOT_EQUAL
        GREATER
        LESS
        GREATER_DATE
        LESS_DATE
        
        Otherwise, if it's an int, the case for the Operand will handle the casting
        of conditionValue
        */
        let noTimeCondValue = null;
        if ( conditionValue instanceof Date ) {
            
            noTimeCondValue = AxonUtils.getDate( <Date> conditionValue );
            
        } else if ( conditionValue instanceof String || conditionValue.constructor.name === 'String') {
            
            /* Although it's sent as a String, it could be an Integer. Test this */
            conditionValue = Number( conditionValue );

            /* If it's not a number, then 'carry on' will be true. i.e. Assume it's a String representation of a date i.e. 03/06/1986 */
            const carryOn = isNaN( conditionValue );
            
            if ( carryOn ) {
            
                const d = new Date(Date.parse( conditionValue ) );

                noTimeCondValue = AxonUtils.getDate( d );
            }
            
        } else if ( ! ( conditionValue instanceof Number ) ) {
            throw new Error( "Condition value can only be of type Date / String / Number for Date comparisions" );
        }
        
        
        switch ( operand ) {
            case Operand.IN:

                /* Not applicable for Dates */
                return false;

            case Operand.EQUAL:
                
                if ( noTimeCondValue == null ) {
                    throw new Error( "Condition Value must be of type Date when performing an [" + operand + "] match" );
                }
                
                /* Direct match - excluding time portion */
                return ( new Date( Date.parse(noTimeValue) ).getTime() === ( new Date( Date.parse(noTimeCondValue) ).getTime() ) ); 
            
            case Operand.NOT_EQUAL:

                if ( noTimeCondValue == null ) {
                    throw new Error( "Condition Value must be of type Date when performing an [" + operand + "] match" );
                }
                
                /* Direct match - excluding time portion */        
                return ( new Date( Date.parse(noTimeValue) ).getTime() !== ( new Date( Date.parse(noTimeCondValue) ).getTime() ) ); 
            
            case Operand.GREATER:
            case Operand.GREATER_DATE:

                if ( noTimeCondValue == null ) {
                    throw new Error( "Condition Value must be of type Date when performing an [" + operand + "] match" );
                }
                
                /* Value greater than conditionValue */
                return ( new Date( Date.parse(noTimeValue) ).getTime() > ( new Date( Date.parse(noTimeCondValue) ).getTime() ) ); 

            case Operand.LESS:
            case Operand.LESS_DATE:
                
//                LOG.debug( "Test if [" + noTimeValue + "] is 'LESS THAN' [" + noTimeCondValue + "]" );

                if ( noTimeCondValue == null ) {
                    throw new Error( "Condition Value must be of type Date when performing an [" + operand + "] match" );
                }
                
                /* Value less than conditionValue */
                return ( new Date( Date.parse(noTimeValue) ).getTime() < ( new Date( Date.parse(noTimeCondValue) ).getTime() ) ); 

            case Operand.GREATER_AGE:
            case Operand.LESS_AGE:
                
                /* GREATER_AGE and LESS_AGE have similar errors / requirements to setup the tests */
                if ( !( conditionValue instanceof Number ) && ! (conditionValue.constructor.name === 'Number' ) ) {
                    throw new Error( "Condition Value must be of type Number when performing an [" + operand + "] match" );
                }
                
				let years = moment().diff(moment(noTimeValue), 'year', true);

                if ( operand === Operand.GREATER_AGE ) {
                    return years > conditionValue;
                } else if ( operand === Operand.LESS_AGE ) {
                    return years < conditionValue;
                }
                return false;
        }
        
        return false;
    }

    private getOperand( operandStr: string): Operand {

        return Operand[operandStr];

    }

}