import { Component, Input } from '@angular/core';
import { Image } from '../../dto/dtos';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AxonComponent } from '../../axon.component';
import { CustomerService } from '../../services/customers/customer.service';

export interface DialogData {
	title: string;
	input?: string;
	negative?: string;
	positive?: string;
}

@Component({
	selector: 'app-original-image-dialog',
	templateUrl: './original-image-dialog.component.html',
	styleUrls: ['./original-image-dialog.component.scss']
})

export class OriginalImageDialogComponent extends AxonComponent {
	@Input('viewingImage') viewingImage: Image;
	@Input('customer') customer: Image;

	constructor(public activeModal: NgbActiveModal, private customerService: CustomerService) {
		super();
	}

	close() {
		this.activeModal.close();
	}
	addRotation(degrees: number) {
		let rotation = this.viewingImage.rotation;
		if (!rotation) {
			rotation = 0;
		}
		rotation += degrees;
		rotation = rotation % 360;
		this.viewingImage.rotation = rotation;
		this.customerService.rotateImage(this.viewingImage.id, degrees).subscribe(response => {
			this.viewingImage.thumbImageUrl = response.data.thumbImageUrl;
			this.viewingImage.imageUrl = response.data.imageUrl;
			this.viewingImage.rotation = response.data.rotation;
			this.viewingImage.hashCode = response.data.hashcode;

			if (this.customer && this.viewingImage.type === 'customer_portrait') {
				console.log('Changing customer image url', this.viewingImage);
				this.customer.imageUrl = this.viewingImage.imageUrl;
				this.customer.thumbImageUrl = this.viewingImage.thumbImageUrl;
				this.customer.hashCode = this.customer.hashCode + 1;
			}
		});
	}
	get imageUrl() {
		let out = this.viewingImage.imageUrl;
		let seperator = out.indexOf('?') === -1 ? '?' : '&';

		if (out.indexOf("width") === -1) {
			out += seperator + "width=" + Math.round(window.innerWidth);
			seperator = "&";
    	}
		if(out.indexOf('max-width') === -1){
			out += seperator + 'max-width=' + Math.round(window.innerWidth * 0.8);
			seperator = '&';
		}
		if(out.indexOf('max-height') === -1){
			out += seperator + 'max-height=' + Math.round(window.innerHeight * 0.75);
			seperator = '&';
		}
		// out += seperator + 'height=2500';
		// seperator = '&';
		return out;
	}
	get fullScreenImageUrl() {
		let out = this.viewingImage.imageUrl;
		if (out.indexOf("?") !== -1) {
			out = out.substring(0, out.indexOf("?"));
		}
		return out + "?width=" + window.outerHeight;
	}
}
