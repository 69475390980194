import { AxonComponent } from "../../axon.component";
import { OnInit, AfterViewInit, OnDestroy, Component, Input } from "@angular/core";
import { ImageService } from "../../services/images/images.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ImageViewerComponent } from "./image-viewer.component";
import { tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { DataResponse, Image } from "../../dto/dtos";


/**
 * Class to image data.
 * Used for cache hit/miss - as a hit might not yet have image data.
 * Should the image not have been loaded within 30 seconds, this entry will expire allowing the browser to potencially refetch the image.
 */
class ImageCacheValue {
	data: string;
	createdAt: Date;
	constructor() {
		this.createdAt = new Date();
	}
	get isExpired() {
		if (this.data !== null) {
			return false;
		}
		return (this.createdAt.getTime() + (1000 * 30)) < new Date().getTime();
	}
}

@Component({
	selector: 'app-multiscan-viewer',
	templateUrl: './multiscan-viewer.component.html',
	styleUrls: ['./multiscan-viewer.component.scss']
})
export class MultiscanViewerComponent extends AxonComponent implements OnInit, OnDestroy {
    
    @Input() axonId: number;

    private imageId: number
    private fetchThumbnails: boolean;
    private maxDisplayWidth: number;
    private maxDisplayHeight: number;
    private multiscanImages: Array<Image>;
    imageCache: Map<string, ImageCacheValue> = new Map();
    isLoadingResults = false;


    constructor(private _imageService: ImageService, private _modalService: NgbModal) {
        super();
        // super(_imageService, _modalService);
	}

    getImage() {

        this.maxDisplayWidth = 150;
        this.maxDisplayHeight = 150;        
        const key = this.maxDisplayWidth + ":" + this.maxDisplayHeight + ":" + this.imageId;

        const out = this.imageCache.get(key);
		if (out !== undefined && out !== null) {
			if (!out.isExpired) {
				console.log("Image Cache Lookup.", key, "HIT");
				return out.data;
			}
			console.log("Image Cache Lookup.", key, "Expired");
		} else {
			this.imageCache.set(key, new ImageCacheValue());
		}

        console.log("Image Cache Lookup.", key, out);
        
        this.fetchThumbnails = this.multiscanImages = null;

        this._imageService.getImage(
            this.axonId, this.imageId, this.fetchThumbnails, this.maxDisplayWidth, this.maxDisplayHeight
        ).pipe(
            tap(data => console.log("Loaded data", data))
        ).subscribe(
            result => {
                const value = new ImageCacheValue();
                value.data = <string>this.sanitise(result.data.imageUrl);
                this.multiscanImages = (<Image>result.data).multiscanImages;
                this.imageCache.set(key, value);
                this.isLoadingResults = false;
            }
        );


    }
    
    ngOnInit(): void {
        
    }    
    
    ngOnDestroy(): void {
        
    }



    

}