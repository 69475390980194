import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InjectionToken, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NotifierModule } from 'angular-notifier';
import { AppRoutingModule } from './app-routing.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { SIDEBAR_TOGGLE_DIRECTIVES } from './shared/sidebar.directive';
import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { RightSidebarComponent } from './shared/right-sidebar/rightsidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';
import { Exporter } from './shared/file-exporter/exporter.component';

import { AgentFormService } from './shared/agent.service';

import { customNotifierOptions } from './utils/constants';
import { DatePickerModule } from './component/dateadapter/custom-date-adapter';
import { Crypto } from './utils/crypto';
import { TokenAndVersionInterceptor, TimeoutInterceptor, DEFAULT_TIMEOUT } from './services/http/http-abstract-service';
import { AxonSharedModule } from './axon-shared.module';
import { ReportComponent } from './report/report.component';
import { OfflineDialogComponent } from './component/offline-dialog/offline-dialog.component';
import { ReportTypeComponent } from './report/report-list.component';
import { MatProgressButtonsModule } from 'mat-progress-buttons';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProfileDynamicCardComponent } from './agents/profile/profile-dynamic-card.component';
import { ProgressSpinnerModule } from './component/overlay-spinner/spinner/progress-spinner.module';
import { MatFormFieldModule, MatProgressSpinnerModule, MAT_DATE_LOCALE, MatRadioModule } from '@angular/material';
import { environment } from '../environments/environment';
import { DmsModule } from './dms/dms.module';

export const TIME_ZONE = new InjectionToken<string>('TIME_ZONE');

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    BlankComponent,
	OfflineDialogComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    RightSidebarComponent,
    Exporter,
	SIDEBAR_TOGGLE_DIRECTIVES,
    ReportComponent,
    ReportTypeComponent,
    ProfileDynamicCardComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    ReactiveFormsModule,
    MatFormFieldModule,
    MatRadioModule,
    MatTooltipModule,
    HttpClientModule,
    AuthenticationModule,
    AppRoutingModule,
    NgbModule.forRoot(),
    NotifierModule.withConfig(customNotifierOptions),
    // AxonMaterialModule,
    DatePickerModule,
	AxonSharedModule,
    MatProgressButtonsModule.forRoot(),
    MatTooltipModule,
    MatProgressSpinnerModule,
    ProgressSpinnerModule
  ],
  providers: [
      AgentFormService,
      Crypto,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenAndVersionInterceptor,
        multi: true
      },
      [{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }],
      [{ provide: DEFAULT_TIMEOUT, useValue: 120000 }],
      { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
	  { provide: TIME_ZONE, useValue: environment.timezone }
    ],
  bootstrap: [AppComponent],
  entryComponents: [
	OfflineDialogComponent,
    Exporter
  ]
})
export class AppModule { }
