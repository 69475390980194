import { FormControl } from '@angular/forms';
import { DynamicField } from '../../../dto/dtos';
import { AgentService } from '../../../services/agents/agent.service';
import { switchMap } from 'rxjs/operators';
import { TimerObservable } from 'rxjs/observable/TimerObservable';

export class UniqueFieldValidator {

    static createValidator(dynamicField: DynamicField, agentService: AgentService, identifierNum?: number) {

        const dynamicFieldCopy = new DynamicField(dynamicField);
        dynamicFieldCopy.formControl = null;

        return (control: FormControl) => {

          return TimerObservable.create(500).pipe(
            switchMap(() => {

                return agentService.validateUnique(dynamicFieldCopy, control.value, identifierNum).map( response => {
                    if (response.success === true && response.error === undefined) {
						// const nativeElement = (<any> control).nativeElement;
						// setTimeout(() => {
						// 	control.markAsTouched();
						// 	if ( nativeElement !== undefined ) {
						// 		nativeElement.blur();
						// 	}
						// });
                        return null;
                    } else {
                        console.error("returning error for field");
                        return { uniqueness: true };
                    }
                });
            })
          );
        };
    }
}
