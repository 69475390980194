import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, OnChanges, OnDestroy } from '@angular/core';
import { Agent } from '../../../dto/dtos';
import { AxonComponent } from '../../../axon.component';
import { MaterialTableComponent, FilterOption } from '../../../component/material-table/material-table.component';
import { AgentAuditTrailTableData } from '../../../component/material-table/table-data/agents/agent-audit-trail-table-data';
import { SystemService } from '../../../services/system/system.service';
import { Notifier } from '../../../utils/notifier';

@Component({
	selector: 'app-agent-audit-trail',
	templateUrl: './agent-audit-trail.component.html',
	styleUrls: ['./agent-audit-trail.component.scss']
})
export class AgentAuditTrailComponent extends AxonComponent implements OnInit, OnDestroy, OnChanges {
	@Input() agent: Agent;

	@ViewChild(MaterialTableComponent) child;
	tableData: AgentAuditTrailTableData;

	public instance: AgentAuditTrailComponent;

	columns = [
		{ columnDef: 'id', header: 'Audit ID', cell: (element: any) => `${element.id}` },
		{ columnDef: 'datetime', header: 'Date', cell: (element: any) => `${element.datetime}`, isDate: true },

		{ columnDef: 'agentId', header: 'Agent ID', cell: (element: any) => `${element.agentId}` },
		
		{ columnDef: 'agentUsername', header: 'Agent Username', cell: (element: any) => `${element.agentUsername}` },
		{ columnDef: 'agentFirstName', header: 'Agent Name', cell: (element: any) => `${element.agentFirstName}` },
		{ columnDef: 'agentMiddleName', header: 'Agent Middle', cell: (element: any) => `${element.agentMiddleName}` },
		{ columnDef: 'agentLastName', header: 'Agent Last', cell: (element: any) => `${element.agentLastName}` },

		{ columnDef: 'toAgentId', header: 'To Agent ID', cell: (element: any) => `${this.getToAgentId(element.toAgentId)}` },
		{ columnDef: 'toAgentFirstName', header: 'To Agent Name', cell: (element: any) => `${element.toAgentFirstName}` },
		{ columnDef: 'toAgentMiddleName', header: 'To Agent Middle', cell: (element: any) => `${element.toAgentMiddleName}` },
		{ columnDef: 'toAgentLastName', header: 'To Agent Last', cell: (element: any) => `${element.toAgentLastName}` },
		{ columnDef: 'action', header: 'Action', cell: (element: any) => `${element.action}` },
		{ columnDef: 'details', header: 'Details', cell: (element: any) => `${element.details}` },
	];


	filters: FilterOption[] = [
		{ order: 1, filter: 'Audit ID', type: 'text' },
		{ order: 2, filter: 'Agent ID', type: 'text' },
		{ order: 2, filter: 'Agent Username', type: 'text' },
		{ order: 3, filter: 'Agent Name', type: 'text' },
		{ order: 4, filter: 'Action Name', type: 'text' },
		{ order: 5, filter: 'Date', type: 'dateRange' }
	];

	constructor(
		public cdRef: ChangeDetectorRef,
		public systemsService: SystemService,
		public notifier: Notifier) {
		super();
		this.instance = this;
	}

	loadData() {
		this.tableData = new AgentAuditTrailTableData(this.agent.agentId, this.systemsService);
	}

	ngOnInit() {
		console.log("Neil:: init", this.constructor.name);
		this.loadData();
	}
	ngOnDestroy() {
		console.log("Neil:: destroy", this.constructor.name);
	}
	ngOnChanges() {
		console.log("Neil:: change", this.constructor.name);

	}

	getAgentFullName(fullname: string): string {

		const s: string = fullname.replace(/[{()}]/g, '');
		if (s.trim() === '') {
			return '';
		}

		return fullname;
	}

	getToAgentId(toAgentId: any) {
		if (toAgentId > 0) {
			return toAgentId;
		}

		return '';
	}
}
