import { Component, OnInit, Input, ViewChild, OnChanges, OnDestroy } from '@angular/core';
import { Agent, AxonModel } from '../../../dto/dtos';
import { MaterialTableComponent, FilterOption } from '../../../component/material-table/material-table.component';
import { AxonModal } from '../../../component/modal-container/modal-container.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AxonComponent } from '../../../axon.component';
import { AgentApprovalHistoryTableData } from '../../../component/material-table/table-data/agents/agent-approval-history-table-data';
import { AgentModalComponent } from '../agent-modal.component';
import { AgentService } from '../../../services/agents/agent.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-agent-approval-history',
	templateUrl: './agent-approval-history.component.html',
	styleUrls: ['./agent-approval-history.component.scss']
})
export class AgentApprovalHistoryComponent extends AxonComponent implements OnInit, OnDestroy {

    @Input() agent: Agent;
    public instance: AgentApprovalHistoryComponent;

    tableData: AgentApprovalHistoryTableData;

    @ViewChild(MaterialTableComponent) child: MaterialTableComponent;

    routeParamsSubscription: Subscription;

    checkedOrgs: AxonModel[];

    columns = [
        // { columnDef: 'checkbox'     , header: ''                , cell: (element: any) => `${element.selected}`     , isCheckbox: true 		},
        { columnDef: 'customerType'   , header: 'Customer Type'   , cell: (element: any) => `${element.customerType}`   ,},
        { columnDef: 'msisdnList'     , header: 'MSISDN List'     , cell: (element: any) => `${ (element.msisdnList !== undefined) ? element.msisdnList.split(/[,]+/).join(', ') : ""}`, isMsisdnList: true },
        { columnDef: 'createdBy'      , header: 'Created By'      , cell: (element: any) => `${element.createdBy}`      ,},
        { columnDef: 'createdDate'    , header: 'Created Date'    , cell: (element: any) => `${element.createdDate}`    , isDate: true },
        { columnDef: 'updatedDate'    , header: 'Updated Date'    , cell: (element: any) => `${element.updatedDate}`    , isDate: true },
        { columnDef: 'province'       , header: 'Province'        , cell: (element: any) => `${element.province}`       ,},
        { columnDef: 'corporateName'  , header: 'Corporate Name'  , cell: (element: any) => `${element.corporateName}`  ,},
        { columnDef: 'corporateId'    , header: 'Corporate ID'    , cell: (element: any) => `${element.corporateId}`    ,},
        { columnDef: 'previousStatus' , header: 'Previous Status' , cell: (element: any) => `${element.previousStatus}` , isApprovalStatus: true },
        { columnDef: 'currentStatus'  , header: 'Current Status'  , cell: (element: any) => `${element.currentStatus}`  , isApprovalStatus: true },
        { columnDef: 'comments'       , header: 'Comments'        , cell: (element: any) => `${this.determineComment(element)}`,},
        { columnDef: 'buttons'        , header: ''                , cell: (element: any) => `${element.id}`             ,},
    ];

    //MSISDN, customer name, date ranges 1 day, 7 Days, 30 days and approval status
    filters: FilterOption[] = [
        { order: 0, filter: 'MSISDN List'			, type: 'text' },
        { order: 1, filter: 'Created By'		  , type: 'text' },
        { order: 2, filter: 'Created Date'	  , type: 'dateRange' },
        { order: 1, filter: 'Updated By'		  , type: 'text' },
        { order: 2, filter: 'Updated Date'	  , type: 'dateRange' },
        { order: 3, filter: 'Current Status'	, type: 'agentApprovalHistoryStatus' },
    ];

    constructor(
      private agentService: AgentService,
      private router: Router,
      private route: ActivatedRoute) {
        super();
        this.instance = this;
    }

    ngOnInit(): void {
		console.log("Neil:: init", this.constructor.name);
		// this.hideGroupActions = true;
		this.loadData();
    }

    loadData() {
        /* Allow us to open individual org modal from other components (add org provides a link in it's success notification) */
        /* Also allows for showing selected orgs as the table data (without using filters) */
        this.routeParamsSubscription = this.route.params.subscribe(params => {
            if (params['data'] !== undefined) {
                this.fetchAndDisplayOrg( params['data'] );
            } else if ( params.agentId ) {
                this.fetchAndDisplayOrg( params.orgId );
            }
        });

        this.tableData = new AgentApprovalHistoryTableData(this.agent.agentId, this.agentService);
    }

    /**
     * Fetches org from the server, and displays a modal if the fetch was successful
     * @param orgId
     */
    private fetchAndDisplayOrg(orgId: number) {
        // this.agentService.getOrg(orgId).subscribe(resp => {
        //     console.log('Org fetch successful ? [' + resp.success + ']');
        //     if ( resp.success ) {
        //         /* We can call open modal in MaterialTableComponent with our single added org */
        //         this.child.onCreate(resp.data);
        //     }
        // });
    }

    setCheckboxState(selectedCheckboxes: AxonModel[]) {
        // this.checkedOrgs = selectedCheckboxes;
		    // this.hideGroupActions = (this.checkedOrgs.length === 0);
    }

    /* Return an array of all the org ids that have been checked */
    get orgList() {
        const orgList = [];
        this.checkedOrgs.forEach((org) => {
            orgList.push(org.elementId);
        });
        return orgList;
    }

    /**
     * A generic method to handle bulk actions
     * @param path eg. '/blockall'
     * @param pastTense eg 'blocked' or 'reset' so we can add it to notifier strings
     * @param singular eg 'block' or 'reset' so we can add it to notifier strings
     */
    async bulkAction(path, pastTense: string, singular: string) {

        // const modalRef = this.modalService.open(AgentActionModalComponent, { windowClass : "modal-confirm-action" });
        // modalRef.componentInstance.singular = singular;
        // modalRef.componentInstance.pastTense = pastTense;
        // modalRef.componentInstance.path = path;
        // modalRef.componentInstance.agentList = this.agentList;
        // modalRef.componentInstance.child = this.child;
    }

    refresh() {
        this.child.refreshData();
    }

    bulkActionByCSV(actionDescription: string) {
        // const modalRef = this.modalService.open(AgentsModalBulkBlockComponent, { windowClass : "modal-bulkblock" });
        // modalRef.componentInstance.action = actionDescription;
        // modalRef.componentInstance.parent = this;
    }

    addOrg() {
        // const nav = ['/organizations/add-org'];
        // this.router.navigate(nav);
    }

    bulkAddOrg() {
        // const nav = ['/organizations/bulk-add-org'];
        // this.router.navigate(nav);
    }

    ngOnDestroy() {
		console.log("Neil:: destroy", this.constructor.name);
        if ( this.routeParamsSubscription ) {
            this.routeParamsSubscription.unsubscribe();
        }
    }

    determineComment(element) {
        let comment = "";
        if (element.comments === null || element.comments === undefined) {
            if (element.approvedNote !== undefined) {
                comment = element.approvedNote;
            }
        } else {
            comment = element.comments;
        }

        return comment;
    }
}
