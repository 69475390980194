import { Injectable, Component, AfterViewInit } from "@angular/core";
import { AxonComponent } from "../../../axon.component";
import { DashBoardService } from "../../../services/dashboard/dashboard.service";
import { Chart, ChartRow } from "../../../dto/dtos";
import { FormControl } from "@angular/forms";

export interface ChartExample {
    title: string;
    type: string;
    data: Array<Array<any>>;
    columnNames: Array<string>;
    options: any;
}

@Injectable({
    providedIn: 'root'
})

@Component({
    // selector: 'app-users',
    templateUrl: './qr-generator-form.component.html',
    styleUrls: ['./qr-generator-form.component.scss'],
})
export class QRGeneratorFormComponent extends AxonComponent {

    data: string;

    fullNameCtrl: FormControl = new FormControl();
    firstNameCtrl: FormControl = new FormControl();
    lastNameCtrl: FormControl = new FormControl();
    birthDateCtrl: FormControl = new FormControl();
    issueDateCtrl: FormControl = new FormControl();
    departmentCtrl: FormControl = new FormControl();
    cityCtrl: FormControl = new FormControl();
    functionCtrl: FormControl = new FormControl();
    badgeTypeCtrl: FormControl = new FormControl();
    badgeNumberCtrl: FormControl = new FormControl();

    constructor() {
        super();

    }

    generate() {
        const jsonData = {
            fullName: this.fullNameCtrl.value,
            firstName: this.firstNameCtrl.value,
            lastName: this.lastNameCtrl.value,
            birthDate: this.birthDateCtrl.value,
            issueDate: this.issueDateCtrl.value,
            department: this.departmentCtrl.value,
            city: this.cityCtrl.value,
            function: this.functionCtrl.value,
            badgeType: this.badgeTypeCtrl.value,
            badgeNumber: this.badgeNumberCtrl.value,
        };

        this.data = JSON.stringify( jsonData );
    }

    
}