import { Agent } from './../../../dto/dtos';
import { Component, Injectable, Input, ViewChild } from "@angular/core";
import { Customer } from "../../../dto/dtos";
import { FilterOption, MaterialTableComponent } from "../../../component/material-table/material-table.component";
import { CustomerService } from "../../../services/customers/customer.service";
import { RegistrationHistoryTableData } from "../../../component/material-table/table-data/customers/registration-history-table-data";
import { CustomerModalHistoricComponent } from "./customer-modal-historic.component";
import { environment } from "../../../../environments/environment";
import { AxonUtils } from "../../../utils/axon-utils";

@Injectable({
	providedIn: 'root'
})

@Component({
	selector: 'app-reg-history',
	templateUrl: './registration-history.component.html',
	styleUrls: ['./registration-history.component.scss'],
})
export class RegistrationHistoryComponent {
    @Input() customer: Customer;

    private instance: RegistrationHistoryComponent;
    tableData: RegistrationHistoryTableData;

    @ViewChild(MaterialTableComponent) table: MaterialTableComponent;

    isLoading = true;
    isQueueing = false;
    pdfUrl;
    safePdfUrl;
    env = environment;
    pdf: Blob;

    columns = [
        // tslint:disable: max-line-length
        { columnDef: 'pinref'            , header: 'Pinref'        , cell: (element: Customer) => `${element.pinref}` },
        { columnDef: 'axonId'            , header: 'Axon ID'       , cell: (element: Customer) => `${element.axonId}` },
        { columnDef: 'submissionDatetime', header: 'Datetime'      , cell: (element: Customer) => `${element.submissionDatetime}`, isDateTime: true },
        { columnDef: 'name'              , header: 'First Name'    , cell: (element: Customer) => `${element.name}` },
        { columnDef: 'otherName'         , header: 'Middle Name'   , cell: (element: Customer) => `${element.otherName}` },
        { columnDef: 'surname'           , header: 'Last Name'     , cell: (element: Customer) => `${element.surname}` },
        { columnDef: 'id'                , header: 'ID'            , cell: (element: Customer) => `${element.id}` },
        { columnDef: 'idType'            , header: 'ID Type'       , cell: (element: Customer) => `${element.idType}` },
        { columnDef: 'approvalStatus'    , header: 'Status'        , cell: (element: Customer) => `${element.approvalStatus}`, isApprovalStatus: true },
        { columnDef: 'recordAction'      , header: 'Record Action' , cell: (element: Customer) => `${element.recordAction}` },
        { columnDef: 'recordType'        , header: 'Record Type'   , cell: (element: Customer) => `${element.recordType}` },
        { columnDef: 'channel'           , header: 'Channel'       , cell: (element: Customer) => this.getApp(element.submissionApp) },
        { columnDef: 'uploadStatus'      , header: 'Upload Status' , cell: (element: Customer) => `${element.uploadStatus}` },
        { columnDef: 'capAgent'          , header: 'Agent'         , cell: (element: Customer) => `${element.captureAgent ? AxonUtils.getAgentFullname(element.captureAgent) : ''}`},
        { columnDef: 'capAgentMsisdn'    , header: 'Agent MSISDN'  , cell: (element: Customer) => `${element.captureAgent ? element.captureAgent.msisdn : ''}` },
        { columnDef: 'msisdn'            , header: 'MSISDN'        , cell: (element: Customer) => `${element.msisdn}` },
        { columnDef: 'comment'           , header: 'Comment'       , cell: (element: Customer) => `${element.comment}`, isNoWrap: true },
        { columnDef: 'captureApp'        , header: 'Capture App'   , cell: (element: Customer) => this.getApp(element.captureApp) },
        { columnDef: 'pdf'               , header: 'Download PDF'  , cell: (element: Customer) => this.isDownloadPdf(element), isDownloadPdf: true }
    ];

    columnsNoSIM = [
        // tslint:disable: max-line-length
        { columnDef: 'pinref'            , header: 'Pinref'        , cell: (element: Customer) => `${element.pinref}` },
        { columnDef: 'axonId'            , header: 'Axon ID'       , cell: (element: Customer) => `${element.axonId}` },
        { columnDef: 'submissionDatetime', header: 'Datetime'      , cell: (element: Customer) => `${element.submissionDatetime}`, isDateTime: true },
        { columnDef: 'name'              , header: 'First Name'    , cell: (element: Customer) => `${element.name}` },
        { columnDef: 'otherName'         , header: 'Middle Name'   , cell: (element: Customer) => `${element.otherName}` },
        { columnDef: 'surname'           , header: 'Last Name'     , cell: (element: Customer) => `${element.surname}` },
        { columnDef: 'id'                , header: 'ID'            , cell: (element: Customer) => `${element.id}` },
        { columnDef: 'idType'            , header: 'ID Type'       , cell: (element: Customer) => `${element.idType}` },
        { columnDef: 'approvalStatus'    , header: 'Status'        , cell: (element: Customer) => `${element.approvalStatus}`, isApprovalStatus: true },
        { columnDef: 'recordAction'      , header: 'Record Action' , cell: (element: Customer) => `${element.recordAction}` },
        { columnDef: 'recordType'        , header: 'Record Type'   , cell: (element: Customer) => `${element.recordType}` },
        { columnDef: 'channel'           , header: 'Channel'       , cell: (element: Customer) => this.getApp(element.submissionApp) },
        { columnDef: 'uploadStatus'      , header: 'Upload Status' , cell: (element: Customer) => `${element.uploadStatus}` },
        { columnDef: 'capAgent'          , header: 'Agent'         , cell: (element: Customer) => `${element.captureAgent ? AxonUtils.getAgentFullname(element.captureAgent) : ''}`},
        { columnDef: 'capAgentMsisdn'    , header: 'Agent MSISDN'  , cell: (element: Customer) => `${element.captureAgent ? element.captureAgent.msisdn : ''}` },
        { columnDef: 'comment'           , header: 'Comment'       , cell: (element: Customer) => `${element.comment}`, isNoWrap: true },
        { columnDef: 'captureApp'        , header: 'Capture App'   , cell: (element: Customer) => this.getApp(element.captureApp) },
        { columnDef: 'pdf'               , header: 'Download PDF'  , cell: (element: Customer) => this.isDownloadPdf(element), isDownloadPdf: true }
    ];


    filters: FilterOption[] = [
        { order: 0, filter: 'Pinref'        , type: 'text' },
        { order: 1, filter: 'MSISDN'        , type: 'text' },
        { order: 2, filter: 'Agent Username', type: 'text' },
        { order: 3, filter: 'First Name'    , type: 'text' },
        { order: 4, filter: 'Middle Name'   , type: 'text' },
        { order: 5, filter: 'Last Name'     , type: 'text' }
    ];

    constructor(private customerService: CustomerService, private historicModal: CustomerModalHistoricComponent) {
        this.instance = this;
    }

    loadData() {
        this.tableData = new RegistrationHistoryTableData(this.customer.axonId, this.customerService);
        if ( this.table ) {
            this.table.refreshData();
        }
    }

    downloadPdf(pinref: number) {

        const mediaType = 'application/pdf';

        setTimeout(() => {
            this.customerService.getPDFFormFilledHistoric(pinref).subscribe(response => {

                this.pdf = new Blob([<any>response], { type: mediaType });
                console.log(this.pdf);

                this.pdfUrl = URL.createObjectURL(
                    this.pdf
                );
                this.safePdfUrl = AxonUtils.domSanitizer.bypassSecurityTrustResourceUrl(this.pdfUrl);

                this.isLoading = false;

                this.download();
            });
        }, 1000);
    }

    download() {
        /* Open the PDF in a new window */
        // window.open(this.pdfUrl, '_blank', undefined, true);
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(this.pdf);

        const date = new Date();
        const timestamp = date.getTime();

        a.href = url;
        a.download = "form_historic_" + timestamp + ".pdf";
        a.target = "_blank";
        a.textContent = "Download PDF";

        a.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));

        setTimeout(() => {
            window.URL.revokeObjectURL(url);
            a.remove();
        }, 150);
    }

    isDownloadPdf(customer: Customer) {
        if (customer.images === undefined || customer.images.length === 0) {
            return null;
        }
        return customer.pinref;
    }

    getApp(appChannel: string) {
        if (appChannel !== undefined) {
            appChannel = (appChannel === 'roid') ? 'Android' : appChannel;
            return appChannel[0].toUpperCase() + appChannel.slice(1);
        }
        return "";
    }

}
