import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import * as jwt from 'jsonwebtoken';
import { AxonComponent } from '../axon.component';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { Location } from '@angular/common';
import * as es6printJS from "print-js";
import { allowPreviousPlayerStylesMerge } from '@angular/animations/browser/src/util';


@Component({
	selector: 'app-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss']
})
export class ReportComponent extends AxonComponent implements OnInit, OnDestroy {
	subtitle: string;
    iframeUrl: SafeResourceUrl;

    queryParamsSubscription: Subscription;

    title: string;
    orgName: string;

    @ViewChild('iframeRef') iframeRef: ElementRef;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private _location: Location,
		) {
		super();
		this.subtitle = 'This is some text within a card block.';
		// this.routeParamsSubscription = this.route.params.subscribe( params => {
		// 	console.log('Params: ' + JSON.stringify( params ) );
		// 	this.initMetabase(parseInt(params.id, 10));
        // });
        this.queryParamsSubscription = this.route.queryParams.subscribe( queryParams => {
            console.log('Query Params: ' + JSON.stringify( queryParams ) );

            const reportId = parseInt(queryParams.id, 10);
            let orgId = null;
            if ( queryParams.org_id ) {
                orgId = parseInt(queryParams.org_id, 10);
            }

            if ( queryParams.title ) {
                this.title = queryParams.title;
            }

            if ( queryParams.org_name ) {
                this.orgName = queryParams.org_name;
            }

			this.initMetabase(reportId, orgId);
		});
  }

	ngOnInit() {
		// this.initMetabase();
    }

    ngOnDestroy() {
        if ( this.queryParamsSubscription ) {
            this.queryParamsSubscription.unsubscribe();
        }
    }

	private initMetabase(reportId: number, orgId: number) {

		/*
		NOTE: To make this shit work, you need to do some voodoo - which I am not sure how good or bad it is
		Edit this file: node_modules/@angular-devkit/build-angular/src/angular-cli-files/models/webpack-configs/browser.js
		at the bottom, where it says:
		node: false
		change it to:
		node: { crypto: true, stream: true, buffer: true },

		Info found from this link: https://github.com/auth0/node-jsonwebtoken/issues/471

		*/

		const METABASE_SITE_URL = environment.metabase_url; /* Axon Local Server */
		const METABASE_SECRET_KEY = environment.metabase_secret_key;

		const payload = {
			resource: { dashboard: reportId },
            params: {
                org_id: orgId,
            },
			exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
		};
		const token = jwt.sign(payload, METABASE_SECRET_KEY);

        console.log("Report Section Token [" + token + "]. Resource [" + JSON.stringify( payload.resource ) + "]. " +
            "Params [" + JSON.stringify( payload.params ) + "]. Exp [" + JSON.stringify( payload.exp ) + "]" );

		this.iframeUrl = this.sanitiseResource(METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=false&titled=false");
		console.log('URL: ' + this.iframeUrl);
		// this.iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=true&titled=true";
	}
	backClicked() {
		this._location.back();
	}

	scrapePrint() {
		es6printJS("iframe", "html");
	}
}

