
import { Observable } from 'rxjs';
import { DataResponse } from '../../../../dto/dtos';
import { TableData } from '../table-data';
import { Injectable } from '@angular/core';
import { FilterOption } from '../../material-table.component';
import { CustomerService } from '../../../../services/customers/customer.service';

/**
 * Overrides getTableData by fetching submission history table data
 */
export class SubmissionHistoryTableData extends TableData {

    private axonId: number;

    constructor(axonId: number, private customerService: CustomerService) {
        super(customerService);

        this.axonId = axonId;
    }

    getTableData(sort: string, order: string, page: number, pageSize: number, filterOptions: FilterOption[]): Observable<DataResponse> {
        return this.customerService.getCustomerSubmissions(this.axonId, page, pageSize, filterOptions);
    }
}
