import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { ModalContainerComponent } from '../modal-container/modal-container.component';
import { Storage } from '../../utils/storage';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

export interface DialogData {
	title: string;
	input?: string;
	negative?: string;
	positive?: string;
}

@Component({
	selector: 'app-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss']
})

export class ConfirmDialogComponent extends ModalContainerComponent implements OnInit {

	display = "";
	negative = 'Cancel';
	positive = 'Apply';

	instance: ConfirmDialogComponent;
	pinForm: FormGroup;

	// tslint:disable-next-line: max-line-length
	constructor(
		public formBuilder: FormBuilder,
		dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<ConfirmDialogComponent>,
		public storage: Storage) {
		super(dialog, storage);
		this.positive = (data.positive ? data.positive : 'Apply');
		this.negative = (data.negative ? data.negative : 'Cancel');
		this.pinForm = new FormGroup({
			pin: new FormControl()
		});
	}

	ngOnInit() {
		const seconds = this.data.pin.expiry / 1000;
		const now = new Date().getTime() / 1000;
		const minute = (seconds - now) / 60;
		this.timer(minute);
	}

	getType() {
		return ConfirmDialogComponent;
	}

	onNegative() {
		this.dialogRef.close(this.data);
		this.data.onNegative(this.data.caller);
	}

	onPositive() {

		if (this.data.pinProtected) {
			const pinData = atob(this.data.pin.encPin);
			if (this.pinForm.controls.pin.value !== pinData) {
				alert("Pin is not correct");
				return;
			}
		}

		this.dialogRef.close(this.data);
		this.data.onPositive(this.data);
	}

	timer(minute) {
		// let minute = 1;
		let seconds: number = minute * 60;
		let textSec: any = "0";
		let statSec: number = 60;

		const prefix = minute < 10 ? "0" : "";

		const timer = setInterval(() => {
			seconds--;
			if (statSec != 0) statSec--;
			else statSec = 59;

			if (statSec < 10) {
				textSec = "0" + statSec;
			} else textSec = statSec;

			this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

			if (seconds <= 0) {
				this.display = `-:-:-:-`
			}
		}, 1000);
	}
}