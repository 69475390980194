import { Directive, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appmatinput]'
})
export class NativeElementInjectorDirective {

    constructor (
      private el: ElementRef,
      private control: NgControl) {

        setTimeout(() => {
            (this.control.control as any).nativeElement = this.el.nativeElement;
        });
    }
}
